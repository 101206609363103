import { useForm } from "react-hook-form"

type formType = {
    number: string,
    firstName: string,
    lastName: string
}

const PlateReprintForm = () => {
    ///myaccount/registration/plate-reprint/upload-plate-number

 
    const {register, handleSubmit, formState: { errors } } = useForm<formType>()

    const submit = (data: formType) => {
        console.log(data)
    }
    

    return (
        <form action="" onSubmit={handleSubmit(submit)}>
            <div className="form-group mt-0">
                <label htmlFor="">First Name</label>
                <input type="text" className="form-control" placeholder="Benjamin" 
                {...register("firstName", { required: "First name can not be empty"})}
                />
                {errors.firstName && <span className='error'>{errors.firstName.message}</span>}
            </div>
            <div className="form-group">
                <label htmlFor="">Last Name</label>
                <input type="text" className="form-control" placeholder="Wood" 
                {...register("lastName", { required: "Last name can not be empty"})}
                />
                {errors.lastName && <span className='error'>{errors.lastName.message}</span>}
            </div>
            <div className="form-group">
                <label htmlFor="">Driver's Plate Number</label>
                <input type="text" className="form-control" placeholder="2134-AD32" 
                {...register("number", { required: "Plate number can not be empty"})}
                />
                {errors.number && <span className='error'>{errors.number.message}</span>}
            </div> 
            <div className="form-group">  
                <button className='btn btn-primary' type='submit'>Continue</button> 
            </div> 
        </form>
    )
}

export default PlateReprintForm
