import './UploadPlateDocumentAuth.scss'
import { UploadPlateDocumentElement } from 'Pages/Frontend/Components';

const UploadPlateDocumentAuth = () => {
    return ( <div className="UploadPlateDocumentAuth">
        
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-6 my-5">
                        <div className="card border-0">
                            <div className="card-header text-center border-0 pt-4"> 
                                <div className="title border-bottom text-start pb-3 fw-bold">
                                    Upload Documents
                                </div>
                            </div>
                            <UploadPlateDocumentElement module="user"></UploadPlateDocumentElement>
                        
                        </div>
                    </div>
                </div>
            </div>
    </div> );
}
 
export default UploadPlateDocumentAuth;