import React, { Key, useEffect, useState } from 'react'
import "./RegistrationOrder.scss"
import { Link } from 'react-router-dom';
import { useAuth } from 'Components/AuthProvider/AuthProvider';
// import { orderDataType } from 'Models';
// import { vehicleRegistrationService } from 'Services/vehicleRegistrationService';
import car from "Assets/Icons/car.svg"
import Swal from 'sweetalert2';
import { orderService } from 'Services/orderService';
import { orderType } from 'Models/orderType';
import { TimeConverter } from 'Helpers';
import RegistrationOrderSkeleton from './RegistrationOrderSkeleton'; 

const RegistrationOrder = () => {
    const limit = 100;
    const offset = 0;
 
    const { user } = useAuth()
      
    const [request, setRequest] = useState(false) 
    const [orderData, setOrderData] = useState<orderType[]>()

    const getOrder = async () =>{ 
        setRequest(true);
        await orderService.getOrder(limit, offset,  user.userProfile.id).then((response: { data: React.SetStateAction<orderType[] | undefined>; })=>{
            setRequest(false)
            console.log("order response 00", response.data)
            setOrderData(response.data)
        }, (error: { response: { data: { message: string; }; }; }) => {
            setRequest(false)
            console.log("error make", error);
            Swal.fire({
                position: "top-end",
                icon: "warning",
                text: error.response.data.message,
                showConfirmButton: false,
                timer: 3000
            });
        })
    }

    useEffect(() => {
        getOrder()
    }, [])

    return (
        <div className='RegistrationOrder'> 
            <div className="row mt-2">
                <div className="col-12 col-md-8">
                    <div  className='search-field'>
                        <span>
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.675 11.5661C9.34155 13.8995 5.55835 13.8995 3.22493 11.5661C0.891523 9.23269 0.891523 5.44948 3.22493 3.11607C5.55835 0.782656 9.34156 0.782656 11.675 3.11607C14.0084 5.44948 14.0084 9.23269 11.675 11.5661ZM11.675 11.5661L16.4126 16.3037" stroke="#868484" strokeWidth="1.5" strokeLinecap="round"/>
                            </svg>
                        </span>

                        <input type="text"  className='form-control' placeholder='Search order'/>
                    </div>
                </div>
                <div className="col-6 col-md-3 col-xl-2">
                    <button className='btn btn-filter'>
                    <svg width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.94 22.65C10.46 22.65 9.99002 22.53 9.55002 22.29C8.67002 21.8 8.14002 20.91 8.14002 19.91V14.61C8.14002 14.11 7.81002 13.36 7.50002 12.98L3.76002 9.02001C3.13002 8.39001 2.65002 7.31001 2.65002 6.50001V4.20001C2.65002 2.60001 3.86002 1.35001 5.40002 1.35001H18.6C20.12 1.35001 21.35 2.58001 21.35 4.10001V6.30001C21.35 7.35001 20.72 8.54001 20.13 9.13001L15.8 12.96C15.38 13.31 15.05 14.08 15.05 14.7V19C15.05 19.89 14.49 20.92 13.79 21.34L12.41 22.23C11.96 22.51 11.45 22.65 10.94 22.65ZM5.40002 2.85001C4.70002 2.85001 4.15002 3.44001 4.15002 4.20001V6.50001C4.15002 6.87001 4.45002 7.59001 4.83002 7.97001L8.64002 11.98C9.15002 12.61 9.65002 13.66 9.65002 14.6V19.9C9.65002 20.55 10.1 20.87 10.29 20.97C10.71 21.2 11.22 21.2 11.61 20.96L13 20.07C13.28 19.9 13.56 19.36 13.56 19V14.7C13.56 13.63 14.08 12.45 14.83 11.82L19.11 8.03001C19.45 7.69001 19.86 6.88001 19.86 6.29001V4.10001C19.86 3.41001 19.3 2.85001 18.61 2.85001H5.40002Z" fill="#868484"/>
                        <path d="M6.00004 10.75C5.86004 10.75 5.73004 10.71 5.60004 10.64C5.25004 10.42 5.14004 9.94999 5.36004 9.59999L10.29 1.69999C10.51 1.34999 10.97 1.23999 11.32 1.45999C11.67 1.67999 11.78 2.13999 11.56 2.48999L6.63004 10.39C6.49004 10.62 6.25004 10.75 6.00004 10.75Z" fill="#868484"/>
                    </svg> Filters</button>
                </div>
                <div className="col-6 col-md-3 col-xl-2 mt-md-3 mt-xl-0">
                    <button className='btn btn-export'>
                    <svg  width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9 11V17L11 15" stroke="#868484" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M9 17L7 15" stroke="#868484" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M22 10V15C22 20 20 22 15 22H9C4 22 2 20 2 15V9C2 4 4 2 9 2H14" stroke="#868484" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M22 10H18C15 10 14 9 14 6V2L22 10Z" stroke="#868484" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg> Export</button>
                </div>
            </div>


            <div className="container mt-3 pb-5">
                {request && <RegistrationOrderSkeleton></RegistrationOrderSkeleton>}

                <div className="row"> 
                    {orderData && orderData.map((order: any, index:Key)=>{
                     return <div key={index} className="col-12 col-sm-6 col-md-6 col-xl-4"> 
                        <Link to={`/myaccount/orders/registration/${order.id}`}><div className="card px-2 py-2 mt-2">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-2 text-center">
                                        <span className='icon'>
                                           <img src={car} alt=''></img>
                                        </span>
                                    </div>
                                    <div className="col-9">
                                        <div className="title">{order.service.name}</div>
                                        <div className="details">Reg {order.orderNo}, {TimeConverter(order.time)} </div>
                                    </div>
                                    {/* <div className="col-2">
                                        <button className='btn border-0 dropdown-toggle' data-bs-toggle="dropdown" aria-expanded="false" data-bs-popper-config='{"strategy":"fixed"}'>
                                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.49991 8.4705C7.49991 7.72933 8.0955 7.14697 8.82344 7.14697C9.55138 7.14697 10.147 7.74256 10.147 8.4705C10.147 9.19844 9.55138 9.79403 8.82344 9.79403C8.0955 9.79403 7.49991 9.21168 7.49991 8.4705Z" fill="#707070"/>
                                                <path d="M7.49991 14.1175C7.49991 13.3763 8.0955 12.7939 8.82344 12.7939C9.55138 12.7939 10.147 13.3895 10.147 14.1175C10.147 14.8454 9.55138 15.441 8.82344 15.441C8.0955 15.441 7.49991 14.8587 7.49991 14.1175Z" fill="#707070"/>
                                                <path d="M7.49991 2.82353C7.49991 2.08235 8.0955 1.5 8.82344 1.5C9.55138 1.5 10.147 2.09559 10.147 2.82353C10.147 3.55147 9.55138 4.14706 8.82344 4.14706C8.0955 4.14706 7.49991 3.56471 7.49991 2.82353Z" fill="#707070"/>
                                            </svg>

                                        </button>

                                        <ul className="dropdown-menu dropdown-menu-end export-dropdown border-0 shadow">
                                            <li className='dropdown-item'>
                                                <Link to={`/myaccount/orders/registration/${order.id}`}>View Details</Link>
                                            </li>    
                                        </ul> 

                                    </div> */}
                                </div> 
                            </div>
                            <div className="card-footer bg-white d-flex justify-content-center">
                                 <span className='status-warn'>Sourcing Vehicle</span>
                                 {/* <span className='status-success'>Delivered</span>
                                 <span className='status-info'>Delivered</span> */}
                            </div>
                        </div>
                        </Link>
                    </div>})}
                </div>



                {orderData?.length === 0 && <div className='emptyList'>
                    <div className="row"> 
                        <div className='col-12 text-center my-5'> 
                            <svg width="55" height="54" viewBox="0 0 65 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="0.5" width="64" height="64" rx="18" fill="#F0F4FF"/>
                                <path d="M34.9297 34.5508H29.9297" stroke="#6A80A3" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" stroke-linejoin="round"/>
                                <path d="M42.5 31V37C42.5 41 41.5 42 37.5 42H27.5C23.5 42 22.5 41 22.5 37V27C22.5 23 23.5 22 27.5 22H29C30.5 22 30.83 22.44 31.4 23.2L32.9 25.2C33.28 25.7 33.5 26 34.5 26H37.5C41.5 26 42.5 27 42.5 31Z" stroke="#6A80A3" strokeWidth="1.5" strokeMiterlimit="10"/>
                            </svg> 
                            <div className="mt-3">You have no registration yet. Click the button below to register a new vehicle </div> 
                            <div className="w-100 d-flex justify-content-center mt-3">
                                <Link to="/myaccount/registration"><button className='btn'>Register a Vehicle</button></Link>
                            </div>
                        </div>
                    </div>
                </div>}
            </div>
            {/* <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Order no</th>
                            <th>Vehicle name</th>
                            <th>Photo</th>
                            <th>Date</th>
                            <th>Status</th> 
                            <th></th> 
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>#11958 </td>
                            <td>Toyota Camry 2.5</td>
                            <td>2</td>
                            <td>30th June, 2023</td>  
                            <td><span className='status-warn'>Sourcing Vehicle</span></td>  
                            <td className='dropdown option-dropdown'>
                                <button className='btn border-0 dropdown-toggle' data-bs-toggle="dropdown" aria-expanded="false" data-bs-popper-config='{"strategy":"fixed"}'>
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.49991 8.4705C7.49991 7.72933 8.0955 7.14697 8.82344 7.14697C9.55138 7.14697 10.147 7.74256 10.147 8.4705C10.147 9.19844 9.55138 9.79403 8.82344 9.79403C8.0955 9.79403 7.49991 9.21168 7.49991 8.4705Z" fill="#707070"/>
                                        <path d="M7.49991 14.1175C7.49991 13.3763 8.0955 12.7939 8.82344 12.7939C9.55138 12.7939 10.147 13.3895 10.147 14.1175C10.147 14.8454 9.55138 15.441 8.82344 15.441C8.0955 15.441 7.49991 14.8587 7.49991 14.1175Z" fill="#707070"/>
                                        <path d="M7.49991 2.82353C7.49991 2.08235 8.0955 1.5 8.82344 1.5C9.55138 1.5 10.147 2.09559 10.147 2.82353C10.147 3.55147 9.55138 4.14706 8.82344 4.14706C8.0955 4.14706 7.49991 3.56471 7.49991 2.82353Z" fill="#707070"/>
                                    </svg>

                                </button>

                                <ul className="dropdown-menu dropdown-menu-end export-dropdown border-0 shadow">
                                    <li className='dropdown-item'>
                                       <Link to={`/myaccount/orders/234`}>View Details</Link>
                                    </li>   
                                    <li className='dropdown-item text-danger d-none'>Delete Order </li> 
                                </ul> 
                            </td>
                        </tr> 


                        <tr>
                            <td>#11958 </td>
                            <td>Toyota Camry 2.5</td>
                            <td>2</td>
                            <td>30th June, 2023</td>  
                            <td><span className='status-success'>Delivered</span></td>  
                            <td className='dropdown option-dropdown'>
                                <button className='btn border-0 dropdown-toggle' data-bs-toggle="dropdown" aria-expanded="false" data-bs-popper-config='{"strategy":"fixed"}'>
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.49991 8.4705C7.49991 7.72933 8.0955 7.14697 8.82344 7.14697C9.55138 7.14697 10.147 7.74256 10.147 8.4705C10.147 9.19844 9.55138 9.79403 8.82344 9.79403C8.0955 9.79403 7.49991 9.21168 7.49991 8.4705Z" fill="#707070"/>
                                        <path d="M7.49991 14.1175C7.49991 13.3763 8.0955 12.7939 8.82344 12.7939C9.55138 12.7939 10.147 13.3895 10.147 14.1175C10.147 14.8454 9.55138 15.441 8.82344 15.441C8.0955 15.441 7.49991 14.8587 7.49991 14.1175Z" fill="#707070"/>
                                        <path d="M7.49991 2.82353C7.49991 2.08235 8.0955 1.5 8.82344 1.5C9.55138 1.5 10.147 2.09559 10.147 2.82353C10.147 3.55147 9.55138 4.14706 8.82344 4.14706C8.0955 4.14706 7.49991 3.56471 7.49991 2.82353Z" fill="#707070"/>
                                    </svg>

                                </button>

                                <ul className="dropdown-menu dropdown-menu-end export-dropdown border-0 shadow">
                                    <li className='dropdown-item'>
                                       <Link to={`/myaccount/orders/234`}>View Details</Link>
                                    </li>   
                                    <li className='dropdown-item text-danger d-none'>Delete Order </li> 
                                </ul> 
                            </td>
                        </tr> 



                        <tr>
                            <td>#11958 </td>
                            <td>Toyota Camry 2.5</td>
                            <td>2</td>
                            <td>30th June, 2023</td>  
                            <td><span className='status-info'>Sourcing Complete</span></td>  
                            <td className='dropdown option-dropdown'>
                                <button className='btn border-0 dropdown-toggle' data-bs-toggle="dropdown" aria-expanded="false" data-bs-popper-config='{"strategy":"fixed"}'>
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.49991 8.4705C7.49991 7.72933 8.0955 7.14697 8.82344 7.14697C9.55138 7.14697 10.147 7.74256 10.147 8.4705C10.147 9.19844 9.55138 9.79403 8.82344 9.79403C8.0955 9.79403 7.49991 9.21168 7.49991 8.4705Z" fill="#707070"/>
                                        <path d="M7.49991 14.1175C7.49991 13.3763 8.0955 12.7939 8.82344 12.7939C9.55138 12.7939 10.147 13.3895 10.147 14.1175C10.147 14.8454 9.55138 15.441 8.82344 15.441C8.0955 15.441 7.49991 14.8587 7.49991 14.1175Z" fill="#707070"/>
                                        <path d="M7.49991 2.82353C7.49991 2.08235 8.0955 1.5 8.82344 1.5C9.55138 1.5 10.147 2.09559 10.147 2.82353C10.147 3.55147 9.55138 4.14706 8.82344 4.14706C8.0955 4.14706 7.49991 3.56471 7.49991 2.82353Z" fill="#707070"/>
                                    </svg>

                                </button>

                                <ul className="dropdown-menu dropdown-menu-end export-dropdown border-0 shadow">
                                    <li className='dropdown-item'>
                                       <Link to={`/myaccount/orders/234`}>View Details</Link>
                                    </li>   
                                    <li className='dropdown-item text-danger d-none'>Delete Order </li> 
                                </ul> 
                            </td>
                        </tr> 
                    </tbody> 
                </table>
            </div> */}
        </div>
    )
}

export default RegistrationOrder
