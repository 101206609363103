import { Link, useNavigate } from 'react-router-dom';
import './RecorveryPasswordToken.scss'
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import Swal from 'sweetalert2';
// import { authentication } from 'Services/authentication';
import OtpInput from 'react-otp-input';
import { authService } from 'Services/authService';

type formType = { 
    token: string; 
}
const RecorveryPasswordToken = () => {
    const { handleSubmit } = useForm<formType>()
    const navigate = useNavigate()

    const [request, setRequest] = useState<boolean>(false)
    const submit = handleSubmit((data: formType) =>registerUser(data))
    const [otp, setOtp] = useState('');

    const handleChangeOtp = (otp:string) => {
        setOtp(otp);
    }
  
    // const submit = async (tokens: formType) => {
    //     const token = {...tokens, otp}
    //     console.log(token)
    //     setRequest(true)
    //     await authentication.recorveryPasswordToken(token.otp).then((response) => {
    //         console.log('Response', response)
    //         setRequest(false)
    //     }).catch((err) => {
    //         console.log('Error', err.message)
    //         setRequest(false)
    //         Swal.fire({
    //             icon: "warning",
    //             text: err.response.data.message,
    //             showConfirmButton: false,
    //             timer: 3000
    //         });
    //     })
    // } 


    
    const registerUser = async (data: formType) => {
        setRequest(true)

        await authService.recorveryPasswordToken(otp)  
        .then(async (userCred: any) => {
            // console.log('Response', userCred.data.token.access_token)
            localStorage.setItem('forgetPasswordToken', userCred.data.token.access_token)

            setRequest(false)  
            navigate("/reset-password", { replace : true }) 

            setTimeout(()=>{
                navigate("/reset-password", { replace : true }) 
            }, 2000)
    
        }, (error) => {
            setRequest(false)
            Swal.fire({
                icon: "warning",
                text: error.response.data.error.message,
                showConfirmButton: true, 
            });
 
            console.log(error)
        })
    }

    
    return ( 
        <div className="RecorverPassword py-5">
            
            <div className="container h-100 d-flex justify-content-center align-items-center">
                <div className="form-container p-4 d-flex justify-content-center align-items-center">
                    <div className="form-container ">
                    
                        <div className="form-header text-center">
                            <div className="form-header-title h2">Verify Account</div>
                            <div className="form-notify-header text-secondary small">The password recovery token has been sent to your phone number via SMS and your registered email address.</div>
                        </div>

                        <form className="form w-100 h-100" onSubmit={submit}>
                           

                            <div className="form-group mt-4 mb-3 container-token text-center d-flex justify-content-center">
                               
                                <OtpInput
                                    value={otp}
                                    onChange={handleChangeOtp}
                                    numInputs={6}
                                    renderInput={(props) => <input {...props} />}
                                    renderSeparator={<span>   </span>}
                                    inputStyle={{
                                        width: '3.0rem',
                                        height: '2.9rem',
                                        minHeight: '2.9rem',
                                        margin: '0 .5rem',
                                        fontSize: '1rem',
                                        borderRadius: 4,
                                        backgroundColor: '#ffffff',
                                        boxShadow: 'inset 0px 2px 2px -1px rgba(74, 74, 104, 0.1)',
                                        border: '1px solid rgba(0,0,0,0.1)'
                                    }}
                                />
                            </div>

                            <div className="form-input py-2">
                                <button onClick={() => console.log(otp)} disabled={otp.length < 6 || request} className="form-control btn btn-primary rounded-pill">Continue {request && <span className='spinner-border spinner-border-sm'></span>}</button>
                            </div>
                            
                            <div className="form-input pt-3">
                                    <div className="small text-center">
                                    Didn’t Recieve a Code?
                                        <Link to='/login' className='text-decoration-none ms-1'>Recend Code</Link>
                                    </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default RecorveryPasswordToken;