import { combineReducers } from 'redux'
import {
  ActiveMenuReducer,
  vehicleInformation
} from './'

export const rootReducer = combineReducers({
  activeMenu: ActiveMenuReducer,
  vehicleInformation
})
