import React, { FC } from 'react'
import "./StatsCard.scss"

interface propsType{
    label: string;
    value: number
}
const StatsCard:FC<propsType> = ({label, value}) => {
    return (
        <div className='StatsCard card border-0'>
            <div className="card-body">
                <div className="label">{label}</div>
                <div className="value">{value}</div>
            </div>
        </div>
    )
}

export default StatsCard
