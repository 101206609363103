import { vehicleServiceDocument } from '@/Models/vehicleServiceType'
import { vehicleService } from 'Services'
import { firebaseStorage } from 'Services/firebaseInitService'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import React, { FC, Key, useEffect, useState } from 'react'
import { BsCheck2 } from 'react-icons/bs'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'

interface propsType {
    module?: string | undefined
}

const UploadPlateDocumentElement:FC<propsType> = ({ module }) => {
    const { service_id } = useParams()

    const navigate = useNavigate()

    const [serviceDocuments, setServiceDocuments] = useState<Array<vehicleServiceDocument>>()

    const  vehicleInformation = useSelector((state: {vehicleInformation: {vehicleMakeId: string, vehicleModelId: string, type: string, year: string }})=> state.vehicleInformation)
 
    const [request, setRequest] = useState(false)
    
    const getDocuments = async () => {
        setRequest(true)
       await vehicleService.serviceDocuments(service_id).then((response)=>{
        setRequest(false) 
         console.log("serviceDocuments", response.data)
         setServiceDocuments(response.data)
       }, error =>{
        setRequest(false)
        console.log("error make", error)
        Swal.fire({
            position: "top-end",
            icon: "warning",
            text: error.response.data.message,
            showConfirmButton: false,
            timer: 3000
        });
       })
    }

    useEffect(()=>{
        getDocuments() 
    }, [service_id])

    const [files, setFiles] = useState<{ name: string; file: File, selected: boolean, documentId:string, url:string }[]>([]);
    const [progresspercent, setProgresspercent] = useState<{progress: string | number, name: string}[]>([])

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>, name: string, documentId:string) => {
      const file = event.target.files?.[0];
      if (file) { 
        try { 
            const storageRef = ref(firebaseStorage, `files/${file.name}`); 
            const uploadTask = uploadBytesResumable(storageRef, file);
 
            uploadTask.on("state_changed",
                (snapshot) => {
                    const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);

                    setProgresspercent([...progresspercent, { progress, name}])
                },(error) => {
                    console.log(error)
                    alert(error);
                },() => {
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => { 

                        console.log('File uploaded successfully.', downloadURL);
                        saveFile(downloadURL)  
                    });
                }
                );

          } catch (error) {
            Swal.fire({
                position: "top-end",
                icon: "warning",
                text: "Error occured while uploading file, please try again.",
                showConfirmButton: false,
                timer: 3000
            });
            console.error('Error uploading file:', error);
          }

          const saveFile = (url:string) =>{ 
                // Check if a file with the same name already exists
                const fileIndex = files.findIndex((f) => f.name === name);

                if (fileIndex !== -1) {
                    // If it exists, replace the file
                    const updatedFiles = [...files];
                    updatedFiles[fileIndex] = { name, file, selected: true, documentId, url };

                    console.log(updatedFiles)
                    setFiles(updatedFiles);

                    Swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: "Document saved",
                        showConfirmButton: false,
                        timer: 2000
                    });

                } else {
                    // If it doesn't exist, add the new file
                    console.log([...files, { name, file, selected: true, documentId, url }])
                    setFiles([...files, { name, file, selected: true, documentId, url }]);

                    Swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: "Document saved",
                        showConfirmButton: false,
                        timer: 2000
                    });
                }
            }
        }
    };

    const submit = async () =>{
        // process file upload
        if(serviceDocuments && files.length < serviceDocuments?.length ) {
            Swal.fire({
                position: "top-end",
                icon: "warning",
                text: "All fields are required",
                showConfirmButton: false,
                timer: 3000
            });
            return
        }


        try {
            const getLocalStorage = await JSON.parse(localStorage.getItem("kit_new_reg") || "{}");

            await localStorage.setItem("kit_new_reg", JSON.stringify({...getLocalStorage,  uploadedFiles: files, ...vehicleInformation}));
           
            navigate(module && module === 'user' ? `/myaccount/checkout/dealers-plate-number/${service_id}` : `/checkout/change-of-ownership/${service_id}`, { replace: true });
            // navigate(module && module === 'user' ? `/myaccount/checkout/dealers-plate-number/${service_id}` : `/checkout/change-of-ownership/${service_id}`, { replace: true });
        } catch (error: unknown) {
            if (error instanceof DOMException && error.name === 'QuotaExceededError') {
                console.log("Local storage quota exceeded.");
                Swal.fire('Oops!', 'Local storage quota exceeded. Please free up some space.', 'warning');
            } else {
                console.log("Error:", error);
                Swal.fire('Oops!', 'An error occurred while storing data.', 'warning');
            }
            setRequest(false);
        }
  
    }


    const renderProgressBar = (name: string) => {
        const foundProgress = progresspercent.find((res) => res.name === name);
        
        return foundProgress ? (
          <div className="progress mt-1" style={{ height: "1px" }}>
            <div
              className="progress-bar bg-success"
              role="progressbar"
              style={{ width: `${foundProgress?.progress ?? 0}%` }}
              aria-valuenow={Number(foundProgress?.progress ?? 0)}
              aria-valuemin={0}
              aria-valuemax={100}
            ></div>
          </div>
        ) : null;
      };
      
      
    return (
        <div className="card-body">  
         {request && <div className="w-100 d-flex justify-content-center">
                <span className="spinner-border spinner-border-sm my-5"></span>
            </div>}

        {serviceDocuments && serviceDocuments.map((docs:vehicleServiceDocument, index:Key)=> <div key={index} className="upload-container mt-3">
            
            <label htmlFor={docs.document.name} className="d-block text mb-2">Upload {docs.document.name} {files.some((file) => file.name === docs.document.name && file.selected) && <BsCheck2 className="fw-bold text-success"/>}
                  
            <input
                type="file"
                id={docs.document.name}
                className=""
                onChange={(e) => handleFileChange(e, docs.document.name, docs.id)}
                hidden
            /><br></br>
            <button className="btn px-4 rounded-pill mt-2" 
                id={docs.document.name} 
                    onClick={() =>
                        document
                            .getElementById(docs.document.name)
                            ?.click()
                    }
            >Upload File</button>
            </label>
            {renderProgressBar(docs.document.name)} 
        </div>)}  

        {serviceDocuments && serviceDocuments.length > 0 && <div className="form-group row">
                <div className="my-auto p-3"> 
                    <button className='btn btn-primary  btn-dealer-doc-continue form-control rounded-pill' onClick={submit} disabled={serviceDocuments && files.length < serviceDocuments?.length? true : false}>Continue</button>
                </div>
            </div>} 
    </div>
    )
}

export default UploadPlateDocumentElement
