import React, { FC } from 'react'
import { Link, useParams } from 'react-router-dom';
import toyota from "Assets/Images/toyota.png" 
import { useSelector } from 'react-redux';
import { vehicleServiceType } from 'Models/vehicleServiceType';

interface propsType {
    module?: string | undefined;
    serviceDetails?: vehicleServiceType|undefined
}
 const InsuranceVehicleTypeFormPreview:FC<propsType> = ({ module, serviceDetails }) => {
 
    const { service_id } = useParams<{ service_id: string }>()
    
    const  vehicleInformation = useSelector((state: {vehicleInformation: {vehicleInfo:{ make: { name: string },  model: { name:string }}, year: string, typeOfInsurance: string }})=> state.vehicleInformation)
 
    

    console.log("vehicleInformation", vehicleInformation)

    
    return (
        <div className="card document-details-card p-0">

        <div className="card-header border-0">
            <div className="row h-100">
                <div className="col-2 text-center my-auto">
                    <div className="logo"> 
                       <img src={toyota} alt="" />
                    </div>
                </div>
                <div className="col-10 my-auto">
                   {vehicleInformation?.vehicleInfo?.make?.name} {vehicleInformation?.vehicleInfo?.model?.name}, {vehicleInformation?.year}
                </div>
            </div>
        </div>
        <div className="card-body px-0">   
            <div className="processing-time px-3 pe-0">
                <div className="row w-100 h-100"> 
                    <div className="col-6 my-auto">
                       Processing Time
                    </div>
                    <div className="col-6 my-auto text-end text-dark">
                       {serviceDetails?.processingTime} Weeks
                    </div>
                    
                </div> 
            </div>

            <div className="total-balance px-3"> 
                <div className="row h-100"> 
                    <div className="col-md-6 my-auto">
                        Total
                    </div>
                    <div className="col-md-6 text-end my-auto">
                        ₦{serviceDetails?.amount}
                    </div>
                </div> 
            </div>
        </div>

        <div className="card-footer px-0">
            <div className="form-group"> 
                <Link to={module && module==='user'? `/myaccount/insurance/${service_id}/insurance-registration-particulars` : `/insurance//${service_id}/insurance-registration-particulars`}>
                    <button className='btn btn-primary' type='button'>Continue</button> 
                </Link>
            </div>
        </div>
    </div>  
    )
}

export default InsuranceVehicleTypeFormPreview
