import React from 'react'
import "./VehicleParticulars.scss"
import { BreadCrumb } from 'Components' 
import { VehicleParticularsFlow } from 'Pages/Frontend/Components'
 
const VehicleParticulars = () => {
   
    return (
        <div className='VehicleParticulars'>
              <div className="container">
                <BreadCrumb parentTitle={'Registration'} parentUrl={'/register-vehicle'} pageTitle={'New Registration'}></BreadCrumb>
                <div className="row justify-content-center">
                    <div className="col-md-5 mb-5 mt-4">
                    <VehicleParticularsFlow></VehicleParticularsFlow>
                </div>
            </div>
        </div>
    </div>
    )
}

export default VehicleParticulars
