import React, { FC, Key, useEffect, useState } from 'react' 
import car from "Assets/Icons/car.svg" 
import { Link } from 'react-router-dom'
import { vehicleService } from 'Services'
import RegistrationMenuOptionSkeleton from './RegistrationMenuOptionSkeleton'
import { vehicleServiceType } from 'Models'
import { useAuth } from 'Components/AuthProvider/AuthProvider'

interface propsType {
    module?: string
}

const RegistrationMenuOption:FC<propsType> = ({ module }) => {
    const limit = 100;
    const offset = 0;

    const [serviceList, setServiceList] = useState<Array<vehicleServiceType>>()
    const [request, setRequest] = useState(false)
    const [threeSelectedServices, setThreeSelectedServices] = useState<any | null>()


    const { user } = useAuth()
    
    const getList = async () => {
        setRequest(true)
       await vehicleService.serviceList().then((response:{ data: {data: Array<vehicleServiceType>}})=>{
          console.log("response", response)
          setServiceList(response.data.data)  
          setRequest(false)
       }, error =>{
        console.log("serviceList error", error.response)
        setRequest(false) 
       })
    }
    

    useEffect(() => {
        getList()
    }, [])


    return (
        <div className="registrationType" id='registerLists'>
            <div className="title text-center mb-5">
            Select your type of registration
            </div>


        <div className="container">
            <div className="row"> 
              {request && <RegistrationMenuOptionSkeleton></RegistrationMenuOptionSkeleton>}
            </div>
            <div className="row">
                {!request && serviceList && serviceList.map((service: vehicleServiceType, index:Key)=>{
                    return service.status === true && <div key={index} className="col-12 col-sm-6 col-md-6 col-xl-4 mt-3">
                    <div className="card">
                        <div className="card-body">
                            <div className="card-icon">
                                <span> 
                                  <img src={car} alt=''></img>
                                </span> 
                            </div>
                            <div className="card-title mt-4">
                                {service.name}
                            </div>
                            <div className="card-text">
                               {service.description === "N/A"? "The description of the service comes here or Imported fairly used vehicle": service.description }
                            </div>
                            <div className="card-action mt-4"> 
                                 <Link to={user? `/myaccount/${service.path}/${service.uuid}` : `/registration-service/${service.uuid}`}>  
                                   <button className='btn'>Continue</button>
                                </Link> 
                            </div>
                        </div>
                    </div>
                </div>})}


                {/* <div className="col-12 col-sm-6 col-md-4 mt-3">
                    <div className="card">
                        <div className="card-body">
                            <div className="card-icon">
                                <span> 
                                  <img src={car} alt=''></img>
                                </span> 
                            </div>
                            <div className="card-title mt-4">
                                Renew Vehicle Registration
                            </div>
                            <div className="card-text">
                               Renew cehicle registration before expiry or after overdue
                            </div>  
                            <div className="card-action mt-4"> 
                                <Link to={module && module ==='user'? "/myaccount/registration/renew-registration" : "/renew-registration"}> 
                                    <button className='btn'>Continue</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="col-12 col-sm-6 col-md-4 mt-3">
                    <div className="card">
                        <div className="card-body">
                            <div className="card-icon">
                                <span> 
                                  <img src={change} alt=''></img>
                                </span> 
                            </div>
                            <div className="card-title mt-4">
                            Change of Ownership
                            </div>
                            <div className="card-text">
                            Change registered vehicle ownership documentations
                            </div>
                            <div className="card-action mt-4">
                                <Link to={module && module ==='user'? "/myaccount/registration/change-of-ownership" : "/change-of-ownership"}>  
                                    <button className='btn'>Continue</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="col-12 col-sm-6 col-md-4 mt-3">
                    <div className="card">
                        <div className="card-body">
                            <div className="card-icon">
                                <span> 
                                  <img src={license} alt=''></img>
                                </span> 
                            </div>
                            <div className="card-title mt-4">
                            Drivers License
                            </div>
                            <div className="card-text">
                            Register to legally operate a motor vehicle on public roads
                            </div>
                            <div className="card-action mt-4">
                                <Link to={module && module ==='user'? "/myaccount/registration/drivers-license-registration" : "/change-of-ownership"}>  
                                   <button className='btn'>Continue</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="col-12 col-sm-6 col-md-4 mt-3">
                    <div className="card">
                        <div className="card-body">
                            <div className="card-icon">
                                <span> 
                                  <img src={license} alt=''></img>
                                </span> 
                            </div>
                            <div className="card-title mt-4">
                               Renew Drivers License
                            </div>
                            <div className="card-text">
                                Register to legally operate a motor vehicle on public roads
                            </div>
                            <div className="card-action mt-4"> 
                                <Link to={module && module ==='user'? "/myaccount/registration/drivers-license-renewal" : "/reg-drivers-license"}>  
                                    <button className='btn'>Continue</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="col-12 col-sm-6 col-md-4 mt-3">
                    <div className="card">
                        <div className="card-body">
                            <div className="card-icon">
                                <span> 
                                  <img src={plate} alt=''></img>
                                </span> 
                            </div>
                            <div className="card-title mt-4">
                               Customized Plate Number
                            </div>
                            <div className="card-text">
                               Choose a specific combinations  for their license plate
                            </div>
                            <div className="card-action mt-4">
                                <Link to={module && module ==='user'? '/myaccount/registration/customize-plate-number':'customize-plate-number'}>
                                    <button className='btn'>Continue</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>



                <div className="col-12 col-sm-6 col-md-4 mt-3">
                    <div className="card">
                        <div className="card-body">
                            <div className="card-icon">
                                <span> 
                                  <img src={plate_number } alt=''></img>
                                </span> 
                            </div>
                            <div className="card-title mt-4">
                               Dealer Plate Number
                            </div>
                            <div className="card-text">
                               Government recognized for buying and selling of cars.
                            </div>
                            <div className="card-action mt-4">
                                <Link to={module && module ==='user'? "/myaccount/registration/dealers-plate-number" : "/dealers-plate-number"}>  
                                    <button className='btn'>Continue</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="col-12 col-sm-6 col-md-4 mt-3">
                    <div className="card">
                        <div className="card-body">
                            <div className="card-icon">
                                <span> 
                                  <img src={print} alt=''></img>
                                </span> 
                            </div>
                            <div className="card-title mt-4">
                               Plate Reprint
                            </div>
                            <div className="card-text">
                               Get a new plate with the same number for certain reasons.
                            </div>
                            <div className="card-action mt-4"> 
                                <Link to={module && module ==='user'? "/myaccount/registration/plate-reprint" : "/plate-reprint"}>  
                                    <button className='btn'>Continue</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    </div>
    )
}

export default RegistrationMenuOption
