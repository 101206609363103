import React, { FC, Fragment } from 'react'
import "./BreadCrumb.scss"
import { Link, useNavigate } from 'react-router-dom'

interface propsType {
    parentTitle: string;
    parentUrl: string;
    pageTitle: string;
    childTitle?: string | undefined;
    noPad?:boolean;
}

const BreadCrumb:FC<propsType> = ({parentTitle, parentUrl, pageTitle, childTitle, noPad}) => {
    const navigate = useNavigate();
    
    const goBack = () => {
        navigate(-1); // Go back to the previous page
      };
  return (
        <div className={noPad? "container pt-4 ps-0": "container pt-4"}>
            <div className={noPad? "ps-0": "ps-2"}>
               <div className="breadcrumb">
                    <div className="col-b">
                        <Link to='#' onClick={goBack}>
                            <span className='btn-back'>
                                <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.31836 12.9401L1.42836 8.05006C0.850859 7.47256 0.850859 6.52756 1.42836 5.95006L6.31836 1.06006" stroke="#00163A" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" stroke-linejoin="round"/>
                                </svg>
                            </span>
                        </Link>
                    </div>

                    <div className="col-b parent">
                        <Link to={parentUrl}>
                          {parentTitle}
                        </Link>
                    </div>

                    <div className="col-b me-2 ms-2">
                        <svg width="15" height="15" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                             <path d="M6.68172 15.5026C6.53922 15.5026 6.39672 15.4501 6.28422 15.3376C6.06672 15.1201 6.06672 14.7601 6.28422 14.5426L11.1742 9.65264C11.5342 9.29264 11.5342 8.70764 11.1742 8.34764L6.28422 3.45764C6.06672 3.24014 6.06672 2.88014 6.28422 2.66264C6.50172 2.44514 6.86172 2.44514 7.07922 2.66264L11.9692 7.55264C12.3517 7.93514 12.5692 8.45264 12.5692 9.00014C12.5692 9.54764 12.3592 10.0651 11.9692 10.4476L7.07922 15.3376C6.96672 15.4426 6.82422 15.5026 6.68172 15.5026Z" fill="#6A80A3"/>
                        </svg>
                    </div>

                    <div className={childTitle? "col-b mid-child" : "col-b child"}>{pageTitle}</div>

                    {childTitle && <Fragment>
                        <div className="col-b me-2 ms-2">
                            <svg width="15" height="15" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.68172 15.5026C6.53922 15.5026 6.39672 15.4501 6.28422 15.3376C6.06672 15.1201 6.06672 14.7601 6.28422 14.5426L11.1742 9.65264C11.5342 9.29264 11.5342 8.70764 11.1742 8.34764L6.28422 3.45764C6.06672 3.24014 6.06672 2.88014 6.28422 2.66264C6.50172 2.44514 6.86172 2.44514 7.07922 2.66264L11.9692 7.55264C12.3517 7.93514 12.5692 8.45264 12.5692 9.00014C12.5692 9.54764 12.3592 10.0651 11.9692 10.4476L7.07922 15.3376C6.96672 15.4426 6.82422 15.5026 6.68172 15.5026Z" fill="#6A80A3"/>
                            </svg>
                        </div> 

                        <div className="col-b child">{childTitle}</div>
                    </Fragment>}

               </div> 
            </div>
        </div>
  )
}

export default BreadCrumb
