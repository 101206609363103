import React, { FC, Fragment, useEffect, useState } from 'react' 
import { Link, useNavigate, useParams } from 'react-router-dom'
import cloud from 'Assets/Images/cloud-plus.svg'
import file from 'Assets/Images/Layer_x0020_1.svg'
import Swal from 'sweetalert2'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import { firebaseStorage } from 'Services/firebaseInitService'
import { FileUploader } from "react-drag-drop-files"; 

interface propsType {
    module?: string | undefined
}
const UploadOldLicenseElement:FC<propsType> = ({ module })  => {
    const { service_id } = useParams()
    const [confirmUpload, setConfirmUpload] = useState(false) 
    const [rawFile, setRawFile] = useState<File>() 

    const fileTypes = ["JPG", "PNG", "JPEG"];

    const navigate = useNavigate()
    const [files, setFiles] = useState<string>();

    const [progresspercent, setProgresspercent] = useState<{progress: string | number}>()

    const [request, setRequest] = useState(false)
 
    const handleFileChange = async (event: File) => { 
      const file = event;
      setRawFile(file)
      setConfirmUpload(true)
      if (file) { 
        try { 
            const storageRef = ref(firebaseStorage, `files/${file.name}`); 
            const uploadTask = uploadBytesResumable(storageRef, file);
 
            uploadTask.on("state_changed",
                (snapshot: { bytesTransferred: number; totalBytes: number }) => {
                    const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);

                    setProgresspercent({ progress})
                },(error) => {
                    console.log(error) 
                },() => {
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => { 

                        console.log('File uploaded successfully.', downloadURL);
                        saveFile(downloadURL)  
                    });
                }
                );

          } catch (error) {
            Swal.fire({
                position: "top-end",
                icon: "warning",
                text: "Error occured while uploading file, please try again.",
                showConfirmButton: false,
                timer: 3000
            });
            console.error('Error uploading file:', error);
          }

          const saveFile = (url:string) =>{  
 
                console.log(url)
                setFiles(url);

                Swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: "Document saved",
                    showConfirmButton: false,
                    timer: 2000
                }); 
            }
        }
    };
 

    const submit = async () =>{
        // process file upload
        console.log("submit", files)
        if(!files) {
            Swal.fire({
                position: "top-end",
                icon: "warning",
                text: "All fields are required",
                showConfirmButton: false,
                timer: 3000
            });
            return
        }


        try {
            const getLocalStorage = await JSON.parse(localStorage.getItem("kit_new_reg") || "{}");

            await localStorage.setItem("kit_new_reg", JSON.stringify({...getLocalStorage,  uploadedDoc: files}));
           
            navigate(module && module === 'user' ? `/myaccount/checkout/drivers-license-renewal/${service_id}` : `/checkout/drivers-license-renewal/${service_id}`, { replace: true });
         
        } catch (error: unknown) {
            if (error instanceof DOMException && error.name === 'QuotaExceededError') {
                console.log("Local storage quota exceeded.");
                Swal.fire('Oops!', 'Local storage quota exceeded. Please free up some space.', 'warning');
            } else {
                console.log("Error:", error);
                Swal.fire('Oops!', 'An error occurred while storing data.', 'warning');
            }
            setRequest(false);
        }
  
    }


    const renderProgressBar = (name: string) => { 
        return progresspercent ? (
            <Fragment>  
                <div className="progress mt-1" style={{ height: "1px" }}>
                    <div
                        className="progress-bar bg-success"
                        role="progressbar"
                        style={{ width: `${progresspercent?.progress ?? 0}%` }}
                        aria-valuenow={Number(progresspercent?.progress ?? 0)}
                        aria-valuemin={0}
                        aria-valuemax={100}
                    ></div>
                </div> 
                
                <div className="text-1-2nd pt-1">{progresspercent?.progress ?? 0}%</div>
            </Fragment>  
        ) : null;
      };
      


    return (
        <Fragment>
            <div className="w-100 drag-and-drop">
                <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
                    <FileUploader
                    multiple={false}
                    handleChange={handleFileChange}
                    name="file"
                    label="Drag and drop files or Browse to upload,"
                    types={fileTypes}
                />
                    {/* <div className="blue-cloud-upload-img-container d-flex justify-content-center w-100">
                        <img src={cloud} alt="cloud" />
                    </div> */}

                    {/* <input
                        type="file"
                        id="driverlicense"
                        className=""
                        onChange={(e) => handleFileChange(e)}
                        hidden
                    />

                    <div className="texts text-center">
                        Drag and drop files or <span className="text-primary" onClick={() =>
                            document
                                .getElementById("driverlicense")
                                ?.click()
                        }>Browse</span> to upload
                    </div> */}
                </div>
            </div> 

            
            {confirmUpload && <div className="my-3 px-3">
                <div className="total-file-1 w-100">
                    <div className="file-1 row">
                        <div className="img-cont px-3 col-3">
                            <img src={file} alt="file" />
                        </div>
                        <div className="progress-main-container col-9">
                            <div className="text-1 pb-1">{rawFile?.name}</div> 
                                {renderProgressBar("driverlicense")} 
                        </div>
                    </div> 
                </div>
            </div>}
            
            <div className="form-group">  
                <button className='btn btn-primary' type='submit' onClick={submit} disabled={rawFile? false : true}>Done</button>
            </div> 
        </Fragment>
    )
}

export default UploadOldLicenseElement
