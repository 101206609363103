import React, { useState } from 'react' 
import './CustomToastr.scss'

const CustomToastr = ({ closeToast, toastProps, message }:any) => {

  return(
    <div className="Customtoastr d-flex justify-content-between">
      {message}
      <button onClick={closeToast} className="ok-btn">Ok</button>
    </div>
  )
  };

export default CustomToastr
