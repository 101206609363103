import React from 'react'
import "./UploadOldDriversLicenseAuth.scss"
import { BreadCrumb } from 'Components' 
import { UploadOldLicenseElement } from 'Pages/Frontend/Components'

const UploadOldDriversLicenseAuth = () => {

    return (
        <div className='UploadOldDriversLicenseAuth'>
             <div className="container">
                <BreadCrumb parentTitle={'Registration'} parentUrl={'/renew-drivers-license'} pageTitle={'Renew Drivers License'}></BreadCrumb>
                <div className="row justify-content-center">
                    <div className="col-12 col-md-7  col-lg-7  col-xl-5 mb-5 mt-4">
                       <div className="card border-0 py-3">
                        <div className="card-header text-left border-0 pt-3"> 
                            <div className="title">
                                Upload Old Drivers License
                            </div> 
                        </div>
                        <div className="card-body pt-2">
                            <UploadOldLicenseElement module="user"></UploadOldLicenseElement>
                        </div>
                       </div>
                    </div>

                </div>
             </div>
        </div>
    )
}

export default UploadOldDriversLicenseAuth
