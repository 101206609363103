import React from 'react'
import "./CustomizePlateNumberAuth.scss"
import { BreadCrumb } from 'Components'  
import { CustomizePlateNumberEntry } from 'Pages/Frontend/Components'


const CustomizePlateNumberAuth = () => {
 
    return ( 
        <div className="CustomizePlateNumberAuth">
            
            <div className="container">
                <BreadCrumb parentTitle={'Registration'} parentUrl={'/customize-plate-number'} pageTitle={'Customize Plate Number'}></BreadCrumb>
                <CustomizePlateNumberEntry module='user'></CustomizePlateNumberEntry>
             </div>
        </div>
     );
}
 
export default CustomizePlateNumberAuth;