import React from 'react'
import "./LatestTransaction.scss"

const LatestTransaction = () => {
    return (
        <div className='LatestTransaction mt-4'>
             <div className="card border-0 px-3">
                <div className="card-header bg-white px-0 py-3">
                    <div className="row">
                        <div className="col-8">
                            <div className="title">Latest Transactions</div>
                        </div>
                        <div className="col-4">
                            <div className="see-all">See All</div>
                        </div>
                    </div>
                </div>
                <div className="card-body px-0"></div>
                </div>
        </div>
    )
}

export default LatestTransaction
