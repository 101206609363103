
import React, { useEffect } from 'react' 
import './AuthenticatedWrapper.scss'
import EventBus from 'Helpers/EventBus'
import { Logout } from 'Helpers/Logout'
import { Footer } from 'Layout' 
import AuthenticatedSidebar from 'Layout/Authenticated/Sidebar/AuthenticatedSidebar';
import AuthenticatedHeader from 'Layout/Authenticated/Header/AuthenticatedHeader' 
import MobileHeader from 'Layout/Authenticated/MobileHeader/MobileHeader'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { auth } from 'Services/firebaseInitService' 
import { useAuth } from 'Components/AuthProvider/AuthProvider'


const AuthenticatedWrapper = () => {
 
   useEffect(() => {
    EventBus.on("logout", () => {
      Logout()
    });

    return () => {
      EventBus.remove("logout", () => {
        Logout()
      });
    };
  }, []);

   
  const navigate = useNavigate();
  const location = useLocation();
  const { user:authUser, logout } = useAuth();


  const checkAuth = async () => {
    if(authUser){
  
      auth.onAuthStateChanged(async (user) => {
        if (user) {
          const tokenResult = await user.getIdTokenResult();
          const isExpired = tokenResult.expirationTime < new Date().toISOString();
          
          if (isExpired) { 

            alert("Session expired")
            
            logout()
            // Token is expired, redirect to login
            navigate('/login', { replace : true });
          }
        } 
      });
    }
  };


  useEffect(() => {
    checkAuth();
  }, [location, navigate]);
 
   
  return (
    <React.Fragment>
      <div className='authenticated-wrapper-wraper container-xxxxl'> 
       <MobileHeader></MobileHeader>
      {/* {document.body.clientWidth <= 991 && <MobileHeader></MobileHeader>} */} 
          <div className="row">
            <div className="col-25 pe-0">
              <AuthenticatedSidebar></AuthenticatedSidebar>
            {/* {document.body.clientWidth >= 991 && <AuthenticatedSidebar></AuthenticatedSidebar>  } */}
            </div>
            <div className="col-85">
                <AuthenticatedHeader></AuthenticatedHeader> 
                  <div className="container"> 
                      <Outlet />
                  </div>
                <Footer></Footer>
            </div>
        </div>
      </div> 
    </React.Fragment>
  )
}
  
export default AuthenticatedWrapper
