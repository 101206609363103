import React from 'react'
import "./Header.scss"
import NavBar from './NavBar/NavBar'

const Header = () => {
    return (
        <div className='Header'>
           <NavBar></NavBar> 
        </div>
    )
}

export default Header
