import React, { FC, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import toyota from "Assets/Images/toyota.png"
import { vehicleService } from 'Services';
import Swal from 'sweetalert2'
import { vehicleServiceDocument, vehicleServiceType } from 'Models/vehicleServiceType';
import { useSelector } from 'react-redux';
import './RenewRegPayment.scss'

interface propsType {
    module?: string | undefined,
    vehicleService?: vehicleServiceType|undefined
}
 const RenewRegPayment:FC<propsType> = ({ module, vehicleService: vehicleServiceItem }) => { 
    const { service_id } = useParams<{ service_id: string }>()
    const [request, setRequest] = useState(false)
    const [serviceDocuments, setServiceDocuments] = useState<Array<vehicleServiceDocument>>()

    const  vehicleInformation = useSelector((state: {vehicleInformation: {vehicleInfo:{ make: { name: string },  model: { name:string }}, year: string, typeOfInsurance: string }})=> state.vehicleInformation)
 
    const getDocuments = async () => {
        setRequest(true)
       await vehicleService.serviceDocuments(service_id).then((response)=>{
        setRequest(false) 
         console.log("serviceDocuments", response.data)
         setServiceDocuments(response.data)
       }, error =>{
        setRequest(false)
        console.log("error make", error)
        Swal.fire({
            position: "top-end",
            icon: "warning",
            text: error.response.data.message,
            showConfirmButton: false,
            timer: 3000
        });
       })
    }

    useEffect(()=>{
        getDocuments() 
    }, [service_id])

    console.log("vehicleInformation", vehicleInformation)

    return (
        <div className="col-md-5 mb-5 mt-4">
            <div className="card document-details-card p-0">

                <div className="card-header border-0">
                    <div className="row h-100">
                        <div className="col-md-2 text-center my-auto">
                            <div className="logo"> 
                            <img src={toyota} alt="" />
                            </div>
                        </div>
                        <div className="col-md-10 my-auto">
                        Toyota Camry 2.5 
                        </div>
                    </div>
                </div>
                <div className="card-body px-0">  
                    <div className="docYouWillGet px-3"> 
                        <div className='tagline'>Documents you will get</div> 

                        <div className="row pb-4 pt-3">  
                            <div className="col-6">
                                <label className="form-input">Vehicle License
                                    <input type="checkbox" checked/>
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                            <div className="col-6">
                                <label className="form-input">Insurance
                                    <input type="checkbox" checked/>
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                            <div className="col-6">
                                <label className="form-input">Road worthiness
                                    <input type="checkbox" checked/>
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                            <div className="col-6">
                                <label className="form-input">CRM
                                    <input type="checkbox" checked/>
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                            <div className="col-6">
                                <label className="form-input">Plate Number
                                    <input type="checkbox" checked/>
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                            <div className="col-6">
                                <label className="form-input">Proof of ownership
                                    <input type="checkbox" checked/>
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                            <div className="col-6">
                                <label className="form-input">VIN
                                    <input type="checkbox" checked/>
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="processing-time px-3">
                        <div className="row w-100 h-100"> 
                            <div className="col-6 my-auto">
                            Processing Time
                            </div>
                            <div className="col-6 my-auto text-end text-dark">
                                {vehicleServiceItem?.processingTime ? vehicleServiceItem?.processingTime : '2'} Weeks
                            </div>
                            
                        </div> 
                    </div>

                    <div className="total-balance px-3"> 
                        <div className="row h-100"> 
                            <div className="col-md-6 my-auto">
                                Total
                            </div>
                            <div className="col-md-6 text-end my-auto">
                                {/* ₦{vehicleServiceItem?.amount} */}
                                ₦{vehicleServiceItem?.amount ? vehicleServiceItem?.amount : '200,000.00'}
                            </div>
                        </div> 
                    </div>
                </div>

                <div className="card-footer px-0">
                    <div className="form-group"> 
                        <Link  to={module && module==='user'? `/myaccount/registration/new-registration/${service_id}/vehicle-particulars` : `/registration/new-registration/${service_id}/vehicle-particulars`}>
                            <button className='btn btn-primary' type='button'>Continue</button> 
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RenewRegPayment
