import './AboutUsTextContents.scss'
import pic1 from '../../../../../Assets/Images/AboutUsTextContents.svg'
import pic2 from '../../../../../Assets/Images/AboutUsTextContents2.svg'


const AboutUsTextContents = () => {
    return ( 
        <div className="AboutUsTextContents">
            
            <div className="container mb-5 mt-5 text-center text-md-start">
                <div className="row mt-5">
                    <div className="col-md-6 p-3 d-flex align-items-center order-1 order-md-0">
                        <div className="img-container h-100 d-flex justify-content-start">
                            <img src={pic1} alt="pic1" className='w-100'/>
                        </div>
                    </div>
                    <div className="col-md-6 p-3 d-flex justify-content-center flex-column ">
                        <div className="h2">Helping you journey<br></br> without borders</div>
                        <div className="paragraphs">
                            <p className="small">
                                 At Kitaniz, we understand that the journey to owning and registering a vehicle should be as exciting as the first drive itself. With us, it's not just about buying a car or completing paperwork; it's about embarking on a seamless adventure towards hassle-free driving.
                            </p>    
                            <p className="small">
                                Our mission is simple: to redefine the vehicle ownership experience. We strive to provide a one-stop-shop where customers can not only find their dream vehicles but also navigate the often intricate processes of registration and insurance with ease and confidence.
                            </p>
                        </div>
                    </div>
                </div>
                
                <div className="row mt-5">
                    <div className="col-md-6 p-3 d-flex justify-content-center flex-column ">
                        <div className="h2">Join Kitaniz on <br></br>the Road Ahead</div>
                        <div className="paragraphs mt-3">
                            <p className="small">
                               Whether you're embarking on your first vehicle purchase, looking to renew your registration, or seeking the right insurance coverage, Kitaniz is your trusted companion on the road ahead. Let's turn your driving dreams into a reality, where each mile is a testament to confidence and convenience.
                            </p>
                            <p className="small">
                               Ready to start your journey? Explore our services, and discover why Kitaniz is the preferred choice for those who seek a seamless and reliable vehicle ownership experience.
                            </p>
                        </div>
                    </div>

                    <div className="col-md-6 p-3 d-flex align-items-center order-1 order-md-0">
                        <div className="img-container h-100 d-flex justify-content-end">
                            <img src={pic2} alt="pic1" className='w-100'/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default AboutUsTextContents;