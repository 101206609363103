import { Link } from 'react-router-dom';
import './ResetPassword.scss'
import { useForm } from 'react-hook-form';
import { useState } from 'react';
// import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa6';
import Swal from 'sweetalert2';
import { authService } from 'Services/authService';
import PasswordStrengthBar from 'react-password-strength-bar';
// import { watch } from 'fs';

type formType = { 
    password: string; 
    confirmPassword: string; 
}

const ResetPassword = () => {
    const navigate = useNavigate();
    const { handleSubmit, register, watch, formState: { errors } } = useForm<formType>()
    const [request, setRequest] = useState<boolean>(false)
    const [passwordType, setPasswordType] = useState<string>("password")
    const [disableBtn, setDisableBtn] = useState<boolean>(true);
    const [errBorder, setErrBorder] = useState<string>('');
    const token: string | null = localStorage.getItem('forgetPasswordToken');
  
    const checkPasswordHideOut = () => {
        if(passwordType === 'password') {
            setPasswordType('text')
        } else {
            setPasswordType('password')
        }
    }
    // console.log(token)

    
    const [passwordType2, setPasswordType2] = useState<string>("password");
    const watchPassword = watch('password');
  
    const checkPasswordHideOut2 = () => {
        if(passwordType2 === 'password') {
            setPasswordType2('text')
        } else {
            setPasswordType2('password')
        }
    }
  
    const submit = async (data: formType) => {
        console.log(data)
        setRequest(true)
        await authService.resetPassword(data, token).then(async (userCred: any) => {
            console.log('Response', userCred)
            setRequest(false)

            Swal.fire({
                icon: "success",
                text: "",
                showConfirmButton: false,
                timer: 3000
            });

            setTimeout(()=>{ 
                navigate('/login', {replace: true})
            }, 3000)
            
            // /recorver-password-token
            
        }).catch((err) => {
            console.log('Error', err)
            setRequest(false)
            Swal.fire({
                icon: "warning",
                text: err.message,
                showConfirmButton: false,
                timer: 3000
            });
        })
    } 

    const [password, setPassword] = useState<string>()

    
    return ( 
        <div className="RecorverPassword py-5">
            
            <div className="container h-100 d-flex justify-content-center align-items-center">
                <div className="form-container p-4 d-flex justify-content-center align-items-center">
                    <div className="form-container">
                    
                        <div className="form-header text-center">
                            <div className="form-header-title h2">Reset Password</div>
                        </div>

                        <form className="form w-100 h-100" onSubmit={ handleSubmit((e) => submit(e)) }>

                            <div className="form-input py-2 password-reset-input">
                                <div className={`d-flex align-items-center form-control p-0 ${errBorder}`}> 
                                    <input className={`border-0 w-100 px-3 ${errors.password ? 'is-invalid' : ''}`} 
                                        type={passwordType} 
                                        placeholder='Password'  
                                        {...register('password', { required : 'This field is required',  
                                            minLength: {
                                                value: 6,
                                                message: 'Password must be at least 6 characters'
                                            },
                                            pattern: {
                                                value: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/,
                                                message: 'Password must contain letters and numbers'
                                            },
                                            onChange(event) {
                                            event.target.value.length > 0 && event.target.value.length < 8 ? setErrBorder('Invalid') : setErrBorder('');
                                            setPassword(event.target.value);
                                        },})}/>
                                    <div className="btn-container  d-flex justify-content-center align-items-center h-100 ps-3">
                                        <span className="cursor-pointer" onClick={checkPasswordHideOut} >{passwordType === 'password' ? <FaRegEyeSlash /> : <FaRegEye />}</span>
                                    </div>
                                 </div>
                                {errors.password && <small className='error'>{errors.password.message}</small>} 
                            </div>
                            
                            <div className="form-input py-2 password-reset-input">
                                <div className="d-flex align-items-center form-control p-0"> 
                                    <input className={`border-0 w-100 px-3 ${errors.confirmPassword ? 'is-invalid' : ''}`}  type={passwordType2} placeholder='Confirm Password'  {...register('confirmPassword', { required : 'This field is required', onChange(event) {
                                        event.target.value === watchPassword ? setDisableBtn(false) : setDisableBtn(true)
                                    },})}/>
                                    <div className="btn-container  d-flex justify-content-center align-items-center h-10 ps-3">
                                        <span className="cursor-pointer" onClick={checkPasswordHideOut2} >{passwordType2 === 'password' ? <FaRegEyeSlash /> : <FaRegEye />}</span>
                                    </div>
                                 </div>
                                {errors.confirmPassword && <small className='error'>{errors.confirmPassword.message}</small>} 
                            </div>
                            <PasswordStrengthBar password={password} scoreWordClassName="text-center"/>


                            <div className="form-input py-2">
                                <button className="form-control btn btn-primary rounded-pill" disabled={disableBtn}>Save {request && <span className='spinner-border spinner-border-sm'></span>}</button>
                            </div>
                             
                        </form>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default ResetPassword;