 
import './AccountVerification.scss'
import { useLocation, useNavigate } from 'react-router-dom'; 
import { useEffect, useState } from 'react';  
import { applyActionCode, getAuth, signOut } from 'firebase/auth';
import Swal from 'sweetalert2';  
import { HorizontalLoader } from 'Components';
 
 
const AccountVerification = () => { 
    const navigate = useNavigate();
    const [request, setRequest] = useState<boolean>(false)
    const [requestSent, setRequestSent] = useState<boolean>(false)
     
    // const { search } = useLocation();
    // const searchParams = new URLSearchParams(search);

    // const auth = getAuth(); 

  
    // const verifyUser = async () => {
    //     // Convert the searchParams into an object
    //     const params = Object.fromEntries(searchParams.entries());
   
    //     const oobCode =  params['oobCode']

    //     setRequest(true)

    //     // Verify the user's email
    //     await applyActionCode(auth, encodeURIComponent(oobCode)).then(async (response) => {
           
    //         // Email verified successfully 
    //         //signout active session
    //         await signOut(auth)
    //         setRequest(false)  
    //         Swal.fire('Success!', 'Your Email has been verified successfully, proceed to login.', 'success')
    //         .then((action)=>{
    //             if(action.isConfirmed){
    //                 navigate("/login", { replace : true }) 
    //             }

    //         })
    

    //     }, (error) => {
    //         // Handle errors
    //         Swal.fire("Oops!", 'Your email address has already been verified, click ok to login.', 'error')
    //         .then((response)=>{
    //             if(response.isConfirmed)
    //             {
    //                 navigate("/login", { replace: true })
    //             }

    //         })
    //         console.error('Error verifying email:', error);
    //     });
    // }

    // useEffect(() => {
    //     if(!requestSent){
    //         setRequestSent(true)
    //         verifyUser()
    //     }
    // }, [])
     
   
    return ( 
        <div className="AccountVerification py-5">
            
            <div className="container h-100 d-flex justify-content-center align-items-center">
                <div className="form-container p-4 rounded shadow-sm d-flex justify-content-center align-items-center">
                    <div className="form-container text-center"> 
                         <div>Email verification in progress </div>
                         <div className='w-100 text-center'>
                            <HorizontalLoader></HorizontalLoader>
                         </div>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default AccountVerification;