import React from 'react'
import "./AuthenticatedSidebar.scss"
import AuthenticatedSidebarContent from './AuthenticatedSidebarContent/AuthenticatedSidebarContent'
 
 
const AuthenticatedSidebar = () => {
  return (
        <div className='AuthenticatedSidebar d-none d-lg-block'>
          <AuthenticatedSidebarContent></AuthenticatedSidebarContent>
        </div>
  )
}

export default AuthenticatedSidebar
