import React, { useEffect, useState } from 'react'
import "./RegistrationSummary.scss"
import { BreadCrumb } from 'Components' 
import { RegistrationSummaryItem } from 'Pages/Frontend/Components';
import { useParams } from 'react-router-dom';
import { ServiceOrderDataType } from 'Models';
import { orderService } from 'Services';
import Swal from 'sweetalert2';
import Loader from '../../../../../../Pages/Frontend/Components/Loader/Loader';

const RegistrationSummary = () => {
    const { service_order_id } = useParams<{ service_order_id: string }>()
  
   const [vehicleOrderServices, setVehicleOrderServices] = useState<ServiceOrderDataType>()
   const [loadingData, setLoadingData] = useState<boolean>(true)

   const getServices = async () =>{
        setLoadingData(true)
       await orderService.getServiceOrderId(service_order_id).then((response) =>{
           console.log("response getServiceOrderId!", response.data)
           setVehicleOrderServices(response.data.data)
            setLoadingData(false)
       }, error => {
            setLoadingData(false)
           Swal.fire({
               position: "top-end",
               icon: "warning",
               text: error.response.data.message,
               showConfirmButton: false,
               timer: 3000
           });
       })
   }

   useEffect(() => {
       getServices()
   }, [service_order_id])


    return (
        <>
            {
                loadingData ?
                <Loader /> :
                <div className='RegistrationSummary'> 
                    <div className="container">
                        <BreadCrumb parentTitle={'Registration'} parentUrl={'/'} pageTitle={vehicleOrderServices? vehicleOrderServices.registrationService.name : ""} childTitle={'Summary'}></BreadCrumb>
                    
                        <RegistrationSummaryItem vehicleOrderServices={vehicleOrderServices} refreshServiceOrder={()=>getServices()}></RegistrationSummaryItem>
                </div>
                </div>
            }
        </>
    )
}

export default RegistrationSummary
