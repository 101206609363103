import { compose } from 'redux'
import { rootReducer } from '../Reducers/index'
import { Tuple, configureStore } from '@reduxjs/toolkit'
import logger from 'redux-logger'

declare global {
    // eslint-disable-next-line no-unused-vars
    interface Window {
      __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}

const Store = configureStore({
  reducer: rootReducer,
  middleware: () => new Tuple(logger)
}
)

export default Store
