import React from 'react'
import "./CustomizePlateNumber.scss"
import { BreadCrumb } from 'Components'   
import { CustomizePlateNumberEntry } from '../../Components'
 

const CustomizePlateNumber = () => {
     
    return ( 
        <div className="CustomizePlateNumber">
            
            <div className="container">
                <BreadCrumb parentTitle={'Registration'} parentUrl={'/customize-plate-number'} pageTitle={'Customize Plate Number'}></BreadCrumb>
                <CustomizePlateNumberEntry></CustomizePlateNumberEntry>
         
             </div>
        </div>
     );
}
 
export default CustomizePlateNumber;