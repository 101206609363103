import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { DriverLicenseType } from "../DriversLicenseRegistration/DriverLicensePackage";
import Swal from "sweetalert2";

interface propsType {
    module?: string
}

type FormData = { 
    firstName: string;
    otherNames: string; 
  };
  
const DriversLicenseRenewalFormElement:FC<propsType> = ({ module }) => {  
    const { service_id } = useParams<{ service_id: string }>()
     
    const packageInformation = useSelector((state: {vehicleInformation: DriverLicenseType})=> state.vehicleInformation)
   
    const [request, setRequest] = useState<boolean>(false)
    const { register, handleSubmit, formState: { errors } } = useForm<FormData>();

    const navigate = useNavigate()
     
    const submit = (data: FormData) => {
        setRequest(true)
        console.log("onSubmit", data); 
  
          try {
              localStorage.setItem("kit_new_reg", JSON.stringify({...packageInformation, ...data})); 
             
              navigate(module && module === 'user' ? `/myaccount/registration/drivers-license-renewal/${service_id}/upload-old-drivers-license` : `/registration/drivers-license-renewal/${service_id}/upload-old-drivers-license`, { replace: true });
             
             
              setRequest(false)
          } catch (error: unknown) {
              if (error instanceof DOMException && error.name === 'QuotaExceededError') {
                  console.log("Local storage quota exceeded.");
                  Swal.fire('Oops!', 'Local storage quota exceeded. Please free up some space.', 'warning');
              } else {
                  console.log("Error:", error);
                  Swal.fire('Oops!', 'An error occurred while storing data.', 'warning');
              }
              setRequest(false);
          }
      };
    
      
    return (   
        <form onSubmit={handleSubmit(submit)}>
            <div className="form-group mt-0">
                <label htmlFor="firstName">First Name</label> 
                <input type="text" id="firstName" className={`form-control ${errors.firstName ? 'is-invalid' : ''}`} placeholder="Woods" {...register("firstName", { required: "field can not be empty" })} />
                {errors.firstName && <small className='error'>{errors.otherNames?.message}</small>}
            </div>

            <div className="form-group mt-3">
                <label htmlFor="otherNames">Last Name</label> 
                <input type="text" id="otherNames" className={`form-control ${errors.otherNames ? 'is-invalid' : ''}`} placeholder="Tree" {...register("otherNames", { required: "field can not be empty" })} />
                {errors.otherNames && <small className='error'>{errors.otherNames.message}</small>}
            </div>
    
            
            <div className="form-group">  
                <button className='btn btn-primary' type='submit'>Continue</button> 
            </div> 
        </form>
     );
}
 
export default DriversLicenseRenewalFormElement;