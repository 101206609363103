import React from 'react'
import "./DriversLicenseRenewalForm.scss" 
import BreadCrumb from 'Components/BreadCrumb/BreadCrumb'; 
import { DriversLicenseRenewalFormElement } from 'Pages/Frontend/Components';

const DriversLicenseRenewalForm = () => {
    return (   
        <div className='RenewDriversLicense'>
            <div className="container">
                <BreadCrumb parentTitle={'Registration'} parentUrl={'/renew-drivers-license'} pageTitle={'Renew Drivers License'}></BreadCrumb>
                <div className="row justify-content-center">
                    <div className="col-md-5 mb-5 mt-4">
                        <div className="card border-0 py-3">
                            <div className="card-header text-left border-0 pt-3"> 
                                <div className="title">
                                What type of vehicle?
                                </div> 
                            </div>
                            <div className="card-body pt-2">
                                <DriversLicenseRenewalFormElement module='user'></DriversLicenseRenewalFormElement>
                             </div>
                        </div>
                    </div> 
                </div>
            </div>
        </div>
   )
}

export default DriversLicenseRenewalForm