import { InsuranceRegistrationType } from "Models/insuranceType"
import axios from "axios"
import { AxiosInterceptor } from "Config/AxiosInterceptor"
AxiosInterceptor()

export const insuranceService = {
    getProviders: async (limit:number, offset: number) => {
        return await axios.get(`/insurance/provider?limit=${limit}&offset=${offset}`)
    } ,
    
    registerThirdPartyInsurance: async (data:InsuranceRegistrationType) => {
        return await axios.post('/insurance/third-party', data)
    },
    
    registerComprehensiveInsurance: async (data:InsuranceRegistrationType) => {
        return await axios.post('/insurance/comprehensive', data)
    },
     
    getThirdPartyInsuranceById: async (id:string|undefined, limit:number, offset: number) => {
        return await axios.get(`/insurance/third-party?id=${id}&limit=${limit}&offset=${offset}`)
    },

    getComprehensiveInsuranceById: async (id:string|undefined, limit:number, offset: number) => {
        return await axios.get(`/insurance/comprehensive?id=${id}&limit=${limit}&offset=${offset}`)
    },
    
}