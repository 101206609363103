import React from 'react'
import "./DriversLicenseRegistrationForm.scss"
import { DriversLicenseRegistrationFormElement } from 'Pages/Frontend/Components'

const DriversLicenseRegistrationForm = () => {
    return ( 
        <div className="DriversLicenseRegistrationForm">
            <div className="container p-3 py-5"> 
                <div className="card border-0">
                    <div className="card-header bg-white text-center border-0 pt-3"> 
                        <div className="title border-bottom text-start pb-3 fw-bold">
                           Drivers License Application Form
                        </div>
                        <div className="tagline text-start pt-3 small fw-medium">
                           Personal Information
                        </div>
                    </div>
                    <div className="card-body mt-0">
                        <DriversLicenseRegistrationFormElement module='user'></DriversLicenseRegistrationFormElement>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DriversLicenseRegistrationForm
