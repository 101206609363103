import React from 'react'
import "./RenewExistingVehicle.scss"
import { RenewExistingVehicleOption } from 'Pages/Frontend/Components'

const RenewExistingVehicle = () => {
    return (
        <div className='RenewExistingVehicle'>
            <RenewExistingVehicleOption module="user"></RenewExistingVehicleOption>
        </div>
    )
}

export default RenewExistingVehicle
