import React from 'react' 
import cloud from 'Assets/Images/cloud-plus.svg' 

const UploadReprintElement = () => {
    return (
        <div className="w-100 drag-and-drop">
            <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
                <div className="blue-cloud-upload-img-container d-flex justify-content-center w-100">
                    <img src={cloud} alt="cloud" />
                </div>
                <div className="texts text-center">
                    Drag and drop files or <a href="/">Browse</a> to upload
                </div>
            </div>
        </div> 
    )
}

export default UploadReprintElement
