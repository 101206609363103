import './Login.scss'
import { Link, useNavigate } from 'react-router-dom';  
import { useForm } from 'react-hook-form';
import {  useState } from 'react'; 
import Swal from 'sweetalert2';
import { useAuth } from 'Components/AuthProvider/AuthProvider'; 
import { FaRegEyeSlash, FaRegEye } from 'react-icons/fa6';
import { authService } from 'Services/authService';
import { decodeToken } from "react-jwt";

type formType = { 
    email: string;
    password: string; 
}

export interface authUserObjectType {
    id: string,
    firstName: string,
    email: string|undefined,
    phoneNumber: string|undefined,
    exp: string,
    iat: string,
    role: string
 }

const Login = () => { 
    const navigate = useNavigate();
    const [request, setRequest] = useState<boolean>(false)
 
    const { login } = useAuth()
  
    const { register, handleSubmit, formState: { errors } } = useForm<formType>();

    const submit = handleSubmit((data: formType) => loginUser(data))
  
    const loginUser = async (data: formType) => {
        setRequest(true)
  
        await authService.login({ email: data.email, password: data.password})
         .then(async (loginResponse: {data: { 
            token: {
                access_token: string,
                refreshToken: string,
            }
        }}) => { 
            console.log("idToken", loginResponse)

            const myDecodedToken:authUserObjectType|null = decodeToken(loginResponse.data.token.access_token);

            console.log({myDecodedToken})
            
            const userObj = { 
                uid: myDecodedToken?.id,
                accessToken:  loginResponse.data.token.access_token,
                refreshToken: loginResponse.data.token.refreshToken,
                displayName:  myDecodedToken?.firstName,
                email: myDecodedToken?.email, 
                phoneNumber: myDecodedToken?.phoneNumber, 
                photoURL:  "",
                providerData: myDecodedToken 
            } 

            Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Welcome Back",
                showConfirmButton: false,
                timer: 2000
            });

            login({ ...userObj, switched: 0 });

            setRequest(false);

            setTimeout(() => {
                navigate('/myaccount/dashboard', { replace: true });
            }, 3000);

        }, error => { 
            console.log(error)
            setRequest(false)
            Swal.fire('Oops!', error.response.data.error.message, 'warning');
        })
                 
      }

   
    const [passwordType, setPasswordType] = useState<string>("password")

    const checkPasswordHideOut = () => {
        if(passwordType === 'password') {
            setPasswordType('text')
        } else {
            setPasswordType('password')
        }
    }

    return ( 
        <div className="Login py-5">
            
            <div className="container h-100 d-flex justify-content-center align-items-center">
                <div className="form-container p-4 d-flex justify-content-center align-items-center">
                    <div className="form-container ">
                    
                        <div className="form-header text-center">
                            <div className="form-header-title h2">Welcome back!</div>
                            <div className="form-notify-header text-secondary small">Log in to your Kitaniz Account</div>
                        </div>
 

                        <form className="form w-100 h-100"  onSubmit={submit}>
 
                            <div className="form-input py-2">
                                <input className={`form-control ${errors.email ? 'is-invalid' : ''}`} type="email" placeholder='Email'  {...register('email', { required : 'This field is required'})}/>
                                {errors.email && <small className='error'>{errors.email.message}</small>} 
                            </div>
                            
                            <div className="form-input py-2">
                                <div className="d-flex align-items-center form-control p-0 password">
                                   <input className={`border-0 w-100 px-3 ${errors.password ? 'is-invalid' : ''}`}  type={passwordType} placeholder='Password'  {...register('password', { required : 'This field is required'})}/> 
                                    <div className="btn-container d-flex justify-content-center align-items-center h-100 ps-3">
                                        <span className="cursor-pointer" onClick={checkPasswordHideOut} >{passwordType === 'password' ? <FaRegEyeSlash /> : <FaRegEye />}</span>
                                    </div>
                                 </div>
                                {errors.password && <small className='error'>{errors.password.message}</small>} 
                            </div>
                            
                            <div className="form-input mb-3">
                                <div className="small text-start">
                                    <Link to='/recorver-password' className='text-decoration-none ms-1'>Forgotten password?</Link>
                                </div>
                            </div>

                            <div className="form-input py-2">
                                <button className="form-control btn btn-primary rounded-pill" disabled={request}>Login {request && <span className='spinner-border spinner-border-sm'></span>}</button>
                            </div>
                            
                            <div className="form-input py-3">
                                    <div className="dhan-account text-center">
                                        Dont have an account?
                                        <Link to='/register' className='text-decoration-none ms-1 fw-bold'>Register</Link>
                                    </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default Login;