import React, { FC, useState } from 'react'
import './Footer.scss' 
import logo from "Assets/Icons/logo.svg";
import facebook from "Assets/Icons/facebook.svg";
import instagram from "Assets/Icons/instagram.svg";
// import tiktok from "Assets/Icons/tiktok.svg";
import twitter from "Assets/Icons/twitter.svg";
// import youtube from "Assets/Icons/youtube.svg";
import paymentmethod from "Assets/Icons/payment-method.svg";
import Logo from 'Assets/Images/RegXpress.png' 
import { Link } from 'react-router-dom';
import { FaXTwitter } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa";

interface propsType {
  module?: string
}

const FooterLarge:FC<propsType> = ({module}) => {
  const [isActive, setActive] = useState<any>({ isActive: false, menu: 0 });

  const handleToggle = (data:any) => {
    setActive({ isActive: !isActive, ...data });
  };
  
  return ( 
    <React.Fragment> 
       <footer className={module && module ==='estate'? "footer footer-estate fixed-footer": "footer fixed-footer"} id="footer">
          <div className="container"> 
            <div className="row mt-3">
              <div className={isActive.isActive && isActive.menu === 1 ? "col-sm-12 col-md-6 col-lg-3 widget active" : "col-sm-12 col-md-6 col-lg-3 widget"}>

              <img src={Logo} alt="LOGO" className="footer-logo-item d-none d-md-block mb-2"/>

              <h4 onClick={() => handleToggle({ isActive: true, menu: 1 })} className={isActive.isActive && isActive.menu === 1 ? "footer-widget-title mb-3 active d-md-none" : "footer-widget-title mb-3 d-md-none"}>Contact Information</h4>

              <div className="widget_content footer-widget-content"> 
                    <ul className="m-0 p-0 list-group">
                      <li className="list-group-item border-0 ps-0 mt-2">
                           <div className="border-0 location-address">©2023, Kitaniz. All Rights Reserved</div>
                       </li>
                      <li className="list-group-item border-0 ps-0">
                           <div className="border-0 location-address pt-0 mt-2">
                              <a href="https://www.facebook.com/crosscarz?mibextid=ZbWKwL">
                              <img src={facebook} alt='' className='me-2'></img>
                              </a>
                              <a href="https://www.instagram.com/kitaniz_?igsh=bHBobnZ4NHhnZ21p">
                              <img src={instagram} alt='' className='me-2'></img>
                              </a>
                              <a href="https://x.com/Kitaniz_Limited?t=xPyoqHP1-8HU-IwPn80IjQ&s=09">
                              <div className="icon-container rounded-pill bg-primary">
                                <FaXTwitter />
                              </div>
                              </a>
                              <a href="https://www.linkedin.com/company/kitaniz/">
                              <div className="icon-container rounded-pill bg-primary ms-2">
                                <FaLinkedinIn />
                              </div>
                              </a>
                              {/* <img src={tiktok} alt='' className='me-2'></img> */}
                              {/* <img src={youtube} alt='' className='me-2'></img>   */}
                           </div>
                       </li> 
                    </ul>
                </div> 
              </div>
              <div className={isActive.isActive && isActive.menu === 2 ? "col-sm-12 col-md-6 col-lg-2 widget active" : "col-sm-12 col-md-6 col-lg-2 widget"}>
                <h4 onClick={() => handleToggle({ isActive: true, menu: 2 })} className={isActive.isActive && isActive.menu === 2 ? "footer-widget-title mb-3 active" : "footer-widget-title mb-3"}>Services</h4>

                <div className="widget_content footer-widget-content pb-5">
                  <nav className="nav flex-column mb-3">
                    <Link className="nav-link" to="/new-registration">Registration</Link> 
                    {/* <Link className="nav-link" to="/insurance">Insurance</Link>
                    <Link className="nav-link" to="/tracking-vehicle">Tracker</Link> */}
                  </nav>
                </div> 
              </div>
              <div className={isActive.isActive && isActive.menu === 4 ? "col-sm-12 col-md-6 col-lg-2 widget active" : "col-sm-12 col-md-6 col-lg-2 widget"}>
                <h4 onClick={() => handleToggle({ isActive: true, menu: 4 })} className={isActive.isActive && isActive.menu === 4 ? "footer-widget-title mb-3 active" : "footer-widget-title mb-3"}>Company</h4>

                <div className="widget_content footer-widget-content">
                  <nav className="nav flex-column mb-3">
                    <Link className="nav-link active" to="/about">About</Link> 
                    {/* <Link className="nav-link" to="/blog">Blog</Link> */}
                    <Link className="nav-link" to="/frequently-asked-questions">FAQs</Link> 
                  </nav>
                </div>
              </div>

              <div className={isActive.isActive && isActive.menu === 3 ? "col-sm-12 col-md-6 col-lg-2 widget active" : "col-sm-12 col-md-6 col-lg-2 widget"}>
                <h4 onClick={() => handleToggle({ isActive: true, menu: 3 })} className={isActive.isActive && isActive.menu === 3 ? "footer-widget-title mb-3 active" : "footer-widget-title mb-3"}>Legal</h4>

                <div className="widget_content footer-widget-content">
                  <nav className="nav flex-column mb-3">
                    <a className="nav-link active" href="/products-by-category/e40d9bba-8ccb-4daa-a36f-51ba9eee575e">Return policy</a>
                    <a className="nav-link" href="/products-by-category/b7d897ec-1e07-4d07-a299-8227318555d5">Privacy policy</a>
                    <a className="nav-link" href="/products-by-category/40f9e3e7-fa9b-40c6-9613-8126a2b833b0">Terms of use</a> 
                  </nav>
                </div>
              </div>

              <div className={isActive.isActive && isActive.menu === 6 ? "col-sm-12 col-md-6 col-lg-2 widget active" : "col-sm-12 col-md-6 col-lg-2 widget"}>
                <h4 onClick={() => handleToggle({ isActive: true, menu: 6 })} className={isActive.isActive && isActive.menu === 6 ? "footer-widget-title mb-3 active" : "footer-widget-title mb-3"}>Get in Touch</h4>

                <div className="widget_content footer-widget-content">
                  <nav className="nav flex-column mb-3">
                    <a className="nav-link active" href="mailto:support@kitaniz.com">support@kitaniz.com</a>
                    <a className="nav-link" href="tel:+2348105578524">+2348105578524</a>
                    <a className="nav-link">No 1 akisanya street, Ojodu Berger. Lagos</a> 
                  </nav>
                </div>
              </div>

              <div className={isActive.isActive && isActive.menu === 5 ? "col-sm-12 col-md-6 col-lg-2 widget active d-none" : "col-sm-12 col-md-6 col-lg-2 widget d-none"}>
                <h4 onClick={() => handleToggle({ isActive: true, menu: 5 })} className={isActive.isActive && isActive.menu === 5 ? "footer-widget-title mb-3 active" : "footer-widget-title mb-3"}>Resources</h4>

                <div className="widget_content footer-widget-content">
                  <nav className="nav flex-column mb-3">
                    <a className="nav-link" href="/">How to sell fast on CinderBuild</a>
                    <a className="nav-link" href="/">Learn how to post strategically and make the best sales on CinderBuild</a>
                    <a className="nav-link" href="/">Top 10 best selling brands on CinderBuild</a>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </footer>
 
        <div className="footer-credit text-center">
            <div className="container">
              <div className="row">
                <div className="col-md-6">We accept online card payments as well as wire transfers.</div>
                <div className="col-md-6"><img src={paymentmethod} alt=""></img></div>
              </div> 
            </div>
        </div>  
    </React.Fragment>
  )
}

export default FooterLarge