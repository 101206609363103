import React from 'react'
import "./RegistrationSummaryAuth.scss"
import { BreadCrumb } from 'Components' 
import { RegistrationSummaryItem } from 'Pages/Frontend/Components';

const RegistrationSummaryAuth = () => {
    return (
        <div className='RegistrationSummaryAuth'> 
            <div className="container">
                <BreadCrumb parentTitle={'Registration'} parentUrl={'/register-vehicle'} pageTitle={'New Registration'} childTitle={'Summary'}></BreadCrumb>
            
                <RegistrationSummaryItem module="user"></RegistrationSummaryItem>
           </div>
        </div>
    )
}

export default RegistrationSummaryAuth
