import { vehicleServiceType } from 'Models';
import React, { FC, Fragment, Key } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom' 
import { useState } from 'react';
import { CurrencyFormatter } from '../../../../Helpers/CurrencyFormatter';
import { VEHICLE_REG_STEP_ONE } from 'Constants/RegistrationConstant';
import { useDispatch } from 'react-redux';

interface propsType {
    module?: string;
    vehicleServices?: vehicleServiceType
}

export interface DriverLicenseType { 
        id: string,
        time: string,
        name: string,
        description: string,
        path: string,
        imageUrl: string,
        amount: string,
        standalone: boolean,
        embedded: boolean,
        minimumRequirement: string,
        status: boolean,
        relative: boolean,
        percentage: number,
        processingTime: number
    
}

const DriverLicensePackage:FC<propsType> = ({ module, vehicleServices }) => {  
    const [ selectedType, setSelectedType] = useState<DriverLicenseType>()
    const navigate = useNavigate()
    const {service_id} = useParams()
    const dispatch = useDispatch()


    const goNext = ()=>{

       dispatch({ type: VEHICLE_REG_STEP_ONE, payload: selectedType}); 

        navigate(module && module ==='user'? `/myaccount/registration/drivers-license-registration/${service_id}/biodata` : "/drivers-license-reg", {replace: true})
    }
    return (
        <Fragment>
            <div className="card border-0">
                <div className="card-header text-center border-0 pt-4"> 
                    <div className="title border-bottom text-start pb-2 fw-bold">
                        Drivers License
                    </div>
                </div>
                <div className="card-body px-0 pb-0">
                    <div className='px-3 mt-3'>
                        <div className="tagline text-start small">
                            Validity duration
                        </div>

                        <div className="row btn-cont mb-4 mt-3"> 
                            {vehicleServices && vehicleServices.types.map((serviceType: any, index: Key) => {
                            return <div  key={index} className={`col-6 btn${selectedType && serviceType.id===selectedType.id? 1: 2}-cont`}>
                                <button className="btn btn-selection rounded-pill py-2 px-1 w-100 fw-bold" onClick={()=> setSelectedType(serviceType)}>{serviceType.name}</button>
                            </div>})} 
                        </div>
                    </div>
 
                    <div className="card-isol p-3 d-flex justify-content-between small">
                        <span className='small'>Processing time</span>
                        <span className='small'>{selectedType && selectedType.processingTime} Weeks</span>
                    </div>
                    
                    <div className="card-footer bg-white py-3 d-flex justify-content-between fw-bold border-0">
                        <span className='fw-bold'>Total</span>
                        <span className='fw-bold'>N{selectedType? CurrencyFormatter(+selectedType.amount):0.0}</span>
                    </div>
                </div> 
            </div>

            <div className="form-group row mt-2">
                <div className="my-auto p-3">    
                    <button className='btn btn-primary continue-btn form-control rounded-pill' onClick={goNext} disabled={selectedType? false:true}>Continue</button> 
                </div>
            </div>
        </Fragment>
    )
}

export default DriverLicensePackage
