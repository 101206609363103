import React from 'react';
import './Loader.scss'; 
import kitaniz_gif from '../../../../Assets/gif/icon_gif.gif' 

const Loader = () => {
  return (
    <div className="loader-component">
      <img src={kitaniz_gif} alt="loading...." height="30%"/>
    </div>
  );
};

export default Loader;
