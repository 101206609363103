import React from 'react';
import './PaymentSkeleton.scss'; 

const PaymentSkeleton = () => {
  return (
    <div className="PaymentSkeleton card border-0">
      <div className="card-body mt-4"> 
        <div className="payment-head">
             <div className="skeleton-loader payment-icon rounded-circle"></div> 
        </div>

        <div className="payment-head">
            <div className='text-center'>
                <div className="skeleton-loader payment-title"></div>
                <div className="skeleton-loader payment-desc"></div>
                <div className="skeleton-loader payment-total"></div>
            </div>
          </div>

        <div className="details border-top mt-5">
          <div className="container">
            <div className="row">
              {[1].map((item) => (
                <React.Fragment key={item}>
                  <div className="col-6">
                    <div className="skeleton-loader label"></div>
                  </div>
                  <div className="col-6 d-flex justify-content-end text-end">
                    <div className="skeleton-loader value"></div>
                  </div>
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>

        <div className="payment-bottom">
          <div className="container-fluid">
            <div className="row">
              <div className="col-6">
                <div className="skeleton-loader btn-share-receipt"></div>
              </div>
              <div className="col-6">
                <div className="skeleton-loader btn-view-order"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentSkeleton;
