import './DriversLicenseReg.scss'


const DriversLicenseReg = () => {
    return ( 
        <div className="DriversLicenseReg">
            <div className="container p-3 py-5">
                <form className="form p-3 rounded mx-auto">
                    
                    <div className="card-header text-center border-0 pt-3"> 
                        <div className="title border-bottom text-start pb-3 fw-bold">
                            Renew Drivers License
                        </div>
                        <div className="tagline text-start pt-3 small fw-medium">
                            Validity duration
                        </div>
                    </div>

                    <div className="row">
                        
                        <div className="first-col p-3 col-md-6">
                            <div className="form-input">
                                <label htmlFor="">TItle (Mr/Mrs/Miss) Suraname</label>
                                <input type="text" className="form-control" placeholder='Mr Woods' />
                            </div>
                        </div>
                        
                        <div className="first-col p-3 col-md-6">
                            <div className="form-input">
                                <label htmlFor="">First Name</label>
                                <input type="text" className="form-control" placeholder='Woods' />
                            </div>
                        </div>
                        
                        <div className="first-col p-3 col-md-6">
                            <div className="form-input">
                                <label htmlFor="">Other Name</label>
                                <input type="text" className="form-control" placeholder='Woods' />
                            </div>
                        </div>
                        
                        <div className="first-col p-3 col-md-6">
                            <div className="form-input">
                                <label htmlFor="">Sex</label>
                                <select className='form-control' name="" id="">
                                    <option value="m">Male</option>
                                    <option value="m">Female</option>
                                </select>
                            </div>
                        </div>


                        
                        <div className="first-col p-3 col-md-6">
                            <div className="form-input">
                                <label htmlFor="">Date of Birth (DD/MM/YYYY)</label>
                                <input type="text" className="form-control" placeholder='11/12/1994' />
                            </div>
                        </div>
                        
                        <div className="first-col p-3 col-md-6">
                            <div className="form-input">
                                <label htmlFor="">Place of Birth (City/Town)</label>
                                <select className='form-control' name="" id="">
                                    <option value="m">Lagos</option>
                                    <option value="m">Ilorin</option>
                                </select>
                            </div>
                        </div>

                        
                        <div className="first-col p-3 col-md-6">
                            <div className="form-input">
                                <label htmlFor="">Marital Status</label>
                                <select className='form-control' name="" id="">
                                    <option value="m">Single</option>
                                    <option value="m">Married</option>
                                </select>
                            </div>
                        </div>
                        
                        <div className="first-col p-3 col-md-6">
                            <div className="form-input">
                                <label htmlFor="">Nationality</label>
                                <select className='form-control' name="" id="">
                                    <option value="m">Nigeria</option>
                                    <option value="m">USA</option>
                                </select>
                            </div>
                        </div>
                        
                        <div className="first-col p-3 col-md-6">
                            <div className="form-input">
                                <label htmlFor="">Address</label>
                                <input type="text" className="form-control" placeholder='44, Paul street, victoris island, Lagos, Nigeria.' />
                            </div>
                        </div>
                        
                        <div className="first-col p-3 col-md-6">
                            <div className="form-input">
                                <label htmlFor="">State of Origin</label>
                                <input type="text" className="form-control" placeholder='Lagos' />
                            </div>
                        </div>
                        
                        <div className="first-col p-3 col-md-6">
                            <div className="form-input">
                                <label htmlFor="">Local Govt. Area of Origin</label>
                                <input type="text" className="form-control" placeholder='Oti Osa' />
                            </div>
                        </div>

                        
                        <div className="first-col p-3 col-md-6">
                            <div className="form-input">
                                <label htmlFor="">Phone Number</label>
                                <input type="text" className="form-control" placeholder='+23491600000' />
                            </div>
                        </div>
                        
                        <div className="first-col p-3 col-md-6">
                            <div className="form-input">
                                <label htmlFor="">Mother’s Maiden Name</label>
                                <input type="text" className="form-control" placeholder='Mary' />
                            </div>
                        </div>
                        
                        <div className="first-col p-3 col-md-6">
                            <div className="form-input">
                                <label htmlFor="">Blood Group</label>
                                <select className='form-control' name="" id="">
                                    <option value="m">O+</option>
                                    <option value="m">AA</option>
                                </select>
                            </div>
                        </div>
                        
                        <div className="first-col p-3 col-md-6">
                            <div className="form-input">
                                <label htmlFor="">Height in Meters</label>
                                <select className='form-control' name="" id="">
                                    <option value="m">170m</option>
                                    <option value="m">175m</option>
                                </select>
                            </div>
                        </div>
                        
                        <div className="first-col p-3 col-md-6">
                            <div className="form-input">
                                <label htmlFor="">Next of Kin Phone Number</label>
                                <input type="text" className="form-control" placeholder='+23491600000' />
                            </div>
                        </div>

                        <div className="row small">
                            <button className="btn rounded-pill col-md-3 mx-3">Submit Application</button>
                        </div>

                    </div>
                </form>
            </div>
        </div>
     );
}
 
export default DriversLicenseReg;