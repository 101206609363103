import { createVehicleInformationType } from '@/Models/vehicleServiceType'
import { vehicleService } from 'Services'
import React, { FC, Fragment, Key, useState } from 'react' 
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import state from "Data/stateNG.json"
import lgas from "Data/lgaNG.json"

interface propsType {
    module?: string | undefined
}

type formType = {
    engineNumber: string;
    chasisNumber: string;
    color: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
    localGovernment: string;
    address: string;
    state: string
}

const RenewVehicleParticularsFlow:FC<propsType> = ({module}) => {
    
    const [request, setRequest] = useState(false)

    const  vehicleInformation = useSelector((state: {vehicleInformation: {vehicleMakeId: string, vehicleModelId: string, type: string, year: string }})=> state.vehicleInformation)

    const { service_id } = useParams()

    const [selectLgas, setSelectedLgas] = useState<Array<{name: string, id: number}>>([])
    const [selectState, setSelectedState] = useState<string>("")

    const navigate = useNavigate()
    const [step, setStep] = useState(1)

    const addStep = () => { 
        if(step <= 2){
            setStep(step + 1);
        } else {  
           
        }
    }

    const remStep = () => { 
        if(step >= 1){
            setStep(step - 1);
        } 
    }

    const { register, handleSubmit, trigger, formState: { errors }} = useForm<formType>()

    const submit = handleSubmit(async (data) =>{  
        setRequest(true)

        const newdata:createVehicleInformationType[] = [
            {
              vehicleMakeId: vehicleInformation.vehicleMakeId,
              vehicleModelId: vehicleInformation.vehicleModelId,
              year: Number(vehicleInformation.year),
              engineNumber: data.engineNumber,
              chasisNumber: data.chasisNumber,
              color: data.color,
              type: vehicleInformation.type
            }
        ]

        await vehicleService.createVehicle(newdata).then((response)=>{
            console.log("createVehicle response", response)
            setRequest(false)  
           
            try {
                localStorage.setItem("kit_new_reg", JSON.stringify({...vehicleInformation, ...data, vehicleInformationId: response.data[0]})); 
                navigate(module && module === 'user' ? `/myaccount/registration/renew-registration/${service_id}/registration-summary` : "/renew-registration/registration-summary", { replace: true });

            } catch (error: unknown) {
                if (error instanceof DOMException && error.name === 'QuotaExceededError') {
                    console.log("Local storage quota exceeded.");
                    Swal.fire('Oops!', 'Local storage quota exceeded. Please free up some space.', 'warning');
                } else {
                    console.log("Error:", error);
                    Swal.fire('Oops!', 'An error occurred while storing data.', 'warning');
                }
                setRequest(false);
            }
         
        }, (error)=>{
            console.log("error", error)
            Swal.fire('Oops!', error.response.data.message, 'warning'); 
            setRequest(false)
        })
  

    })


    const handleState = (e: any) => {
        const state = JSON.parse(e.target.value); 
        setSelectedState(state.name)
        const localGovt: any = lgas[2].data;
        const getLga: any = localGovt.filter((res: any) => Number(res.state_id) === Number(state.id))
        setSelectedLgas(getLga)
      }

    console.log("vehicleInformation", vehicleInformation)

  
  const validatePhoneNumber = (value: string) => {
    // Regular expression to validate phone number with country code
    const phoneRegex = /^\+\d{1,3}-\d{3,14}$/;
    return phoneRegex.test(value) || 'Phone number must have a leading country code (e.g., +23481456789..)';
  };

  const handleChange = () => {  
    trigger('phoneNumber'); 
  };

    return ( 
        <div className="card border-0 py-3">
            <div className="card-header text-left border-0 pt-3 px-0"> 
                <div className="title">
                    What type of vehicle?
                </div> 

                <div className="stepper">
                    <div className={step >= 1? "steps active": "steps"}>
                        <div className='steping'> 
                            <div className="step-child">
                                <span>{(step >= 2 || (step <= 3 && step > 2) )?
                                    <svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.5 3.83L4.33 6.66L10 1" stroke="white" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                                    </svg>: 1
                                }</span> 
                            </div>
                            <div className="label">Vehicle Info</div>
                        </div>
                    </div>
                    <div className={step >= 2? "steps active": "steps"}>
                        <div className='steping'>
                            <div className="step-child">
                                <span>{step === 3 && step <= 3? 
                                    <svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.5 3.83L4.33 6.66L10 1" stroke="white" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                                    </svg> : 2
                                }</span> 
                            </div>
                            <div className="label">Personal Info</div>
                        </div>
                    </div>
                    <div className={step >= 3? "steps active": "steps"}>
                        <div className='steping'>
                            <div className="step-child">
                                <span>{step === 4? 
                                    <svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.5 3.83L4.33 6.66L10 1" stroke="white" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                                    </svg>: 3
                                }</span> 
                            </div>
                            <div className="label">Contact Info</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card-body pt-2 px-0">
                <form onSubmit={submit}>  
                   {step === 1 && <Fragment>
                        <div className="form-group mt-0">
                            <label htmlFor="engineNumber">Engine Number</label>
                            <input type="text" {...register("engineNumber")} id="engineNumber" className='form-control'/> 
                            {errors.engineNumber && <small className='error'>{errors.engineNumber.message}</small>} 
                        </div>
                        
                        <div className="form-group mt-3">
                            <label htmlFor="chasisNumber">Chasis Number</label>
                            <input type="text" {...register("chasisNumber")} id="chasisNumber" className='form-control'/> 
                            {errors.chasisNumber && <small className='error'>{errors.chasisNumber.message}</small>} 
                        </div>


                        <div className="form-group mt-3">
                            <label htmlFor="color">Color</label>
                            <select {...register("color")} id="color" className='form-control'>
                                <option value="">select color</option>
                                <option value="red">Red</option>
                                <option value="black">Black</option>
                                <option value="yellow">Yellow</option>
                            </select>
                            {errors.color && <small className='error'>{errors.color.message}</small>} 
                        </div> 
                    </Fragment>}

                   {step === 2 && <Fragment>
                       <div className="form-group mt-0">
                            <label htmlFor="firstName">First Name</label>
                            <input type="text" {...register("firstName", { required: "Field can not be empty"})} id="firstName" className='form-control'/> 
                            {errors.firstName && <small className='error'>{errors.firstName.message}</small>} 
                        </div>
                        
                        <div className="form-group mt-3">
                            <label htmlFor="lastName">Last Name</label>
                            <input type="text" {...register("lastName", { required: "Field can not be empty"})} id="lastName" className='form-control'/> 
                            {errors.lastName && <small className='error'>{errors.lastName.message}</small>} 
                        </div>
                          
                        <div className="form-group mt-3">
                            <label htmlFor="phoneNumber">Phone Number</label>
                            <input type="tel" {...register("phoneNumber", { required: "Field can not be empty", /**validate: validatePhoneNumber, onChange: handleChange**/})} id="phoneNumber" className='form-control'/> 
                            {errors.phoneNumber && <small className='error'>{errors.phoneNumber.message}</small>} 
                        </div>

                        <div className="form-group mt-3">
                            <label htmlFor="email">Email</label>
                            <input type="text" {...register("email", { required: "Field can not be empty"})} id="email" className='form-control'/> 
                            {errors.email && <small className='error'>{errors.email.message}</small>} 
                        </div> 
                    </Fragment>}

                   {step === 3 && <Fragment>
                    <div className="form-group mt-0">
                            <label htmlFor="address">Address</label>
                            <input type="text" {...register("address", { required: "Field can not be empty"})} id="address" className='form-control' placeholder=""/> 
                            {errors.address && <small className='error'>{errors.address.message}</small>} 
                        </div>
                        
                         <div className='form-group mt-3'>
                            <label htmlFor="state">State</label>
                            <select id="state" className={`form-control ${errors.state ? 'is-invalid' : ''}`} {...register('state', { required: "Field can not be empty", onChange: (e) => handleState(e)})}>
                                <option>select state</option>
                                {state.map((stateItem:{name: string}, index: Key)=><option key={index} value={JSON.stringify(stateItem)}>{stateItem.name}</option>)}
                            </select>
                            {errors.state && <small className='error'>{errors.state.message}</small>} 
                        </div>

                        <div className='form-group mt-3'>
                            <label htmlFor="lga">L.G.A</label>
                            <select id="lga" className={`form-control ${errors.localGovernment ? 'is-invalid' : ''}`} {...register('localGovernment',  { required: "Field can not be empty"})}>
                                <option>select L.G.A</option>
                                {selectLgas.map((stateItem:{name: string}, index: Key)=><option key={index}>{stateItem.name}</option>)}
                            </select>
                            {errors.localGovernment && <small className='error'>{errors.localGovernment.message}</small>} 
                        </div> 
                    </Fragment>}


                    <div className="row mt-3">
                        <div className="col-6 col-md-6">
                            <button className={step >=2 ? "btn btn-primary-outline": "btn btn-primary-outline disabled"} type='button' onClick={remStep}>Prev</button>
                        </div>
                        <div className="col-6 col-md-6 text-end">  
                            <button className={step < 3 ? "btn btn-primary": "btn btn-primary d-none"} type='button' onClick={addStep}>Next</button>  
                            <button className={step < 3 ? "btn btn-primary d-none": "btn btn-primary"} type='submit' disabled={request}>
                                {request && <span className='spinner-border spinner-border-sm'></span>} Next</button>  
                        </div>
                    </div>
                </form>
            </div>
        </div> 
    )
}

export default RenewVehicleParticularsFlow
