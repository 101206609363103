import { Link } from 'react-router-dom';
import './WhatTypeOfVehicle.scss'


const WhatTypeOfVehicle = () => {
    return (
        <div className="WhatTypeOfVehicle">
            
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-5 my-5">
                       <div className="card border-0">
                        <div className="card-header text-start border-0 pt-4"> 
                            <div className="title">
                                What type of vehicle?
                            </div>
                        </div>
                        <div className="card-body">
                            <form action="">
                                <div className="form-group">
                                    <label htmlFor="">Make</label>
                                    <select name="" id="" className='form-control'>
                                        <option value="">select make</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Model</label>
                                    <select name="" id="" className='form-control'>
                                        <option value="">select model</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Year</label>
                                    <select name="" id="" className='form-control'>
                                        <option value="">select year</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Type</label>
                                    <select name="" id="" className='form-control'>
                                        <option value="">Commercial</option>
                                    </select>
                                </div>

                                

                                {/* <div className="form-group">
                                    <input type="checkbox" id="myCheckbox"></input>
                                    <label htmlFor="myCheckbox" className="checkbox-label">Unaccidented</label>
                                </div>  */}
                                
                                <div className="form-group row">
                                    <div className="col-12 my-auto">
                                        <button className='btn btn-primary w-100'>Confirm vehicle</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                       </div>
                    </div>
                    
                    <div className="col-lg-5 my-5">
                    <div className="card border-0">
                        <div className="card-header text-center border-0 pt-4"> 
                            <div className="title border-bottom text-start pb-3">
                                Toyota Camry
                            </div>
                            <div className="tagline text-start pt-3">
                                Documents you will get
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="btn-cont d-flex">

                                <div className="btn1-cont p-3 w-50">
                                
                                    <div className="form-group">
                                        <input type="checkbox" id="myCheckbox1"></input>
                                        <label htmlFor="myCheckbox1" className="checkbox-label">Vehicle License</label>
                                    </div> 
                                    
                                    <div className="form-group">
                                        <input type="checkbox" id="myCheckbox2"></input>
                                        <label htmlFor="myCheckbox2" className="checkbox-label">Road worthiness</label>
                                    </div> 

                                    <div className="form-group">
                                        <input type="checkbox" id="myCheckbox3"></input>
                                        <label htmlFor="myCheckbox3" className="checkbox-label">Plate number</label>
                                    </div> 
                                    
                                    <div className="form-group">
                                        <input type="checkbox" id="myCheckbox4"></input>
                                        <label htmlFor="myCheckbox4" className="checkbox-label">VIN</label>
                                    </div> 

                                </div>

                                <div className="btn2-cont p-3 w-50">

                                
                                    <div className="form-group">
                                        <input type="checkbox" id="myCheckbox5"></input>
                                        <label htmlFor="myCheckbox5" className="checkbox-label">Insurance</label>
                                    </div> 
                                    
                                    <div className="form-group">
                                        <input type="checkbox" id="myCheckbox6"></input>
                                        <label htmlFor="myCheckbox6" className="checkbox-label">CRM</label>
                                    </div> 
                                    
                                    {/* <div className="form-group">
                                        <input type="checkbox" id="myCheckbox7"></input>
                                        <label htmlFor="myCheckbox7" className="checkbox-label">Proof of ownership</label>
                                    </div>  */}
                                    
                                    <div className="form-group">
                                        <input type="checkbox" id="myCheckbox8"></input>
                                        <label htmlFor="myCheckbox8" className="checkbox-label">Hackney permit</label>
                                    </div> 


                                </div>

                            </div>
                        </div>
                        <div className="card-isol p-3 d-flex justify-content-between border-0 small">
                            <span>Processing time</span>
                            <span>2 Weeks</span>
                        </div>
                        
                        <div className="card-footer p-3 d-flex justify-content-between border-0 fw-bold">
                            <span>Total</span>
                            <span>N200,000.00</span>
                        </div>

                        
                    </div>

                        <div className="form-group row mt-3">
                            <div className="col-12 my-auto">
                                <Link to='/new-owners-information'>
                                    <button className='btn btn-primary w-100'>Continue</button>
                                </Link>
                            </div>
                        </div>

                    </div>
                </div>
             </div>
        </div>
    )
}

export default WhatTypeOfVehicle;