import React, { FC, useEffect, useState } from 'react'
import "./Profile.scss"
import { useForm } from 'react-hook-form'; 
import { auth } from 'Services/firebaseInitService';
import { User } from 'firebase/auth';


interface ProfileFormType {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    address: string;
    country: string;
    state: string;
    city: string;
}

interface UserProfile {
    uid: string;
    email: string | null;
    displayName?: string | null;
    photoURL?: string | null;
    phoneNumber?: string | null; 
}

const Profile: FC = () => {

    const [request, setRequest] = useState(false);
    const { register, handleSubmit, setValue, formState: { errors } } = useForm<ProfileFormType>();

    const submit = handleSubmit((data: ProfileFormType) => {
        setRequest(true); 
        console.log(data);  
    });


    const [userProfile, setUserProfile] = useState<UserProfile|null>();

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (user: User | null) => {
            console.log("user", user)
            setUserProfile(user)
        }); 
        return () => unsubscribe();
    }, []);

  
    return ( 
        <div className="Profile">
            
            <div className="profile-header d-flex justify-content-start align-items-center my-3">
                <div className="profile-pg-title">Profile Details</div>
            </div>

            <div className=""> 
                <form onSubmit={submit}>
                    <div className="row justify-content-center">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label htmlFor="firstName">First Name</label>
                                <input type="text" id="firstName" className={`form-control ${errors.firstName ? 'is-invalid' : ''}`} {...register('firstName')} />
                                {errors.firstName && <span className="invalid-feedback">{errors.firstName.message}</span>}
                            </div>
                            <div className="form-group">
                                <label htmlFor="lastName">Last Name</label>
                                <input type="text" id="lastName" className={`form-control ${errors.lastName ? 'is-invalid' : ''}`} {...register('lastName')} />
                                {errors.lastName && <span className="invalid-feedback">{errors.lastName.message}</span>}
                            </div>
                            <div className="form-group">
                                <label htmlFor="email">Email</label>
                                <input type="email" id="email" className={`form-control ${errors.email ? 'is-invalid' : ''}`} {...register('email')} />
                                {errors.email && <span className="invalid-feedback">{errors.email.message}</span>}
                            </div>
                            <div className="form-group">
                                <label htmlFor="phoneNumber">Phone Number</label>
                                <input type="number" id="phoneNumber" className={`form-control ${errors.phoneNumber ? 'is-invalid' : ''}`} {...register('phoneNumber')} />
                                {errors.phoneNumber && <span className="invalid-feedback">{errors.phoneNumber.message}</span>}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label htmlFor="address">Address</label>
                                <input type="text" id="address" className={`form-control ${errors.address ? 'is-invalid' : ''}`} {...register('address')} />
                                {errors.address && <span className="invalid-feedback">{errors.address.message}</span>}
                            </div> 
                            <div className="form-group">
                                <label htmlFor="state">State</label>
                                <select id="state" className={`form-control ${errors.state ? 'is-invalid' : ''}`} {...register('state')}>
                                    {/* Options for state */}
                                </select>
                                {errors.state && <span className="invalid-feedback">{errors.state.message}</span>}
                            </div>
                            <div className="form-group">
                                <label htmlFor="city">City</label>
                                <input type="text" id="city" className={`form-control ${errors.city ? 'is-invalid' : ''}`} {...register('city')} />
                                {errors.city && <span className="invalid-feedback">{errors.city.message}</span>}
                            </div>
                        </div>
                    </div>

                    <div className="form-group mb-5">
                        <button className='btn rounded-pill px-4' type='submit' disabled={request}>
                            {request && <span className='spinner-border spinner-border-sm'></span>} Save Changes
                        </button>
                    </div>
                </form> 
            </div>
        </div>
     );
}
 
export default Profile;