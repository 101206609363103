import React, { useEffect, useState } from 'react'
import "./Checkout.scss"
import { BreadCrumb } from 'Components' 
import { CheckoutElement } from '../../Components'
import { userService, vehicleService } from 'Services'
import Swal from 'sweetalert2'
import { vehicleServiceType } from 'Models'
import { deliveryListType } from 'Models/deliveryType'
import { useParams } from 'react-router-dom'

const Checkout = () => {

   const limit = 100;
   const offset = 0;
    
   const { service_id, type } = useParams<{ service_id: string; type: string }>()

   const  vehicleInformation = JSON.parse(localStorage.getItem('kit_new_reg')?? '')
  
   const [request, setRequest] = useState(false)
   const [address, setAddress] = useState<Array<deliveryListType>>()

   
   
   const [vehicleServices, setVehicleServices] = useState<vehicleServiceType>()
 
   const getServices = async () =>{
       await vehicleService.serviceItem(service_id, limit, offset).then((response) =>{
           console.log("response vehicleServices", response.data)
           setVehicleServices(response.data[0])
       }, error => {

           console.log("error vehicleServices", error)
           Swal.fire({
               position: "top-end",
               icon: "warning",
               text: error.response.data.message,
               showConfirmButton: false,
               timer: 3000
           });
       })
   }

   useEffect(() => {
       getServices()
   }, [service_id])

   
   const getAddress = async () => {
        setRequest(true)
        await userService.getDeliveryAddress(limit, offset).then((response)=>{ 
            setRequest(false)

            setAddress(response.data)
            console.log('Address', response.data)

            Swal.fire({
                position: "top-end",
                icon: "success",
                title: "",
                showConfirmButton: false,
                timer: 1600
            });

            console.log("getDeliveryAddress", response)
        }, error =>{ 
            Swal.fire('Oops!', error.response.data.message, 'warning'); 
            setRequest(false)
        })
    }

    useEffect(() => {
        getAddress()
    }, [])


   const reloadAddress = () => getAddress()
   
    return (
        <div className='Checkout'>
            <BreadCrumb parentTitle={'Registration'} parentUrl={'/register-vehicle'} pageTitle={'New Registration'} childTitle={'Checkout'}></BreadCrumb>
            
            <div className="container"> 
               <CheckoutElement  vehicleService={vehicleServices} vehicleInformation={vehicleInformation} address={address} reloadAddress={reloadAddress} request={request}></CheckoutElement>
            </div>
        </div>
    )
}

export default Checkout
