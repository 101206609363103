import React from 'react'
import "./InsuranceVehicleParticularsAuth.scss"
import { BreadCrumb } from 'Components' 
import { InsuranceVehicleParticularsFlow } from 'Pages/Frontend/Components';
 
const InsuranceVehicleParticularsAuth = () => {
   
    return (
        <div className='VehicleParticularsAuth'>
              <div className="container">
                <BreadCrumb parentTitle={'Registration'} parentUrl={'/register-vehicle'} pageTitle={'New Registration'}></BreadCrumb>
                <div className="row justify-content-center">
                    <div className="col-md-7 col-xl-5 mb-5 mt-4">
                    <InsuranceVehicleParticularsFlow module="user"></InsuranceVehicleParticularsFlow>
                </div>
            </div>
        </div>
    </div>
    )
}

export default InsuranceVehicleParticularsAuth
