
import yourChoice from '../../../../../Assets/Images/YourChoice.svg'
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";
import './YourChoice.scss'
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
  
import { useRef } from 'react';
 
 
const YourChoice = () => {
    const swiperRef = useRef<any>(null);

  const handlePrev = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNext = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideNext();
    }
  };


    return ( 
        <div className="YourChoice">
            
            <div className="container mb-5">
                <div className="title-tag">NOT SURE OF YOUR CHOICE?</div>

                <div className="row">
                    <div className="title col-xl-8">Check Out Popular Models</div>
                    <div className="col-xl-4">
                        <ul className="nav nav-pills mb-1 shadow-sm p-2" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link rounded-pill active" id="pills-cars-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Cars</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link rounded-pill" id="pills-suv-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">SUV</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link rounded-pill" id="pills-van-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Mini-Van</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link rounded-pill" id="pills-sport-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Sport</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link rounded-pill" id="pills-truck-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Truck</button>
                            </li>
                        </ul>
                    </div>
                </div>


                <div className="card-container mt-5 d-flex position-relative justify-content-center">
 
                    <Swiper
                        ref={swiperRef}
                        slidesPerView={'auto'}
                        spaceBetween={30}  
                        className="mySwiper"
                    >
                    <SwiperSlide> 
                        <div className="card border-0">
                            <div className="card-body">
                                <div className="card-img-container d-flex justify-content-center">
                                    <img src={yourChoice} alt="yourChoice" className='w-75' />
                                </div>
                                <div className="card-texts mt-4 text-dark">
                                    <div className="card-title-text small">Toyota Camry 2.5</div>
                                    <div className="card-price-text small">N2,500,000 - N3,500,000</div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide> 
                        <SwiperSlide> 
                            <div className="card border-0">
                                <div className="card-body">
                                    <div className="card-img-container d-flex justify-content-center">
                                        <img src={yourChoice} alt="yourChoice" className='w-75' />
                                    </div>
                                    <div className="card-texts mt-4 text-dark">
                                        <div className="card-title-text small">Toyota Camry 2.5</div>
                                        <div className="card-price-text small">N2,500,000 - N3,500,000</div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide> 
                        <SwiperSlide> 
                            <div className="card border-0">
                                <div className="card-body">
                                    <div className="card-img-container d-flex justify-content-center">
                                        <img src={yourChoice} alt="yourChoice" className='w-75' />
                                    </div>
                                    <div className="card-texts mt-4 text-dark">
                                        <div className="card-title-text small">Toyota Camry 2.5</div>
                                        <div className="card-price-text small">N2,500,000 - N3,500,000</div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide> 
                        <SwiperSlide> 
                            <div className="card border-0">
                                <div className="card-body">
                                    <div className="card-img-container d-flex justify-content-center">
                                        <img src={yourChoice} alt="yourChoice" className='w-75' />
                                    </div>
                                    <div className="card-texts mt-4 text-dark">
                                        <div className="card-title-text small">Toyota Camry 2.5</div>
                                        <div className="card-price-text small">N2,500,000 - N3,500,000</div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide> 
                        <SwiperSlide> 
                            <div className="card border-0">
                                <div className="card-body">
                                    <div className="card-img-container d-flex justify-content-center">
                                        <img src={yourChoice} alt="yourChoice" className='w-75' />
                                    </div>
                                    <div className="card-texts mt-4 text-dark">
                                        <div className="card-title-text small">Toyota Camry 2.5</div>
                                        <div className="card-price-text small">N2,500,000 - N3,500,000</div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide> 
                        <SwiperSlide> 
                            <div className="card border-0">
                                <div className="card-body">
                                    <div className="card-img-container d-flex justify-content-center">
                                        <img src={yourChoice} alt="yourChoice" className='w-75' />
                                    </div>
                                    <div className="card-texts mt-4 text-dark">
                                        <div className="card-title-text small">Toyota Camry 2.5</div>
                                        <div className="card-price-text small">N2,500,000 - N3,500,000</div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide> 
                    </Swiper>

               
                    <div className="btn-toggler-container w-100 position-absolute p-3 d-flex align-items-center justify-content-between h-75">
                        
                        <div className="h-100 right-slidder-toggle-btn d-flex align-items-center end-100">
                            <button className="btn rounded-pill btn-light shadow fs-5" onClick={handlePrev}><RiArrowLeftSLine /></button>
                        </div>

                        <div className="h-100 left-slidder-toggle-btn d-flex align-items-center end-0">
                            <button className="btn rounded-pill btn-light shadow fs-5" onClick={handleNext}><RiArrowRightSLine /></button>
                        </div>
                    </div>

                </div>

                <div className="btn-container d-flex justify-content-center">
                    <button className="btn learn-more-btn">See all porpular vehicles</button>
                </div>

            </div>
        </div>
     );
}
 
export default YourChoice;