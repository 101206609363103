import './InsuranceAsVisitors.scss'
import React, { useState } from 'react'
import { BreadCrumb } from 'Components'
import toyota from "Assets/Images/toyota.png"
import { Link } from 'react-router-dom'


const InsuranceAsVisitors = () => {
    
    const [confirmVehicle, setConfirmVehicle] = useState(false) 

    return ( 
        <div className="InsuranceAsVisitors">
            
            <div className="container">
                <BreadCrumb parentTitle={'Registration'} parentUrl={'/insurance-as-visitors'} pageTitle={'Insurance as Visitors'}></BreadCrumb>
                <div className="row justify-content-center">
                    <div className="col-md-5 mb-5 mt-4">
                       <div className="card border-0 py-3">
                        <div className="card-header text-left border-0 pt-3"> 
                            <div className="title">
                                Insurance as Visitors
                            </div> 
                        </div>
                        <div className="card-body pt-2">
                            <form action="">
                                <div className="form-group mt-0">
                                    <label htmlFor="">Make</label>
                                    <select name="" id="" className='form-control'>
                                        <option value="">Toyota</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Model</label>
                                    <select name="" id="" className='form-control'>
                                        <option value="">Camry 2.5</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Year</label>
                                    <select name="" id="" className='form-control'>
                                        <option value="">2020</option>
                                    </select>
                                </div> 
                                <div className="form-group">
                                    <label htmlFor="">Valuation</label>
                                    <select name="" id="" className='form-control'>
                                        <option value="">N5,000,000.00</option>
                                    </select>
                                </div> 
                                <div className="form-group">
                                    <label htmlFor="">Insurance Provider</label>
                                    <select name="" id="" className='form-control'>
                                        <option value="">Select Provider</option>
                                    </select>
                                </div> 
                                
                                <div className="form-group"> 
                                    <button className='btn btn-primary' type='button' onClick={()=>setConfirmVehicle(true)}>Confirm Vehicle</button>
                                </div> 
                            </form>
                        </div>
                       </div>
                    </div>

                    {confirmVehicle && <div className="col-md-5 mb-5 mt-4">
                        <div className="card document-details-card p-0">

                            <div className="card-header border-0">
                                <div className="row h-100">
                                    <div className="col-md-2 text-center my-auto">
                                        <div className="logo"> 
                                           <img src={toyota} alt="" />
                                        </div>
                                    </div>
                                    <div className="col-md-10 my-auto">
                                       Toyota Camry 2.5 
                                    </div>
                                </div>
                            </div>
                            <div className="card-body px-0">  
                                <div className="docYouWillGet px-3"> 
                                    <div className='tagline'>Documents you will get</div> 
 
                                    <div className="row pb-4 pt-3">  
                                        <div className="col-6">
                                            <label className="form-input">Vehicle License
                                                <input type="checkbox"/>
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>
                                        <div className="col-6">
                                            <label className="form-input">Insurance
                                                <input type="checkbox"/>
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>
                                        <div className="col-6">
                                            <label className="form-input">Road worthiness
                                                <input type="checkbox"/>
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>
                                        <div className="col-6">
                                            <label className="form-input">CRM
                                                <input type="checkbox"/>
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>
                                        <div className="col-6">
                                            <label className="form-input">Plate Number
                                                <input type="checkbox"/>
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>
                                        <div className="col-6">
                                            <label className="form-input">Proof of ownership
                                                <input type="checkbox"/>
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>
                                        <div className="col-6">
                                            <label className="form-input">VIN
                                                <input type="checkbox"/>
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="processing-time px-3">
                                    <div className="row w-100 h-100"> 
                                        <div className="col-md-6 my-auto">
                                           Processing Time
                                        </div>
                                        <div className="col-md-6 my-auto text-end text-dark">
                                           2 Weeks
                                        </div>
                                    </div> 
                                </div>

                                <div className="total-balance px-3"> 
                                    <div className="row h-100"> 
                                        <div className="col-md-6 my-auto">
                                            Total
                                        </div>
                                        <div className="col-md-6 text-end my-auto">
                                            ₦200,000.00
                                        </div>
                                    </div> 
                                </div>
                            </div>

                            <div className="card-footer px-0">
                                <div className="form-group"> 
                                    <Link to="/new-registration/vehicle-particulars">
                                        <button className='btn btn-primary' type='button'>Continue</button> 
                                    </Link>
                                </div>
                            </div>
                        </div>
                    
                    </div>}

                </div>
             </div>
        </div>
     );
}
 
export default InsuranceAsVisitors;