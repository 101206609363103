import React from 'react'
import "./RenewVehicleParticularsAuth.scss"
import { BreadCrumb } from 'Components' 
import { RenewVehicleParticularsFlow } from 'Pages/Frontend/Components';
 
const RenewVehicleParticularsAuth = () => {
   
    return (
        <div className='RenewVehicleParticularsAuth'>
              <div className="container">
                <BreadCrumb parentTitle={'Registration'} parentUrl={'/myaccount/register-vehicle'} pageTitle={'Renew Registration'}></BreadCrumb>
                <div className="row justify-content-center">
                    <div className="col-md-5 mb-5 mt-4">
                    <RenewVehicleParticularsFlow module="user"></RenewVehicleParticularsFlow>
                </div>
            </div>
        </div>
    </div>
    )
}

export default RenewVehicleParticularsAuth
