import React, { useEffect, useState } from 'react'
import "./NewRegistrationAuth.scss"
import { BreadCrumb } from 'Components' 
import { VehicleTypeForm, VehicleTypeFormPreview } from 'Pages/Frontend/Components'
import { vehicleService } from 'Services'
import Swal from 'sweetalert2'
import { vehicleServiceType } from 'Models'
import { useParams } from 'react-router-dom'
 
const NewRegistrationAuth = () => {
   const limit = 100;
   const offset = 0;
   
   const { service_id } = useParams<{ service_id: string }>()
   const [confirmVehicle, setConfirmVehicle] = useState(false) 
   const [vehicleServices, setVehicleServices] = useState<vehicleServiceType>()

    const getServices = async () =>{
        await vehicleService.serviceItem(service_id, limit, offset).then((response) =>{
            console.log("response vehicleServices", response.data)
            setVehicleServices(response.data[0])
        }, error => {

            console.log("error vehicleServices", error)
            Swal.fire({
                position: "top-end",
                icon: "warning",
                text: error.response.data.message,
                showConfirmButton: false,
                timer: 3000
            });
        })
    }

    useEffect(() => {
        getServices()
    }, [service_id])

    
    return (
        <div className='NewRegistrationAuth'>
             <div className="container">
                <BreadCrumb parentTitle={'Registration'} parentUrl={'/myaccount/registration'} pageTitle={'New Registration'}></BreadCrumb>
                <div className="row justify-content-center">
                    <div className={confirmVehicle? "col-md-5 mb-5 mt-4": "col-sm-12 col-md-7 col-lg-6 col-xl-5 mb-5 mt-4"}>
                       <div className="card border-0 py-3">
                        <div className="card-header text-left border-0 pt-3"> 
                            <div className="title">
                            What type of vehicle?
                            </div> 
                        </div>
                        <div className="card-body pt-2">
                           <VehicleTypeForm setConfirmVehicle={(val:boolean)=>setConfirmVehicle(!confirmVehicle)}></VehicleTypeForm>
                        </div>
                       </div>
                    </div>

                    {confirmVehicle && <div className={confirmVehicle? "col-md-5 mb-5 mt-4": "col-sm-12 col-md-7 col-lg-6 col-xl-5 mb-5 mt-4"}>
                   
                       <VehicleTypeFormPreview module="user" vehicleService={vehicleServices}></VehicleTypeFormPreview>
                    
                    </div>}
                </div>
             </div>
        </div>
    )
}

export default NewRegistrationAuth
