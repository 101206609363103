import { VEHICLE_REG_STEP_ONE } from 'Constants/RegistrationConstant'
import { CurrencyFormatter } from 'Helpers'
import { vehicleServiceType } from 'Models'
import React, { FC, Fragment, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'


interface propsType {
    module?: string
    vehicleServices?: vehicleServiceType
}

const DealersPlateNumberPackage:FC<propsType> = ({ module, vehicleServices }) => {
    const [number, setNumber] = useState(1)
    const {service_id} = useParams<{service_id: string}>()
    const navigate = useNavigate() 
    const dispatch = useDispatch()

    
    const goNext = ()=>{ 
        const amount =  Number(vehicleServices?.amount) * number;
        dispatch({ type: VEHICLE_REG_STEP_ONE, payload: { number, amount }}); 
 
        navigate(module && module ==='user'? `/myaccount/registration/dealers-plate-number/${service_id}/upload-docs` : "/upload-docs", {replace: true})
     }

    return (
        <Fragment>
        <div className="card border-0">
            <div className="card-header text-center border-0 pt-4"> 
                <div className="title border-bottom text-start pb-3 fw-bold">
                    Dealers Plate Number
                </div>
                <div className="tagline text-start pt-3 small">
                What you will get
                </div>
            </div>
            <div className="card-body pt-1">
                <div className="btn-cont d-flex justify-content-between">

                    <div className="col-6">
                        <label className="form-input">{number} Plates Number 
                        </label>
                    </div>

                    <div className="add-more"><button className='btn add-more border-0' onClick={()=>setNumber(number + 1)}>+ Add More</button></div> 
                </div>
            </div>
            <div className="card-isol p-3 d-flex justify-content-between small">
                <span className='small'>Duration</span>
                <span className='small'>{vehicleServices && vehicleServices.processingTime} Weeks</span>
            </div>
            
            <div className="card-footer p-3 d-flex bg-white justify-content-between fw-bold border-0">
                <span className='fw-bold'>Total</span>
                <span className='fw-bold'>N{vehicleServices? CurrencyFormatter(Number(vehicleServices?.amount) * number): '00'}</span>
            </div>
            
        </div>

        <div className="form-group row">
            <div className="my-auto p-3">
                 <button className='btn btn-primary dealer-btn-continue form-control rounded-pill' onClick={()=>goNext()}>Continue</button> 
            </div>
        </div>
    </Fragment>
    )
}

export default DealersPlateNumberPackage
