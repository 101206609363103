import React, { createContext, useContext, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLocalStorage } from '../../Hooks/UseLocalStorage'
import { userService } from 'Services'
const AuthContext = createContext()

export const AuthProvider = ({ children }) => {

  const [user, setUser] = useLocalStorage('__ktz', null)
    // { displayName: "" }

  
  const navigate = useNavigate()

  // call this function when you want to authenticate the user
  const login = async (data) => {
    setUser(data)
    if (data) {
      return navigate('/myaccount/dashboard', { replace: true })
    } 
  }

  // call this function to sign out logged in user
  const logout = () => {
    setUser(null)
    return navigate('/login', { replace: true })
  }

  const getTemporaryToken = async () => { 
    let token = sessionStorage.getItem('ktGuestToken'); 
    // if (!token) {
    //   // get custom token
    //   return await userService.createGuestUser().then(async (response)=>{ 
    //     // get ID token
    //      return await userService.createGuestUserToken(response.data.userId)
    //   })
    // }
  };



  const value = useMemo(() => ({ user, login, logout, getTemporaryToken }),
    [user])

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  return useContext(AuthContext)
}
