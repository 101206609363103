import React, { Fragment } from 'react';
import "./RegistrationMenuOption.scss"

const RegistrationMenuOptionSkeleton = () => {
  const numberOfLoaders = Array.from({ length: 3 });

  return (
    <Fragment>{
      numberOfLoaders.map((_, index) => (
        <div key={index} className="col-12 col-sm-6 col-md-4 mt-3">
          <div className="card registrationMenuOptionSkeleton">
            <div className="card-body">
              <div className="card-icon"> 
                <span className="skeleton-loader skeleton-icon"></span>
              </div>
              <div className="card-title mt-4"> 
                <div className="skeleton-loader skeleton-title"></div>
              </div>
              <div className="card-text"> 
                <div className="skeleton-loader skeleton-text"></div>
                <div className="skeleton-loader skeleton-text"></div> 
              </div>
              <div className="card-action mt-4"> 
                <div className="skeleton-loader skeleton-button"></div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </Fragment>
  );
};

export default RegistrationMenuOptionSkeleton;
