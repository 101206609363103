import './NewCustomPlateNumberAuth.scss' 
import { NewCustomPlateNumberForm } from 'Pages/Frontend/Components';

const NewCustomPlateNumberAuth = () => {  
    return ( 
        <div className="NewCustomPlateNumberAuth">
            <div className="row justify-content-center">
                <div className="col-md-6 mb-5 mt-4">
                    <div className="card border-0 px-4">
                        <div className="card-header text-left border-0 border-bottom px-0 py-3"> 
                            <div className="title">
                                Customize Plate Number
                            </div> 
                        </div>
                        <div className="card-body pt-2 px-0">
                            <NewCustomPlateNumberForm module='user'></NewCustomPlateNumberForm>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default NewCustomPlateNumberAuth;