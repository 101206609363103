import React, { useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import Logo from 'Assets/Images/RegXpress.png'  
import "./NavBar.scss" 
import menu from 'Assets/Icons/menu.png' 
import { useAuth } from 'Components/AuthProvider/AuthProvider' 
import dashboard from 'Assets/Images/dashboard.svg'  
import { getAuth, signOut } from 'firebase/auth'
import Swal from 'sweetalert2'

const NavBar = () => {
    const { user, logout } = useAuth()

    const [showOffcanvas, setShowOffcanvas] = useState(false);

    const handleLinkClick = () => {
        setShowOffcanvas(false);  
    };
 
    const auth = getAuth();
  
   
    const logoutFunc = () => {
      Swal.fire({
        title: "Are you sure?",
        text: "You will be logged out of your account!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#186AFF",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, logout!"
      })
        .then((result) => {
            if (result.isConfirmed) {
    
                signOut(auth).then(() => { 
                    logout()
                }).catch((error) => {
                    console.log("error", error)
                    alert("can not logout")
                });

            }
      });
    }

    return ( 
        <React.Fragment>
            <nav className="navbar navbar-expand-lg bg-light">
                <div className="container">
                        <Link className="navbar-brand" to="/">
                            {/* <Logos></Logos> */}
                            <img className='Logo' src={Logo} alt="Logo" />
                        </Link>
                        <button onClick={() => setShowOffcanvas(!showOffcanvas)} className="navbar-toggler border-0" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions" aria-controls="offcanvasWithBothOptions" aria-expanded="false" aria-label="Toggle navigation">
                            <img src={menu} className="menuIcon" alt=''></img>
                        </button> 
                        <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                {/* <li className="nav-item">
                                    <Link className="nav-link active" aria-current="page" to="/buy-vehicle">Buy Vehicle</Link>
                                </li>
                                <li className="nav-item">
                                <Link className="nav-link" to="#">How It Works</Link>
                                </li> 
                                <li className="nav-item dropdown">
                                    <Link className="nav-link dropdown-toggle" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Services
                                    </Link>
                                    <ul className="dropdown-menu border-0 shadow-sm">
                                        <li><Link className="dropdown-item" to="/register-vehicle"><Registration></Registration> Registration</Link></li>
                                        <li><Link className="dropdown-item" to="/insurance"><img src={Insurance} alt=""></img> Insurance</Link></li>
                                        <li><Link className="dropdown-item" to="/tracking-vehicle"><img src={Tracker} alt=""></img> Tracker</Link></li>
                                    </ul>
                                </li> */}
                            </ul>
                            <div className="d-flex" role="search">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0 d-flex align-items-center">
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/about">
                                            About us
                                        </Link>
                                    </li>
                                    
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/register-vehicle">
                                            Services
                                        </Link>
                                    </li>  
                                    
                                    
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/pricing">
                                            Pricing
                                        </Link>
                                    </li>
                                    
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/tracking">
                                            Track order
                                        </Link>
                                    </li>
                                    

                                    {/* <li className="nav-item d-flex"> 
                                        
                                        <Link className="nav-link" to="/login">
                                            <button className="btn login-btn">Login</button>
                                        </Link>
                                        
                                        <Link className="nav-link" to="/register">
                                            <button className="btn create-acc-btn">Create account</button>
                                        </Link>
                                    </li> */}
                                </ul>
                                {/* <ul className="navbar-nav me-auto mb-2 mb-lg-0 d-none">
                                    <li className="nav-item dropdown">
                                        <Link className="nav-link dropdown-toggle" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Services
                                        </Link>
                                        <ul className="dropdown-menu border-0 shadow-sm">
                                            <li><Link className="dropdown-item" to="/"><Registration></Registration> Registration</Link></li>
                                              <li><Link className="dropdown-item" to="/insurance"><img src={Insurance} alt=""></img> Insurance</Link></li>
                                            <li><Link className="dropdown-item" to="/tracking-vehicle"><img src={Tracker} alt=""></img> Tracker</Link></li>
                                        </ul>
                                    </li>
                                    
                                    <li className="nav-item">
                                    <Link className="nav-link" to="/contact-us">
                                    <Support></Support> Support</Link>
                                    </li> 

                                <li className="nav-item dropdown"> 
                                    <div className="nav-link account-option  dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-display="static" >
                                        <Account></Account>
                                        Account
                                        </div>
                                        {user && <ul className="dropdown-menu shadow-sm border-0 dropdown-menu-lg-end my-account-dropdown">
                                        <li><Link className="dropdown-item" to="/myaccount/dashboard"><img src={Dashboard} alt=''></img> Dashboard</Link></li>
                                        <li><Link className="dropdown-item" to="/myaccount/orders/vehicle"><img src={orders} alt=''></img> Orders</Link></li>
                                        <li><Link className="dropdown-item" to="/myaccount/quote-request"><img src={quote} alt=''></img> Quote Request</Link></li>  
                                        <li><Link className="dropdown-item" to="/myaccount/transactions"><img src={transaction} alt=''></img> Transactions</Link></li>   
                                        <li>
                                            <Link className="dropdown-item" to="/myaccount/settings/profile"><img src={setting} alt=''></img> Settings</ Link>
                                        </li>
                                        <li>
                                            <Link className="dropdown-item" to="#" onClick={logoutFunc}><CiLock  className='fs-5'/> Logout</ Link>
                                        </li> 
                                    </ul>}

                                    {!user &&
                                        <ul className="dropdown-menu shadow-sm border-0 dropdown-menu-lg-end my-account-dropdown" aria-labelledby='navbarDropdown'>
                                            <li>
                                                <Link className="dropdown-item" to="/login">
                                                    <button className='btn btn-primary login-button w-100'>Login</button>
                                                </Link>
                                            </li>
                                            <li className="dropdown-item dont-have-account">Dont have an account? <Link to="/register"> Register</Link></li>
                                        </ul>}
                                    </li>
                                </ul> */}
                            </div>
                        </div>
                    </div> 
            </nav> 

            <div
                className={`offcanvas navbar-offcanvas offcanvas-start${showOffcanvas ? ' show' : ''}`} 
                data-bs-scroll="true" 
                tabIndex={-1} 
                id="offcanvasWithBothOptions" 
                aria-labelledby="offcanvasWithBothOptionsLabel">
                    
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvasWithBothOptionsLabel">
                        {/* <Logos></Logos> */}
                        <img className='Logo' src={Logo} alt="Logo" />
                        </h5>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body">
                    <div className="emsc-unauth">
                        <ul className="nav flex-column mb-4">
                            {/* <li className="nav-item my-1 mt-3 mb-4 ps-4 d-none d-lg-block">
                                <Link to="/">
                                    <Logos></Logos> 
                                    <img className='Logo' src={Logo} alt="Logo" />
                                </Link>
                            </li> */}
                            <li className="nav-item my-1 bg-white ps-0">
                                <NavLink className={({ isActive }) => isActive ? "nav-link active h-100 ps-2" : "nav-link h-100 ps-2"}
                                    to="/"
                                    onClick={handleLinkClick}> 
                                    <img src={dashboard} alt="" className='icons'/>
                                    <span className='my-auto'>Home</span>
                                    <i className="d-icon d-arrowhead-right me-3 float-end my-auto"></i>
                                </NavLink>
                            </li>
                            <li className="nav-item my-1 bg-white ps-0">
                                <NavLink className={({ isActive }) => isActive ? "nav-link active h-100 ps-2" : "nav-link h-100 ps-2"}
                                    to="/register-vehicle"
                                    onClick={handleLinkClick}> 
                                    <img src={dashboard} alt="" className='icons'/>
                                    <span className='my-auto'>Register</span>
                                    <i className="d-icon d-arrowhead-right me-3 float-end my-auto"></i>
                                </NavLink>
                            </li>
                            <li className="nav-item my-1 bg-white ps-0">
                                <NavLink className={({ isActive }) => isActive ? "nav-link active h-100 ps-2" : "nav-link h-100 ps-2"}
                                    to="/buy-vehicle"
                                    onClick={handleLinkClick}> 
                                    <img src={dashboard} alt="" className='icons'/>
                                    <span className='my-auto'>Buy Vehicle</span>
                                    <i className="d-icon d-arrowhead-right me-3 float-end my-auto"></i>
                                </NavLink>
                            </li>
                            <li className="nav-item my-1 bg-white ps-0">
                                <NavLink className={({ isActive }) => isActive ? "nav-link active h-100 ps-2" : "nav-link h-100 ps-2"}
                                    to="/insurance"
                                    onClick={handleLinkClick}> 
                                    <img src={dashboard} alt="" className='icons'/>
                                    <span className='my-auto'>Insurance</span>
                                    <i className="d-icon d-arrowhead-right me-3 float-end my-auto"></i>
                                </NavLink>
                            </li>
                            <li className="nav-item my-1 bg-white ps-0">
                                <NavLink className={({ isActive }) => isActive ? "nav-link active h-100 ps-2" : "nav-link h-100 ps-2"}
                                    to="/tracking-vehicle"
                                    onClick={handleLinkClick}> 
                                    <img src={dashboard} alt="" className='icons'/>
                                    <span className='my-auto'>Tracking</span>
                                    <i className="d-icon d-arrowhead-right me-3 float-end my-auto"></i>
                                </NavLink>
                            </li>
                            <li className="nav-item my-1 bg-white ps-0">
                                <NavLink className={({ isActive }) => isActive ? "nav-link active h-100 ps-2" : "nav-link h-100 ps-2"}
                                    to="/"
                                    onClick={handleLinkClick}> 
                                    <img src={dashboard} alt="" className='icons'/>
                                    <span className='my-auto'>How It works</span>
                                    <i className="d-icon d-arrowhead-right me-3 float-end my-auto"></i>
                                </NavLink>
                            </li>
                            <li className="nav-item my-1 bg-white ps-0">
                                <NavLink className={({ isActive }) => isActive ? "nav-link active h-100 ps-2" : "nav-link h-100 ps-2"}
                                    to="/blog"
                                    onClick={handleLinkClick}> 
                                    <img src={dashboard} alt="" className='icons'/>
                                    <span className='my-auto'>Blog</span>
                                    <i className="d-icon d-arrowhead-right me-3 float-end my-auto"></i>
                                </NavLink>
                            </li>

                            <li className="nav-item my-1 bg-white ps-0">
                                <NavLink className={({ isActive }) => isActive ? "nav-link active h-100 ps-2" : "nav-link h-100 ps-2"}
                                    to="/frequently-asked-questions"
                                    onClick={handleLinkClick}> 
                                    <img src={dashboard} alt="" className='icons'/>
                                    <span className='my-auto'>Frequently Asked Questions</span>
                                    <i className="d-icon d-arrowhead-right me-3 float-end my-auto"></i>
                                </NavLink>
                            </li>
                            <li className="nav-item my-1 bg-white ps-0">
                                <NavLink className={({ isActive }) => isActive ? "nav-link active h-100 ps-2" : "nav-link h-100 ps-2"}
                                    to="/contact-us"
                                    onClick={handleLinkClick}> 
                                    <img src={dashboard} alt="" className='icons'/>
                                    <span className='my-auto'>Contact us</span>
                                    <i className="d-icon d-arrowhead-right me-3 float-end my-auto"></i>
                                </NavLink>
                            </li>
                            {!user && <li className="nav-item my-1 bg-white ps-0">
                                <NavLink className={({ isActive }) => isActive ? "nav-link active h-100 ps-2" : "nav-link h-100 ps-2"}
                                    to="/login"
                                    onClick={handleLinkClick}> 
                                    <img src={dashboard} alt="" className='icons'/>
                                    <span className='my-auto'>Login</span>
                                    <i className="d-icon d-arrowhead-right me-3 float-end my-auto"></i>
                                </NavLink>
                            </li>}
                            {!user && <li className="nav-item my-1 bg-white ps-0">
                                <NavLink className={({ isActive }) => isActive ? "nav-link active h-100 ps-2" : "nav-link h-100 ps-2"}
                                    to="/register"
                                    onClick={handleLinkClick}> 
                                    <img src={dashboard} alt="" className='icons'/>
                                    <span className='my-auto'>Register</span>
                                    <i className="d-icon d-arrowhead-right me-3 float-end my-auto"></i>
                                </NavLink>
                            </li>}
                            {user && <li className="nav-item my-1 bg-white ps-0">
                                <NavLink className={({ isActive }) => isActive ? "nav-link active h-100 ps-2" : "nav-link h-100 ps-2"}
                                    to="/myaccount/dashboard"
                                    onClick={handleLinkClick}> 
                                    <img src={dashboard} alt="" className='icons'/>
                                    <span className='my-auto'>My Account</span>
                                    <i className="d-icon d-arrowhead-right me-3 float-end my-auto"></i>
                                </NavLink>
                            </li>}
                            
                        </ul>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default NavBar
