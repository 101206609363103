import { useState } from 'react'

export const useLocalStorage = (keyName: string, defaultValue: object) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const value = window.localStorage.getItem(keyName) 
      
      if (value && value !== 'null') {
        return JSON.parse(value)
      } else {
        // window.localStorage.setItem(keyName, JSON.stringify(defaultValue))
        return defaultValue
      }
    } catch (err) {
      return defaultValue
    }
  })

  const setValue = (newValue: object) => {
    try {
      window.localStorage.setItem(keyName, JSON.stringify(newValue))
    } catch (err) {
      console.warn(err)
    }
    setStoredValue(newValue)
  }

  const removeValue = () => {
    return localStorage.removeItem( keyName )
  }

  return [storedValue, setValue, removeValue]
}
