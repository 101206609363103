import React from 'react';
import "./AddressSkeleton.scss"

const AddressSkeleton = () => {
    return (
        <div className="row">
            {[1, 2, 3, 4].map((index) => (
                <div key={index} className="card address-skeleton col-md-6 border-0 mb-4">
                    <div className="inner-card-container border px-3">
                        <div className="card-header d-flex justify-content-between p-0  pb-2 align-items-center">
                            <div className="card-title-text skeleton-text skeleton-animation"></div>
                            <div className="card-header-action-container d-flex align-items-center">
                                <span className="default me-3 rounded-pill skeleton-pill"></span>
                                <div className="dropdown-container">
                                    <button className="btn border-0 dropdown-toggle skeleton-button">
                                        <div className="skeleton-icon"></div>
                                    </button>
                                    <ul className="dropdown-menu dropdown-menu-end export-dropdown border-0 shadow">
                                        <li className="dropdown-item skeleton-text skeleton-animation"></li>
                                        <li className="dropdown-item skeleton-text skeleton-animation"></li>
                                        <li className="dropdown-item skeleton-text skeleton-animation"></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="card-body p-0 py-3">
                            <div className="my-2 skeleton-text skeleton-animation"></div>
                            <div className="my-2 skeleton-text skeleton-animation"></div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default AddressSkeleton;
