import React, { useEffect, useState } from 'react'
import "./RenewRegistrationAuth.scss"
import { BreadCrumb } from 'Components' 
import { RenewVehicleTypeForm, RenewVehicleTypeFormPreview} from 'Pages/Frontend/Components'
import { useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import { vehicleService } from 'Services'
import { vehicleServiceType } from 'Models'

const RenewRegistrationAuth = () => {
    const [confirmVehicle, setConfirmVehicle] = useState(false)
    const limit = 100;
    const offset = 0;
    
    const { service_id } = useParams<{ service_id: string }>() 
    
    const [vehicleServices, setVehicleServices] = useState<vehicleServiceType>()
 
     const getServices = async () =>{
         await vehicleService.serviceItem(service_id, limit, offset).then((response) =>{
             console.log("response vehicleServices", response.data)
             setVehicleServices(response.data[0])
         }, error => {
 
             console.log("error vehicleServices", error)
             Swal.fire({
                 position: "top-end",
                 icon: "warning",
                 text: error.response.data.message,
                 showConfirmButton: false,
                 timer: 3000
             });
         })
     }
 
     useEffect(() => {
         getServices()
     }, [service_id])
 
    return (
        <div className='RenewRegistrationAuth'>
            <div className="container">
                <BreadCrumb parentTitle={'Registration'} parentUrl={'/myaccount/register-vehicle'} pageTitle={'Renew Registration'}></BreadCrumb>
                <div className="row justify-content-center">
                    <div className="col-md-5 mb-5 mt-4">
                       <div className="card border-0 py-3">
                        <div className="card-header text-left border-0 pt-3"> 
                            <div className="title">
                            What type of vehicle?
                            </div> 
                        </div>
                        <div className="card-body pt-2">
                           <RenewVehicleTypeForm setConfirmVehicle={(val:boolean)=>setConfirmVehicle(val)}></RenewVehicleTypeForm>
                        </div>
                       </div>
                    </div>

                    {confirmVehicle && <div className="col-md-5 mb-5 mt-4">
                   
                       <RenewVehicleTypeFormPreview module="user" vehicleService={vehicleServices}></RenewVehicleTypeFormPreview>
                    
                    </div>}
                </div>
             </div>
        </div>
    )
}

export default RenewRegistrationAuth
