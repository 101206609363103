import React from 'react';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-loading-skeleton/dist/skeleton.css'

import { createRoot } from 'react-dom/client';
import './index.scss'; 
import reportWebVitals from './reportWebVitals'; 
import { BrowserRouter } from 'react-router-dom'; 
import { AuthProvider } from './Components/AuthProvider/AuthProvider';
 
import { Route, Routes } from 'react-router-dom'; 
import Wrapper from './Pages/Skeleton/Wrapper/Wrapper';  

import 'react-toastify/dist/ReactToastify.css';

import { 
    Dashboard, 
    AboutUs,
    BuyVehicle,
    Checkout,
    ContactUs, 
    Insurance, 
    Login, 
    NewRegistration, 
    PaymentSuccessful, 
    RegisterVehicle, 
    Registration, 
    RegistrationSummary,  
    TrackingVehicle,
    VehicleParticulars,
    DriversLicense,
    DriversLicenseReg,
    Orders,
    VehicleOrder,
    RegistrationOrder,
    InsuranceOrder,
    TrackerOrder,
    QuoteRequest,
    OrderDetails,
    RecorverPassword, 
    WhatTypeOfVehicle,
    NewOwnersInformation,
    RegDriversLicense,
    RenewDriversLicense,
    UploadOldDriversLicense,
    FAQ,
    NewCustomPlateNumber,
    UploadDocument,
    UploadPlatesNumber,
    CustomizePlateNumber,
    DealersPlateNumber,
    PlateReprint,
    InsuranceAsVisitors,
    Blog,
    Settings,
    Address,
    Profile,
    Password,
    RegistrationHome,
    NewRegistrationAuth,
    VehicleParticularsAuth,
    RegistrationSummaryAuth,
    CheckoutAuth,
    PaymentSuccessfulAuth,
    RenewRegistrationAuth, 
    RenewRegistrationSummaryAuth,
    RenewVehicleParticularsAuth,
    ChangeOfOwnership,
    NewOwnersInformationAuth,
    UploadDocumentsAuth,
    CoSummaryAuth,
    DriversLicenseRegistration,
    DriversLicenseRegistrationForm,
    DriversLicenseRenewal,
    DriversLicenseRenewalForm,
    UploadOldDriversLicenseAuth,
    CustomizePlateNumberAuth,
    NewCustomPlateNumberAuth,
    DealersPlateNumberAuth,
    UploadPlateDocumentAuth,
    PlateReprintAuth,
    UploadPlatesReprintNumber,
    InsuranceAuth,
    InsuranceRegistrationAuth,
    InsuranceVehicleParticularsAuth,
    InsuranceSummaryAuth,
    AccountVerification,
    RenewExistingVehicle,
    RegistrationOrderDetails,
    RenewReg,
    CoSummaryGuest,
    UploadDocumentsGuest,
    ChangeofOwnershipGuest,
    RegistrationVerification,
    RecorveryPasswordToken,
    ResetPassword,  
    LandingPage,
    Tracking,
    Pricing,
    RegistrationService
} from './Pages/Frontend/Interface'; 

import { ProtectedUser } from './Components';
import { Provider } from 'react-redux';
import Store from './Store/Store';


createRoot(document.getElementById("root") as HTMLElement).render(  
  <React.StrictMode> 
  <Provider store={Store}>
  <BrowserRouter> 
    <AuthProvider>
      <Routes>
          <Route path="/" element={<Wrapper />}>
              {/* <Route index element={<RegisterVehicle />} /> */}
              <Route index element={<LandingPage />} />
 
              <Route path='/tracking' element={<Tracking />} />
              <Route path='/pricing' element={<Pricing />} />

              <Route path='/register' element={<Registration />} />
              <Route path='/register-verify' element={<RegistrationVerification />} /> 
              <Route path='/login' element={<Login />} />
              <Route path='/recorver-password-token' element={<RecorveryPasswordToken />} />
              <Route path='/reset-password' element={<ResetPassword />} />

              <Route path='/verify' element={<AccountVerification />} />
              <Route path='/recorver-password' element={<RecorverPassword />} />
              <Route path='/about' element={<AboutUs />} />

              <Route path='/registration-service/:service_id' element={<RegistrationService />} />
              <Route path='/registration/:service_order_id/registration-summary' element={<RegistrationSummary />} />
              
              <Route path='/Checkout/:type/:service_id' element={<Checkout />} /> 
              <Route path='/register-vehicle' element={<RegisterVehicle />} />
              <Route path='/new-registration' element={<NewRegistration />} />
              <Route path='/new-registration/vehicle-particulars' element={<VehicleParticulars />} />
              <Route path='/new-registration/registration-summary' element={<RegistrationSummary />} />
              <Route path='/payment-successful/:orderId' element={<PaymentSuccessful />} />
              {/* <Route path='/renew-registration' element={<RenewRegistration />} /> */}
              <Route path='/tracking-vehicle' element={<TrackingVehicle />} />
              <Route path='/contact-us' element={<ContactUs />} />
              <Route path='/buy-vehicle' element={<BuyVehicle />} />
              <Route path='/insurance' element={<Insurance />} /> 
              <Route path='/change-of-ownership' element={<ChangeofOwnershipGuest />} /> 
              <Route path='/what-type-of-vehicle' element={<WhatTypeOfVehicle />} /> 
              <Route path='/new-owners-information' element={<NewOwnersInformation />} /> 
              <Route path='/drivers-license' element={<DriversLicense />} />
              <Route path='/drivers-license-reg' element={<DriversLicenseReg />} />
              <Route path='/reg-drivers-license' element={<RegDriversLicense />} />
              <Route path='/renew-drivers-license' element={<RenewDriversLicense />} />
              <Route path='/upload-old-drivers-license' element={<UploadOldDriversLicense />} />
              <Route path='/frequently-asked-questions' element={<FAQ />} />
              <Route path='/customize-plate-number' element={<CustomizePlateNumber />} />
              <Route path='/new-customized-plate-number' element={<NewCustomPlateNumber />} />
              <Route path='/dealers-plate-number' element={<DealersPlateNumber />} />
              <Route path='/upload-docs' element={<UploadDocument />} />
              <Route path='/plate-reprint' element={<PlateReprint />} />
              <Route path='/upload-plate-number' element={<UploadPlatesNumber />} />
              <Route path='/insurance-as-visitors' element={<InsuranceAsVisitors />} />
              <Route path='/blog' element={<Blog />} />
              <Route path='/RenewReg' element={<RenewReg />} />
          </Route>
        
          <Route path='myaccount' element={<ProtectedUser />}>
            <Route index element={<Dashboard />} />
            <Route path='dashboard' element={<Dashboard />} />
            <Route path='checkout/:type/:service_id' element={<CheckoutAuth />} />
            <Route path='payment-successful/:type/:orderId' element={<PaymentSuccessfulAuth />} />
            
            
            {/* Corrected: Make the path of the /orders route relative */}
            <Route path='orders' element={<Orders />}> 
              <Route path='vehicle' element={<VehicleOrder />} />
              <Route path='registration' element={<RegistrationOrder />} />
              
              <Route path='insurance' element={<InsuranceOrder />} />
              <Route path='tracker' element={<TrackerOrder />} /> 
            </Route>
            <Route path='orders/registration/:orderId' element={<RegistrationOrderDetails />} />
              
 
            <Route path='registration'>
               <Route index element={<RegistrationHome />} />
               <Route path='new-registration/:service_id'>  
                  <Route index element={<NewRegistrationAuth />} />
                  <Route path="vehicle-particulars" element={<VehicleParticularsAuth />} />
                  <Route path="registration-summary" element={<RegistrationSummaryAuth />} /> 
               </Route>     
             
               <Route path='renew-registration/:service_id'>  
                  <Route index element={<RenewRegistrationAuth />} />
                  <Route path="vehicle-particulars" element={<RenewVehicleParticularsAuth />} />
                  <Route path="registration-summary" element={<RenewRegistrationSummaryAuth />} /> 
                  <Route path="existing-vehicle" element={<RenewExistingVehicle />} /> 
                  
               </Route>    
               
               <Route path="change-of-ownership/:service_id">  
                  <Route index element={<ChangeOfOwnership />} />
                  <Route path="new-owners-information" element={<NewOwnersInformationAuth />} />
                  <Route path="upload-document" element={<UploadDocumentsAuth />} /> 
                  <Route path="order-summary" element={<CoSummaryAuth />} />  
               </Route>


               <Route path="drivers-license-registration/:service_id">  
                  <Route index element={<DriversLicenseRegistration />} />
                  <Route path="biodata" element={<DriversLicenseRegistrationForm />} />  
                </Route>
               
                <Route path="drivers-license-renewal/:service_id">  
                  <Route index element={<DriversLicenseRenewal />} />
                  <Route path="biodata" element={<DriversLicenseRenewalForm />} />  
                  <Route path="upload-old-drivers-license" element={< UploadOldDriversLicenseAuth/>} />   
                </Route>

                <Route path="customize-plate-number/:service_id">  
                  <Route index element={<CustomizePlateNumberAuth />} />
                  <Route path="new-customized-plate-number" element={<NewCustomPlateNumberAuth />} />     
                </Route>
                  
                <Route path="dealers-plate-number/:service_id">  
                  <Route index element={<DealersPlateNumberAuth />} />
                  <Route path="upload-docs" element={<UploadPlateDocumentAuth />} />     
                </Route>
                  
                <Route path="plate-reprint/:service_id">  
                  <Route index element={<PlateReprintAuth />} />
                  <Route path="upload-plate-number" element={<UploadPlatesReprintNumber />} />     
                </Route> 
            </Route>

            <Route path='insurance/:service_id?'>
              <Route index element={<InsuranceAuth></InsuranceAuth>}/> 
              <Route path="insurance-vehicle-registration" element={<InsuranceRegistrationAuth />} />
              <Route path="insurance-registration-particulars" element={<InsuranceVehicleParticularsAuth />} /> 
              <Route path="insurance-registration-summary" element={<InsuranceSummaryAuth />} /> 
 
            </Route>

            
 

            <Route path='orders/:orderId' element={<OrderDetails />} />
              

            <Route path='settings' element={<Settings />}> 
              <Route index element={<Profile />} />
              <Route path='profile' element={<Profile />} />
              <Route path='address' element={<Address />} />
              <Route path='password' element={<Password />} />
            </Route>

            <Route path='quote-request' element={<QuoteRequest />} /> 
          </Route>

          <Route path="/" element={<Wrapper />}>
            <Route path='registration'> 
                <Route path='new-registration/:service_id'>  
                    <Route index element={<NewRegistration />} />
                    <Route path="vehicle-particulars" element={<VehicleParticulars />} />
                    <Route path="registration-summary" element={<RegistrationSummary />} /> 
                </Route>     
              
                 <Route path='renew-registration/:service_id'>  
                    <Route index element={<RenewReg />} />
                    <Route path="vehicle-particulars" element={<VehicleParticulars />} />
                    <Route path="registration-summary" element={<RegistrationSummary />} /> 
                    <Route path="existing-vehicle" element={<RenewExistingVehicle />} />  
                </Route>   
                
                <Route path="change-of-ownership/:service_id">  
                    <Route index element={<ChangeofOwnershipGuest />} />
                    <Route path="new-owners-information" element={<NewOwnersInformation />} />
                    <Route path="upload-document" element={<UploadDocumentsGuest />} /> 
                    <Route path="order-summary" element={<CoSummaryGuest />} />   
                </Route>


                {/* <Route path="drivers-license-registration/:service_id">  
                    <Route index element={<DriversLicenseRegistration />} />
                    <Route path="biodata" element={<DriversLicenseRegistrationForm />} />  
                  </Route>
                
                  <Route path="drivers-license-renewal/:service_id">  
                    <Route index element={<DriversLicenseRenewal />} />
                    <Route path="biodata" element={<DriversLicenseRenewalForm />} />  
                    <Route path="upload-old-drivers-license" element={< UploadOldDriversLicense/>} />   
                  </Route>
                  */}

                  <Route path="customize-plate-number/:service_id">  
                    <Route index element={<CustomizePlateNumber />} />
                    <Route path="new-customized-plate-number" element={<NewCustomPlateNumber />} />     
                  </Route>

                  {/*   
                  <Route path="dealers-plate-number/:service_id">  
                    <Route index element={<DealersPlateNumber />} />
                    <Route path="upload-docs" element={<UploadPlateDocument />} />     
                  </Route>
                  
                  <Route path="plate-reprint/:service_id">  
                    <Route index element={<PlateReprint />} />
                    <Route path="upload-plate-number" element={<UploadPlatesReprintNumber />} />     
                  </Route>  */}
              </Route>
            </Route>
      </Routes>
  </AuthProvider>
</BrowserRouter>
</Provider>
</React.StrictMode>
);
 

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
