
import { getFirestore } from "@firebase/firestore";
import { initializeApp } from "@firebase/app"; 
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth'
 

const firebaseConfig = {
  apiKey: "AIzaSyAOO8dJdRR0MwcDQyc17fiyIouYPQ92cUU",
  authDomain: "kitaniz-87fcb.firebaseapp.com",
  projectId: "kitaniz-87fcb",
  storageBucket: "kitaniz-87fcb.appspot.com",
  messagingSenderId: "1065531464543",
  appId: "1:1065531464543:web:7b4d4fee350ea3a83e74ea",
  measurementId: "G-YXDYRT2490"
};
 


const firebase = initializeApp(firebaseConfig);

export const firebaseStorage = getStorage(firebase);
export const auth = getAuth(firebase);
export const firbaseDB = getFirestore(firebase);

 