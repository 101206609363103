import React from 'react'
import regexpress from "Assets/Images/regexpress.svg"
import "./Regexpress.scss"

const Regexpress = () => {
    return (
        <div className='dash-regexpress'>
             <img src={regexpress} alt='' className='w-100'/> 
        </div>
    )
}

export default Regexpress
