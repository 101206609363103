import { AboutUsHero, AboutUsTextContents, AboutWhyChooseUs, JoinKitaniz } from '../../Components';
import './AboutUs.scss'


const AboutUs = () => {
    return ( 
        <div className="AboutUs">
            <AboutUsHero />
            <AboutUsTextContents />
            <AboutWhyChooseUs />
            <JoinKitaniz />
        </div>
     );
}
 
export default AboutUs;