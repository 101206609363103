import { useEffect, useState } from 'react';
import './DealersPlateNumberAuth.scss'
import { DealersPlateNumberPackage } from 'Pages/Frontend/Components';
import Swal from 'sweetalert2';
import { vehicleService } from 'Services';
import { useParams } from 'react-router-dom';
import { vehicleServiceType } from 'Models';
 

const DealersPlateNumberAuth = () => {
    const [confirmVehicle, setConfirmVehicle] = useState(false)
  
    const limit = 100;
    const offset = 0;
    
    const { service_id } = useParams<{ service_id: string }>() 
    
    const [vehicleServices, setVehicleServices] = useState<vehicleServiceType>()
 
     const getServices = async () =>{
         await vehicleService.serviceItem(service_id, limit, offset).then((response) =>{
             console.log("response vehicleServices", response.data)
             setVehicleServices(response.data[0])
         }, error => {
 
             console.log("error vehicleServices", error)
             Swal.fire({
                 position: "top-end",
                 icon: "warning",
                 text: error.response.data.message,
                 showConfirmButton: false,
                 timer: 3000
             });
         })
     }
 
     useEffect(() => {
         getServices()
     }, [service_id])
     
    return ( 
        <div className="DealersPlateNumberAuth">
            
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-6 my-5">
                       <DealersPlateNumberPackage module='user' vehicleServices={vehicleServices}></DealersPlateNumberPackage>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default DealersPlateNumberAuth;