import { Link } from 'react-router-dom';
import './JoinKitaniz.scss'
 

const JoinKitaniz = () => {
    return ( 
        <div className="JoinKitaniz container-fluid bg-primary mb-4">
            <div className="container position-relative">
                <div className="row align-items-center joinKitaniz-main">
                    <div className="text-container col-md-6 p-3">
                        <div className="title text-center text-md-start w-100 text-light col-md-6">
                            Join Kitaniz to enjoy <br/>all the benefits!
                        </div>
                        <div className="btn-container d-flex justify-content-center justify-content-md-start mb-5 mb-md-0">
                            <Link to='/myAccount/dashboard'>
                                <button className="btn learn-more-btn">Get Started</button>
                            </Link>
                        </div>
                    </div>

                </div>
            </div>
        </div>
     );
}
 
export default JoinKitaniz;