import './Registration.scss'
import { Link, useNavigate } from 'react-router-dom'; 
import { useForm } from 'react-hook-form'; 
import { useState } from 'react';
import Swal from 'sweetalert2';  
import { FaRegEyeSlash, FaRegEye } from 'react-icons/fa6';
import { authService } from 'Services/authService';

type formType = {  
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    phoneNumber: string;
}

const Registration = () => {
  
    const navigate = useNavigate()

    const [request, setRequest] = useState<boolean>(false)

     
    const { register, handleSubmit, formState: { errors } } = useForm<formType>();

    const submit = handleSubmit((data: formType) =>registerUser(data))

    const registerUser = async (data: formType) => {
        setRequest(true)

        await authService.register(data)  
        .then(async (userCred: any) => {
    
            Swal.fire('Account Verification!', 'Verification code sent via SMS and email. Kindly check your phone number or email for the verification token.', 'success')
            .then((action)=>{
                if(action.isConfirmed){
                    navigate("/register-verify", { replace : true }) 
                }
            })
            setRequest(false)  
            setTimeout(()=>{
                navigate("/register-verify", { replace : true }) 
            }, 2000)
    
        }, (error) => {
            setRequest(false)
            // Swal.fire('Oops!', error.response? error.response.data.error.message : error.message, 'warning');
            Swal.fire(
                'Oops!',
                Array.isArray(error.response?.data?.message) 
                    ? error.response.data.message[0] 
                    : error.response?.data?.error?.message || error.message,
                'warning'
            );
            console.log(error)
        })
    }

    
    const [passwordType, setPasswordType] = useState<string>("password")

    const checkPasswordHideOut = () => {
        if(passwordType === 'password') {
            setPasswordType('text')
        } else {
            setPasswordType('password')
        }
    }
   
    return ( 
        <div className="Registration py-5">
            <div className="container h-100 d-flex justify-content-center align-items-center">
                <div className="d-flex justify-content-center align-items-center">
                    <div className="form-container p-4">
                    
                        <div className="form-header text-center">
                            <div className="form-header-title h2">Registration</div>
                            <div className="form-notify-header text-secondary small">Create account with email and password</div>
                        </div>
 

                        <form className="form w-100 h-100 needs-validation" onSubmit={submit}>
  
                            <div className="names-input-container row py-2">
                                <div className="form-input pe-2 col-6 has-validation">
                                    <input className={`form-control ${errors.firstName ? 'is-invalid' : ''}`} type="text" placeholder='First Name' {...register('firstName', { required : 'This field is required'})}/>
                                    {errors.firstName && <small className='error'>{errors.firstName.message}</small>} 
                                </div>

                                <div className="form-input ps-2 col-6">
                                    <input className={`form-control ${errors.lastName ? 'is-invalid' : ''}`} type="text" placeholder='Last Name' {...register('lastName', { required : 'This field is required'})}/>
                                    {errors.lastName && <small className='error'>{errors.lastName.message}</small>}
                                </div>
                            </div>
                            
                            <div className="form-input py-2">
                                <input  className={`form-control ${errors.email ? 'is-invalid' : ''}`} type="email" placeholder='Email' {...register('email', { required : 'This field is required'})}/>
                                    {errors.email && <small className='error'>{errors.email.message}</small>}
                            </div>
                            
                            <div className="form-input py-2">
                               <input className={`form-control ${errors.phoneNumber ? 'is-invalid' : ''}`} type="number" placeholder='Phone number' 
                                {...register('phoneNumber', { 
                                        required: 'This field is required',
                                        minLength: {
                                            value: 11,
                                            message: 'Phone number must be 11 digits'
                                        },
                                        maxLength: {
                                            value: 11,
                                            message: 'Phone number must be 11 digits'
                                        },
                                        pattern: {
                                            value: /^\d{11}$/,
                                            message: 'Phone number must be exactly 11 digits'
                                        }
                                    })}/>
                                {errors.phoneNumber && <small className='error'>{errors.phoneNumber.message}</small>}
                            </div>
                            
                            <div className="form-input py-2">
                                <div className="d-flex align-items-center form-control p-0 password">  
                                    <input 
                                        className={`border-0 w-100 px-3 ${errors.password ? 'is-invalid' : ''}`}  
                                        type={passwordType} 
                                        placeholder='Password'  
                                        {...register('password', { 
                                            required: 'This field is required', 
                                            minLength: {
                                                value: 6,
                                                message: 'Password must be at least 6 characters'
                                            },
                                            pattern: {
                                                value: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/,
                                                message: 'Password must contain letters and numbers'
                                            }
                                        })}
                                    />
                                    <div className="btn-container  d-flex justify-content-center align-items-center h-100 ps-3">
                                        <span className="cursor-pointer" onClick={checkPasswordHideOut} >{passwordType === 'password' ? <FaRegEyeSlash /> : <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M21.25 9.14969C18.94 5.51969 15.56 3.42969 12 3.42969C10.22 3.42969 8.49 3.94969 6.91 4.91969C5.33 5.89969 3.91 7.32969 2.75 9.14969C1.75 10.7197 1.75 13.2697 2.75 14.8397C5.06 18.4797 8.44 20.5597 12 20.5597C13.78 20.5597 15.51 20.0397 17.09 19.0697C18.67 18.0897 20.09 16.6597 21.25 14.8397C22.25 13.2797 22.25 10.7197 21.25 9.14969ZM12 16.0397C9.76 16.0397 7.96 14.2297 7.96 11.9997C7.96 9.76969 9.76 7.95969 12 7.95969C14.24 7.95969 16.04 9.76969 16.04 11.9997C16.04 14.2297 14.24 16.0397 12 16.0397Z" fill="#95A3B4"/>
                                            <path d="M12 9.14062C10.43 9.14062 9.15002 10.4206 9.15002 12.0006C9.15002 13.5706 10.43 14.8506 12 14.8506C13.57 14.8506 14.86 13.5706 14.86 12.0006C14.86 10.4306 13.57 9.14062 12 9.14062Z" fill="#95A3B4"/>
                                            </svg>
                                            }</span>
                                    </div>
                                 </div>
                                {errors.password && <small className='error'>{errors.password.message}</small>} 
                            </div>
                              
                            <div className="form-input py-3">
                                    <div className="small text-start">
                                        By signing up, i agree to the
                                        <a href="/terms" className='text-primary ms-1'>Terms of service and Privacy Policy</a>
                                    </div>
                            </div>

                            <div className="form-input">
                                <button className="btn btn-primary rounded-pill w-100" disabled={ request }>Register {request && <span className="spinner-sm-white"></span>}</button>
                            </div>
                            
                            <div className="form-input pt-3">
                                <div className="small text-center">
                                    Already have an account?
                                    <Link to='/login' className='text-decoration-none ms-1'>Login</Link>
                                </div>
                            </div> 
                        </form>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default Registration;
 
