import './DriversLicense.scss'
import { DriverLicensePackage } from '../../Components';


const DriversLicense = () => {
    return ( 
        <div className="DriversLicense">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-5 my-5">
                      <DriverLicensePackage></DriverLicensePackage>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default DriversLicense;