import React from 'react'
import "./RecentActivities.scss"

const RecentActivities = () => {
    return (
        <div className='RecentActivities'>
            <div className="card border-0 px-3">
                <div className="card-header bg-white px-0 py-3">
                    <div className="row">
                        <div className="col-8">
                            <div className="title">Recent Activities (2)</div>
                        </div>
                        <div className="col-4">
                            <div className="see-all">See All</div>
                        </div>
                    </div>
                </div>
                <div className="card-body px-0">
                   
                <div className="card item-list item-list-pending">
                    <div className="card-body">
                        <div className="row h-100">
                            <div className="col-2 my-auto">
                                <svg width="22" height="23" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect y="0.5" width="32" height="32" rx="8" fill="white"/>
                                    <path d="M19.51 7.33008H12.49C10 7.33008 9.45 8.57008 9.13 10.0901L8 15.5001H24L22.87 10.0901C22.55 8.57008 22 7.33008 19.51 7.33008Z" stroke="#6A80A3" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                                    <path d="M25.9902 24.32C26.1002 25.49 25.1602 26.5 23.9602 26.5H22.0802C21.0002 26.5 20.8502 26.04 20.6602 25.47L20.4602 24.87C20.1802 24.05 20.0002 23.5 18.5602 23.5H13.4402C12.0002 23.5 11.7902 24.12 11.5402 24.87L11.3402 25.47C11.1502 26.04 11.0002 26.5 9.92022 26.5H8.04022C6.84022 26.5 5.90022 25.49 6.01022 24.32L6.57022 18.23C6.71022 16.73 7.00022 15.5 9.62022 15.5H22.3802C25.0002 15.5 25.2902 16.73 25.4302 18.23L25.9902 24.32Z" stroke="#6A80A3" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                                    <path d="M8 12.5H7" stroke="#6A80A3" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                                    <path d="M25 12.5H24" stroke="#6A80A3" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                                    <path d="M16 7.5V9.5" stroke="#6A80A3" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                                    <path d="M14.5 9.5H17.5" stroke="#6A80A3" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                                    <path d="M10 19.5H13" stroke="#6A80A3" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                                    <path d="M19 19.5H22" stroke="#6A80A3" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                                </svg> 
                            </div>
                            <div className="col-8 ps-0">
                                <div className="heading-text">
                                    Vehicle Images are ready
                                </div>
                                <div className="service-type">
                                    Buy Vehicle
                                </div> 
                            </div>
                            <div className="col-2">
                                <div className="heading-text"></div> 
                                <div className="service-time">
                                    2s
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card item-list item-list-warning">
                    <div className="card-body">
                        <div className="row h-100">
                            <div className="col-2 my-auto">
                                <svg width="22" height="23" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect y="0.5" width="32" height="32" rx="8" fill="white"/>
                                    <rect x="7" y="7.875" width="18" height="18" rx="5" stroke="#6A80A3" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" stroke-linejoin="round"/>
                                    <path d="M15.436 20.259V18.027H16.264C16.832 18.027 17.252 17.875 17.524 17.571C17.804 17.267 17.944 16.895 17.944 16.455C17.944 15.959 17.792 15.575 17.488 15.303C17.192 15.023 16.808 14.883 16.336 14.883C15.8 14.883 15.372 15.043 15.052 15.363C14.74 15.683 14.584 16.087 14.584 16.575H13.48C13.48 16.103 13.584 15.663 13.792 15.255C14.008 14.847 14.328 14.515 14.752 14.259C15.176 14.003 15.704 13.875 16.336 13.875C16.896 13.875 17.376 13.991 17.776 14.223C18.184 14.447 18.496 14.751 18.712 15.135C18.936 15.511 19.048 15.927 19.048 16.383V16.527C19.048 16.943 18.956 17.327 18.772 17.679C18.588 18.031 18.312 18.323 17.944 18.555C17.584 18.779 17.136 18.915 16.6 18.963L16.588 20.259H15.436ZM15.316 22.875V21.411H16.708V22.875H15.316Z" fill="#6A80A3"/>
                                    <path d="M13 10.875H19" stroke="#6A80A3" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                                </svg> 
                            </div>
                            <div className="col-8 ps-0">
                                <div className="heading-text">
                                    Due in 10 hours!
                                </div>
                                <div className="service-type">
                                    Quote Request
                                </div> 
                            </div>
                            <div className="col-2">
                                <div className="heading-text"></div> 
                                <div className="service-time">
                                    2s
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card item-list item-list-success">
                    <div className="card-body">
                        <div className="row h-100">
                            <div className="col-2 my-auto">
                                <svg width="22" height="23" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect y="0.5" width="32" height="32" rx="8" fill="white"/>
                                    <path d="M26 14.5V19.5C26 24.5 24 26.5 19 26.5H13C8 26.5 6 24.5 6 19.5V13.5C6 8.5 8 6.5 13 6.5H18" stroke="#6A80A3" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                                    <path d="M26 14.5H22C19 14.5 18 13.5 18 10.5V6.5L26 14.5Z" stroke="#6A80A3" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                                    <path d="M11 17.5H17" stroke="#6A80A3" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                                    <path d="M11 21.5H15" stroke="#6A80A3" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                                </svg> 
                            </div>
                                <div className="col-8 ps-0">
                                <div className="heading-text">
                                    Documents are ready!
                                </div>
                                <div className="service-type">
                                    Registration
                                </div> 
                            </div>
                            <div className="col-2">
                                <div className="heading-text"></div> 
                                <div className="service-time">
                                    2s
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card item-list item-list-success">
                    <div className="card-body">
                        <div className="row h-100">
                            <div className="col-2 my-auto">
                                <svg width="22" height="23" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect y="0.5" width="32" height="32" rx="8" fill="white"/>
                                    <path d="M19.51 7.33008H12.49C10 7.33008 9.45 8.57008 9.13 10.0901L8 15.5001H24L22.87 10.0901C22.55 8.57008 22 7.33008 19.51 7.33008Z" stroke="#6A80A3" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                                    <path d="M25.9902 24.32C26.1002 25.49 25.1602 26.5 23.9602 26.5H22.0802C21.0002 26.5 20.8502 26.04 20.6602 25.47L20.4602 24.87C20.1802 24.05 20.0002 23.5 18.5602 23.5H13.4402C12.0002 23.5 11.7902 24.12 11.5402 24.87L11.3402 25.47C11.1502 26.04 11.0002 26.5 9.92022 26.5H8.04022C6.84022 26.5 5.90022 25.49 6.01022 24.32L6.57022 18.23C6.71022 16.73 7.00022 15.5 9.62022 15.5H22.3802C25.0002 15.5 25.2902 16.73 25.4302 18.23L25.9902 24.32Z" stroke="#6A80A3" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                                    <path d="M8 12.5H7" stroke="#6A80A3" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                                    <path d="M25 12.5H24" stroke="#6A80A3" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                                    <path d="M16 7.5V9.5" stroke="#6A80A3" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                                    <path d="M14.5 9.5H17.5" stroke="#6A80A3" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                                    <path d="M10 19.5H13" stroke="#6A80A3" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                                    <path d="M19 19.5H22" stroke="#6A80A3" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                                </svg> 
                            </div>
                            <div className="col-8 ps-0">
                                <div className="heading-text">
                                    Sourcing Completed!
                                </div>
                                <div className="service-type">
                                    Buy Vehicle
                                </div> 
                            </div>
                            <div className="col-2">
                                <div className="heading-text"></div> 
                                <div className="service-time">
                                    2s
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
}

export default RecentActivities
