import React from 'react' 
import NavMenu from '../NavMenu/NavMenu' 
import "./AuthenticatedHeader.scss"
  

const AuthenticatedHeader = () => { 
  
  return (
        <div className='authenticated-header d-none d-lg-block'>
          <NavMenu></NavMenu>
        </div>
  )
}

export default AuthenticatedHeader
