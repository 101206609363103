import React, { Key, useEffect, useState } from 'react'
import "./RegistrationOrderDetails.scss"
import { BreadCrumb, ImageSrc } from 'Components'
import { orderService, vehicleService } from 'Services';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2'; 
import { orderDataType } from 'Models';
import { vehicleServiceDocument } from 'Models/vehicleServiceType';
import { TimeConverter } from '../../../../../../Helpers/TimeConverter';

const RegistrationOrderDetails = () => {
    const limit = 100;
    const offset = 0;
    
    const { orderId } = useParams<{ orderId: string }>()
    
    const [registrationDetails, setRegistrationDetails] = useState<orderDataType>()
 
    const [request, setRequest] = useState(false)
    const [serviceDocuments, setServiceDocuments] = useState<Array<vehicleServiceDocument>>()

    
    const getServices = async () =>{
        await orderService.getOrderId(limit, offset, orderId).then((response) =>{
            console.log("response vehicleServices", response.data)
            setRegistrationDetails(response.data[0])
        }, error => {
 
            console.log("error vehicleServices", error)
            Swal.fire({
                position: "top-end",
                icon: "warning",
                text: error.response.data.message,
                showConfirmButton: false,
                timer: 3000
            });
        })
    }
 
    
 
   const getDocuments = async () => {
       setRequest(true)
      await vehicleService.serviceDocuments(orderId).then((response)=>{
       setRequest(false) 
        console.log("serviceDocuments", response.data)
        setServiceDocuments(response.data)
      }, error =>{
       setRequest(false)
       console.log("error make", error)
       Swal.fire({
           position: "top-end",
           icon: "warning",
           text: error.response.data.message,
           showConfirmButton: false,
           timer: 3000
       });
      })
   }

   useEffect(() => {
    getServices()
    getDocuments() 
}, [orderId])

   
    return (
        <div className='RegistrationOrderDetails'>
              <div className="container">
                <BreadCrumb parentTitle={'Orders'} parentUrl={'/myaccount/orders/registration'} pageTitle={'Registration'} childTitle={registrationDetails?.service.name} noPad={true}></BreadCrumb>
            
                {!request && <div className="row mt-4 pt-2 h-100">
                    <div className="col-md-6 my-auto mt-2 mt-md-0">
                        <div className="orderInfo">  
                            Reg #{registrationDetails?.orderNo}, {TimeConverter(registrationDetails?.time)}
                        </div> 
                    </div>
                    <div className="col-md-6 text-sm-end mt-2 mt-md-0 my-auto">
                        <span className='status'>{registrationDetails?.registrationStatus?.name}</span>
                    </div>
                </div>}
                <div className="row justify-content-center pt-3 pb-5">
                    <div className="col-md-6">
                        <div className="card border-0 mt-3 mt-md-0">
                            <div className="card-header bg-white pt-3">
                                <div className="title">
                                    Registration Details
                                </div>
                            </div>
                            <div className="card-body pt-0">
                                <div className="row border-bottom border-top pb-2 pt-3">
                                    <div className="col-6">
                                        <div className="label">
                                            Make
                                        </div> 
                                    </div>
                                    <div className="col-6 text-end">
                                        <div className="value">
                                            {registrationDetails?.registrationStatus?.name}
                                        </div>
                                    </div>

                                    <div className="col-6">
                                        <div className="label">
                                            Model
                                        </div> 
                                    </div> 

                                    <div className="col-6 text-end">
                                        <div className="value">  
                                            {registrationDetails?.registrationStatus?.name}  
                                        </div>
                                    </div>


                                    {/* <div className="col-6">
                                        <div className="label">
                                            Year
                                        </div> 
                                    </div> 
                                    <div className="col-6 text-end">
                                        <div className="value">
                                        {registrationDetails?.vehicleInformation.year}
                                        ...
                                        </div>
                                    </div>


                                    <div className="col-6">
                                        <div className="label">
                                            Color
                                        </div> 
                                    </div> 
                                    <div className="col-6 text-end">
                                        <div className="value text-capitalize">
                                        {registrationDetails?.vehicleInformation.color}
                                        ...
                                        </div>
                                    </div>


                                    <div className="col-6">
                                        <div className="label">
                                            Engine number
                                        </div> 
                                    </div> 

                                    <div className="col-6 text-end">
                                        <div className="value">
                                        {registrationDetails?.vehicleInformation.engineNumber}
                                        ...
                                        </div>
                                    </div>

                                    <div className="col-6">
                                        <div className="label">
                                            Chasis number
                                        </div> 
                                    </div> 

                                    <div className="col-6 text-end">
                                        <div className="value">
                                        {registrationDetails?.vehicleInformation.chasisNumber}
                                        ...
                                        </div>
                                    </div> */}
                                </div> 


                                <div className="row border-bottom pb-2 mt-2">
                                    <div className="col-6">
                                        <div className="label">
                                            Name
                                        </div> 
                                    </div>
                                    <div className="col-6 text-end">
                                        <div className="value">
                                        {registrationDetails?.owner.name}
                                        </div>
                                    </div>

                                    <div className="col-6">
                                        <div className="label">
                                            Phone number
                                        </div> 
                                    </div> 

                                    <div className="col-6 text-end">
                                        <div className="value">
                                        {registrationDetails?.deliveryDetail.phoneNumber}
                                        </div>
                                    </div>


                                    <div className="col-6">
                                        <div className="label">
                                            Email
                                        </div> 
                                    </div> 
                                    <div className="col-6 text-end">
                                        <div className="value">
                                        {registrationDetails?.owner.email}
                                        </div>
                                    </div> 
                                </div>


                                <div className="row border-bottom pb-2 mt-2">
                                    <div className="col-6">
                                        <div className="label">
                                            Address
                                        </div> 
                                    </div>
                                    <div className="col-6 text-end">
                                        <div className="value">
                                            {registrationDetails?.deliveryDetail.address}
                                        </div>
                                    </div>

                                    <div className="col-6">
                                        <div className="label">
                                            Local Govt.
                                        </div> 
                                    </div> 

                                    <div className="col-6 text-end">
                                        <div className="value">
                                            {registrationDetails?.deliveryDetail.localGovernment}
                                        </div>
                                    </div>


                                    <div className="col-6">
                                        <div className="label">
                                            State
                                        </div> 
                                    </div> 

                                    <div className="col-6 text-end">
                                        <div className="value">
                                            {registrationDetails?.deliveryDetail.state}
                                        </div> 
                                    </div> 

                                    <div className="col-6">
                                        <div className="label">
                                            Email
                                        </div> 
                                    </div> 
                                    <div className="col-6 text-end">
                                        <div className="value">
                                        {registrationDetails?.owner.email}
                                        </div>
                                    </div> 
                                </div> 
                            </div>
                        </div>


                        <div className="card document-details-card p-0 border-0 mt-3 px-2">
                            <div className="card-header pt-3 border-bottom"> 
                                <div className="title">
                                    Documents
                                </div>  
                            </div>
                            <div className="card-body">
                                <div className="document-section">
                                        <div className="row">
                                            {request &&  <div className="col-12 h-100 bg-white text-center">
                                                <span className='spinner-border spinner-border-md text-secondary'></span>
                                                </div>}
                                                
                                            {serviceDocuments && serviceDocuments.map((doc: vehicleServiceDocument, index:Key)=>{
                                            return <div key={index} className="col-6">  
                                                <div className="label d-inline-flex">
                                                    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M22 10V15C22 20 20 22 15 22H9C4 22 2 20 2 15V9C2 4 4 2 9 2H14" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                                                        <path d="M22 10H18C15 10 14 9 14 6V2L22 10Z" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                                                    </svg>
                                                    <div className='ms-2'>{doc.document.name}</div>
                                                </div>
                                            </div>})}  

                                            {(!serviceDocuments || serviceDocuments.length === 0) && <div className='emptyMessage'>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <svg width="54" height="54" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <rect width="64" height="64" rx="18" fill="#F0F4FF"/>
                                                            <path d="M34.4297 34.5508H29.4297" stroke="#6A80A3" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" stroke-linejoin="round"/>
                                                            <path d="M42 31V37C42 41 41 42 37 42H27C23 42 22 41 22 37V27C22 23 23 22 27 22H28.5C30 22 30.33 22.44 30.9 23.2L32.4 25.2C32.78 25.7 33 26 34 26H37C41 26 42 27 42 31Z" stroke="#6A80A3" strokeWidth="1.5" strokeMiterlimit="10"/>
                                                        </svg> 
                                                    </div>
                                                    <div className="col-12 mt-3">
                                                    Documents are not ready, kindly check back later
                                                    </div>
                                                </div>
                                            </div>}
                                        </div>
                                </div>
                            </div> 
                        </div>
                    </div>

                    <div className="col-md-6"> 
                        <div className="card document-details-card mt-3 mt-md-0 p-0 border-0"> 
                            <div className="card-header pt-3 border-bottom"> 
                                <div className="title">
                                  Summary
                                </div>  
                            </div>  
                            
                            <div className="card-body pt-2 pb-4 px-0"> 
                                <div className="make-info">
                                    <div className="row h-100 px-3 py-2">
                                        <div className="col-1 my-auto">
                                            <div className="logo"> 
                                                <ImageSrc src={''} alt="" title={undefined} width={''} />
                                            </div> 
                                        </div> 

                                        <div className="col-7 my-auto">
                                            {/* <div className="my-auto ms-3 title-x">{registrationDetails?.vehicleInformation.make.name} {registrationDetails?.vehicleInformation.model.name}, {registrationDetails?.vehicleInformation.year}</div>  */}
                                            ...
                                        </div>
                                        <div className="col-4 my-auto text-end">
                                                ₦{registrationDetails?.amount}
                                        </div> 
                                    </div>
                                </div>
                                <div className="total-balance pt-2"> 
                                    <div className="inner-row px-3">   
                                        <div className="row h-100">   
                                            <div className="col-6 mb-2 fw-lighter value-title sub-total mt-1 pt-1"> 
                                                Sub Total
                                            </div>

                                            <div className="col-6 text-end mb-2 fw-lighter sub-total mt-1 pt-1">
                                                ₦{registrationDetails?.amount}
                                            </div>

                                            <div className="col-6 mb-2 fw-lighter value-title sub-total"> 
                                               Delivery
                                            </div>
                                            
                                            <div className="col-6 text-end mb-2 fw-lighter sub-total">
                                                ₦{registrationDetails?.service.percentage}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row total-amount h-100 px-3 pt-3">   
                                        <div className="col-6 my-auto">
                                            Total
                                        </div>

                                        <div className="col-6 text-end my-auto">
                                            ₦{registrationDetails?.amount}
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div> 


                         <div className="card document-details-card p-0 border-0 mt-3 px-2">
                            <div className="card-header pt-3 border-bottom"> 
                                <div className="title">
                                Delivery
                                </div>  
                            </div>
                            <div className="card-body">
                                <div className="document-section">
                                        <div className="row">
                                            {request &&  <div className="col-12 h-100 bg-white text-center">
                                                <span className='spinner-border spinner-border-md text-secondary'></span>
                                                </div>}   

                                                <div className="address">
                                                    <div> 
                                                       {registrationDetails?.deliveryDetail.address}, {registrationDetails?.deliveryDetail.localGovernment}, {registrationDetails?.deliveryDetail.state}.
                                                    </div> 
                                                    <div> 
                                                       {registrationDetails?.owner.name}, {registrationDetails?.deliveryDetail.phoneNumber}
                                                    </div>
                                                </div>
                                        </div>
                                </div>
                            </div> 
                        </div> 
                    </div> 
                </div> 
             </div>
        </div>
    )
}

export default RegistrationOrderDetails
