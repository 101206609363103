import React from 'react'
import "./Orders.scss"
import { Outlet } from 'react-router-dom'
import { NavLink } from 'react-router-dom'

const Orders = () => {
    return (
        <div className='Orders'>
            <div className="card border-0">
                <div className="card-header bg-white">
                        {/* <NavLink className={({ isActive }) => isActive ? "nav-link active h-100" : "nav-link h-100"} to={'/myaccount/orders/vehicle'}>Vehicle</NavLink>  */}
                        <NavLink className={({ isActive }) => isActive ? "nav-link active h-100" : "nav-link h-100"} to={'/myaccount/orders/registration'}>Registration</NavLink> 
                        {/* <NavLink className={({ isActive }) => isActive ? "nav-link active h-100" : "nav-link h-100"} to={'/myaccount/orders/insurance'}>Insurance</NavLink>  */}
                        {/* <NavLink className={({ isActive }) => isActive ? "nav-link active h-100" : "nav-link h-100"}  to={'/myaccount/orders/tracker'}>Tracker</NavLink> */}
                </div>
                <div className="card-body">
                    <Outlet />
                </div>
            </div>
        </div>
    ) 
}

export default Orders
