import React, { useEffect, useState } from 'react'
import "./InsuranceRegistrationAuth.scss"
import { BreadCrumb } from 'Components' 
import { InsuranceVehicleTypeForm, InsuranceVehicleTypeFormPreview } from 'Pages/Frontend/Components'
import { vehicleServiceType } from 'Models'
import { vehicleService } from 'Services'
import { useParams } from 'react-router-dom'
import Swal from 'sweetalert2'

const InsuranceRegistrationAuth = () => { 

    const limit = 100;
    const offset = 0;
    
    const { service_id } = useParams<{ service_id: string }>()
    const [confirmVehicle, setConfirmVehicle] = useState(false) 
    const [serviceDetails, setServiceDetails] = useState<vehicleServiceType>()
 
     const getServices = async () =>{
         await vehicleService.serviceItem(service_id, limit, offset).then((response) =>{
             console.log("response vehicleServices", response.data)
             setServiceDetails(response.data[0])
         }, error => {
 
             console.log("error vehicleServices", error)
             Swal.fire({
                 position: "top-end",
                 icon: "warning",
                 text: error.response.data.message,
                 showConfirmButton: false,
                 timer: 3000
             });
         })
     }
 
     useEffect(() => {
         getServices()
     }, [service_id])
     
    return (
        <div className='NewRegistrationAuth'>
             <div className="container">
                <BreadCrumb parentTitle={'Registration'} parentUrl={'/register-vehicle'} pageTitle={'New Registration'}></BreadCrumb>
                <div className="row justify-content-center">
                    <div className="col-md-6 col-xl-5 mb-5 mt-4">
                       <div className="card border-0 py-3">
                        <div className="card-header text-left border-0 pt-3"> 
                            <div className="title">
                            What type of vehicle?
                            </div> 
                        </div>
                        <div className="card-body pt-2">
                           <InsuranceVehicleTypeForm setConfirmVehicle={(val:boolean)=>setConfirmVehicle(val)}></InsuranceVehicleTypeForm>
                        </div>
                       </div>
                    </div>

                    {confirmVehicle && <div className="col-md-6 col-xl-5 mb-5 mt-4">
                   
                       <InsuranceVehicleTypeFormPreview module="user" serviceDetails={serviceDetails}></InsuranceVehicleTypeFormPreview>
                    
                    </div>}
                </div>
             </div>
        </div>
    )
}

export default InsuranceRegistrationAuth
