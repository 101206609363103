 
import { RegExpressNavbar } from "Layout";
import { JoinKitaniz, RegExpressHero, RegFAQ, RegFlexibility, RegistrationService, WhyKitanizRegEx } from "../../Components";

const RegExpress = () => {

    return (
        <div className="">
            <RegExpressNavbar />  
            <RegExpressHero />
            <RegistrationService/> 
            <WhyKitanizRegEx />
            <RegFlexibility />
            <RegFAQ />
            <JoinKitaniz />
        </div>
    );
}
export default RegExpress;