import axios from "axios";
import { AxiosInterceptor } from "Config/AxiosInterceptor";
AxiosInterceptor();

export const deliveryService = {
  getFeeByLg: async (
    limit: number,
    offset: number,
    state: string | undefined,
    lga: string | undefined
  ) => {
    return await axios.get(
      `/delivery/fee?location=${state}&localGovernment=${lga}&limit=${limit}&offset=${offset}`
    );
  },
  getServiceType: async () => {
    return await axios.get("/vehicle-type-prices");
  },
  getServiceTypePricesByServiceId: async (id: string) => {
    return await axios.get(`/registration-services/${id}/vehicle-type-prices`);
  },
  updateOrderDeliveryService: async (
    orderId: string | undefined,
    data: {
      deliveryFeeServiceId: string;
      state: string | null;
      amount: string;
    }
  ) => {
    return await axios.patch(`/main-order/add-delivery-fee/${orderId}`, data);
  },
  updateVehicleTypePriceOrder: async (data: {
    amount: number;
    vehicleTypePriceId: string;
    orderUuid: string;
  }) => {
    return await axios.post(`/vehicle-type-price-orders`, data);
  },
  updateVehicleServiceTypeOrder: async (data: {
    orderUuid: string;
    amount: number;
    vehicleServiceTypeUuid: string;
  }) => {
    return await axios.post(`/order/vehicle-service-type-orders`, data);
  },
  saveDeliveryDetails: async (data: {
    amount: number;
    orderId: string;
    localGovernmentUuid: string;
    address: string;
    contact_name: string;
    contact_phone: string;
  }) => {
    return await axios.post(`/local-government-orders`, data);
  },
  createRegistrationServiceDocumentOrder: async (data: {
    amount: number;
    registrationServiceDocument: string;
    order: string;
  }) => {
    return await axios.post(
      `/order/registration-service-document-orders`,
      data
    );
  },
  getServiceTypeByregistrationServiceIdAndVehicleTypeId: async (
    registrationServiceId: string,
    vehicleTypeId: string
  ) => {
    return await axios.get(
      `/registration-services/${registrationServiceId}/${vehicleTypeId}/vehicle-type-prices`
    );
  },
};
