// import React, { useEffect } from 'react'
import "./Dashboard.scss"
import { BuyVehicle, LatestTransaction, Regexpress, StatsCard } from '../../Components'
import RecentActivities from '../../Components/Dashboard/RecentActivities/RecentActivities' 
// import { deliveryService } from 'Services'

const Dashboard = () => {
    
    // const getOrder = async () => {
    //     await deliveryService.getFeeByLg(100, 0, 'Lagos', 'Apapa').then((response)=>{
    //         console.log({response})
    //     }, error => {
    //         console.log({error})
    //     })
    // }

    // useEffect(() => {
    //     getOrder()
    // }, [])
    return (
        <div className='Dashboard'>
            <div className="dash-page-title">View and manage your account here!</div>
            <div className="row">
                <div className="col-md-8">
                   <div className="stats">
                        <div className="row">
                            <div className="col-12 col-sm-6 col-md-4">
                                <StatsCard label={'Total Orders'} value={400}></StatsCard>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4">
                                <StatsCard label={'Pending Orders'} value={150}></StatsCard>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4">
                                <StatsCard label={'Quote Request'} value={5}></StatsCard>
                            </div>
                        </div>
                   </div>

                   <div className="regExpress mt-3">
                     <Regexpress></Regexpress>
                     {/* <BuyVehicle/> */}
                   </div>
                </div>
                <div className="col-md-4">
                    <RecentActivities/> 

                    <LatestTransaction/>
                </div>
            </div>
        </div>
    )
}

export default Dashboard
