import './PlateReprintAuth.scss' 
import { BreadCrumb } from 'Components'
import { Link } from 'react-router-dom'
import { PlateReprintForm } from 'Pages/Frontend/Components'


const PlateReprintAuth = () => { 
    return ( 
        <div className="PlateReprint">
            
            <div className="container">
                <BreadCrumb parentTitle={'Registration'} parentUrl={'/plate-reprint'} pageTitle={'Plate Reprint'}></BreadCrumb>
                <div className="row justify-content-center">
                    <div className="col-md-5 mb-5 mt-4">
                       <div className="card border-0 py-3">
                        <div className="card-header text-left border-0 pt-3"> 
                            <div className="title">
                                Plate Reprint
                            </div> 
                        </div>
                        <div className="card-body pt-2">
                          <PlateReprintForm></PlateReprintForm>
                           
                          
                        </div>
                       </div>
                    </div>

                </div>
             </div>
        </div>
     );
}
 
export default PlateReprintAuth;