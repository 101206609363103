import './PlateReprint.scss'
import React, { useState } from 'react'
import { BreadCrumb } from 'Components'
import { Link } from 'react-router-dom'


const PlateReprint = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [confirmVehicle, setConfirmVehicle] = useState(false) 

    return ( 
        <div className="PlateReprint">
            
            <div className="container">
                <BreadCrumb parentTitle={'Registration'} parentUrl={'/plate-reprint'} pageTitle={'Plate Reprint'}></BreadCrumb>
                <div className="row justify-content-center">
                    <div className="col-md-5 mb-5 mt-4">
                       <div className="card border-0 py-3">
                        <div className="card-header text-left border-0 pt-3"> 
                            <div className="title">
                                Plate Reprint
                            </div> 
                        </div>
                        <div className="card-body pt-2">
                            <form action="">
                                <div className="form-group mt-0">
                                    <label htmlFor="">First Name</label>
                                    <select name="" id="" className='form-control'>
                                        <option value="">Benjamin</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Last Name</label>
                                    <select name="" id="" className='form-control'>
                                        <option value="">Wood</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Driver's Plate Number</label>
                                    <select name="" id="" className='form-control'>
                                        <option value="">2134-AD32</option>
                                    </select>
                                </div> 
                                
                                <div className="form-group"> 
                                <Link to='/upload-plate-number'>
                                    <button className='btn btn-primary' type='button' onClick={()=>setConfirmVehicle(true)}>Continue</button>
                                </Link>
                                </div> 
                            </form>
                        </div>
                       </div>
                    </div>

                </div>
             </div>
        </div>
     );
}
 
export default PlateReprint;