import { useState } from 'react';
import './NewCustomPlateNumber.scss'
import { Link } from 'react-router-dom';

const NewCustomPlateNumber = () => { 
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [confirmVehicle, setConfirmVehicle] = useState(false)

    return ( 
        <div className="NewCustomPlateNumber">
            <div className="row justify-content-center">
                <div className="col-md-5 mb-5 mt-4">
                    <div className="card border-0 py-3">
                    <div className="card-header text-left border-0 pt-3"> 
                        <div className="title">
                            Customize Plate Number
                        </div> 
                    </div>
                    <div className="card-body pt-2">
                        <form action="">
                            <div className="form-group mt-0">
                                <label htmlFor="">New Place Number</label>
                                <select name="" id="" className='form-control'>
                                    <option value="">5317-32FC</option>
                                </select>
                            </div>
                            
                            <div className="form-group mt-0">
                                <label htmlFor="">Old Place Number</label>
                                <select name="" id="" className='form-control'>
                                    <option value="">5317-32FC</option>
                                </select>
                            </div>
                            
                            
                            <div className="form-group"> 
                                <Link to='/checkout'>
                                    <button className='btn btn-primary' type='button' onClick={()=>setConfirmVehicle(true)}>Checkout</button>
                                </Link>
                            </div> 
                        </form>
                    </div>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default NewCustomPlateNumber;