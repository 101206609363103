import React, { useEffect, useState } from 'react'
import "./InsuranceAuth.scss"
import { InsurancePlan } from 'Pages/Frontend/Components'
import { vehicleServiceType } from 'Models';
import { vehicleService } from 'Services';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

const InsuranceAuth = () => {
    const limit = 100;
   const offset = 0;
   
   const { service_id } = useParams<{ service_id: string }>()
   const [confirmVehicle, setConfirmVehicle] = useState(false) 
   const [serviceDetails, setServiceDetails] = useState<vehicleServiceType>()

    const getServices = async () =>{
        await vehicleService.serviceItem(service_id, limit, offset).then((response) =>{
            console.log("response vehicleServices", response.data)
            setServiceDetails(response.data[0])
        }, error => {

            console.log("error vehicleServices", error)
            Swal.fire({
                position: "top-end",
                icon: "warning",
                text: error.response.data.message,
                showConfirmButton: false,
                timer: 3000
            });
        })
    }

    useEffect(() => {
        getServices()
    }, [service_id])
    
    return (
        <div className='InsuranceAuth'>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-7 col-xl-6 mt-5">
                        <InsurancePlan module="user" serviceDetails={serviceDetails}></InsurancePlan>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InsuranceAuth
