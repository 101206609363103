import React from 'react'
import "./RegisterVehicle.scss"
import registration from "Assets/Images/register-vehicle.png"
import fast from "Assets/Icons/fast.svg"
import secure from "Assets/Icons/secure.svg"
import prompt from "Assets/Icons/prompt.svg" 
 
import { JoinKitaniz, RegistrationMenuOption } from '../../Components'

const RegisterVehicle = () => {
    return (
        <div className='RegisterVehicle'>
             <div className="container py-5">
                <div className="row h-100">
                    <div className="col-md-6 my-auto">
                        <div className="register-left">
                            <div className="regexpress">
                                REGEXPRESS
                            </div>
                            <h5>Streamline Online <br/>Vehicle Registration</h5>
                            <img src={registration} className="left-img d-md-none mt-4" alt=''></img>
                            <div className="tagline mb-4 mt-4">Skip the lines, embrace convenience! Swiftly register your vehicle online. Easy steps, faster results. Renew or new vehicle registration with a click.</div>
                           
                            <a href='#registerLists' className='mt-4'>
                                <button className="btn btn-primary btn-default">Register Vehicle</button>
                            </a>
                        </div> 
                    </div>
                    <div className="col-md-6 text-md-end">
                         <img src={registration} className="left-img d-none d-md-block" alt=''></img>
                    </div>
                </div>
             </div>



            <div className="showcase">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 mt-3">
                            <div className="card border-0 text-center">
                                <div className="card-body"> 
                                    <div className="card-icon-wraper mb-4"> 
                                        <div className="card-icon">
                                        <img src={fast} alt=''/>
                                        </div>
                                    </div>

                                    <div className="card-title">Fast Delivery</div>
                                    <div className="card-text mb-4">We deliver to your doorstep within 24hrs after successful registration</div>

                                </div>
                            </div> 
                        </div>
                        <div className="col-md-4 mt-3"> 
                            <div className="card border-0 text-center">
                                <div className="card-body"> 
                                    <div className="card-icon-wraper mb-4"> 
                                        <div className="card-icon">
                                        <img src={prompt} alt=''/>
                                        </div>
                                    </div>

                                    <div className="card-title">Prompt Reminder</div>
                                    <div className="card-text mb-4">We ensure you get a reminder, before your registration with us expires.</div>

                                </div>
                            </div> 
                        </div>
                        <div className="col-md-4 mt-3"> 
                            <div className="card border-0 text-center">
                                <div className="card-body">
                                    <div className="card-icon-wraper mb-4"> 
                                        <div className="card-icon">
                                        <img src={secure} alt=''/>
                                        </div>
                                    </div>
                                    <div className="card-title">Secure Documents</div>
                                    <div className="card-text mb-4">Should you ever lose your document, You can reach us for a soft copy</div>

                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>

           <RegistrationMenuOption></RegistrationMenuOption>
            
            <div className="faq pb-4">
                <div className="container">
                    <div className="card border-0 pb-4 pt-5">
                        <div className="card-header text-center">
                             Frequently Asked Questions
                        </div>
                        <div className="card-body">
                            <div className="accordion" id="accordionExample">
                                <div className="accordion-item mb-3 border-0 shadow-0">
                                    <h2 className="accordion-header border-0 shadow-0" id="headingOne">
                                    <button className="accordion-button border-0 shadow-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            How do i get my order?
                                    </button>
                                    </h2>
                                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        Register brand new vehicle or Imported fairly used vehicle
                                    </div>
                                    </div>
                                </div>
                                <div className="accordion-item mb-3 border-0 shadow-0">
                                    <h2 className="accordion-header" id="headingTwo border-0 shadow-0">
                                    <button className="accordion-button collapsed border-0 shadow-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                       How do i get my order?
                                    </button>
                                    </h2>
                                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                                    </div>
                                    </div>
                                </div>
                                <div className="accordion-item mb-3 border-0 shadow-0">
                                    <h2 className="accordion-header border-0 shadow-0" id="headingThree">
                                        <button className="accordion-button collapsed border-0 shadow-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        How do i get my order?
                                        </button>
                                    </h2>
                                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <JoinKitaniz />
        </div>
    )
}

export default RegisterVehicle
