import React, { FC, useRef, useState } from 'react'
import "./CustomerReview.scss"  
import { Swiper, SwiperSlide } from 'swiper/react';
 
import 'swiper/css';
import 'swiper/css/pagination';  
import { Navigation } from 'swiper/modules';
import SwiperCore from 'swiper'; 
import ReviewItem from './ReviewItem';

SwiperCore.use([Navigation]);
 

const CustomerReview = () => {
    
  const swiperRef = useRef<any>(null);

  const handlePrev = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNext = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const [activeIndex, setActiveIndex] = useState(0);

  const handleSlideChange = (index: any) => {
    setActiveIndex(index);
  };



    return (
        <div className='CustomerReview'>
            <div className="container">
                <div className="row">
                    <div className="col-md-4 my-auto">
                        <h3>Our Customer Reviews</h3>
                       <div className="row mt-3 mt-sm-5 mb-4">
                        <div className="col-8 col-sm-7 col-xl-5 p-sm-0">
                            <CustomPagination
                                totalSlides={3}
                                activeIndex={activeIndex}
                                onSlideChange={handleSlideChange}
                            />
                        </div>
                        <div className="col-4 col-sm-5 col-xl-7 text-end text-sm-start">
                            <button className="btn btn-white nav-btn" onClick={handlePrev}><i className='d-icon d-arrowhead-left'></i></button>
                            <button className="btn btn-white nav-btn" onClick={handleNext}><i className='d-icon d-arrowhead-right'></i></button>
                        </div>
                       </div>
                       
                    </div>

                    <div className="col-md-8">
                        <Swiper
                            ref={swiperRef}
                            slidesPerView={'auto'}
                            spaceBetween={30}   
                            onSlideChange={(swiper:{activeIndex: number}) => {
                                setActiveIndex(swiper.activeIndex); 
                              }}
                            pagination={{ clickable: false }}
                            className="mySwiper"
                        >
                            <SwiperSlide>
                                <ReviewItem />
                            </SwiperSlide>

                            <SwiperSlide> 
                                <ReviewItem />
                            </SwiperSlide>

                            <SwiperSlide> 
                               <ReviewItem />
                            </SwiperSlide>

                            <SwiperSlide> 
                                <ReviewItem />
                            </SwiperSlide>
                            <SwiperSlide> 
                                <ReviewItem />
                            </SwiperSlide> 
                        </Swiper>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CustomerReview

interface propsType { 
    totalSlides:number, 
    activeIndex: number, 
    onSlideChange : Function
}
export const CustomPagination:FC<propsType> = ({ totalSlides, activeIndex, onSlideChange }) => {
    const renderPaginationItems = () => {
      const items = [];
      for (let i = 0; i < totalSlides; i++) {
        items.push(
          <button
            key={i} 
            className={i === activeIndex ? 'btn paginating active me-1' : 'btn paginating me-1'}
          > 
          </button>
        );
      }
      return items;
    };
  
    return <div className="custom-pagination">{renderPaginationItems()}</div>;
  };
  