import React from 'react' 
import "./AuthenticatedSidebarContent.scss";  
import { useDispatch } from 'react-redux';
import { ACTIVEMENU } from 'Constants/MobileMenuConstants'; 
import { BrowserRouter as Router, Route, NavLink, Link } from 'react-router-dom'; 
import Logo from 'Assets/Images/RegXpress.png' 
// import {ReactComponent as Logo} from 'Assets/Icons/logo.svg' 
import dashboard from 'Assets/Images/dashboard.svg' 
import buyvehicle from 'Assets/Images/buyvehicle.svg' 
import carregistration from 'Assets/Images/car-registration.svg' 
import trackers from 'Assets/Images/trackers.svg' 
import orders from 'Assets/Images/trackers.svg' 
import quoterequest from 'Assets/Images/quote-request.svg' 
import transaction from 'Assets/Images/transaction.svg' 
import settings from 'Assets/Images/settings.svg' 
import help from 'Assets/Images/help.svg' 

const AuthenticatedSidebarContent = () => {
 
  const dispatch = useDispatch() 
  
  function setLink (name:string) {
    dispatch({type: ACTIVEMENU, payload: name})
  }
  
  const preventReload = (pageTitles: string, e: any) => {
    e.preventDefault()
    console.log(pageTitles)
    localStorage.setItem('pageTitle', JSON.stringify(pageTitles));
  }
  
  return (
      <React.Fragment>
         <div className="emsc">
          <ul className="nav flex-column mb-4">
            <li className="nav-item my-1 mt-3 mb-4 ps-4 d-none d-lg-block">
              <Link to="/">
                <img className='Logo' src={Logo} alt="Logo" />
                {/* <Logo></Logo>  */}
              </Link>
            </li>
            <li className="nav-item my-1 bg-white">
              <NavLink className={({ isActive }) => isActive ? "nav-link active h-100" : "nav-link h-100"}
                to="/myaccount/dashboard"
                onClick={(e)=>{setLink('Dashboard'); e.preventDefault();}}> 
                <img src={dashboard} alt="" className='icons'/>
                <span className='my-auto'>Dashboard</span>
                <i className="d-icon d-arrowhead-right me-3 float-end my-auto"></i>
              </NavLink>
            </li>
       
           
            {/* <li className="nav-item my-1">
              <NavLink className={({ isActive }) => isActive ? "nav-link active h-100" : "nav-link h-100"}
                to='/#'
                onClick={()=>setLink(' Buy Vehicle')}> 
                <img src={buyvehicle} alt="" className='icons'/>
                Buy Vehicle
                <i className="d-icon d-arrowhead-right me-3 float-end my-auto"></i>
              </NavLink>
            </li>  */}
            
            <li className="nav-item my-1">
              <NavLink className={({ isActive }) => isActive ? "nav-link active h-100" : "nav-link h-100"}
                to='/myaccount/registration'
                onClick={(e)=>{setLink('Registration');  e.preventDefault();}}> 
                <img src={carregistration} alt="" className='icons'/>
                Registration 
                <i className="d-icon d-arrowhead-right me-3 float-end my-auto"></i>
              </NavLink>
            </li>

            {/* <li className="nav-item my-1">
              <NavLink className={({ isActive }) => isActive ? "nav-link active h-100" : "nav-link h-100"}
                to='/myaccount/insurance'
                onClick={()=>setLink('Insurance')}> 
                <img src={dashboard} alt="" className='icons'/>
                Insurance
                <i className="d-icon d-arrowhead-right me-3 float-end my-auto"></i>
              </NavLink>
            </li> */}

            {/* <li className="nav-item my-1">
              <NavLink className={({ isActive }) => isActive ? "nav-link active h-100" : "nav-link h-100"}
                to='/estate-managers/subscriber-portfolio'
                onClick={()=>setLink('My Portfolio')}> 
                <img src={trackers} alt="" className='icons'/>
                Trackers
                <i className="d-icon d-arrowhead-right me-3 float-end my-auto"></i>
              </NavLink>
            </li> */}

            <li className="nav-item my-1">
              <NavLink className={({ isActive }) => isActive ? "nav-link active h-100" : "nav-link h-100"}
                to='/myaccount/orders/registration'
                onClick={(e)=>{setLink('Orders');  e.preventDefault();}}> 
                <img src={orders} alt="" className='icons'/>
                Orders  
                <i className="d-icon d-arrowhead-right me-3 float-end my-auto"></i>
              </NavLink>
            </li>
            
 
            {/* <li className="nav-item my-1">
              <NavLink className={({ isActive }) => isActive ? "nav-link active h-100" : "nav-link h-100"}
                to='/myaccount/quote-request'
                onClick={()=>setLink('Quote Request')}> 
                 <img src={quoterequest} alt="" className='icons'/>
                 Quote Request  
                <i className="d-icon d-arrowhead-right me-3 float-end my-auto"></i>
              </NavLink>
            </li> */}

            <li className="nav-item my-1">
              <NavLink className={({ isActive }) => isActive ? "nav-link active h-100" : "nav-link h-100"}
                to='/'  onClick={(e)=>{setLink('Transaction');  e.preventDefault();}} > 
                <img src={transaction} alt="" className='icons'/>
                Transaction  
                <i className="d-icon d-arrowhead-right me-3 float-end my-auto"></i>
              </NavLink>
            </li>

            <li className="nav-item my-1">
              <NavLink className={({ isActive }) => isActive ? "nav-link active h-100" : "nav-link h-100"}
                to='/myaccount/settings/profile'  onClick={(e)=>{setLink('Settings');  e.preventDefault();}}> 
                 <img src={settings} alt="" className='icons'/>
                 Settings
                <i className="d-icon d-arrowhead-right me-3 float-end my-auto"></i>
              </NavLink>
            </li> 

            <ul className="nav flex-column mt-5 message-container"> 
              <li className="nav-item my-1">
                <Link to='/contact-us' className='text-decoration-none text-reset'>
                <div className='message text-center w-100'>
                  <div className="icon"> 
                    <div className="icon-wrapper">
                      <img src={help} alt="" className='icons'/> 
                    </div>
                  </div>
                  <div className="heading">Support Center</div>
                  <div className='desc'>Get in touch with our support team. </div> 
                </div>
                </Link>
              </li>
            </ul> 
          </ul> 
 
        </div> 
     </React.Fragment>

  )
}

export default AuthenticatedSidebarContent
