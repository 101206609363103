import React from 'react'
import "./BuyVehicle.scss"

const BuyVehicle = () => {
    return (
        <div className='BuyVehicle'>
             <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-5 my-5">
                       <div className="card border-0">
                        <div className="card-header text-center border-0 pt-4"> 
                            <div className="title">
                                Buy Tokunbo Vehicle
                            </div>
                            <div className="tagline">
                                What type do you want to buy?
                            </div>
                        </div>
                        <div className="card-body">
                            <form action="">
                                <div className="form-group">
                                    <label htmlFor="">Make</label>
                                    <select name="" id="" className='form-control'>
                                        <option value="">select make</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Model</label>
                                    <select name="" id="" className='form-control'>
                                        <option value="">select model</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Year</label>
                                    <select name="" id="" className='form-control'>
                                        <option value="">select year</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Milage</label>
                                    <select name="" id="" className='form-control'>
                                        <option value="">select milage range</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Color</label>
                                    <select name="" id="" className='form-control'>
                                        <option value="">choose color</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Price up</label>
                                    <select name="" id="" className='form-control'>
                                        <option value="">select price range</option>
                                    </select>
                                </div>

                                <div className="form-group">
                                    <input type="checkbox" id="myCheckbox"></input>
                                    <label htmlFor="myCheckbox" className="checkbox-label">Unaccidented</label>
                                </div> 
                                
                                <div className="form-group row">
                                    <div className="col-6 my-auto">
                                        <label htmlFor="" className='notsure'>
                                          Not sure of your choice?
                                        </label>
                                    </div>
                                    <div className="col-6 my-auto">
                                        <button className='btn btn-primary'>Confirm vehicle</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                       </div>
                    </div>
                </div>
             </div>
        </div>
    )
}

export default BuyVehicle
