import './RegistrationVerification.scss'
import { Link, useNavigate } from 'react-router-dom'; 
import { useForm } from 'react-hook-form'; 
import { useState } from 'react';
import Swal from 'sweetalert2';   
import { authService } from 'Services/authService';
import OtpInput from 'react-otp-input';

type formType = {  
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    phoneNumber: string;
}

const RegistrationVerification = () => {
  
    const navigate = useNavigate()

    const [otp, setOtp] = useState<string>()

    const handleChangeOtp = (otp:string) => {
        setOtp(otp);
    }

    const [request, setRequest] = useState<boolean>(false)

     
    const { handleSubmit } = useForm<formType>();

    const submit = handleSubmit((data: formType) =>registerUser(data))

    const registerUser = async (data: formType) => {
        setRequest(true)

        await authService.verify(otp)  
        .then(async (userCred: any) => {
            console.log(userCred)
    
            Swal.fire('Account Verification completed!', 'Verification successful.', 'success')
            .then((action)=>{
                if(action.isConfirmed){
                    navigate("/login", { replace : true }) 
                } 
            })
            setRequest(false)  

            setTimeout(()=>{
                navigate("/login", { replace : true }) 
            }, 2000)
    
        }, (error) => {
            setRequest(false) 

            Swal.fire(
                'Oops!',
                Array.isArray(error.response?.data?.message) 
                    ? error.response.data.message[0] 
                    : error.response?.data?.error?.message || error.message,
                'warning'
            );
 
            console.log(error)
        })
    }

  

    return ( 
        <div className="Registration py-5">
            <div className="container h-100">
                <div className="row h-100 justify-content-center align-items-center">
                    <div className="col-12 col-sm-8 col-md-8 col-lg-6 col-xl-5 my-auto">
                        <div className="card border-0 form-container p-4 pb-0 w-100">
                        
                            <div className="form-header text-center">
                                <div className="form-header-title h2">Verify Account</div>
                                <div className="form-notify-header text-secondary small">Enter the 6 digits verification code sent to your phone number and email.</div>
                            </div>
                           <div className="card-body">
                                <form className="form h-100 needs-validation mt-2 mb-4" onSubmit={submit}> 
                                    <div className="form-group mt-1 mb-3 container-token text-center d-flex justify-content-center">
                                        <OtpInput
                                            value={otp}
                                            onChange={handleChangeOtp}
                                            numInputs={6}
                                            renderInput={(props) => <input {...props} />}
                                            renderSeparator={<span>   </span>}
                                            inputStyle={{
                                                width: '3.0rem',
                                                height: '2.9rem',
                                                minHeight: '2.9rem',
                                                margin: '0 .5rem',
                                                fontSize: '1rem',
                                                borderRadius: 4,
                                                backgroundColor: '#ECF1F4',
                                                boxShadow: 'inset 0px 2px 2px -1px rgba(74, 74, 104, 0.1)',
                                                border: '1px solid rgba(0,0,0,0.1)'
                                            }}
                                        />
                                    </div>

                                    <div className="form-input mt-4"> 
                                        <button className="btn btn btn-primary rounded-pill w-100" disabled={!otp}>Continue {request && <span className='spinner-border spinner-border-sm'></span>}</button>  
                                    </div> 
                                </form>
                                <div className="small">Didn’t Recieve a Code? <Link to="">Recend Code</Link></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default RegistrationVerification;
 
