import './AboutWhyChooseUs.scss' 
import about001 from "Assets/Icons/about-001.svg"


const AboutWhyChooseUs = () => {
    return ( 
        <div className="AboutWhyChooseUs">
            <div className="container mb-5">
                <div className="row">
                    <div className="why-choose-us h2 fw-bold mb-5 text-center">Why Choose Us?</div>

                    <div className="card border-0 p-3 col-sm-6 col-lg-4">
                        <div className="inner-card p-3">
                            <div className="img-container">
                                <div className="heart-icon d-inline-block mb-4 p-2 bg-light rounded">
                                    <span className='h4 text-secondary'><img src={about001} alt=''/></span>
                                </div>
                            </div>
                            <div className="h5 mb-3">Unmatched Selection</div>
                            <div className="card-text">
                            Explore a wide range of vehicles, each handpicked for its quality and performance. From brand new models to certified pre-owned gems, we have the wheels to suit every taste and budget.
                            </div>
                        </div>
                    </div>

                    
                    <div className="card border-0 p-3 col-sm-6 col-lg-4">
                        <div className="inner-card p-3">
                            <div className="img-container">
                                <div className="heart-icon d-inline-block mb-4 p-2 bg-light rounded">
                                    <span className='h4 text-secondary'><img src={about001} alt=''/></span>
                                </div>
                            </div>
                            <div className="h5 mb-3">Customer-Centric Approach</div>
                            <div className="card-text">
                            Your satisfaction is our priority. Our knowledgeable and friendly team is here to guide you through every step, answering questions, and ensuring you feel confident and informed throughout the entire process.
                            </div>
                        </div>
                    </div>

                    
                    <div className="card border-0 p-3 col-sm-6 col-lg-4">
                        <div className="inner-card p-3">
                            <div className="img-container">
                                <div className="heart-icon d-inline-block mb-4 p-2 bg-light rounded">
                                    <span className='h4 text-secondary'><img src={about001} alt=''/></span>
                                </div>
                            </div>
                            <div className="h5 mb-3">Fair and Transparent Pricing</div>
                            <div className="card-text">
                            No hidden costs, no surprises. Our pricing is transparent, reflecting the true value of the vehicle. We believe in fair deals that make both your wallet and your heart happy.
                            </div>
                        </div>
                    </div>

                    
                    <div className="card border-0 p-3 col-sm-6 col-lg-4">
                        <div className="inner-card p-3">
                            <div className="img-container">
                                <div className="heart-icon d-inline-block mb-4 p-2 bg-light rounded">
                                    <span className='h4 text-secondary'><img src={about001} alt=''/></span>
                                </div>
                            </div>
                            <div className="h5 mb-3">Effortless Registration</div>
                            <div className="card-text">
                            Navigating the paperwork maze can be daunting. Our experts handle the registration process with efficiency and precision, ensuring that you hit the road without unnecessary delays
                            </div>
                        </div>
                    </div>

                    
                    <div className="card border-0 p-3 col-sm-6 col-lg-4">
                        <div className="inner-card p-3">
                            <div className="img-container">
                                <div className="heart-icon d-inline-block mb-4 p-2 bg-light rounded">
                                    <span className='h4 text-secondary'><img src={about001} alt=''/></span>
                                </div>
                            </div>
                            <div className="h5 mb-3">Reliability You Can Trust</div>
                            <div className="card-text">
                            Every vehicle in our inventory undergoes rigorous inspections, so you can trust in the reliability of your new ride. We stand by the quality of our cars, providing you with peace of mind for miles to come.
                            </div>
                        </div>
                    </div>

                    
                    <div className="card border-0 p-3 col-sm-6 col-lg-4">
                        <div className="inner-card p-3">
                            <div className="img-container">
                                <div className="heart-icon d-inline-block mb-4 p-2 bg-light rounded">
                                    <span className='h4 text-secondary'><img src={about001} alt=''/></span>
                                </div>
                            </div>
                            <div className="h5 mb-3">Insurance Tailored to You</div>
                            <div className="card-text">
                            Your safety matters. Our insurance services are designed to provide you with the coverage you need, tailored to your unique requirements. Drive with peace of mind, knowing you're protected on every journey.
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
     );
}
 
export default AboutWhyChooseUs;