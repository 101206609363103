import React from 'react'
import "./UploadDocumentsGuest.scss"
import { UploadDocumentElement } from 'Pages/Frontend/Components';

const UploadDocumentsGuest = () => {
    return (
        <div className='UploadDocuments'>  
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-sm-12 col-md-8 col-lg-8 col-xl-6 my-5">
                       <div className="card border-0">
                        <div className="card-header text-start border-0 pt-4"> 
                            <div className="title">
                                Upload Documents
                            </div>
                        </div>
                        <div className="card-body">
                            <UploadDocumentElement></UploadDocumentElement>
                        </div>
                       </div>
                    </div>
                </div>
             </div>
        </div>
    )
}

export default UploadDocumentsGuest
