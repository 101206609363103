import React, { Key, useState } from 'react'
import insurance from "Assets/Icons/insurance-nob.svg" 
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { VEHICLE_REG_STEP_ONE } from 'Constants/RegistrationConstant';
import { useDispatch } from 'react-redux';
import { vehicleServiceType } from 'Models';
import { vehicleInsuranceType } from 'Models/vehicleServiceType';

interface propsType {
    module?: string|undefined, 
    serviceDetails?: vehicleServiceType|undefined
}
const InsurancePlan:FC<propsType> = ({module, serviceDetails}) => {
    const { service_id } = useParams<{ service_id: string }>()
    const [selected, setSelected] = useState<vehicleInsuranceType>()
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onSubmit = () => { 
        dispatch({ type: VEHICLE_REG_STEP_ONE, payload: { insuranceType: selected }});  
        navigate(module && module === 'user'? `/myaccount/insurance/${service_id}/insurance-vehicle-registration` : `/insurance/${service_id}/insurance-vehicle-registration`, { replace: true })
      };

      console.log("vehicleService", serviceDetails)
 

    return (
        <div className='card border-0'>
            <div className="card-body mt-4">
                <div className="text-center"><img src={insurance} alt=''></img></div>
                <div className="title">Buy Insurance</div>
                <div className="desc mb-4">Please select your preferred insurance option</div>
                 {!serviceDetails && <div className='text-center'><span className='spinner-border spinner-border-sm'></span></div>}
               {serviceDetails && serviceDetails?.types.map((insuranceTypes: vehicleInsuranceType, index:Key) => {
                 return <div key={index}className={selected?.id === insuranceTypes.id ? "item-card p-2 border-primary mt-3": "item-card p-2 mt-3"}  onClick={()=>setSelected(insuranceTypes)}> 
                    <div className="row h-100">
                        <div className="col-2 my-auto text-center">
                            <input type="radio" name='plan' value="1" checked={selected?.id === insuranceTypes.id} onChange={()=>setSelected(insuranceTypes)}></input>
                        </div>
                        <div className="col-10 my-auto">
                            <div className="inner-title">
                                {insuranceTypes.name}
                            </div>
                            <div className="inner-desc">
                            {insuranceTypes.description}
                            </div>
                        </div>
                    </div> 
                </div>}
             )}
  
                <button className='btn btn-default w-100 mt-4 mb-3' onClick={onSubmit}>Continue</button> 
            </div>
        </div>
    )
}

export default InsurancePlan
