 import axios from 'axios'

 export const AxiosInterceptor = () => {
    
     axios.defaults.baseURL = process.env.REACT_APP_BASEURL;
     
     axios.defaults.headers.post['Content-Type'] = 'application/json'; 
     
     axios.interceptors.request.use(function (config) {
//       // Do something before request is sent 
      // config.headers['Access-Control-Allow-Origin'] = "*";
      config.headers['Content-Type'] ='application/json'; 
    
      const userObject = localStorage.getItem('__ktz')

      const guestUserObject = sessionStorage.getItem('ktGuestToken')

    //  console.log("userObject 9990", userObject)
    //  console.log("guestUserObject 9990", guestUserObject)

      if (userObject !== null && userObject !== "null" && userObject) {
 
        const token = userObject && JSON.parse(userObject).accessToken
 
        config.headers['Authorization'] = `Bearer ${token}`
        //guest user
      }
      
      // else if( guestUserObject !== null && guestUserObject !== "null" && guestUserObject){ 
        
      //    const token = guestUserObject && JSON.parse(guestUserObject).token

      //    config.headers['Authorization'] = `Bearer ${token}`  
      // }
    
      return config;
    }, function (error:any) {
      // Do something with request error
      return Promise.reject(error);
    });

 

    // const user = window.localStorage.getItem('__ktz')
    // const guestUser = window.localStorage.getItem('ktGuestToken')
    
    // axios.interceptors.response.use(
      // (response) => {
//         // If the response is successful, just return the response
        // return response;
//       },
//       async (error) => { 
//         // If the response has an error
//         console.log(error)
//         if (error.response && (error.response.data.error?.options?.code === "auth/id-token-expired" || error.response.data.error.name === "UnauthorizedException") ) {
//           // Redirect to the login page 
//           if(user){
//             alert("Session expired")
//             window.localStorage.removeItem('__ktz')  
           
//             // redirect to login
//             window.location.href = "/login"
//           } else if(guestUser || guestUser===null){

//             // alert("No Session")

//             await window.sessionStorage.removeItem('ktGuestToken')

//             // window.location.reload()
//           } 
//           console.log("interceptors", error.response.data.error.options.code)
//         } 
//         return Promise.reject(error);
//       }
//     );

//     return axios;
 
}
 
