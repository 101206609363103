import React, { FC } from 'react' 
import { useNavigate } from 'react-router-dom'
import { CurrencyFormatter } from '../../../../Helpers/CurrencyFormatter';
import './PaymentFailure.scss'

interface propsType {
    module?: string
}

const PaymentFailureCard:FC<propsType> = ({ module }) => { 


    const navigate = useNavigate();    

    return ( 
        <div className="modal-dialog">
            <div className="modal-content">
                <div className='payment-failure-card-container'>
                    <div className="card-body mt-4">
                        <div className="payment-head">
                            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="63" viewBox="0 0 64 63" fill="none">
                                <circle cx="31.9985" cy="31.4438" r="31.4438" fill="#FF0000"/>
                                <path d="M23.5 38.7008L39.4491 23.2207" stroke="white" stroke-width="4.23077" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M39.4492 38.7008L23.5001 23.2207" stroke="white" stroke-width="4.23077" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <div className="payment-title">
                            Payment failed, please try again
                            </div>

                            <div className="payment-total">
                                ₦{CurrencyFormatter(Number('1000'))}
                            </div> 
                        </div>
                        
                        <div className="payment-bottom">
                            <p className="text-14px text-center">
                                <span className="text-6A80A3">If you have any questions or encounter any issues, feel free to contact our support team at support@kitaniz.com.</span>
                            </p>
                            

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PaymentFailureCard
