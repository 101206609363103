import React from 'react'
import "./PaymentSuccessfulAuth.scss" 
import { PaymentSuccessfulCard } from 'Pages/Frontend/Components'

const PaymentSuccessfulAuth = () => {
    return (
        <div className='payment-successful-auth'>
             <div className="container h-100">
                <div className="row justify-content-center h-100">
                    <div className="col-12 col-sm-9 col-md-8 col-lg-8 col-xl-5 my-auto">
                        <PaymentSuccessfulCard module='user'></PaymentSuccessfulCard>
                    </div>
                </div>
             </div>
        </div>
    )
}

export default PaymentSuccessfulAuth
