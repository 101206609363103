import React from 'react'
import "./VehicleParticularsAuth.scss"
import { BreadCrumb } from 'Components'
import VehicleParticularsFlow from '../../../../Components/Registration/VehicleParticularsFlow/VehicleParticularsFlow';
import { useParams } from 'react-router-dom';
 
const VehicleParticularsAuth = () => {

    const { service_id } = useParams()
   
    return (
        <div className='VehicleParticularsAuth'>
              <div className="container">
                <BreadCrumb parentTitle={'Registration'} parentUrl={`/myaccount/registration/new-registration/${service_id}`} pageTitle={'New Registration'} childTitle="Vehicle Information"></BreadCrumb>
                <div className="row justify-content-center">
                    <div className="col-sm-12 col-md-7 col-lg-6 col-xl-5 mb-5 mt-4">
                    <VehicleParticularsFlow module="user"></VehicleParticularsFlow>
                </div>
            </div>
        </div>
    </div>
    )
}

export default VehicleParticularsAuth
