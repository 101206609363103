import React from 'react'
import "./Insurance.scss"
import insurance from "Assets/Images/insurance.png"
import insure_plan from "Assets/Images/insure_plan.png"
import { Link } from 'react-router-dom'
import { JoinKitaniz } from '../../Components'

const Insurance = () => {
    return (
        <div className='Insurance'>
            <div className="insurance-head">
              <div className="container py-5">
                <div className="row h-100">
                    <div className="col-md-6 my-auto">
                        <div className="register-left">
                            <div className="regexpress">
                               INSURANCE
                            </div>
                            <h5>Secure Insurance Plans,<br/> drive with confidence!</h5>
                            <img src={insurance} className="left-img d-md-none mt-4" alt=''></img>
                            <div className="tagline mb-4 mt-4">Drive with peace of mind, with your vehicle insured against damage, accidents, theft or more knowing you're protected every mile. Your safety, our priority.</div>
                           
                            <Link to='' className='mt-4'>
                                <button className="btn btn-primary btn-default">Buy Insurance</button>
                            </Link>
                        </div> 
                    </div>
                    <div className="col-md-6 text-md-end">
                         <img src={insurance} className="left-img d-none d-md-block" alt=''></img>
                    </div>
                </div>
             </div>
            </div>


            <div className="insurancePlan"> 
                <div className="container">
                    <div className="card">
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>
                                                <img src={insure_plan} alt=''></img>
                                            </th>
                                            <th>Comprehensive Insurance</th>
                                            <th>Third Party Insurance</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr>
                                            <td>For Whom?</td>
                                            <td>Anyone</td>
                                            <td>Anyone</td>
                                        </tr>
                                        <tr>
                                            <td>Cost</td>
                                            <td>5% of car value</td>
                                            <td>₦15,000/year</td>
                                        </tr>
                                        <tr>
                                            <td>Coverage for your car </td>
                                            <td>Covered</td>
                                            <td>No Coverage</td>
                                        </tr>
                                        <tr>
                                            <td>Towing services</td>
                                            <td>Up to N30,000 / N20,000</td>
                                            <td>Not Covered</td>
                                        </tr>
                                        <tr>
                                            <td>Pays medical bills</td>
                                            <td>Unlimited Cost</td>
                                            <td>Unlimited Cost</td>
                                        </tr>
                                        <tr>
                                            <td>Coverage for the other party</td>
                                            <td>Covered, up to N3 million limit</td>
                                            <td>Covered, up to N3 million limit</td>
                                        </tr>
                                        <tr>
                                            <td>Financial support paid to beneficiary in the case of death.</td>
                                            <td>Unlimited Cost</td>
                                            <td>Unlimited Cost</td>
                                        </tr>
                                        <tr>
                                            <td>Document Requirements</td>
                                            <td>
                                                <ul>
                                                    <li>Proof of Ownership.</li>
                                                    <li>Copy of the Vehicle License/Proof of Ownership.</li>
                                                    <li>Photographs of subject matter.</li>
                                                    <li>Valid means of ID - Driver License, Voter's card, NIMC, National ID, International Passport.</li>
                                                    <li>Proof of Residence - Utility Bill, Front page of the customer's address (same address as indicated on the form or portal), Local Government Levy.</li>
                                                </ul>
                                            </td>
                                            <td>
                                                <ul>
                                                    <li>Valid means of ID - Driver License, Voter's card, NIMC, National ID, International Passport.</li>
                                                    <li>Proof of Residence - Utility Bill, Front page of the customer's address (same address as indicated on the form or portal), Local Government Levy. </li>
                                                </ul>
                                            </td>
                                        </tr> 


                                        <tr>
                                            <td></td>
                                            <td>
                                                <button className='btn'>Buy</button>
                                            </td>
                                            <td> 
                                              <button className='btn'>Buy</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div className="faq pb-4">
                <div className="container">
                    <div className="card border-0 pb-4 pt-5">
                        <div className="card-header text-center">
                             Frequently Asked Questions
                        </div>
                        <div className="card-body">
                            <div className="accordion" id="accordionExample">
                                <div className="accordion-item mb-3 border-0 shadow-0">
                                    <h2 className="accordion-header border-0 shadow-0" id="headingOne">
                                    <button className="accordion-button border-0 shadow-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            How do i get my order?
                                    </button>
                                    </h2>
                                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <strong>This is the first item's accordion body.</strong> It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                                    </div>
                                    </div>
                                </div>
                                <div className="accordion-item mb-3 border-0 shadow-0">
                                    <h2 className="accordion-header" id="headingTwo border-0 shadow-0">
                                    <button className="accordion-button collapsed border-0 shadow-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                       How do i get my order?
                                    </button>
                                    </h2>
                                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                                    </div>
                                    </div>
                                </div>
                                <div className="accordion-item mb-3 border-0 shadow-0">
                                    <h2 className="accordion-header border-0 shadow-0" id="headingThree">
                                        <button className="accordion-button collapsed border-0 shadow-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        How do i get my order?
                                        </button>
                                    </h2>
                                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <JoinKitaniz />
        </div>
    )
}

export default Insurance
