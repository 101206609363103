import React from 'react' 
import { Navigate } from 'react-router-dom'
 
import { useAuth } from '../AuthProvider/AuthProvider'
import AuthenticatedWrapper from 'Pages/Skeleton/AuthenticatedWrapper/AuthenticatedWrapper'

const ProtectedUser = () => {

    const { user } = useAuth()  

    console.log("user", user)

    if (!user) {  
      return <Navigate to="/login" />
    }  
  
    return <AuthenticatedWrapper></AuthenticatedWrapper>
     
  
}

export default ProtectedUser;
