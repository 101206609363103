import React, { useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import Logo from 'Assets/Images/RegXpress-light-logo.png';
import Logo2 from 'Assets/Images/RegXpress.png';
import "./RegExpressNavbar.scss"
// import menu from 'Assets/Icons/menu.png' 
import { useAuth } from 'Components/AuthProvider/AuthProvider' 
import dashboard from 'Assets/Images/dashboard.svg' 
import { getAuth, signOut } from 'firebase/auth'
import Swal from 'sweetalert2'
import useWindowScrollPosition from '@rooks/use-window-scroll-position'


const RegExpressNavbar = () => {
    
    const { user, logout } = useAuth()

    const [showOffcanvas, setShowOffcanvas] = useState(false);

    const handleLinkClick = () => {
        setShowOffcanvas(false);  
    };
 
    const auth = getAuth();
    const {scrollY} = useWindowScrollPosition();
  
   
    const logoutFunc = () => {
      Swal.fire({
        title: "Are you sure?",
        text: "You will be logged out of your account!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#186AFF",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, logout!"
      })
        .then((result) => {
            if (result.isConfirmed) {
    
                signOut(auth).then(() => { 
                    logout()
                }).catch((error) => {
                    console.log("error", error)
                    alert("can not logout")
                });

            }
      });
    }

    return (
        <React.Fragment>
            <nav className={`navbar navbar2 navbar-expand-lg ${scrollY > 70 ? 'droped border-bottom' : ''}`}>
                <div className={`container`}>
                        <Link className="navbar-brand" to="/">
                            {scrollY < 70 && <img className='Logo' src={Logo} alt="Logo" />}
                            {scrollY > 70 && <img className='Logo' src={Logo2} alt="Logo" />}
                        </Link>
                        <button onClick={() => setShowOffcanvas(!showOffcanvas)} className="navbar-toggler border-0" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions" aria-controls="offcanvasWithBothOptions" aria-expanded="false" aria-label="Toggle navigation">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3 6H21" stroke={scrollY < 70? "white":"black"} strokeWidth="1.5" strokeLinecap="round"/>
                                <path d="M3 12H21" stroke={scrollY < 70? "white":"black"} strokeWidth="1.5" strokeLinecap="round"/>
                                <path d="M3 18H21" stroke={scrollY < 70? "white":"black"} strokeWidth="1.5" strokeLinecap="round"/>
                            </svg> 
                        </button> 
                        <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                
                            </ul>
                            <div className="d-flex" role="search">
                                <ul className="navbar-nav me-auto mb-2 mb-lg-0 d-flex align-items-center">
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/about">
                                            About us
                                        </Link>
                                    </li>
                                    
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/register-vehicle">
                                            Services
                                        </Link>
                                    </li>  
                                    
                                    
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/pricing">
                                            Pricing
                                        </Link>
                                    </li>
                                    
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/tracking">
                                            Track order
                                        </Link>
                                    </li>
                                      
                                    {/* <li className="nav-item d-flex"> 
                                        
                                        <Link className="nav-link" to="/login">
                                            <button className="btn login-button" type='button'>Login</button>
                                        </Link>
                                        
                                        <Link className="nav-link" to="/register">
                                            <button className="btn create-acc-button" type='button'>Create account</button>
                                        </Link> 
 
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                    </div> 
            </nav> 

            <div
                className={`offcanvas navbar-offcanvas offcanvas-start${showOffcanvas ? ' show' : ''}`} 
                data-bs-scroll="true" 
                tabIndex={-1} 
                id="offcanvasWithBothOptions" 
                aria-labelledby="offcanvasWithBothOptionsLabel">
                    
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvasWithBothOptionsLabel">
                        {/* <Logos></Logos> */}
                        <img className='Logo' src={Logo} alt="Logo" />
                        </h5>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body">
                    <div className="emsc-unauth">
                        <ul className="nav flex-column mb-4">
                            
                            <li className="nav-item my-1 bg-white ps-0">
                                <NavLink className={({ isActive }) => isActive ? "nav-link active h-100 ps-2" : "nav-link h-100 ps-2"}
                                    to="/"
                                    onClick={handleLinkClick}> 
                                    <img src={dashboard} alt="" className='icons'/>
                                    <span className='my-auto'>Home</span>
                                    <i className="d-icon d-arrowhead-right me-3 float-end my-auto"></i>
                                </NavLink>
                            </li>
                            <li className="nav-item my-1 bg-white ps-0">
                                <NavLink className={({ isActive }) => isActive ? "nav-link active h-100 ps-2" : "nav-link h-100 ps-2"}
                                    to="/register-vehicle"
                                    onClick={handleLinkClick}> 
                                    <img src={dashboard} alt="" className='icons'/>
                                    <span className='my-auto'>Register</span>
                                    <i className="d-icon d-arrowhead-right me-3 float-end my-auto"></i>
                                </NavLink>
                            </li>
                            <li className="nav-item my-1 bg-white ps-0">
                                <NavLink className={({ isActive }) => isActive ? "nav-link active h-100 ps-2" : "nav-link h-100 ps-2"}
                                    to="/buy-vehicle"
                                    onClick={handleLinkClick}> 
                                    <img src={dashboard} alt="" className='icons'/>
                                    <span className='my-auto'>Buy Vehicle</span>
                                    <i className="d-icon d-arrowhead-right me-3 float-end my-auto"></i>
                                </NavLink>
                            </li>
                            <li className="nav-item my-1 bg-white ps-0">
                                <NavLink className={({ isActive }) => isActive ? "nav-link active h-100 ps-2" : "nav-link h-100 ps-2"}
                                    to="/insurance"
                                    onClick={handleLinkClick}> 
                                    <img src={dashboard} alt="" className='icons'/>
                                    <span className='my-auto'>Insurance</span>
                                    <i className="d-icon d-arrowhead-right me-3 float-end my-auto"></i>
                                </NavLink>
                            </li>
                            <li className="nav-item my-1 bg-white ps-0">
                                <NavLink className={({ isActive }) => isActive ? "nav-link active h-100 ps-2" : "nav-link h-100 ps-2"}
                                    to="/tracking-vehicle"
                                    onClick={handleLinkClick}> 
                                    <img src={dashboard} alt="" className='icons'/>
                                    <span className='my-auto'>Tracking</span>
                                    <i className="d-icon d-arrowhead-right me-3 float-end my-auto"></i>
                                </NavLink>
                            </li>
                            <li className="nav-item my-1 bg-white ps-0">
                                <NavLink className={({ isActive }) => isActive ? "nav-link active h-100 ps-2" : "nav-link h-100 ps-2"}
                                    to="/"
                                    onClick={handleLinkClick}> 
                                    <img src={dashboard} alt="" className='icons'/>
                                    <span className='my-auto'>How It works</span>
                                    <i className="d-icon d-arrowhead-right me-3 float-end my-auto"></i>
                                </NavLink>
                            </li>
                            <li className="nav-item my-1 bg-white ps-0">
                                <NavLink className={({ isActive }) => isActive ? "nav-link active h-100 ps-2" : "nav-link h-100 ps-2"}
                                    to="/blog"
                                    onClick={handleLinkClick}> 
                                    <img src={dashboard} alt="" className='icons'/>
                                    <span className='my-auto'>Blog</span>
                                    <i className="d-icon d-arrowhead-right me-3 float-end my-auto"></i>
                                </NavLink>
                            </li>

                            <li className="nav-item my-1 bg-white ps-0">
                                <NavLink className={({ isActive }) => isActive ? "nav-link active h-100 ps-2" : "nav-link h-100 ps-2"}
                                    to="/frequently-asked-questions"
                                    onClick={handleLinkClick}> 
                                    <img src={dashboard} alt="" className='icons'/>
                                    <span className='my-auto'>Frequently Asked Questions</span>
                                    <i className="d-icon d-arrowhead-right me-3 float-end my-auto"></i>
                                </NavLink>
                            </li>
                            <li className="nav-item my-1 bg-white ps-0">
                                <NavLink className={({ isActive }) => isActive ? "nav-link active h-100 ps-2" : "nav-link h-100 ps-2"}
                                    to="/contact-us"
                                    onClick={handleLinkClick}> 
                                    <img src={dashboard} alt="" className='icons'/>
                                    <span className='my-auto'>Contact us</span>
                                    <i className="d-icon d-arrowhead-right me-3 float-end my-auto"></i>
                                </NavLink>
                            </li>
                            {!user && <li className="nav-item my-1 bg-white ps-0">
                                <NavLink className={({ isActive }) => isActive ? "nav-link active h-100 ps-2" : "nav-link h-100 ps-2"}
                                    to="/login"
                                    onClick={handleLinkClick}> 
                                    <img src={dashboard} alt="" className='icons'/>
                                    <span className='my-auto'>Login</span>
                                    <i className="d-icon d-arrowhead-right me-3 float-end my-auto"></i>
                                </NavLink>
                            </li>}
                            {!user && <li className="nav-item my-1 bg-white ps-0">
                                <NavLink className={({ isActive }) => isActive ? "nav-link active h-100 ps-2" : "nav-link h-100 ps-2"}
                                    to="/register"
                                    onClick={handleLinkClick}> 
                                    <img src={dashboard} alt="" className='icons'/>
                                    <span className='my-auto'>Register</span>
                                    <i className="d-icon d-arrowhead-right me-3 float-end my-auto"></i>
                                </NavLink>
                            </li>}
                            {user && <li className="nav-item my-1 bg-white ps-0">
                                <NavLink className={({ isActive }) => isActive ? "nav-link active h-100 ps-2" : "nav-link h-100 ps-2"}
                                    to="/myaccount/dashboard"
                                    onClick={handleLinkClick}> 
                                    <img src={dashboard} alt="" className='icons'/>
                                    <span className='my-auto'>My Account</span>
                                    <i className="d-icon d-arrowhead-right me-3 float-end my-auto"></i>
                                </NavLink>
                            </li>}
                            
                        </ul>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
export default RegExpressNavbar;