import React from 'react'
import enterpriseLoader from "Assets/Images/ckt-loader.gif"


const HorizontalLoader = () => {
    return (
        <div className='w-100 text-center horizontaLoader'>
            <img src={enterpriseLoader} style={{width: "100px"}} alt=''></img>
        </div>
    )
}

export default HorizontalLoader
