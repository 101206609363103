 import "./RegistrationOrder.scss"
const RegistrationOrderSkeleton = () => {
  return (
    <div className="register-order-skeleton row">
      {[...Array(6)].map((_, index) => (
        <div key={index} className="col-6 col-md-6 col-xl-4">
          <div className="card px-2 py-2 skeleton-loader mt-2">
            <div className="card-body">
              <div className="row">
                <div className="col-2 text-center">
                  <span className='icon'>
                    <div className="skeleton-image"></div>
                  </span>
                </div>
                <div className="col-8 ps-0">
                  <div className="title">
                    <div className="skeleton-text skeleton-text-long"></div>
                  </div>
                  <div className="details">
                    <div className="skeleton-text skeleton-text-short"></div>
                  </div>
                </div>
                <div className="col-2">
                  <div className="skeleton-button"></div>
                </div>
              </div>
            </div>
            <div className="card-footer bg-white d-flex justify-content-center">
              <div className="skeleton-text skeleton-text-short"></div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default RegistrationOrderSkeleton;
