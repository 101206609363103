import React, { Key, useEffect, useRef, useState } from 'react'
import './Address.scss'
import { AddDeliveryAddress, EditDeliveryAddress } from 'Pages/Frontend/Components';
import { userService } from 'Services'; 
import Swal from 'sweetalert2';
import { HorizontalLoader } from 'Components';
import { deliveryListType } from 'Models/deliveryType';
import AddressSkeleton from './AddressSkeleton';

const Address = () => {
    const limit = 100;
    const offset = 0;

    const [request, setRequest] = useState(false)
    const [deleteRequest, setDeleteRequest] = useState(false)
    const [address, setAddress] = useState<Array<deliveryListType>>()
    const [deliveryItem, setDeliveryItem] = useState<deliveryListType>()
    
    const closeButtonRef = useRef<any>(null);
    const closeEditButtonRef = useRef<any>(null);
    
    const getAddress = async () => {
        setRequest(true)
      await userService.getDeliveryAddress(limit, offset).then((response)=>{ 
            setRequest(false)

            setAddress(response.data)

            Swal.fire({
                position: "top-end",
                icon: "success",
                title: "",
                showConfirmButton: false,
                timer: 1600
              });

            console.log("getDeliveryAddress", response)
        }, error =>{ 
            Swal.fire('Oops!', error.response.data.message, 'warning'); 
            setRequest(false)
        })
    }

    useEffect(() => {
        getAddress()
    }, [])

    const handleCloseCreate = () => {
        getAddress()
        if (closeButtonRef.current) {
          closeButtonRef.current.click();
        }
    };

    const handleCloseEdit = () => {
        getAddress()
        if (closeEditButtonRef.current) {
            closeEditButtonRef.current.click();
        }
    };
    
    const makeDefault = async (delivery_id: string) => {
        await userService.makeDefaultDeliveryAddress(delivery_id).then((response)=>{ 
            getAddress()
            setRequest(false)
            setAddress(response.data)

            Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Default set succesfully",
                showConfirmButton: false,
                timer: 1600
                });

            console.log("getDeliveryAddress", response)
        }, (error: { response: { data: { message: string | undefined; }; }; }) =>{ 
            console.log("getDeliveryAddress error", error)
            Swal.fire('Oops!', error.response.data.message, 'warning'); 
            setRequest(false)
        })
    }

    const deleteDeliveryItem = (delivery_id:string) => {

        Swal.fire({
            title: "Hey!",
            text: "Are you sure you want to delete this delivery address?",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#186AFF",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete!"
          })
          .then(async (response)=>{

            if(response.isConfirmed){
                setDeleteRequest(true)
               
               await userService.deleteDeliveryAddress(delivery_id).then(()=>{

                Swal.fire({
                    position: "top-end",
                    icon: "success", 
                    showConfirmButton: false,
                    timer: 1600
                });

                getAddress()

               }, error => {
                    console.log("getDeliveryAddress error", error)
                    Swal.fire('Oops!', error.response.data.message, 'warning'); 
                    setRequest(false)
               })

            }

          })
    }
 
    return ( 
        <div className="Address">

            <div className="row address-header d-flex justify-content-between align-items-center my-3">
                <div className="col-12 col-sm-6 address-pg-title">Delivery Addresses</div>
                <div className="col-12 col-sm-6 address-header-btn-container text-start text-sm-end">
                    <button className="btn rounded-pill" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">Add New Address</button>
                </div> 
            </div>

            {request === true &&<div className="bg-white pb-5"> <HorizontalLoader></HorizontalLoader> </div>}
            {request === true && <AddressSkeleton></AddressSkeleton>}

            {!request && address?.length === 0 && <div className="text-secondary pt-5  pb-5 fs-6 text-center">No address found</div>}

            {!request && <div className="row"> 
                {address && address.map((add:deliveryListType, index:Key)=><div key={index} className="card col-md-6 border-0 mb-4">
                    <div className="inner-card-container border px-3">
                        <div className="card-header d-flex justify-content-between p-0 pt-3 pb-2 align-items-center">
                            <div className="card-title-text">{add.state}</div>
                            <div className="card-header-action-container d-flex align-items-center">
                                {add.primary === true && <span className="default me-3 rounded-pill">Default Address</span>}
                                <div className="dropdown-container">
                                    <button className='btn border-0 dropdown-toggle' data-bs-toggle="dropdown" aria-expanded="false" data-bs-popper-config='{"strategy":"fixed"}'>
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.49991 8.4705C7.49991 7.72933 8.0955 7.14697 8.82344 7.14697C9.55138 7.14697 10.147 7.74256 10.147 8.4705C10.147 9.19844 9.55138 9.79403 8.82344 9.79403C8.0955 9.79403 7.49991 9.21168 7.49991 8.4705Z" fill="#707070"/>
                                            <path d="M7.49991 14.1175C7.49991 13.3763 8.0955 12.7939 8.82344 12.7939C9.55138 12.7939 10.147 13.3895 10.147 14.1175C10.147 14.8454 9.55138 15.441 8.82344 15.441C8.0955 15.441 7.49991 14.8587 7.49991 14.1175Z" fill="#707070"/>
                                            <path d="M7.49991 2.82353C7.49991 2.08235 8.0955 1.5 8.82344 1.5C9.55138 1.5 10.147 2.09559 10.147 2.82353C10.147 3.55147 9.55138 4.14706 8.82344 4.14706C8.0955 4.14706 7.49991 3.56471 7.49991 2.82353Z" fill="#707070"/>
                                        </svg>
                                    </button>

                                    <ul className="dropdown-menu dropdown-menu-end export-dropdown border-0 shadow">
                                        <li className='dropdown-item' 
                                           onClick={()=>setDeliveryItem(add)} data-bs-toggle="offcanvas" data-bs-target="#offcanvasRightEdit" aria-controls="offcanvasRight">Edit
                                        </li>   
                                        <li className='dropdown-item' onClick={()=>makeDefault(add.id)}> 
                                           Make Default
                                        </li>   
                                        <li className='dropdown-item' onClick={()=>deleteDeliveryItem(add.id)}>
                                           Delete
                                        </li>
                                    </ul> 
                                </div>
                            </div>
                        </div>
                        <div className="card-body p-0 py-3">
                            <div className='my-2'>{add.address}</div>
                            <div className='my-2'>{add.owner.name},  {add.phoneNumber}</div>
                        </div>
                    </div>
                </div>)} 
            </div>}

 
            <div className="offcanvas offcanvas-end new-delivery-address-canvas" tabIndex={-1} id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
                <div className="offcanvas-header">
                    <h5 id="offcanvasRightLabel"></h5>
                    <button type="button" className="btn-close text-reset fs-6 me-3 mt-0" data-bs-dismiss="offcanvas" aria-label="Close" ref={closeButtonRef}></button>
                </div>
                <div className="offcanvas-body">
                    <AddDeliveryAddress closeModal={handleCloseCreate}></AddDeliveryAddress>
                </div>
            </div>
 
            <div className="offcanvas offcanvas-end new-delivery-address-canvas" tabIndex={-1} id="offcanvasRightEdit" aria-labelledby="offcanvasRightEditLabel">
                <div className="offcanvas-header">
                    <h5 id="offcanvasRightEditLabel"></h5>
                    <button type="button" className="btn-close text-reset fs-6 me-3 mt-0" data-bs-dismiss="offcanvas" aria-label="Close" ref={closeEditButtonRef}></button>
                </div>
                <div className="offcanvas-body">
                    <EditDeliveryAddress closeModal={handleCloseEdit} deliveryItem={deliveryItem}></EditDeliveryAddress>
                </div>
            </div>
        </div>
     );
}
 
export default Address;