import { Link } from 'react-router-dom';
import './WhyKitanizRegEx.scss'

const WhyKitanizRegEx = () => {
    
    return (
        <div className="WhyKitanizRegEx">
            <div className="container">
                <h2 className="h2 Why-Kitaniz-RegExpress mb-4">WHY KITANIZ REGXPRESS?</h2>
                <h1 className="h1 mx-auto mb-4">Built to make your registration process seamless and efficient.</h1>
                <div className="row w-100 reasons-container">

                    <div className="col-md-6 col-lg-4 p-0 column" data-aos="zoom-in-down">
                        <div className="card card1 ps-3">
                            <div className="card-body p-1">
                                <h1 className="card-head mb-2">Efficiency</h1>
                                <h1 className="card-texts">Streamlined registration process, minimizing waiting times and paperwork.</h1>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-md-6 col-lg-4 p-0 column" data-aos="zoom-in-down">
                        <div className="card card2 ps-3">
                            <div className="card-body p-1">
                                <h1 className="card-head mb-2">100% Accuracy</h1>
                                <h1 className="card-texts">Ensuring all information entered is correct and properly recorded.</h1>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-md-6 col-lg-4 p-0 column" data-aos="zoom-in-down">
                        <div className="card card3 ps-3">
                            <div className="card-body p-1">
                                <h1 className="card-head mb-2">Transparency</h1>
                                <h1 className="card-texts">Clear communication of fees, requirements, and timelines.</h1>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-md-6 col-lg-4 p-0 column" data-aos="zoom-in-down">
                        <div className="card card4 ps-3">
                            <div className="card-body p-1">
                                <h1 className="card-head mb-2">Compliance</h1>
                                <h1 className="card-texts">Adherence to local laws and regulations governing vehicle registration.</h1>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-md-6 col-lg-4 p-0 column" data-aos="zoom-in-down">
                        <div className="card card5 ps-3">
                            <div className="card-body p-1">
                                <h1 className="card-head mb-2">Authentic Document</h1>
                                <h1 className="card-texts">Ensuring vehicle is free of encumbrances, and complies with relevant regulations.</h1>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-md-6 col-lg-4 p-0 column" data-aos="zoom-in-down">
                        <div className="card card6 ps-3">
                            <div className="card-body p-1">
                                <h1 className="card-head mb-2">Customer Service</h1>
                                <h1 className="card-texts">Friendly and helpful staff who can assist with any queries or issues.</h1>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div className="btn-container d-flex justify-content-center mt-4">
                    <Link className="nav-links mx-0 d-inline-block" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <button className="btn hero-btn">Start vehicle registration</button>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default WhyKitanizRegEx;