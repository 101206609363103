import React from 'react'
import "./InsuranceSummaryAuth.scss"
import { BreadCrumb } from 'Components' 
import { InsuranceRegistrationSummaryItem } from 'Pages/Frontend/Components';

const InsuranceSummaryAuth = () => {
    return (
        <div className='RenewRegistrationSummaryAuth'> 
            <div className="container">
                <BreadCrumb parentTitle={'Registration'} parentUrl={'/register-vehicle'} pageTitle={'New Registration'} childTitle={'Summary'}></BreadCrumb>
            
                <InsuranceRegistrationSummaryItem module="user"></InsuranceRegistrationSummaryItem>
           </div>
        </div>
    )
}

export default InsuranceSummaryAuth
