import React, { FC, Fragment, Key, useEffect, useState } from 'react'  
import { Link, useNavigate, useParams } from 'react-router-dom'
import './CheckoutElement.scss';
import toyota from "Assets/Images/toyota.png";
import { vehicleServiceType } from 'Models'; 
import { deliveryListType } from 'Models/deliveryType';
import { GrAdd } from "react-icons/gr";
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import { deliveryService, userService } from 'Services';

import state from "Data/stateNG.json"
import lgas from "Data/lgaNG.json"

import { vehicleInformationLocalDataType } from 'Models/vehicleServiceType'; 

import {
    CompleteResponesProps, 
    MonnifyProps, 
    UserCancelledResponseProps,
    usePayWithMonnifyPayment,
  } from 'react-monnify-ts'
   

import { useAuth } from 'Components/AuthProvider/AuthProvider';
import { vehicleRegistrationService } from 'Services/vehicleRegistrationService'; 
import { ErrorMessages } from 'Helpers';
import { CurrencyFormatter } from '../../../../Helpers/CurrencyFormatter';
import { insuranceService } from 'Services/insuranceService';

  
 
interface propsType {
    module?: string|undefined;
    vehicleInformation?: vehicleInformationLocalDataType|undefined;
    vehicleService?: vehicleServiceType|undefined;
    address?: deliveryListType[],
    request?: boolean,
    reloadAddress?:()=> void 
}

type formType = {
    address: string,
    localGovernment: string,
    state: string,
    phoneNumber: string,
    primary: boolean
  }

 const CheckoutElement:FC<propsType> = ({ module, address, reloadAddress, request, vehicleService, vehicleInformation}) => {
    const [newAddress, setNewAddress] = useState(false)
    const { service_id, type } = useParams<{ service_id: string; type: string }>()

    const navigate = useNavigate()


    const [requestAddr, setRequestAddr] = useState(false)
    const { register, handleSubmit, trigger, formState: { errors } } = useForm<formType>();

    const [selectLgas, setSelectedLgas] = useState<Array<{name: string, id: number}>>([])
    const [selectState, setSelectedState] = useState<string>("")
    const [deliveryFee, setDeliveryFee] = useState<number>(0)
    const [cssPhNumber, setCssPhNum]  = useState<string>('')
    const [phoneNumbers, setPhoneNumbers] = useState<string>('')


    const validatePhoneNumber = (value: string) => {
        // Regular expression to validate phone number with country code
        const phoneRegex = /^\+\d{1,3}-\d{3,14}$/;
        return phoneRegex.test(value) || 'Phone number must have a leading country code (e.g., +23481456789..)';
      };
      
      const handleChange = () => {  
        trigger('phoneNumber'); 
      }; 
    
      
      const checkPhNo = (phNumber: string) => {
    
        if(phNumber.length === 14 && phNumber.substring(0, 4) === '+234') {
            setPhoneNumbers(phNumber)
            setCssPhNum('')
        } else if (phNumber.length === 11 && phNumber.substring(0, 1) === '0') {
            setCssPhNum('');
            setPhoneNumbers(`+234${phNumber.slice(1)}`)
        } else if(phNumber.length === 0 && !phNumber) {
            setCssPhNum('')
        }
         else {
            setCssPhNum('Invalid')
        }
        // console.log(phoneNumbers, phNumber)
      }

    const handleState = (e: any) => {
        const state = JSON.parse(e.target.value); 
        setSelectedState(state.name)
        const localGovt: any = lgas[2].data;
        const getLga: any = localGovt.filter((res: any) => Number(res.state_id) === Number(state.id))
        setSelectedLgas(getLga)
      }
      

      const submit = async (data: formType) =>{
        setRequestAddr(true)

        const newData = [
            { 
                ...data,
              state: selectState,  
              primary: true, 
            }
          ]

        await userService.createDeliveryAddress(newData).then((response)=>{ 
            Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Saved",
                text: "Delivery address addeed successfully",
                showConfirmButton: false,
                timer: 1600
              });
            setRequestAddr(false)
            setNewAddress(!newAddress)
            reloadAddress && reloadAddress()
            
        }, error =>{ 
            setRequestAddr(false)
            console.log("error address", error.response) 

            Swal.fire({
                position: "top-end",
                icon: "warning",
                title: "Error",
                text: error?.response?.data?.error,
                showConfirmButton: true
              });
        })


      }
 
    const totalAmount = ():number =>{
        if(vehicleInformation && (!vehicleInformation.typeOfInsurance || vehicleInformation.typeOfInsurance === "none") ){ 
            if(type === 'dealers-plate-number' && vehicleService){
               return Number((Number(vehicleService?.amount) * Number(vehicleInformation.number)) + Number(deliveryFee)); 
            }else{
                return Number(Number(vehicleService?.amount) + Number(deliveryFee))
            }
        } else {
            return Number(Number(vehicleService?.amount?? 0) + 15000 + Number(deliveryFee))
        }
    }
   
    const getFileUrl = (name: string) => { 
         const fileUrl = vehicleInformation?.uploadedFiles?.find((res)=>res.name === name)?.url
         if(fileUrl){
            return fileUrl
         }else{
            Swal.fire({
                position: "top-end",
                icon: "warning",
                title: "Oops!",
                text: "Files can not be empty",
                showCancelButton: true, 
              });
           return 
         }
    }
 
    
    const { user } = useAuth()
 
    const paymentData: MonnifyProps = {
        amount: totalAmount(),
        currency: 'NGN',
        reference: `${new String(new Date().getTime())}`,
        customerName: user? user.displayName : `${vehicleInformation?.lastName} ${vehicleInformation?.firstName}`,
        customerEmail: user?user.email : vehicleInformation?.email,
        customerPhoneNumber: String(vehicleInformation?.phoneNumber),
        apiKey: 'MK_TEST_1PMTF77AM9', 
        contractCode: '0166995308',
        
        paymentDescription: 'Payment for Vehicle Registration',
        metadata: {
            name: `${vehicleInformation?.firstName} ${vehicleInformation?.lastName}`,
            age: 45,
            // ...vehicleInformation
        },
        isTestMode: true, 
    }


     const onLoadStart = () => {
        console.log('loading has started')
      }

      const onLoadComplete = () => {
        console.log('SDK is UP')
      }

      const deliveryAddress = address && address.find((addr)=>addr.primary===true)
      const deliveryDetailId = deliveryAddress?.id
    
      const onComplete = (response: CompleteResponesProps) => {
        //Implement what happens when the transaction is completed.
        console.log('response', response)
 
        if(response.status === "SUCCESS"){ 
            submitRegistration(response) 
        }
        
        if(response.status === "FAILED"){  
            Swal.fire('Oops!', `An error occured while processing your payment, ${response.message}. Please try again.`, 'warning'); 
        }

      }

      const onClose = (data: UserCancelledResponseProps) => {
        //Implement what should happen when the modal is closed here
        console.log('data', data) 
        if(data.paymentStatus === "USER_CANCELLED"){  
            Swal.fire('Oops!', data.responseMessage, 'warning'); 
        }
      }

      const initializePayment = usePayWithMonnifyPayment(paymentData)

         console.log("vehicleInformation 899", vehicleInformation)

      const submitRegistration = async ( response: CompleteResponesProps ) =>{
         
        Swal.fire({ 
            timer: 2000,
            timerProgressBar: false,
            didOpen: () => {
              Swal.showLoading(); 
            } 
          })

        console.log("regData", vehicleInformation && vehicleInformation.typeOfInsurance )

        const insuranceProvider = vehicleInformation && vehicleInformation.typeOfInsurance !== "none" ? 
        vehicleInformation.typeOfInsurance === "third-party-insurance" ? 
          {
            thirdPartyInsurance: {
              vehicleInformationId: vehicleInformation.vehicleInformationId,
              reference: response.transactionReference,
              amount: response.authorizedAmount,
              insuranceProviderId: vehicleInformation.insuranceProvider && typeof vehicleInformation.insuranceProvider === 'string' && vehicleInformation.insuranceProvider !=='none'  ? JSON.parse(vehicleInformation.insuranceProvider)?.id : '',
              serviceId: service_id, 
              deliveryDetailId: deliveryDetailId
            }
          } : 
          {
            comprehensiveInsurance: {
              vehicleInformationId: vehicleInformation.vehicleInformationId,
              reference: response.transactionReference,
              amount: response.authorizedAmount,
              valuation: 2000,
              insuranceProviderId: vehicleInformation.insuranceProvider && typeof vehicleInformation.insuranceProvider === 'string' && vehicleInformation.insuranceProvider !=='none' ? JSON.parse(vehicleInformation.insuranceProvider).id : '',
              serviceId: service_id, 
              deliveryDetailId: deliveryDetailId
            }
          }
         : {};


 
        console.log("regData 2", insuranceProvider)
        console.log("regData vehicleInformation 0009", vehicleInformation)

        const ownerInfo = {
            firstName: vehicleInformation?.firstName,
            lastName: vehicleInformation?.lastName,
            phoneNumber: vehicleInformation?.phoneNumber,
            address: vehicleInformation?.address, 
            email: vehicleInformation?.email, 
            lga: vehicleInformation?.lga??vehicleInformation?.localGovernment,
            state: (vehicleInformation && vehicleInformation.state && typeof vehicleInformation.state === 'string' ?
                    (() => {
                        try {
                            const parsedState = JSON.parse(vehicleInformation.state);
                            return typeof parsedState === 'object' ? parsedState.name : vehicleInformation.state;
                        } catch (error) {
                            return vehicleInformation.state;
                        }
                    })() :
                    null) 
        }

        const regData = {
            vehicleInformationId: vehicleInformation?.vehicleInformationId,
            serviceId: service_id, 
            deliveryDetailId,
            amount: response.authorizedAmount,
            reference: response.transactionReference,

            ...(type === 'change-of-ownership'
                ? { ownerInformation: ownerInfo }
                : { holderInformation: ownerInfo }),
  
            ...insuranceProvider 
        }


        try {
            let requestResponse;
            if (type === 'renew-registration') {
                requestResponse = await vehicleRegistrationService.renewVehicleRegistration(regData);
            } 
            else if(type === 'new-registration'){
                requestResponse = await vehicleRegistrationService.newVehicleRegistration(regData);
            }
            else if(type === 'change-of-ownership'){
                requestResponse = await vehicleRegistrationService.changeOfOwnershipRegistration(regData);
               
                if(vehicleInformation){

                    const files = vehicleInformation.uploadedFiles;

                    for (const file of files) {
                        await vehicleRegistrationService.changeOfOwnershipDocument([
                            {
                                registrationId: requestResponse?.data, 
                                link: file.url, 
                                documentId: file.documentId
                            }
                        ]).then((response)=>{
                            console.log("file uploaded:", response)
                        }, error =>{ 
                            console.log("error uploading file", error.response)
                        });
                    }
                }

            }else if(type === 'drivers-license-registration'){
                requestResponse = await vehicleRegistrationService.driversLicenseRegistration( 
                    {
                        duration: 0,  
                        serviceId: service_id, 
                        deliveryDetailId,
                        amount: response.authorizedAmount,
                        reference: response.transactionReference, 
                        title: vehicleInformation?.title,
                        firstName: vehicleInformation?.firstName,
                        otherNames: vehicleInformation?.otherNames,
                        sex: vehicleInformation?.sex,
                        dateOfBirth: vehicleInformation?.dateOfBirth,
                        placeOfBirth: vehicleInformation?.placeOfBirth,
                        maritalStatus: vehicleInformation?.maritalStatus,
                        nationality: vehicleInformation?.nationality,
                        address: vehicleInformation?.address,
                        stateOfOrigin: vehicleInformation?.stateOfOrigin,
                        lga: vehicleInformation?.lga,
                        phoneNumber: vehicleInformation?.phoneNumber,
                        mothersMaidenName: vehicleInformation?.mothersMaidenName,
                        bloodGroup: vehicleInformation?.bloodGroup,
                        heightInMeters: vehicleInformation?.heightInMeters,
                        nokPhoneNumber: vehicleInformation?.nokPhoneNumber
                    }
                )
            } else if(type === 'dealers-plate-number'){
               
                  requestResponse = await vehicleRegistrationService.dealersRegistration( 
                    { 
                        quantity: Number(vehicleInformation?.number),
                        certificateOfIncorporation: getFileUrl("Certificate of Incorporation"),
                        meansOfId: getFileUrl("Means of ID"),
                        companyLetterHead: getFileUrl("Company Letter Head"),
                        passportPhoto: getFileUrl("Passport Photo"),
                        reference: response.transactionReference, 
                        amount: response.authorizedAmount,
                        serviceId: service_id, 
                        deliveryDetailId 
                    }
                )
            } else if(type === 'customize-plate-number'){
                requestResponse = await vehicleRegistrationService.customizePlateNumber( 
                    {   
                        number: vehicleInformation?.plateNumber,
                        reference: response.transactionReference, 
                        amount: response.authorizedAmount,
                        serviceId: service_id, 
                        deliveryDetailId 
                    }
                )
            } else if(type === 'drivers-license-renewal'){
                requestResponse = await vehicleRegistrationService.driversLicenseRenewal( 
                    {    
                        reference: response.transactionReference, 
                        amount: response.authorizedAmount,
                        duration: vehicleInformation?.duration, 
                        serviceId: service_id, 
                        deliveryDetailId,
                        firstName: vehicleInformation?.firstName,
                        otherNames:vehicleInformation?.otherNames,
                        imageUrl: vehicleInformation?.uploadedDoc
                    }
                )
            }else if(['insurance', 'insurance_third-party-insurance', 'insurance_comprehensive-insurance'].includes(String(type))){
                (type === "insurance_third-party-insurance"?
                requestResponse = await insuranceService.registerThirdPartyInsurance( 
                     {    
                        reference: response.transactionReference, 
                        amount: response.authorizedAmount,
                        valuation: response.authorizedAmount, 
                        serviceId: service_id, 
                        deliveryDetailId,
                        insuranceProviderId: vehicleInformation && vehicleInformation.insuranceProvider && typeof vehicleInformation.insuranceProvider === 'string' ? JSON.parse(vehicleInformation.insuranceProvider).id : '', 
                        vehicleInformationId: vehicleInformation?.vehicleInformationId,
                    }): 
                    requestResponse = await insuranceService.registerThirdPartyInsurance( {    
                        reference: response.transactionReference, 
                        amount: response.authorizedAmount,
                        valuation: response.authorizedAmount, 
                        serviceId: service_id, 
                        deliveryDetailId,
                        insuranceProviderId: vehicleInformation && vehicleInformation.insuranceProvider && typeof vehicleInformation.insuranceProvider === 'string' ? JSON.parse(vehicleInformation.insuranceProvider).id : '', 
                        vehicleInformationId: vehicleInformation?.vehicleInformationId,
                    }))
                
            } 
            
            console.log("requesResponse", requestResponse)
            Swal.fire('Congratulations!', response.message, 'success');  

            await localStorage.removeItem("kit_new_reg");
            navigate(module && module === 'user'? `/myaccount/payment-successful/${type}/${requestResponse?.data}` : `/payment-successful/${type}/${requestResponse?.data}`, {replace: true})
        
        } catch (error) {
            console.log("error",  (error as any).response);

            let errorMessage = 'An error occurred';
            if ((error as any).response && (error as any).response.data && (error as any).response.data.message) {
                errorMessage = (error as any).response.data.message;
            }

            Swal.fire({
                position: "top-end",
                icon: "warning",
                title: "Oops!",
                text: errorMessage,
                showCloseButton: true,
                timer: 3000
            });
        } 
      }
      console.log("vehicleInformation", vehicleInformation)
      console.log("user", user)
      

        
    const getDeliveryFee = async () => {
        console.log({state: deliveryAddress?.state, localGovernment: deliveryAddress?.localGovernment})
        await deliveryService.getFeeByLg(100, 0, deliveryAddress?.state, deliveryAddress?.localGovernment).then((response)=>{
            console.log("getDeliveryFee", response.data[0])

            if(response.data && response.data[0]){
                setDeliveryFee(response.data[0].amount)
            }else{ 
                setDeliveryFee(0)
            }
        }, error => {
            console.log({error})
        })
    }

    useEffect(() => {
        if(deliveryAddress){
           getDeliveryFee()
        }
    }, [deliveryAddress])
 
    return ( 
        <div className="row justify-content-center">
            <div className={module && module==='user' ? "col-md-6 mb-4 mt-5":"col-md-5 mb-4 mt-5"}>
                <div className="card px-4">
                    <div className="card-header profile-title px-0">
                        Delivery Details
                    </div>
                    <div className="card-body px-0 pt-0">
         
                    { address && address?.map((addr: deliveryListType, index:Key)=> {
                      return addr.primary === true && <div key={index} className="card delivery-details-item mt-3">
                            <div className="card-header">
                                <div className="row">
                                    <div className="col-6">
                                        <div className="title">{addr.state}</div></div>
                                    <div className="col-6 text-end">
                                        {/* <Link to={'/myaccount/settings/address'}>Change</Link> */}
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                               <div className="address-details">{addr.address}, {addr.state}.</div>
                               <div className="contact-details">
                                 {addr?.owner?.name} 
                                 {addr?.owner?.phoneNumber}</div>
                            </div>
                        </div>})}

                        <div className='add-new-address'>
                             <span onClick={()=>setNewAddress(!newAddress)}><GrAdd /> Add New Address</span>
                        </div>

                        {address && (newAddress || address.length === 0) &&  <form onSubmit={handleSubmit(submit)}> 
                            <div className='form-group mt-3'>
                                <label htmlFor="address">Address</label>
                                <input type="text" id="address" className={`form-control ${errors.address ? 'is-invalid' : ''}`} {...register('address', { required: "This field is required"})}/>
                                {errors.address && <small className='error'>{errors.address.message}</small>} 
                            </div>
                            <div className='form-group mt-3'>
                                <label htmlFor="state">State</label>
                                <select id="state" className={`form-control ${errors.state ? 'is-invalid' : ''}`} {...register('state', { required: "This field is required", onChange: (e) => handleState(e)})}>
                                    <option>select state</option>
                                    {state.map((stateItem:{name: string}, index: Key)=><option key={index} value={JSON.stringify(stateItem)}>{stateItem.name}</option>)}
                                </select>
                                {errors.state && <small className='error'>{errors.state.message}</small>} 
                            </div>
                            <div className='form-group mt-3'>
                                <label htmlFor="lga">L.G.A</label>
                                <select id="lga" className={`form-control ${errors.localGovernment ? 'is-invalid' : ''}`} {...register('localGovernment', { required: "This field is required"})}>
                                    <option>select L.G.A</option>
                                    {selectLgas.map((stateItem:{name: string}, index: Key)=><option key={index}>{stateItem.name}</option>)}
                                </select>
                                {errors.localGovernment && <small className='error'>{errors.localGovernment.message}</small>} 
                            </div>  
                            <div className='form-group mt-3'>
                                <label htmlFor="contact_numver">Contact Number <small>(e.g +2348143...)</small></label>
                                <input type="text" id="contact_numver" className={`form-control ${cssPhNumber} ${errors.phoneNumber ? 'is-invalid' : ''}`} 
                                {...register("phoneNumber", { required: "This field is required", validate: validatePhoneNumber, onChange: handleChange})}  onChange={(e) => {checkPhNo(e.target.value)}}/>
                            </div>
                            {errors.phoneNumber && <small className='error'>{errors.phoneNumber.message}</small>} 
                            
                            <div className='form-submit-button'> 
                                <button type="submit" className='btn btn-default w-100' disabled={!requestAddr && !cssPhNumber && phoneNumbers ? false : true}>{requestAddr && <span className='spinner-border spinner-border-sm'></span>} Add Address</button>
                            </div>
                        </form>}
                    </div>
                </div>


                {/* <div className="card payment-card px-4 mt-4 d-none">
                    <div className="card-header profile-title px-0">
                        Payment method
                    </div>
                    <div className="card-body px-0 pt-0"> 
                        <form action=""> 
                            <div className="row">
                                <div className="col-6">
                                    <button className='btn btn-payment active'>
                                        <svg width="18" height="18" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2.5 8.50488H22.5" stroke="#186AFF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" stroke-linejoin="round"/>
                                            <path d="M6.5 16.5049H8.5" stroke="#186AFF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" stroke-linejoin="round"/>
                                            <path d="M11 16.5049H15" stroke="#186AFF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" stroke-linejoin="round"/>
                                            <path d="M6.94 3.50488H18.05C21.61 3.50488 22.5 4.38488 22.5 7.89488V16.1049C22.5 19.6149 21.61 20.4949 18.06 20.4949H6.94C3.39 20.5049 2.5 19.6249 2.5 16.1149V7.89488C2.5 4.38488 3.39 3.50488 6.94 3.50488Z" stroke="#186AFF" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                                        </svg> Card</button> 
                                </div>  
                                <div className="col-6">
                                    <button className='btn btn-payment'>
                                        <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.39969 6.32015L15.8897 3.49015C19.6997 2.22015 21.7697 4.30015 20.5097 8.11015L17.6797 16.6002C15.7797 22.3102 12.6597 22.3102 10.7597 16.6002L9.91969 14.0802L7.39969 13.2402C1.68969 11.3402 1.68969 8.23015 7.39969 6.32015Z" stroke="#6A80A3" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                                            <path d="M10.1094 13.6501L13.6894 10.0601" stroke="#6A80A3" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                                        </svg> Transfer
                                    </button> 
                                </div>  
                            </div>   

                                <button className='btn'>Confirm</button>
                        </form>
                    </div>
                </div> */}

            </div>
            <div className={module && module==='user' ? "col-md-6 mb-5 mt-4":"col-md-5 mb-5 mt-4"}>
                <div className="card document-details-card p-0">

                    <div className="card-header border-0">
                        <div className="title-top">
                        Summary
                        </div>
                       {vehicleInformation?.vehicleInfo && <div className="d-inline-flex h-100">
                            <div className="text-center my-auto">
                                <div className="logo"> 
                                    <img src={toyota} alt="" />
                                </div>
                            </div>
                            <div className="ms-3 my-auto title">
                              {vehicleInformation?.vehicleInfo? vehicleInformation?.vehicleInfo.make.name: vehicleInformation?.make?.name} {vehicleInformation?.vehicleInfo? vehicleInformation?.vehicleInfo.model.name : vehicleInformation?.model?.name}, {vehicleInformation?.year}
                            </div>
                        </div>}
                    </div>
                    <div className="card-body px-0">   
                        <div className="processing-time"> 
                            <div className="container">
                               {vehicleInformation && vehicleInformation.typeOfInsurance && <div className="row"> 
                                    {vehicleInformation && vehicleInformation.typeOfInsurance !== "none" && <Fragment>
                                        <div className="col-6 my-auto sub-total"> 
                                        Insurance 
                                        </div>
                                        <div className="col-6 text-end my-auto sub-total">
                                            ₦15,000
                                        </div>
                                    </Fragment>}

                                    {/* <div className="col-md-6">
                                        Total
                                    </div>
                                    <div className="col-md-6 text-end">
                                        ₦{vehicleService?.amount}
                                    </div> */}
                                </div>}

                                <div className="row"> 
                                    <div className="col-6 mb-2 sub-total mt-1 pt-1"> 
                                        Sub Total
                                    </div>
                                        <div className="col-6 text-end mb-2 sub-total mt-1 pt-1">
                                        ₦{vehicleService?.amount}
                                    </div>
                                </div>

                                <div className="row mt-1">
                                    <div className="col-6">
                                        Delivery
                                    </div>
                                    <div className="col-6 text-end">
                                        ₦{CurrencyFormatter(Number(deliveryFee)??0)}
                                    </div>
                                </div>
                            </div> 
                        </div>

                        <div className="total-balance px-3"> 
                            <div className="row h-100"> 
                                <div className="col-6 my-auto">
                                    Pay Total
                                </div>
                                <div className="col-6 text-end my-auto">
                                    ₦{CurrencyFormatter(totalAmount())}
                                </div>
                            </div> 
                        </div>
                    </div> 
                </div>  

               
                <button className='btn btn-primary' type='button' onClick={() => {
                    initializePayment(onLoadStart, onLoadComplete, onComplete, onClose )
                   }}>Make Payment</button> 
                      
                
            </div>
        </div> 
    )
}

export default CheckoutElement
