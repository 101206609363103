import React, { Key, useEffect, useState } from 'react'
import "./RegistrationService.scss"
import { vehicleService } from 'Services'
import { vehicleServiceType } from 'Models';
import { Link } from 'react-router-dom';
import { useAuth } from 'Components/AuthProvider/AuthProvider';
import RegistrationMenuOptionSkeleton from '../../Registration/MenuOption/RegistrationMenuOptionSkeleton';
import ImageSrc from '../../../../../Components/ImageSrc/ImageSrc';

const RegistrationService = () => {
    const limit = 100;
    const offset = 0;

    const { user } = useAuth()
    
    const [serviceList, setServiceList] = useState<Array<vehicleServiceType>>()
    const [request, setRequest] = useState(false)
    
    const getList = async () => {
        setRequest(true)
       await vehicleService.serviceList().then((response:{ data: {data: Array<vehicleServiceType>}})=>{
          console.log("service response", response.data)
          setServiceList(response.data.data)  
          setRequest(false)
       }, error =>{
        console.log("serviceList error", error.response)
        setRequest(false) 
       })
    }

    useEffect(() => {
        getList()
    }, [])
    
    return (
        <div className='RegistrationService'>
            <div className="container-fluid container-lg">
                <div className="row justify-content-center">

                  {request && <RegistrationMenuOptionSkeleton></RegistrationMenuOptionSkeleton>}

                   {serviceList && serviceList.map((service:vehicleServiceType, index:Key)=><div key={index} className="col-12 col-sm-6 col-md-6 col-xl-4" data-aos="zoom-in-down">
                        <Link to={user? `/registration-service/${service.id}` : `/registration-service/${service.uuid}`}> 
                            <div className="card mt-2">
                                <div className="card-body">
                                    <ImageSrc src={service.iconUrl} alt={service.name} title={service.name} width={''} absoluteWidth={'48px'}></ImageSrc>

                                    <div className="title mt-4">{service.name}</div>
                                    <div className="detail mt-2">{service.description}</div>

                                    <div className="text-end">
                                        <Link to={user? `/registration-service/${service.uuid}` : `/registration-service/${service.uuid}`}> 
                                        {/* <Link to={user? `/myaccount/${service.path}/${service.id}` : `/registration-service/${service.id}`}>  */}
                                            <button className="btn border-0">
                                                <svg width="30" height="30" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M16.0003 29.485C23.3641 29.485 29.3337 23.5155 29.3337 16.1517C29.3337 8.7879 23.3641 2.81836 16.0003 2.81836C8.63653 2.81836 2.66699 8.7879 2.66699 16.1517C2.66699 23.5155 8.63653 29.485 16.0003 29.485Z" stroke="#186AFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                    <path d="M11.333 16.1523H19.333" stroke="#186AFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                    <path d="M16.666 20.1523L20.666 16.1523L16.666 12.1523" stroke="#186AFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>
                                            </button>
                                        </Link>  
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>)}

                </div>
            </div>
        </div>
    )
}

export default RegistrationService
