import React from 'react'
import "./TrackingVehicle.scss"
import tracking from "Assets/Icons/tracking.svg"
import trackingDevice from "Assets/Images/tracking-device.svg"
import filter from "Assets/Icons/filter.svg"

import { JoinKitaniz } from '../../Components'  

const TrackingVehicle = () => {
    return ( 
        <div className='TrackingVehicle'>
            <div className="tracking-header py-5">
                <div className="container">
                    <div className="row h-100">
                        <div className="col-md-6 my-auto">
                            <div className="left-text">
                               High-Quality Trackers  for Seamless Security 
                            </div>
                        </div>
                        <div className="col-md-6 d-flex justify-content-center justify-content-md-end">
                            <div className="right-image">
                                <img src={tracking} alt="" className='w-100' />
                            </div> 
                        </div>
                    </div>
                </div>
            </div>

            <div className="filter mt-5">
                <div className="container">
                    <div className="row">
                        <div className="col-8 col-md-11">
                            <div className="search">
                                <span className='d-icon d-search'></span>
                                <input type="text" className="form-control" placeholder='Search Tracker'/>
                             </div>
                             <div className="search-text">15 Tracker products available</div>
                        </div>
                        <div className="col-4 col-md-1"> 
                            <div className="dropdown">
                                <button className="btn btn-filter dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                    <img src={filter} alt=''></img> Filter
                                </button>
                                <ul className="dropdown-menu border shadow-sm" aria-labelledby="dropdownMenuButton1">
                                    <li><a className="dropdown-item" href="/">Action</a></li>
                                    <li><a className="dropdown-item" href="/">Another action</a></li>
                                    <li><a className="dropdown-item" href="/">Something else here</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="trackingOption my-4">
                 <div className="container">
                    <div className="row">
                        <div className="col-sm-6 col-md-4 mt-3">
                            <div className="card">
                                <div className="card-body text-center">
                                    <div className="card-image">
                                        <img src={trackingDevice} alt='' className='w-100'></img>
                                    </div>
                                    <div className="card-title text-start mt-2">
                                        Tracker CAR TRACKER
                                    </div>
                                    <div className="card-price text-start">
                                        ₦20,000
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        
                        <div className="col-sm-6 col-md-4 mt-3">
                            <div className="card">
                                <div className="card-body text-center">
                                    <div className="card-image">
                                        <img src={trackingDevice} alt='' className='w-100'></img>
                                    </div>
                                    <div className="card-title text-start mt-2">
                                        Tracker CAR TRACKER
                                    </div>
                                    <div className="card-price text-start">
                                        ₦20,000
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-6 col-md-4 mt-3">
                            <div className="card">
                                <div className="card-body text-center">
                                    <div className="card-image">
                                        <img src={trackingDevice} alt='' className='w-100'></img>
                                    </div>
                                    <div className="card-title text-start mt-2">
                                        Tracker CAR TRACKER
                                    </div>
                                    <div className="card-price text-start">
                                        ₦20,000
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-6 col-md-4 mt-3">
                            <div className="card">
                                <div className="card-body text-center">
                                    <div className="card-image">
                                        <img src={trackingDevice} alt='' className='w-100'></img>
                                    </div>
                                    <div className="card-title text-start mt-2">
                                        Tracker CAR TRACKER
                                    </div>
                                    <div className="card-price text-start">
                                        ₦20,000
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-6 col-md-4 mt-3">
                            <div className="card">
                                <div className="card-body text-center">
                                    <div className="card-image">
                                        <img src={trackingDevice} alt='' className='w-100'></img>
                                    </div>
                                    <div className="card-title text-start mt-2">
                                        Tracker CAR TRACKER
                                    </div>
                                    <div className="card-price text-start">
                                        ₦20,000
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-6 col-md-4 mt-3">
                            <div className="card">
                                <div className="card-body text-center">
                                    <div className="card-image">
                                        <img src={trackingDevice} alt='' className='w-100'></img>
                                    </div>
                                    <div className="card-title text-start mt-2">
                                        Tracker CAR TRACKER
                                    </div>
                                    <div className="card-price text-start">
                                        ₦20,000
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-6 col-md-4 mt-3">
                            <div className="card">
                                <div className="card-body text-center">
                                    <div className="card-image">
                                        <img src={trackingDevice} alt='' className='w-100'></img>
                                    </div>
                                    <div className="card-title text-start mt-2">
                                        Tracker CAR TRACKER
                                    </div>
                                    <div className="card-price text-start">
                                        ₦20,000
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-6 col-md-4 mt-3">
                            <div className="card">
                                <div className="card-body text-center">
                                    <div className="card-image">
                                        <img src={trackingDevice} alt='' className='w-100'></img>
                                    </div>
                                    <div className="card-title text-start mt-2">
                                        Tracker CAR TRACKER
                                    </div>
                                    <div className="card-price text-start">
                                        ₦20,000
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-6 col-md-4 mt-3">
                            <div className="card">
                                <div className="card-body text-center">
                                    <div className="card-image">
                                        <img src={trackingDevice} alt='' className='w-100'></img>
                                    </div>
                                    <div className="card-title text-start mt-2">
                                        Tracker CAR TRACKER
                                    </div>
                                    <div className="card-price text-start">
                                        ₦20,000
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                 </div>
            </div>

            <JoinKitaniz />
        </div>
    )
}

export default TrackingVehicle
