export const countries = [
    {
        name: "Nigeria",
        code: "NG",
        phoneCode: "+234",
        flag: "🇳🇬",
        digitLength: 10
    },
    {
        name: "Ghana",
        code: "GH",
        phoneCode: "+233",
        flag: "🇬🇭",
        digitLength: 9
    },
    {
        name: "United States of America",
        code: "US",
        phoneCode: "+1",
        flag: "🇺🇸",
        digitLength: 10
    }
]