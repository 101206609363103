import { Link } from 'react-router-dom'; 
import './UploadPlatesReprintNumber.scss'
import { BreadCrumb } from 'Components';
import { useState } from 'react';
import { UploadReprintElement } from 'Pages/Frontend/Components';

const UploadPlatesReprintNumber = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [confirmUpload, setConfirmUpload] = useState(false) 

    return ( 
        <div className="UploadPlatesNumber">
            
            <div className="container">
                <BreadCrumb parentTitle={'Registration'} parentUrl={'/upload-plate-number'} pageTitle={'Upload Plates Number'}></BreadCrumb>
                <div className="row justify-content-center">
                    <div className="col-md-5 mb-5 mt-4">
                       <div className="card border-0 py-3">
                        <div className="card-header text-left border-0 pt-3"> 
                            <div className="title">
                                Upload Plates Number
                            </div> 
                        </div>
                        <div className="card-body pt-2">
                            <form action="">
                               <UploadReprintElement></UploadReprintElement>
                                
                                <div className="form-group"> 
                                    <Link to='/myaccount/checkout'>
                                        <button className='btn btn-primary' type='button'>Done</button>
                                    </Link>
                                </div> 
                            </form>
                        </div>
                       </div>
                    </div>

                </div>
             </div>
        </div>
     );
}
 
export default UploadPlatesReprintNumber;