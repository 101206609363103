import React from 'react'
import "./RenewRegistrationSummaryAuth.scss"
import { BreadCrumb } from 'Components' 
import { RenewRegistrationSummaryItem } from 'Pages/Frontend/Components';

const RenewRegistrationSummaryAuth = () => {
    return (
        <div className='RenewRegistrationSummaryAuth'> 
            <div className="container">
                <BreadCrumb parentTitle={'Registration'} parentUrl={'/register-vehicle'} pageTitle={'New Registration'} childTitle={'Summary'}></BreadCrumb>
            
                <RenewRegistrationSummaryItem module="user"></RenewRegistrationSummaryItem>
           </div>
        </div>
    )
}

export default RenewRegistrationSummaryAuth
