import React from 'react'
import "./PaymentSuccessful.scss"
import { PaymentSuccessfulCard } from '../../Components'

const PaymentSuccessful = () => {
    return (
        <div className='payment-successful'>
             <div className="container h-100">
                <div className="row justify-content-center h-100">
                    {/* <div className="col-12 col-sm-9 col-md-6 col-lg-6 col-xl-4 my-auto"> */}
                    <div className="d-flex justify-content-center my-auto">
                        <PaymentSuccessfulCard></PaymentSuccessfulCard>
                    </div>
                </div>
             </div>
        </div>
    )
}

export default PaymentSuccessful
