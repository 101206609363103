import './AboutUsHero.scss'


const AboutUsHero = () => {
    return ( 
        <div className="AboutUsHero container-fluid p-0">
            <div className="fill-AboutUsHero w-100 h-100 ">
                <div className="container h-100 d-flex align-items-center justify-content-center">
                    <div className="about-us-texts-container text-center">
                        <div className="about-us-hero-title mb-4 ">
                            ABOUT US
                        </div>
                        
                        <div className="about-us-hero-text">
                            Providing Hassle-Free solutions for all your automotive needs
                        </div>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default AboutUsHero;