import { Link } from 'react-router-dom';
import './UploadDocument.scss'

const UploadDocument = () => {
    return ( <div className="UploadDocument">
        
        <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-5 my-5">
                    <div className="card border-0">
                        <div className="card-header text-center border-0 pt-4"> 
                            <div className="title border-bottom text-start pb-3 fw-bold">
                                Upload Documents
                            </div>
                        </div>
                        <div className="card-body">

                            <div className="upload-container mt-2">
                                <div className="text mb-1">Certificate of incorporation</div>
                                <button className="btn px-4 rounded-pill">Upload File</button>
                            </div>
                            
                            <div className="upload-container mt-4">
                                <div className="text mb-1">Means of ID</div>
                                <button className="btn px-4 rounded-pill">Upload File</button>
                            </div>
                            
                            <div className="upload-container mt-4">
                                <div className="text mb-1">Company Letter head</div>
                                <button className="btn px-4 rounded-pill">Upload File</button>
                            </div>
                            
                            <div className="upload-container mt-4">
                                <div className="text mb-1">Passport photo</div>
                                <button className="btn px-4 rounded-pill">Upload File</button>
                            </div>

                        </div>
                        
                        <div className="form-group row">
                            <div className="my-auto p-3">
                                <Link to='/checkout'>
                                    <button className='btn btn-primary form-control rounded-pill'>Continue</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
    </div> );
}
 
export default UploadDocument;