import React, { FC } from 'react' 
import './MobileHeader.scss'  
import { Link } from 'react-router-dom';   
import notification from 'Assets/Images/notification.svg'
import profile from 'Assets/Images/profile.svg' 
import AuthenticatedSidebarContent from '../Sidebar/AuthenticatedSidebarContent/AuthenticatedSidebarContent';

import logo from 'Assets/Images/RegXpress.png' 
import logoSvg from 'Assets/Icons/logo.svg' 
import { CiLock } from 'react-icons/ci';

 
import { useAuth } from '../../../Components/AuthProvider/AuthProvider'   
import { useSelector } from 'react-redux'  
import Dashboard  from 'Assets/Icons/dashboard.svg' 
import orders  from 'Assets/Icons/orders.svg' 
import quote  from 'Assets/Icons/quote-request.svg' 
import setting  from 'Assets/Icons/setting.svg'
import transaction  from 'Assets/Icons/transaction.svg'  
import Swal from 'sweetalert2'
import { getAuth, signOut } from 'firebase/auth'


interface propsType {
    publicAccess?: boolean
}
const MobileHeader:FC<propsType> = ({ publicAccess }) => { 
 

    const { user, logout } = useAuth()
    const auth = getAuth();
   
    const logoutFunc = () => {
      Swal.fire({
        title: "Are you sure?",
        text: "You will be logged out of your account!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#186AFF",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, logout!"
      }).then((result) => {
        if (result.isConfirmed) {
  
          signOut(auth).then(() => { 
             logout()
          }).catch((error) => {
            console.log("error", error)
            alert("can not logout")
          });
        }
      });
    }

  return (
    <header className='mobile-header'> 
    <div className="container h-100 px-4">
        <div className="row h-100">
            {!publicAccess && <div className="col-2 my-auto">
                <button className="btn d-flex justify-content align-item-center"  type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                  <i className="d-icon d-menu-hamburger" aria-hidden="true"></i>
                </button>
            </div>}
            <div className={!publicAccess? 'col-5 my-auto':'col-12 text-center my-auto'}>
                <Link className="navbar-brand" to="/">
                     <img src={logo} alt="" className='w-75'/>
                </Link>
            </div>

            {!publicAccess && <div className="col-5 px-0 my-auto">
                <ul className="nav float-end">
                    <li className="nav-item">
                        <a href="/" className="nav-link">
                            <span className='span-white'>
                                <img src={notification} alt="" />
                            </span>
                        </a>
                    </li> 

                    <li className="nav-item dropdown">
                        <a href="#" className="nav-link dropdown-toggle" role="button" id='navbarDropdown' data-bs-toggle="dropdown" aria-haspopup='true' aria-expanded="false"> 
                            <span className='span-primary'>
                                <img src={profile} alt='' className="avarter"/> 
                            </span>
                        </a>

                        {user && <ul className="dropdown-menu shadow-sm border-0 dropdown-menu-lg-end my-account-dropdown">
                                    <li><Link className="dropdown-item" to="/myaccount/dashboard"><img src={Dashboard} alt=''></img> Dashboard</Link></li>
                                    <li><Link className="dropdown-item" to="/myaccount/orders/vehicle"><img src={orders} alt=''></img> Orders</Link></li>
                                    <li><Link className="dropdown-item" to="/myaccount/quote-request"><img src={quote} alt=''></img> Quote Request</Link></li>
                                    <li><Link className="dropdown-item" to="/myaccount/transactions"><img src={transaction} alt=''></img> Transactions</Link></li>
                                    <li>
                                        <Link className="dropdown-item" to="/myaccount/settings/profile"><img src={setting} alt=''></img> Settings</ Link>
                                    </li>
                                    <li>
                                        <Link className="dropdown-item" to="#" onClick={logoutFunc}> <CiLock  className='me-2'/> Logout</ Link>
                                     </li>
                                    
                                </ul>}
                    </li> 
                </ul>
            </div>}
        </div> 
        <div className="offcanvas offcanvas-start" tabIndex={-1} id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
            <div className="offcanvas-header">
                <h5 className="offcanvas-title text-light" id="offcanvasExampleLabel"> <img src={logoSvg} alt="" className='w-75'/></h5>
                <button type="button" className="btn-close bg-white text-dark" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div className="offcanvas-body px-0">
                <AuthenticatedSidebarContent></AuthenticatedSidebarContent>
            </div>
        </div>
    </div>
 
    </header>
  )
}

export default MobileHeader
