import { Link } from 'react-router-dom';
import cloud from '../../../../../Assets/Images/cloud-plus.svg'
import './UploadPlatesNumber.scss'
import { BreadCrumb } from '../../../../../Components';
import { useState } from 'react';

const UploadPlatesNumber = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [confirmUpload, setConfirmUpload] = useState(false) 

    return ( 
        <div className="UploadPlatesNumber">
            
            <div className="container">
                <BreadCrumb parentTitle={'Registration'} parentUrl={'/upload-plate-number'} pageTitle={'Upload Plates Number'}></BreadCrumb>
                <div className="row justify-content-center">
                    <div className="col-md-5 mb-5 mt-4">
                       <div className="card border-0 py-3">
                        <div className="card-header text-left border-0 pt-3"> 
                            <div className="title">
                                Upload Plates Number
                            </div> 
                        </div>
                        <div className="card-body pt-2">
                            <form action="">
                                <div className="w-100 drag-and-drop">
                                    <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
                                        <div className="blue-cloud-upload-img-container d-flex justify-content-center w-100">
                                            <img src={cloud} alt="cloud" />
                                        </div>
                                        <div className="texts text-center" onClick={()=>setConfirmUpload(true)}>
                                            Drag and drop files or <a href="/">Browse</a> to upload
                                        </div>
                                    </div>
                                </div> 
                                
                                <div className="form-group"> 
                                    <Link to='/checkout'>
                                        <button className='btn btn-primary' type='button'>Done</button>
                                    </Link>
                                </div> 
                            </form>
                        </div>
                       </div>
                    </div>

                </div>
             </div>
        </div>
     );
}
 
export default UploadPlatesNumber;