import React, { useState } from 'react'
import "./RenewReg.scss"
import { BreadCrumb } from 'Components' 
import { VehicleTypeForm, VehicleTypeFormPreview } from 'Pages/Frontend/Components'
import { Link, useParams } from 'react-router-dom';
import toyota from "Assets/Images/toyota.png"
import RenewRegistration from './RenewRegistration';
import RenewRegPayment from './RenewRegPayment';

const RenewReg = () => {
    const [confirmVehicle, setConfirmVehicle] = useState(false)

    return (
        <div className='RenewReg'>
             <div className="container">
                <BreadCrumb parentTitle={'Registration'} parentUrl={'/register-vehicle'} pageTitle={'Renew Registration'}></BreadCrumb>
                <div className="row justify-content-center">
                    <div className="col-md-5 mb-5 mt-4">
                       <div className="card border-0 py-3">
                        <div className="card-header text-left border-0 pt-3"> 
                            <div className="title">
                            What type of vehicle?
                            </div> 
                        </div>
                        <div className="card-body pt-2">
                           {/* <VehicleTypeForm setConfirmVehicle={(val:boolean)=>setConfirmVehicle(val)}></VehicleTypeForm> */}
                           <RenewRegistration setConfirmVehicle={(val:boolean)=>setConfirmVehicle(val)} />
                        </div>
                       </div>
                    </div>

                    
                    {confirmVehicle && <RenewRegPayment />}
                </div>
             </div>
        </div>
    )
}

export default RenewReg
