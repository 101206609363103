import { vehicleService } from "Services";
import { createVehicleInformationType } from "Models/vehicleServiceType";
import { FC, Key, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2"; 
import state from "Data/stateNG.json"
import lgas from "Data/lgaNG.json"

interface propsType {
    module?: string | undefined
}
type FormData = {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
    address: string;
    state: string;
    localGovernment: string;
    // engineNumber: string;
    // chasisNumber: string;
    // color: string;
  };

 const NewOwnersInformationForm:FC<propsType> = ({ module }) => { 

    const { service_id } = useParams()
    const [request, setRequest] = useState(false)
    const [confirmVeh, setConfirmVeh] = useState<string>('d-block')
 
    const [selectLgas, setSelectedLgas] = useState<Array<{name: string, id: number}>>([])

    const navigate = useNavigate()
    
    const  vehicleInformation = useSelector((state: {vehicleInformation: {vehicleMakeId: string, vehicleModelId: string, type: string, year: string }})=> state.vehicleInformation)

    
    const { register, handleSubmit, trigger, formState: { errors } } = useForm<FormData>();

    const onSubmit = async (data:FormData) => {
        // Handle form submission
        console.log(data);

        setRequest(true)

        const newdata:createVehicleInformationType[] = [
            {
              vehicleMakeId: vehicleInformation.vehicleMakeId,
              vehicleModelId: vehicleInformation.vehicleModelId,
              year: Number(vehicleInformation.year),
            //   engineNumber: data.engineNumber,
            //   chasisNumber: data.chasisNumber,
            //   color: data.color,
              type: vehicleInformation.type
            }
        ]


        await vehicleService.createVehicle(newdata).then((response)=>{
            console.log("createVehicle response", response)
            setRequest(false)  
           
            try {
                localStorage.setItem("kit_new_reg", JSON.stringify({...vehicleInformation, ...data, vehicleInformationId: response.data[0]}));
                navigate(module && module === 'user' ? `/myaccount/registration/change-of-ownership/${service_id}/upload-document` : `/registration/change-of-ownership/${service_id}/upload-document`, { replace: true });
            } catch (error: unknown) {
                if (error instanceof DOMException && error.name === 'QuotaExceededError') {
                    console.log("Local storage quota exceeded.");
                    Swal.fire('Oops!', 'Local storage quota exceeded. Please free up some space.', 'warning');
                } else {
                    console.log("Error:", error);
                    Swal.fire('Oops!', 'An error occurred while storing data.', 'warning');
                }
                setRequest(false);
            }
         
        }, (error)=>{
            console.log("error", error)
            Swal.fire('Oops!', error.response.data.message, 'warning'); 
            setRequest(false)
        })
        setConfirmVeh('d-none')
    };

 
     const handleState = (e: any) => {
        const state = JSON.parse(e.target.value);  
        const localGovt: any = lgas[2].data;
        const getLga: any = localGovt.filter((res: any) => Number(res.state_id) === Number(state.id))
        setSelectedLgas(getLga)
      }
      
      const handleChange = () => {  
        trigger('phoneNumber'); 
      };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
                <label htmlFor="firstName">First Name</label>
                <input
                    type="text"
                    className={`form-control ${errors.firstName ? 'is-invalid' : ''}`}
                    id="firstName"
                    placeholder="Benjamin"
                    {...register('firstName', { required: true })}
                />
                {errors.firstName && <small className='error'>{errors.firstName.message}</small>} 
            </div>
        
            <div className="form-group mt-3">
                <label htmlFor="lastName">Last Name</label>
                <input
                    type="text"
                    className={`form-control ${errors.lastName ? 'is-invalid' : ''}`}
                    id="lastName"
                    placeholder="Wood"
                    {...register('lastName', { required: true })}
                />
                {errors.lastName && <small className='error'>{errors.lastName.message}</small>} 
            </div>
         
            <div className="form-group mt-3">
                <label htmlFor="phoneNumber">Phone Number</label>
                <input 
                    type="tel" 
                    {...register("phoneNumber", { 
                    required: "Field can not be empty", 
                    pattern: {
                        value: /^(\+?\d{1,3}[- ]?)?\d{10}$/,
                        message: 'Invalid phone number format. Please include country code.',
                    }, 
                    onChange: handleChange})}
                    id="phoneNumber" 
                    className={`form-control ${errors.phoneNumber ? 'is-invalid' : ''}`}
                /> 
                {errors.phoneNumber && <small className='error'>{errors.phoneNumber.message}</small>} 
            </div>
        
            <div className="form-group mt-3">
                <label htmlFor="email">Email</label>
                <input
                    type="email"
                    className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                    id="email"
                    placeholder="benjamin@gmail.com"
                    {...register('email', { required: true })}
                />
                {errors.email && <small className='error'>{errors.email.message}</small>} 
            </div>
        
            <div className="form-group mt-3">
                <label htmlFor="address">Address</label>
                <input
                    type="text"
                    className={`form-control ${errors.address ? 'is-invalid' : ''}`}
                    id="address"
                    placeholder="5 Maruwa  Lekki Phase 1, Lekki Lagos."
                    {...register('address', { required: true })}
                />
                {errors.address && <small className='error'>{errors.address.message}</small>} 
            </div>
        
            <div className='form-group mt-3'>
                <label htmlFor="state">State</label>
                <select id="state" className={`form-control ${errors.state ? 'is-invalid' : ''}`} {...register('state', { required: "Field can not be empty", onChange: (e) => handleState(e)})}>
                    <option>select state</option>
                    {state.map((stateItem:{name: string}, index: Key)=><option key={index} value={JSON.stringify(stateItem)}>{stateItem.name}</option>)}
                </select>
                {errors.state && <small className='error'>{errors.state.message}</small>} 
            </div>

            <div className='form-group mt-3'>
                <label htmlFor="lga">L.G.A</label>
                <select id="lga" className={`form-control ${errors.localGovernment ? 'is-invalid' : ''}`} {...register('localGovernment',  { required: "Field can not be empty"})}>
                    <option>select L.G.A</option>
                    {selectLgas.map((stateItem:{name: string}, index: Key)=><option key={index}>{stateItem.name}</option>)}
                </select>
                {errors.localGovernment && <small className='error'>{errors.localGovernment.message}</small>} 
            </div> 


            {/* <div className="form-group mt-3">
                <label htmlFor="engineNumber">Vehicle Engine Number</label>
                <input type="text" {...register("engineNumber")} id="engineNumber" className='form-control'/> 
                {errors.engineNumber && <small className='error'>{errors.engineNumber.message}</small>} 
            </div>
            
            <div className="form-group mt-3">
                <label htmlFor="chasisNumber">Vehicle Chasis Number</label>
                <input type="text" {...register("chasisNumber")} id="chasisNumber" className='form-control'/> 
                {errors.chasisNumber && <small className='error'>{errors.chasisNumber.message}</small>} 
            </div>
            
            <div className="form-group mt-3">
                <label htmlFor="color">Vehicle Color</label>
                <select {...register("color")} id="color" className='form-control'>
                    <option value="">select color</option>
                    <option value="red">Red</option>
                    <option value="black">Black</option>
                    <option value="yellow">Yellow</option>
                </select>
                {errors.color && <small className='error'>{errors.color.message}</small>} 
            </div>   */}
        
            <div className="form-group row">
                <div className="my-auto"> 
                    <button type="submit" className={`btn btn-primary w-100 ${confirmVeh}`}>Confirm vehicle {request && <span className='spinner-border spinner-border-sm'></span>}</button> 
                </div>
            </div>
        </form>
    )
}

export default NewOwnersInformationForm;