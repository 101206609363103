import React from 'react'
import "./Pricing.scss"
import { RegExpressNavbar } from 'Layout'
import { JoinKitaniz } from '../../Components'
// import { vehicleService } from 'Services'
// import { vehicleServiceType } from 'Models'
// import { useAuth } from 'Components/AuthProvider/AuthProvider'
 

const Pricing = () => {

    // const limit = 100;
    // const offset = 0;

    // const { user } = useAuth()
    
    // const [serviceList, setServiceList] = useState<Array<vehicleServiceType>>()
    // const [request, setRequest] = useState(false)
    
    // const getList = async () => {
    //     setRequest(true)
    //    await vehicleService.serviceList(limit, offset).then((response:{ data: Array<vehicleServiceType>})=>{
    //       console.log("service response", response.data)
    //       setServiceList(response.data)  
    //       setRequest(false)
    //    }, error =>{
    //     console.log("serviceList error", error.response)
    //     setRequest(false) 
    //    })
    // }

    // useEffect(() => {
    //     getList()
    // }, [])
    
    return (
        <div className='pricing'> 
            <RegExpressNavbar /> 

            <div className="pricing-header">
                <div className="fill-hero container-fluid p-0">
                    <div className="fill-AboutUsHero w-100 h-100 ">
                        <div className="container h-100 d-flex align-items-center justify-content-center">
                            <div className="about-us-texts-container text-center">
                                <div className="about-us-hero-title mb-4 ">
                                PRICING
                                </div>
                                
                                <div className="about-us-hero-text">
                                    Simple and transparent pricing for all your vehicle needs
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="price-list">
                <div className="container">
                    <div className="row">
                    {/*{serviceList && serviceList.map((service:vehicleServiceType, index:Key)=> {
                        return <div key={index} className="col-12 col-sm-6 col-md-6 col-lg-4">
                            <div className="card px-3">
                                <div className="card-header">
                                    {service.name}
                                </div>
                                <div className="card-body p-0 pt-4">
                                    <div className="form-input">
                                        <label htmlFor="vehicle_class" className='title'>Select Vehicle Class</label>
                                        <select id='vehicle_class' className='form-control'>
                                            <option>Saloon</option>
                                        </select>
                                    </div>
                                    
                                    <div className="documents my-3 mt-4">
                                        <div className="title">
                                        Select Documents
                                        </div>
                                        <div className="form-input-2 my-auto d-flex align-items-center"> 
                                            <input type="checkbox" className='me-2' />
                                            <label htmlFor="">Vehicle License</label>
                                        </div> 
                                        <div className="form-input-2 my-auto d-flex align-items-center mt-3">
                                            <input type="checkbox" className='me-2' />
                                            <label htmlFor="">Road worthiness Cert.</label>
                                        </div> 
                                        <div className="form-input-2 my-auto d-flex align-items-center mt-3">
                                            <input type="checkbox" className='me-2' />
                                            <label htmlFor="">Plate Number</label>
                                        </div> 
                                        <div className="form-input-2 my-auto d-flex align-items-center mt-3">
                                            <input type="checkbox" className='me-2' />
                                            <label htmlFor="">Allocation paper</label>
                                        </div> 
                                        <div className="form-input-2 my-auto d-flex align-items-center mt-3">
                                            <input type="checkbox" className='me-2' />
                                            <label htmlFor="">Proof of ownership Cert.</label>
                                        </div> 
                                        <div className="form-input-2 my-auto d-flex align-items-center mt-3">
                                            <input type="checkbox" className='me-2' />
                                            <label htmlFor="">Insurance </label>
                                        </div> 

                                        <div className="form-input-2 my-auto d-flex align-items-center mt-3">
                                            <input type="checkbox" className='me-2' />
                                            <label htmlFor="">VIN </label>
                                        </div>
                                        <div className="form-input-2 my-auto d-flex align-items-center mt-3">
                                            <input type="checkbox" className='me-2' />
                                            <label htmlFor="">CMR </label>
                                        </div> 
                                    </div>
                                </div>

                                <div className="card-footer bg-white pb-5"> 
                                    <div className="amount">
                                       ₦{CurrencyFormatter(Number(service.amount))}
                                    </div>
                                    <div className="text-center mt-4">
                                        <Link to={user? `/myaccount/${service.path}/${service.id}` : `/registration-service/${service.id}`}> 
                                             <button className="btn">Start New Registration</button>
                                       </Link>
                                    </div>
                                </div>
                            </div>
                        </div>})} */}
                        
                        <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                            <div className="card px-3">
                                <div className="card-header">
                                    New Registration
                                </div>
                                <div className="card-body p-0 pt-4">
                                    <div className="form-input">
                                        <label htmlFor="vehicle_class" className='title'>Select Vehicle Class</label>
                                        <select id='vehicle_class' className='form-control'>
                                            <option>Saloon</option>
                                        </select>
                                    </div>
                                    
                                    <div className="documents my-3 mt-4">
                                        <div className="title">
                                        Select Documents
                                        </div>
                                        <div className="form-input-2 my-auto d-flex align-items-center">
                                            <input type="checkbox" className='me-2' />
                                            <label htmlFor="">Vehicle License</label>
                                        </div> 
                                        <div className="form-input-2 my-auto d-flex align-items-center mt-3">
                                            <input type="checkbox" className='me-2' />
                                            <label htmlFor="">Road worthiness Cert.</label>
                                        </div> 
                                        <div className="form-input-2 my-auto d-flex align-items-center mt-3">
                                            <input type="checkbox" className='me-2' />
                                            <label htmlFor="">Plate Number</label>
                                        </div> 
                                        <div className="form-input-2 my-auto d-flex align-items-center mt-3">
                                            <input type="checkbox" className='me-2' />
                                            <label htmlFor="">Allocation paper</label>
                                        </div> 
                                        <div className="form-input-2 my-auto d-flex align-items-center mt-3">
                                            <input type="checkbox" className='me-2' />
                                            <label htmlFor="">Proof of ownership Cert.</label>
                                        </div> 
                                        <div className="form-input-2 my-auto d-flex align-items-center mt-3">
                                            <input type="checkbox" className='me-2' />
                                            <label htmlFor="">Insurance </label>
                                        </div> 

                                        <div className="form-input-2 my-auto d-flex align-items-center mt-3">
                                            <input type="checkbox" className='me-2' />
                                            <label htmlFor="">VIN </label>
                                        </div>
                                        <div className="form-input-2 my-auto d-flex align-items-center mt-3">
                                            <input type="checkbox" className='me-2' />
                                            <label htmlFor="">CMR </label>
                                        </div> 
                                    </div>
                                </div>

                                <div className="card-footer bg-white pb-5"> 
                                    <div className="amount">
                                       ₦25,000.00
                                    </div>
                                    <div className="text-center mt-4">
                                       <button className="btn">Start New Registration</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                            <div className="card px-3">
                                <div className="card-header">
                                    Renew Registration
                                </div>
                                <div className="card-body p-0 pt-4">
                                    <div className="form-input">
                                        <label htmlFor="vehicle_class" className='title'>Select Vehicle Class</label>
                                        <select id='vehicle_class' className='form-control'>
                                            <option>Saloon</option>
                                        </select>
                                    </div>
                                    
                                    <div className="documents my-3 mt-4">
                                        <div className="title">
                                        Select Documents
                                        </div>
                                        <div className="form-input-2 my-auto d-flex align-items-center">
                                            <input type="checkbox" className='me-2' />
                                            <label htmlFor="">Vehicle License</label>
                                        </div> 
                                        <div className="form-input-2 my-auto d-flex align-items-center mt-3">
                                            <input type="checkbox" className='me-2' />
                                            <label htmlFor="">Insurance</label>
                                        </div> 
                                        <div className="form-input-2 my-auto d-flex align-items-center mt-3">
                                            <input type="checkbox" className='me-2' />
                                            <label htmlFor="">Road Worthiness</label>
                                        </div>
                                        <div className="form-input-2 my-auto d-flex align-items-center mt-3">
                                            <input type="checkbox" className='me-2' />
                                            <label htmlFor="">CMR </label>
                                        </div> 
                                    </div>
                                </div>

                                <div className="card-footer bg-white pb-5"> 
                                    <div className="amount">
                                       ₦25,000.00
                                    </div>
                                    <div className="text-center mt-4">
                                       <button className="btn">Start New Registration</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                            <div className="card px-3">
                                <div className="card-header">
                                   Change of Ownership
                                </div>
                                <div className="card-body p-0 pt-4">
                                    <div className="form-input">
                                        <label htmlFor="vehicle_class" className='title'>Select Vehicle Class</label>
                                        <select id='vehicle_class' className='form-control'>
                                            <option>Saloon</option>
                                        </select>
                                    </div>
                                    
                                    <div className="documents my-3 mt-4">
                                        <div className="title">
                                        Select Documents
                                        </div>
                                        <div className="form-input-2 my-auto d-flex align-items-center">
                                            <input type="checkbox" className='me-2' />
                                            <label htmlFor="">Vehicle License</label>
                                        </div> 
                                        <div className="form-input-2 my-auto d-flex align-items-center mt-3">
                                            <input type="checkbox" className='me-2' />
                                            <label htmlFor="">Insurance</label>
                                        </div> 
                                        <div className="form-input-2 my-auto d-flex align-items-center mt-3">
                                            <input type="checkbox" className='me-2' />
                                            <label htmlFor="">Change of ownership Cert.</label>
                                        </div> 
                                        <div className="form-input-2 my-auto d-flex align-items-center mt-3">
                                            <input type="checkbox" className='me-2' />
                                            <label htmlFor="">Proof of ownership Cert.</label>
                                        </div> 
                                        <div className="form-input-2 my-auto d-flex align-items-center mt-3">
                                            <input type="checkbox" className='me-2' />
                                            <label htmlFor="">Allocation paper</label>
                                        </div> 
                                        <div className="form-input-2 my-auto d-flex align-items-center mt-3">
                                            <input type="checkbox" className='me-2' />
                                            <label htmlFor="">Referral slip for road worthiness </label>
                                        </div> 
 
                                        <div className="form-input-2 my-auto d-flex align-items-center mt-3">
                                            <input type="checkbox" className='me-2' />
                                            <label htmlFor="">CMR </label>
                                        </div> 
                                    </div>
                                </div>

                                <div className="card-footer bg-white pb-5"> 
                                    <div className="amount">
                                       ₦25,000.00
                                    </div>
                                    <div className="text-center mt-4">
                                       <button className="btn">Start New Registration</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <JoinKitaniz />
        </div>
    )
}

export default Pricing
