import React from 'react'
import './Settings.scss'
import { NavLink, Outlet } from "react-router-dom";

const Settings = () => {
    return ( 
        <div className="Settings">
            <div className="card border-0">
                <div className="card-header bg-white">
                        <NavLink className={({ isActive }) => isActive ? "nav-link active h-100" : "nav-link h-100"} to={'/myaccount/settings/profile'}>Profile</NavLink> 
                        <NavLink className={({ isActive }) => isActive ? "nav-link active h-100" : "nav-link h-100"} to={'/myaccount/settings/address'}>Address</NavLink> 
                        <NavLink className={({ isActive }) => isActive ? "nav-link active h-100" : "nav-link h-100"} to={'/myaccount/settings/password'}>Password</NavLink>
                </div>
                <div className="card-body">
                    <Outlet />
                </div>
            </div>
        </div>
     );
}
 
export default Settings;