import { Link } from 'react-router-dom';
import './RegFAQ.scss';

const RegFAQ = () => {
    return (
        <div className="RegFAQ">
            <div className="faq pb-4">
                <div className="container">
                    <div className="card border-0 pb-4 pt-5">
                        <div className="card-header mb-2 text-center">
                             Frequently Asked Questions
                        </div>
                        <div className="card-header2 mb-4 mx-auto text-center">
                            We’ve provided answers to some questions
                        </div>
                        <div className="card-body">
                            <div className="accordion" id="accordionExample">
                                <div className="accordion-item mb-3 shadow-0" data-aos="fade-up">
                                    <h2 className="accordion-header border-0 shadow-0" id="headingOne">
                                    <button className="accordion-button collapsed border-0 shadow-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        What services does Kitaniz offer?
                                    </button>
                                    </h2>
                                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        ANS: We provide new vehicle document processing, renewal, ownership transfers, and order tracking.
                                    </div>
                                    </div>
                                </div>
                                <div className="accordion-item mb-3 shadow-0" data-aos="fade-up">
                                    <h2 className="accordion-header" id="headingTwo border-0 shadow-0">
                                    <button className="accordion-button collapsed border-0 shadow-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        How do I register on Kitaniz?
                                    </button>
                                    </h2>
                                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        ANS: Visit our website <a href="https://www.kitaniz.com" target='_blank'>www.kitaniz.com</a>, click "Sign Up," and complete the form with your details, and right away you are ready for a sweet experience                                    
                                    </div>
                                    </div>
                                </div>
                                <div className="accordion-item mb-3 shadow-0" data-aos="fade-up">
                                    <h2 className="accordion-header border-0 shadow-0" id="headingThree">
                                        <button className="accordion-button collapsed border-0 shadow-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        Can I order without registering?
                                        </button>
                                    </h2>
                                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        ANS: Yes, guest users can place orders and track them using their order ID. No one is left out.                                    
                                    </div>
                                    </div>
                                </div>
                                
                                <div className="accordion-item mb-3 shadow-0" data-aos="fade-up">
                                    <h2 className="accordion-header border-0 shadow-0" id="headingFour">
                                        <button className="accordion-button collapsed border-0 shadow-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                        How long does it take to have my documents processed and delivered?
                                        </button>
                                    </h2>
                                    <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        ANS: Once you place your order, processing starts immediately and soft copies are ready for download in less than a week after registration, while hard copies documents are delivered to you in the range of 1 to 2 weeks after registrations.                                    
                                    </div>
                                    </div>
                                </div>
                                
                                <div className="accordion-item mb-3 shadow-0" data-aos="fade-up">
                                    <h2 className="accordion-header border-0 shadow-0" id="heading5">
                                        <button className="accordion-button collapsed border-0 shadow-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
                                        What payment methods are accepted?
                                        </button>
                                    </h2>
                                    <div id="collapse5" className="accordion-collapse collapse" aria-labelledby="heading5" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        ANS:  We accept credit/debit cards, and bank transfers.                                    
                                    </div>
                                    </div>
                                </div>
                                
                                <div className="accordion-item mb-3 shadow-0" data-aos="fade-up">
                                    <h2 className="accordion-header border-0 shadow-0" id="heading6">
                                        <button className="accordion-button collapsed border-0 shadow-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
                                        How do I track my order status?
                                        </button>
                                    </h2>
                                    <div id="collapse6" className="accordion-collapse collapse" aria-labelledby="heading6" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            ANS: Click on “Track Order” on our homepage, input your order ID and view real-time updates on your order status.                                        
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item mb-3 shadow-0" data-aos="fade-up">
                                    <h2 className="accordion-header border-0 shadow-0" id="heading7">
                                        <button className="accordion-button collapsed border-0 shadow-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapse7" aria-expanded="false" aria-controls="collapse7">
                                        How do I contact support?
                                        </button>
                                    </h2>
                                    <div id="collapse7" className="accordion-collapse collapse" aria-labelledby="heading7" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            ANS: Reach us via email, chat, or call from the "Contact Us" section on our website.
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item mb-3 shadow-0" data-aos="fade-up">
                                    <h2 className="accordion-header border-0 shadow-0" id="heading8">
                                        <button className="accordion-button collapsed border-0 shadow-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapse8" aria-expanded="false" aria-controls="collapse8">
                                        Can I edit my order after submission?
                                        </button>
                                    </h2>
                                    <div id="collapse8" className="accordion-collapse collapse" aria-labelledby="heading8" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                        ANS: Yes, but only before processing starts. Contact support for assistance.
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item mb-3 shadow-0" data-aos="fade-up">
                                    <h2 className="accordion-header border-0 shadow-0" id="heading9">
                                        <button className="accordion-button collapsed border-0 shadow-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapse9" aria-expanded="false" aria-controls="collapse9">
                                        Do I need to come to Kitaniz office to get my papers or it will be delivered to me?
                                        </button>
                                    </h2>
                                    <div id="collapse9" className="accordion-collapse collapse" aria-labelledby="heading9" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                        ANS: We deliver at your doorstep. We meet you wherever you choose within Lagos.
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item mb-3 shadow-0" data-aos="fade-up">
                                    <h2 className="accordion-header border-0 shadow-0" id="heading10">
                                        <button className="accordion-button collapsed border-0 shadow-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapse10" aria-expanded="false" aria-controls="collapse10">
                                        How are delivery fees determined?
                                        </button>
                                    </h2>
                                    <div id="collapse10" className="accordion-collapse collapse" aria-labelledby="heading10" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                        ANS: Fees vary based on delivery type (standard or express).
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="w-100 text-center d-flex justify-content-center mt-5">
                                <Link to="/frequently-asked-questions">
                                    <button className='btn btn-faq'>
                                        See all FAQS
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RegFAQ;