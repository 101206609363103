import React, { FC } from 'react'
import { Link } from 'react-router-dom'

interface propsType {
    module?: string
}

const NewCustomPlateNumberForm:FC<propsType> = ({ module }) => {
 
    return (
        <form action="">
            <div className="form-group mt-0">
                <label htmlFor="">New Place Number</label>
                <select name="" id="" className='form-control mt-2'>
                    <option value="">5317-32FC</option>
                </select>
            </div>
            
            <div className="form-group mt-0 mt-3">
                <label htmlFor="">Old Place Number</label>
                <select name="" id="" className='form-control mt-2'>
                    <option value="">5317-32FC</option>
                </select>
            </div>
            
            
            <div className="form-group mt-5"> 
                <Link to={module && module === 'user'? '/myaccount/checkout': '/checkout'}>
                    <button className='btn btn-primary' type='button'>Checkout</button>
                </Link>
            </div> 
        </form>
    )
}

export default NewCustomPlateNumberForm
