import React, { useEffect } from 'react'
import { Outlet } from 'react-router'
import { FooterLarge, Header } from '../../../Layout' 
import { useLocation, useNavigate } from 'react-router-dom'; 
import { getAuth } from 'firebase/auth';
import { useAuth } from 'Components/AuthProvider/AuthProvider';
import { GuestUserMiddleware } from 'Components';
import AOS from 'aos';
import 'aos/dist/aos.css';


const Wrapper = () => {
 
  const navigate = useNavigate();
  const location = useLocation();

  const auth = getAuth();
  const { user:authUser, logout } = useAuth();


  const checkAuth = async () => {
    if(authUser){ 
        auth.onAuthStateChanged(async (user) => {
            if (user) {
                const tokenResult = await user.getIdTokenResult();
                const isExpired = new Date(tokenResult.expirationTime) < new Date();

                // alert(`${new Date(tokenResult.expirationTime)} < ${new Date()}`);

                if (isExpired) { 

                    alert("Session expired")
                    logout()
                    // Token is expired, redirect to login
                    navigate('/login', { replace: true });
                }
            }  
        });
    }
  };

  useEffect(() => {  
    checkAuth();
  }, [auth, navigate]);
  
 
const OutletMiddleWare = GuestUserMiddleware(Outlet);


    useEffect(() => { 
       AOS.init(); 
    }, []);


    return (
        <div> 
            {["/", "/pricing", '/tracking'].includes(location.pathname) ===false  && <Header></Header>}
            <OutletMiddleWare></OutletMiddleWare>
            <FooterLarge></FooterLarge>
        </div> 
    )
}

export default Wrapper
