import { vehicleRegistrationService } from 'Services/vehicleRegistrationService'
import React, { FC, Fragment, useEffect, useState } from 'react' 
import { useNavigate, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import PaymentSkeleton from './PaymentSkeleton'
import { orderDataType, ServiceOrderDataType } from 'Models' 
import { TimeConverter } from 'Helpers'
import { CurrencyFormatter } from '../../../../Helpers/CurrencyFormatter';
import { insuranceService } from 'Services/insuranceService'
import './PaymentSuccessfulCard.scss'
import { orderService } from '../../../../Services'

interface propsType {
    module?: string
}

const PaymentSuccessfulCard:FC<propsType> = ({ module }) => { 


    const navigate = useNavigate();

    // const { type, orderId } = useParams<{ type: string, orderId:string }>()
    const { orderId } = useParams<{ orderId:string }>()
    const limit = 100;
    const offset = 0;

    const [request, setRequest] = useState(false)
    const [orderData, setOrderData] = useState<ServiceOrderDataType>()

    const getVehicleRegistration = async () =>{ 
        setRequest(true)
        await orderService.getServiceOrderId(orderId).then((response)=>{
            console.log(response)
            setRequest(false)
            setOrderData(response.data.data)
            console.log("vehicleRegistrationService by id response", response)
        }, error => {
            setRequest(false)
            console.log("error make", error)
            Swal.fire({
                position: "top-end",
                icon: "warning",
                text: error.response.data.message,
                showConfirmButton: false,
                timer: 3000
            });
        })
    }

    const goToTrackOrder = () => {
        navigate({
            pathname: '/tracking',
            search: `?code=${orderData?.orderReferenceCode}`,
        });
    }

    // const getVehicleRegistration = async () =>{ 
    //     setRequest(true)
    //     await vehicleRegistrationService.getVehicleRegistrationById(orderId, limit, offset).then((response)=>{
    //         setRequest(false)
    //         setOrderData(response.data[0])
    //         console.log("vehicleRegistrationService by id response", response)
    //     }, error => {
    //         setRequest(false)
    //         console.log("error make", error)
    //         Swal.fire({
    //             position: "top-end",
    //             icon: "warning",
    //             text: error.response.data.message,
    //             showConfirmButton: false,
    //             timer: 3000
    //         });
    //     })
    // }

    const getRenewVehicleRegistration = async () =>{ 
        setRequest(true)
        await vehicleRegistrationService.getRenewalVehicleRegistrationById(orderId, limit, offset).then((response)=>{
            setRequest(false)
            setOrderData(response.data[0])
            console.log("vehicleRegistrationService by id response", response)
        }, error => {
            setRequest(false)
            console.log("error make", error)
            Swal.fire({
                position: "top-end",
                icon: "warning",
                text: error.response.data.message,
                showConfirmButton: false,
                timer: 3000
            });
        })
    }

    const getCOORegistration = async () =>{ 
        setRequest(true)
        await vehicleRegistrationService.getCooRegistrationById(orderId, limit, offset).then((response)=>{
            setRequest(false)
            setOrderData(response.data[0])
            console.log("vehicleRegistrationService by id response", response)
        }, error => {
            setRequest(false)
            console.log("error make", error)
            Swal.fire({
                position: "top-end",
                icon: "warning",
                text: error.response.data.message,
                showConfirmButton: false,
                timer: 3000
            });
        })
    }
    const getDealershipRegistration = async () =>{ 
        setRequest(true)
        await vehicleRegistrationService.getDealershipRegistrationById(orderId, limit, offset).then((response)=>{
            setRequest(false)
            setOrderData(response.data[0])
            console.log("vehicleRegistrationService by id response", response)
        }, error => {
            setRequest(false)
            console.log("error make", error)
            Swal.fire({
                position: "top-end",
                icon: "warning",
                text: error.response.data.message,
                showConfirmButton: false,
                timer: 3000
            });
        })
    }

    const getDriversLicenseRegistration = async () =>{ 
        setRequest(true)
        await vehicleRegistrationService.getDriversRegistrationById(orderId, limit, offset).then((response)=>{
            setRequest(false)
            setOrderData(response.data[0])
            console.log("vehicleRegistrationService by id response", response)
        }, error => {
            setRequest(false)
            console.log("error make", error)
            Swal.fire({
                position: "top-end",
                icon: "warning",
                text: error.response.data.message,
                showConfirmButton: false,
                timer: 3000
            });
        })
    }

    const getCustomizePlateRegistration = async () =>{ 
        setRequest(true)
        await vehicleRegistrationService.getCustomizePlateNumberById(orderId, limit, offset).then((response)=>{
            setRequest(false)
            setOrderData(response.data[0])
            console.log("vehicleRegistrationService by id response", response)
        }, error => {
            setRequest(false)
            console.log("error make", error)
            Swal.fire({
                position: "top-end",
                icon: "warning",
                text: error.response.data.message,
                showConfirmButton: false,
                timer: 3000
            });
        })
    }



    const getDriverLienseRenewal = async () =>{ 
        setRequest(true)
        await vehicleRegistrationService.getDriversLicenseRenewalById(orderId, limit, offset).then((response)=>{
            setRequest(false)
            setOrderData(response.data[0])
            console.log("vehicleRegistrationService by id response", response)
        }, error => {
            setRequest(false)
            console.log("error make", error)
            Swal.fire({
                position: "top-end",
                icon: "warning",
                text: error.response.data.message,
                showConfirmButton: false,
                timer: 3000
            });
        })
    }



    const getThirdPartyInsurance = async () =>{ 
        setRequest(true)
        await insuranceService.getThirdPartyInsuranceById(orderId, limit, offset).then((response)=>{
            setRequest(false)
            setOrderData(response.data[0])
            console.log("vehicleRegistrationService by id response", response)
        }, error => {
            setRequest(false)
            console.log("error make", error)
            Swal.fire({
                position: "top-end",
                icon: "warning",
                text: error.response.data.message,
                showConfirmButton: false,
                timer: 3000
            });
        })
    }



    const getComprehensiveInsurance = async () =>{ 
        setRequest(true)
        await insuranceService.getComprehensiveInsuranceById(orderId, limit, offset).then((response)=>{
            setRequest(false)
            setOrderData(response.data[0])
            console.log("vehicleRegistrationService by id response", response)
        }, error => {
            setRequest(false)
            console.log("error make", error)
            Swal.fire({
                position: "top-end",
                icon: "warning",
                text: error.response.data.message,
                showConfirmButton: false,
                timer: 3000
            });
        })
    }
    
    
    // useEffect(() => {
       
    //     const fetchRegistration = () => {
    //         switch (type) {
    //             case "new-registration":
    //                 getVehicleRegistration();
    //                 break;
    //             case "renew-registration":
    //                 getRenewVehicleRegistration();
    //                 break;
    //             case "change-of-ownership":
    //                 getCOORegistration();
    //                 break;
    //             case "dealers-plate-number":  
    //                 getDealershipRegistration();
    //                 break;
    //             case "drivers-license-registration":  
    //                 getDriversLicenseRegistration();
    //                 break;
    //             case "customize-plate-number":  
    //                 getCustomizePlateRegistration();
    //                 break; 
    //             case "drivers-license-renewal":  
    //                 getDriverLienseRenewal();
    //                 break; 
    //             case "insurance_third-party-insurance":  
    //                 getThirdPartyInsurance();
    //                 break;  
    //             case "insurance_comprehensive-insurance":  
    //                 getComprehensiveInsurance();
    //                 break;  
    //             default: 
    //                 break;
    //         }
    //     };
     
    //     fetchRegistration();
    // }, [type, orderId]);

    useEffect(() => {
        getVehicleRegistration()
    },  [])
    

    return ( 
        <Fragment>
            {request && <PaymentSkeleton></PaymentSkeleton>}
            {!request && <div className="card border-0 payment-successful-card-container">
            <div className="card-body mt-4">
                <div className="payment-head">
                    <svg width="78" height="70" viewBox="0 0 90 89" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="44.9985" cy="44.4438" r="44.4438" fill="#38C172"/>
                        <path d="M29.7266 46.4944L38.1802 54.6994L60.7233 32.8193" stroke="white" strokeWidth="4.23077" strokeLinecap="round" stroke-linejoin="round"/>
                    </svg> 
                    <div className="payment-title">
                        Thanks for your payment
                    </div>

                    <div className="payment-desc">
                        Your order was completed successfully
                    </div>

                    <div className="payment-total">
                        ₦{CurrencyFormatter(Number(orderData?.totalAmount))}
                    </div> 
                </div>

                <div className="details border-top mt-4">
                    <div className="d-flex justify-content-between align-items-center mt-3">
                        <p className="text-14px mt-3">Tracking Order ID: {orderData?.orderReferenceCode}</p>
                        <div>
                            <button className="primary-button" onClick={goToTrackOrder}>Track Order</button>
                        </div>
                    </div>
                    {/* <div className="container">
                        <div className="row">
                            <div className="col-6">
                                <div className="label">Order No</div>
                            </div>
                            <div className="col-6">
                                <div className="value">#{orderData?.orderNo}</div>
                            </div>


                            <div className="col-6">
                                <div className="label">Payment Status</div>
                            </div>
                            <div className="col-6">
                                <div className="value text-success">Completed</div>
                            </div>

                            
                            <div className="col-6">
                                <div className="label">Time</div>
                            </div>
                            <div className="col-6">
                                <div className="value">{TimeConverter(orderData?.time)}</div>
                            </div>

                        </div>
                    </div> */}
                </div>
                
                <div className="payment-bottom">
                    <p className="text-14px text-center">
                        <span className="text-6A80A3">We have sent detailed information about the order confirmation to </span>
                        <br/>{orderData?.email}
                    </p>
                    {/* <div className="container-fluid">
                        <div className="row">
                            <div className="col-6">
                                <button className="btn btn-share-receipt">Share Receipt</button>
                            </div>
                            <div className="col-6">
                                <button className="btn btn-view-order">View Order</button>
                            </div>
                        </div>
                    </div> */}

                </div>
            </div>
        </div> }
        </Fragment> 
    )
}

export default PaymentSuccessfulCard
