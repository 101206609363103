import React, { FC, useEffect, useState } from 'react'
import "./RenewExistingVehicleOption.scss"
import { Link, useNavigate, useParams } from 'react-router-dom'
import ImageSrc from '../../../../../Components/ImageSrc/ImageSrc';
import { vehicleRegistrationService } from 'Services/vehicleRegistrationService';
import { useAuth } from 'Components/AuthProvider/AuthProvider';
import Swal from 'sweetalert2';
import { orderDataType } from 'Models'; 
import { useForm } from 'react-hook-form';
import { vehicleService } from 'Services';
import { useSelector } from 'react-redux';
 
interface propsType {
    module?: string | undefined
}

const RenewExistingVehicleOption:FC<propsType> = ({ module }) => {
    const limit = 100;
    const offset = 0;

    const { service_id } = useParams<{ service_id: string }>()
    const { user } = useAuth()
    const navigate = useNavigate()

    const  vehicleInformationStore = useSelector((state: {vehicleInformation: {vehicleMakeId: string, vehicleModelId: string, type: string, year: string }})=> state.vehicleInformation)
   
    console.log("vehicleInformationStore 009000", vehicleInformationStore);

    const [request, setRequest] = useState(false)
    const [requestNext, setRequestNext] = useState(false)
    const [orderData, setOrderData] = useState<orderDataType[]>()

    
    const getVehicleRegistration = async () =>{  
        setRequest(true)
        await vehicleRegistrationService.getVehicleRegistration(user.userProfile.id, limit, offset).then((response)=>{
            setRequest(false)
            console.log("vehicleRegistrationService response 00", response.data)
            setOrderData(response.data)
        }, error => {
            setRequest(false)
            console.log("error make", error)
            Swal.fire({
                position: "top-end",
                icon: "warning",
                text: error.response.data.message,
                showConfirmButton: false,
                timer: 3000
            });
        })

        !orderData && setTimeout(() => {
            navigate('/myaccount/registration/renew-registration/${service_id}/registration-summary', { replace: true })
        }, 3000) 

    }

    useEffect(() => {
        getVehicleRegistration()
    }, [])

    const { register, watch, handleSubmit } = useForm()
    
    const selectedVehicle = watch('vehicle')

    const submit = handleSubmit(async (formData) => {
        setRequestNext(true)
      
        try {
            const vehicleInformation = await (await vehicleService.getVehicle(formData.vehicle, limit, offset)).data[0];
            const newVehicleInfo = await (await vehicleRegistrationService.getNewVehicleRegistrationByVehicleInfoId(vehicleInformation.id, limit, offset)).data[0];
            console.log("vehicleInformation 009", vehicleInformation);
            console.log("getNewVehicleRegistrationByVehicleInfoId 010", newVehicleInfo);
        
            localStorage.setItem("kit_new_reg", JSON.stringify({  ...vehicleInformationStore, ...vehicleInformation, vehicleInformationId: vehicleInformation.id, ...newVehicleInfo.holderInformation }));
            
            setRequestNext(false);
            
            navigate(module && module === 'user' ? `/myaccount/registration/renew-registration/${service_id}/registration-summary` : "/renew-registration/registration-summary", { replace: true });
        } catch (error) {
            setRequestNext(false);
            console.log("error make", error); 

            let errorMessage = 'An error occurred';
            if (typeof error === 'string') {
                errorMessage = error;
            } else if (error instanceof Error && error.message) {
                errorMessage = error.message;
            }

            Swal.fire({
                position: "top-end",
                icon: "warning",
                text: errorMessage,
                showConfirmButton: false,
                timer: 3000
            });
        }
        
    })

    useEffect(() => {
        console.log("selectedVehicle", selectedVehicle)
    }, [selectedVehicle])





    return (
        <div className='RenewExistingVehicleOption'> 
            <div className="row justify-content-center mt-3"> 
                <div className="col-md-6">
                    <div className="card"> 
                        <div className="card-header border-0 fw-bolder pt-3">
                            Select the vehicle
                        </div>
                           <form onSubmit={submit}>
                            <div className="card-body">
                            {orderData && orderData.length > 0 && orderData.map((orderItem: orderDataType) => {
                                return <div className='card mb-3'>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-1 my-auto text-center">
                                                <input type="radio" id="vehicle" value={orderItem && orderItem.vehicleInformation.id} {...register('vehicle')}/>
                                            </div>
                                            <div className="col-11">
                                                <label htmlFor={`vehicle${orderItem.vehicleInformation.id}`}>
                                                    <div className="row"> 
                                                        <div className="col-1 p-0 my-auto">
                                                            <div className="border p-1 rounded">
                                                            <ImageSrc src={''} alt={''} title={undefined} width={'w-100'}></ImageSrc>
                                                            </div>
                                                        </div>
                                                        <div className="col-10">
                                                            <div className="title p-0 m-0">{orderItem.vehicleInformation.make.name} {orderItem.vehicleInformation.model.name} {orderItem.vehicleInformation.year}</div>
                                                            <div className="p-0 m-0">
                                                                <span className="sub-label">Engine No:</span>
                                                                <span className="sub-value"> {orderItem.vehicleInformation.engineNumber} </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </label>
                                            </div>
                                        </div> 
                                    </div> 
                                </div>
                                })}


                                    <div className='card'>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-1 text-center">
                                                    <input type="radio" {...register('vehicle')} value="new_registration" id="vehicle_reg "/>
                                                </div>
                                                <div className="col-11">
                                                    <label htmlFor="vehicle_reg">
                                                    I haven’t registered it on Kitaniz
                                                    </label>
                                                </div>
                                            </div> 
                                        </div> 
                                    </div> 
                            </div>
                            <div className="card-footer">
                                <div className="form-group pb-4"> 
                                    {selectedVehicle && selectedVehicle === 'new_registration' && <Link to={module && module==='user'? `/myaccount/registration/renew-registration/${service_id}/vehicle-particulars` : "/new-registration/vehicle-particulars"}>
                                        <button className='btn btn-primary' type='button'>Continue</button> 
                                    </Link>}

                                    {selectedVehicle !== 'new_registration' &&  
                                    <button className='btn btn-primary' type='submit' disabled={!selectedVehicle}>{requestNext && <span className='spinner-border spinner-border-sm'></span>} Continue</button> 
                                    }
                                </div>
                            </div> 
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RenewExistingVehicleOption
