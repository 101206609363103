import { Link } from 'react-router-dom';
import './RegDriversLicense.scss'


const RegDriversLicense = () => {
    return ( 
        <div className="RegDriversLicense">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-5 my-5">
                    <div className="card border-0">
                        <div className="card-header text-center border-0 pt-4"> 
                            <div className="title border-bottom text-start pb-3 fw-bold">
                                Renew Drivers License
                            </div>
                            <div className="tagline text-start pt-3 small">
                                Validity duration
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="btn-cont d-flex">

                                <div className="btn1-cont p-3 w-50">
                                    <button className="btn btn-selection rounded-pill py-2 px-1 w-100 fw-bold">3 Years</button>
                                </div>

                                <div className="btn2-cont p-3 w-50">
                                    <button className="btn btn-selection rounded-pill py-2 px-1 w-100 fw-bold">5 Years</button>
                                </div>

                            </div>
                        </div>
                        <div className="card-isol p-3 d-flex justify-content-between small">
                            <span className='small'>Processing time</span>
                            <span className='small'>4-6 Weeks</span>
                        </div>
                        
                        <div className="card-footer p-3 d-flex justify-content-between fw-bold border-0">
                            <span className='fw-bold'>Total</span>
                            <span className='fw-bold'>N160,000.00</span>
                        </div>
                        
                        <div className="form-group row">
                            <div className="my-auto p-3">
                                <Link to='/renew-drivers-license'>
                                    <button className='btn btn-primary form-control rounded-pill'>Continue</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default RegDriversLicense;