import React, { FC, Key, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import toyota from "Assets/Images/toyota.png"
import Swal from 'sweetalert2';
import { useSelector } from 'react-redux';
import { vehicleServiceDocument, vehicleServiceType } from 'Models/vehicleServiceType';
import { vehicleService } from 'Services';

interface propsType {
    module?: string | undefined
}
interface propsType {
    module?: string | undefined,
    vehicleService?: vehicleServiceType|undefined
}
 const RenewVehicleTypeFormPreview:FC<propsType> = ({ module, vehicleService: vehicleServiceItem }) => {
 
    const { service_id } = useParams<{ service_id: string }>()
    const [request, setRequest] = useState(false)
    const [serviceDocuments, setServiceDocuments] = useState<vehicleServiceDocument[]>()

    const  vehicleInformation = useSelector((state: {vehicleInformation: {vehicleInfo:{ make: { name: string },  model: { name:string }}, year: string, typeOfInsurance: string }})=> state.vehicleInformation)
 
    const getDocuments = async () => {
        setRequest(true)
       await vehicleService.serviceDocuments(service_id).then((response)=>{
        setRequest(false) 
         console.log("serviceDocuments", response.data)
         setServiceDocuments(response.data)
       }, error =>{
        setRequest(false)
        console.log("error make", error)
        Swal.fire({
            position: "top-end",
            icon: "warning",
            text: error.response.data.message,
            showConfirmButton: false,
            timer: 3000
        });
       })
    }

    useEffect(()=>{
        getDocuments() 
    }, [service_id])
    return (
        <div className="card document-details-card p-0"> 
            
            <div className="card-header border-0">
                <div className="row h-100">
                    <div className="col-2 text-center my-auto">
                        <div className="logo"> 
                        <img src={toyota} alt="" />
                        </div>
                    </div>
                    <div className="col-10 my-auto">
                    {vehicleInformation?.vehicleInfo?.make?.name} {vehicleInformation?.vehicleInfo?.model?.name}, {vehicleInformation?.year}
                    </div>
                </div>
            </div>
            <div className="card-body px-0">  
                <div className="docYouWillGet px-3"> 
                    <div className='tagline'>Documents you will get</div> 

                    <div className="row pb-4 pt-3">  
                        {request &&  <div className="col-12 h-100 bg-white text-center">
                            <span className='spinner-border spinner-border-md text-secondary'></span>
                            </div>}
                        {serviceDocuments && serviceDocuments.map((doc: vehicleServiceDocument, index:Key)=>{
                        return <div key={index} className="col-6">
                            <label className="form-input">{doc.document.name}
                                <input type="checkbox" checked={true}/>
                                <span className="checkmark"></span>
                            </label>
                        </div>})} 
                        {serviceDocuments && vehicleInformation.typeOfInsurance !== "none"  &&  <div className="col-6">
                            <label className="form-input">{vehicleInformation.typeOfInsurance}
                                <input type="checkbox" checked={true}/>
                                <span className="checkmark"></span>
                            </label>
                        </div>} 
                    </div>
                </div>
                <div className="processing-time px-3 pe-0">
                    <div className="row w-100 h-100"> 
                        <div className="col-6 my-auto">
                        Processing Time
                        </div>
                        <div className="col-6 my-auto text-end text-dark">
                        {vehicleServiceItem?.processingTime} Weeks
                        </div>
                        
                    </div> 
                </div>

                <div className="total-balance px-3"> 
                    <div className="row h-100"> 
                        <div className="col-6 my-auto">
                            Total
                        </div>
                        <div className="col-6 text-end my-auto">
                            ₦{vehicleServiceItem?.amount}
                        </div>
                    </div> 
                </div>
            </div>

            <div className="card-footer px-0">
                <div className="form-group"> 
                    <Link to={module && module==='user'? `/myaccount/registration/renew-registration/${service_id}/existing-vehicle` : "/new-registration/existing-vehicle"}>
                        <button className='btn btn-primary' type='button'>Continue</button> 
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default RenewVehicleTypeFormPreview
