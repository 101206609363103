 

import React, { useEffect, useState } from 'react'
import "./ChangeofOwnershipGuest.scss"
import { BreadCrumb } from 'Components' 
import { CoVehicleTypeForm, CoVehicleTypeFormPreview } from 'Pages/Frontend/Components'
import Swal from 'sweetalert2'
import { vehicleService } from 'Services'
import { useParams } from 'react-router-dom'
import { vehicleServiceType } from 'Models'

const ChangeofOwnershipGuest = () => {
    const [confirmVehicle, setConfirmVehicle] = useState(false)
  
    const limit = 100;
    const offset = 0;
    
    const { service_id } = useParams<{ service_id: string }>() 
    
    const [vehicleServices, setVehicleServices] = useState<vehicleServiceType>()
 
     const getServices = async () =>{
         await vehicleService.serviceItem(service_id, limit, offset).then((response) =>{
             console.log("response vehicleServices", response.data)
             setVehicleServices(response.data[0])
         }, error => {
 
             console.log("error vehicleServices", error)
             Swal.fire({
                 position: "top-end",
                 icon: "warning",
                 text: error.response.data.message,
                 showConfirmButton: false,
                 timer: 3000
             });
         })
     }
 
     useEffect(() => {
         getServices()
     }, [service_id])
     
    return (
        <div className='ChangeOfOwnership'>
            <div className="container">
                <BreadCrumb parentTitle={'Registration'} parentUrl={'/register-vehicle'} pageTitle={'Change of Ownership'}></BreadCrumb>
                <div className="row justify-content-center">
                    <div className={confirmVehicle? "col-md-5 mb-5 mt-4": "col-sm-12 col-md-7 col-lg-6 col-xl-5 mb-5 mt-4"}>
                       <div className="card border-0 py-3">
                        <div className="card-header text-left border-0 pt-3"> 
                            <div className="title">
                            What type of vehicle?
                            </div> 
                        </div>
                        <div className="card-body pt-2">
                           <CoVehicleTypeForm setConfirmVehicle={(val:boolean)=>setConfirmVehicle(val)}></CoVehicleTypeForm>
                        </div>
                       </div>
                    </div>

                    {confirmVehicle && <div className={confirmVehicle? "col-md-5 mb-5 mt-4": "col-sm-12 col-md-7 col-lg-6 col-xl-5 mb-5 mt-4"}>
                   
                       <CoVehicleTypeFormPreview  vehicleService={vehicleServices}></CoVehicleTypeFormPreview>
                    
                    </div>}
                </div>
             </div>
        </div>
    )
}

export default ChangeofOwnershipGuest












// import { Link } from 'react-router-dom';
// import './ChangeofOwnershipGuest.scss'

// const ChangeofOwnershipGuest = () => {
//     return ( 
//         <div className="ChangeofOwnership">
            
//             <div className="container">
//                 <div className="row justify-content-center">
//                     <div className="col-lg-5 my-5">
//                        <div className="card border-0">
//                         <div className="card-header text-start border-0 pt-4"> 
//                             <div className="title">
//                                 What type of vehicle?
//                             </div>
//                         </div>
//                         <div className="card-body">
//                             <form action="">
//                                 <div className="form-group">
//                                     <label htmlFor="">Make</label>
//                                     <select name="" id="" className='form-control'>
//                                         <option value="">select make</option>
//                                     </select>
//                                 </div>
//                                 <div className="form-group">
//                                     <label htmlFor="">Model</label>
//                                     <select name="" id="" className='form-control'>
//                                         <option value="">select model</option>
//                                     </select>
//                                 </div>
//                                 <div className="form-group">
//                                     <label htmlFor="">Year</label>
//                                     <select name="" id="" className='form-control'>
//                                         <option value="">select year</option>
//                                     </select>
//                                 </div>
//                                 <div className="form-group">
//                                     <label htmlFor="">Type</label>
//                                     <select name="" id="" className='form-control'>
//                                         <option value="">Commercial</option>
//                                     </select>
//                                 </div>

                                

//                                 {/* <div className="form-group">
//                                     <input type="checkbox" id="myCheckbox"></input>
//                                     <label htmlFor="myCheckbox" className="checkbox-label">Unaccidented</label>
//                                 </div>  */}
                                
//                                 <div className="form-group row">
//                                     <div className="col-12 my-auto">
//                                         <Link to='/what-type-of-vehicle'>
//                                             <button className='btn btn-primary w-100'>Confirm vehicle</button>
//                                         </Link>
//                                     </div>
//                                 </div>
//                             </form>
//                         </div>
//                        </div>
//                     </div>
                    
//                 </div>
//              </div>
//         </div>
//      );
// }
 
// export default ChangeofOwnershipGuest;