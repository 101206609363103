import React, { useState } from 'react'
import "./NavMenu.scss" 
import { Link } from 'react-router-dom'
import { useAuth } from '../../../Components/AuthProvider/AuthProvider'   
import { useSelector } from 'react-redux' 
import notification from 'Assets/Images/notification.svg'
import profile from 'Assets/Images/profile.png' 
import Dashboard  from 'Assets/Icons/dashboard.svg' 
import orders  from 'Assets/Icons/orders.svg' 
import quote  from 'Assets/Icons/quote-request.svg' 
import setting  from 'Assets/Icons/setting.svg' 
import transaction  from 'Assets/Icons/transaction.svg'  
import { CiLock } from "react-icons/ci";
import Swal from 'sweetalert2'
import { getAuth, signOut } from 'firebase/auth'

const NavMenu = () => {
  const [scroll, setScroll] = useState(false) 
 
  window.onscroll = function () {
    const currentScrollPos = window.pageYOffset;

    if (currentScrollPos > 200) {
      setScroll(true)
    } else {
      setScroll(false)
    }
  }
  const { user, logout } = useAuth()
  const auth = getAuth();

  const activeMenu = useSelector((state: { activeMenu: string })=> state.activeMenu);
  console.log(activeMenu)
   
  const logoutFunc = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will be logged out of your account!",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#186AFF",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, logout!"
    }).then((result) => {
      if (result.isConfirmed) {

        signOut(auth).then(() => { 
           logout()
        }).catch((error) => {
          console.log("error", error)
          alert("can not logout")
        });
      }
    });
  } 
  return (
    <nav className={scroll ? "navbar kit-navbar navbar-expand-lg" : "kit-navbar navbar navbar-expand-lg"}>
      <div className="container">
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
              <ul className="navbar-nav w-50 mb-2 mb-lg-0">
                  <li className="nav-item ms-0 ps-0"> 
                     <div id="pageTitle">{activeMenu}</div> 
                  </li>
              </ul>

              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">   

                  <li className="nav-item">
                    <span className='span-white'>
                      <img src={notification} alt="" />
                    </span>
                  </li> 

                   <li className="nav-item dropdown">
                      <Link className="nav-link dropdown-toggle nav-responsive" to="#" role="button" id='navbarDropdown' data-bs-toggle="dropdown" aria-haspopup='true' aria-expanded="false">
                          <span className='span-primary'>
                             <img src={profile} alt='' className="avarter"/> 
                          </span>
                      </Link>
                      {!user &&
                      <ul className="dropdown-menu dropdown-menu-end border-0 shadow" aria-labelledby='navbarDropdown'>
                            <li><Link className="dropdown-item" to="/login"> <img src={""} alt=''></img> Login</Link></li>
                            <li><Link className="dropdown-item" to="/register"> <img src={""} alt=''></img> Signup</Link></li>
                        </ul>}
                        {user && <ul className="dropdown-menu shadow-sm border-0 dropdown-menu-lg-end my-account-dropdown">
                                    {/* <li><Link className="dropdown-item" to="/myaccount/dashboard"><img src={Dashboard} alt=''></img> Dashboard</Link></li>
                                    <li><Link className="dropdown-item" to="/myaccount/orders/vehicle"><img src={orders} alt=''></img> Orders</Link></li>
                                    <li><Link className="dropdown-item" to="/myaccount/quote-request"><img src={quote} alt=''></img> Quote Request</Link></li>
                                    <li><Link className="dropdown-item" to="/myaccount/transactions"><img src={transaction} alt=''></img> Transactions</Link></li> */}
                                    <li>
                                        <Link className="dropdown-item" to="/myaccount/settings/profile"><img src={setting} alt=''></img> Settings</ Link>
                                    </li>
                                    <li>
                                        <Link className="dropdown-item" to="#" onClick={logoutFunc}> <CiLock  className='me-2'/> Logout</ Link>
                                     </li>
                                    
                                </ul>}
                  </li> 
              </ul>
          </div>
        </div> 
    </nav>
  )
}

export default NavMenu
