import { vehicleServiceType } from 'Models'
import { customizePlateService, vehicleService } from 'Services'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import { CurrencyFormatter } from 'Helpers';

interface propsType {
    module?: string
}
type formType = {
    plateNumber: string
}

const CustomizePlateNumberEntry:FC<propsType> = ({ module }) => { 

    const { service_id } = useParams<{service_id: string}>()

    const [confirmVehicle, setConfirmVehicle] = useState(false)
    const [errorMessage, setErrorMessage] = useState<string>()
     
    const navigate = useNavigate()

    const limit = 100;
    const offset = 0;
      
    const [vehicleServices, setVehicleServices] = useState<vehicleServiceType>()
    


    const { register, getValues, handleSubmit, formState: { errors } } = useForm<formType>()

    const submit = async (data: formType) =>{
        
        Swal.showLoading();
        await customizePlateService.checkAvailability(data.plateNumber).then((response)=>{ 
            Swal.close()
            setErrorMessage(`Sorry! ${data.plateNumber} is unavailable.`)
            console.log("customizePlateService response", response)
        }, error => {
            console.log("error", error)
            Swal.fire('Oops!', error.response.data.message, 'warning');  
            setConfirmVehicle(true)
            Swal.close()
        })
    }

    const handleChange = () =>{
        setConfirmVehicle(false)
    }

    const getServiceDetails = useCallback(async ()=>{
         
             await vehicleService.serviceItem(service_id, limit, offset).then((response) =>{
                 console.log("response vehicleServices", response.data)
                 setVehicleServices(response.data[0])
             }, error => {
     
                 console.log("error vehicleServices", error)
                 Swal.fire({
                     position: "top-end",
                     icon: "warning",
                     text: error.response.data.message,
                     showConfirmButton: false,
                     timer: 3000
                 });
             }) 
    }, [])

    useEffect(()=>{
        getServiceDetails()
    }, [service_id])


    const checkout = async () => {
        try {
            
            await localStorage.setItem("kit_new_reg",  JSON.stringify({plateNumber: getValues("plateNumber").toString()}));
        
            navigate(module && module === 'user' ? `/myaccount/checkout/customize-plate-number/${service_id}` : `/checkout/customize-plate-number/${service_id}`, { replace: true });
       
        } catch (error: unknown) {
            if (error instanceof DOMException && error.name === 'QuotaExceededError') {
                console.log("Local storage quota exceeded.");
                Swal.fire('Oops!', 'Local storage quota exceeded. Please free up some space.', 'warning');
            } else {
                console.log("Error:", error);
                Swal.fire('Oops!', 'An error occurred while storing data.', 'warning');
            } 
        }
    }
  
    return (
        <div className="row justify-content-center">
        <div className="col-md-6 mb-5 mt-4">
           <div className="card border-0 pt-2 pb-2 px-3">
            <div className="card-header text-left border-0 px-0"> 
                <div className="title">
                    Customize Plate Number
                </div> 
            </div>
            <div className="card-body pt-2 border-top px-0">
                <form onSubmit={handleSubmit(submit)}>
                    <div className="form-group mt-2 mb-3">
                        <label htmlFor="">Desired Plate Number</label>
                        <input type="text" {...register("plateNumber", { required: "field can not be empty", onChange: handleChange})} id="" className='form-control mt-1' placeholder='JOBS-0001'/>
                        {errors.plateNumber && <span className='error'>{errors.plateNumber.message}</span>}
                    </div> 

                    {errorMessage && <div className="text-center text-danger">{errorMessage}</div>}
                    {confirmVehicle && <div className='text-center text-success mt-3'>Congratulations! <b>{getValues("plateNumber")}</b> is available.</div>}
                    
                    {!confirmVehicle && <div className="form-group mt-5"> 
                        <button className='btn btn-primary' type='submit'>Check Availability</button>
                    </div> }
                </form>
            </div>
           </div>
        </div>

        {confirmVehicle && <div className="col-md-6 mb-5 mt-4">
            <div className="card document-details-card p-0"> 
                <div className="card-header border-0">
                    <div className="row h-100">
                        <div className="col-md-10 my-auto">
                             {getValues("plateNumber")}
                        </div>
                    </div>
                </div>
                <div className="card-body px-0">  
                    <div className="processing-time px-3">
                        <div className="row w-100 h-100"> 
                            <div className="col-md-6 my-auto">
                               Processing Time
                            </div>
                            <div className="col-md-6 my-auto text-end text-dark">
                               {vehicleServices?.processingTime} Weeks
                            </div>
                        </div> 
                    </div>

                    <div className="total-balance px-3 pt-3"> 
                        <div className="row h-100"> 
                            <div className="col-md-6 my-auto">
                                Total
                            </div>
                            <div className="col-md-6 text-end my-auto">
                                ₦ {CurrencyFormatter(Number(vehicleServices?.amount))}
                            </div>
                        </div> 
                    </div>
                </div>

                <div className="card-footer px-0 mt-2">
                    <div className="form-group"> 
                        {/* <Link to={module && module ==='user'? "/myaccount/registration/customize-plate-number/new-customized-plate-number": "/new-customized-plate-number"}>
                            <button className='btn btn-primary' type='button'>Checkout</button> 
                        </Link> */}
                         <button className='btn btn-primary' type='button' onClick={checkout}>Checkout</button>  
                    </div>
                </div>
            </div>
        
        </div>}
    </div>
    )
}

export default CustomizePlateNumberEntry
