import { vehicleService } from 'Services';
import { makeType } from 'Models';
import { insuranceProviderType } from 'Models/insuranceType';
import { modelType } from 'Models/modelType';
import { vehicleYearType } from 'Models/vehicleYeasrType';
import React, { FC, Key, useEffect, useState } from 'react' 
import Swal from 'sweetalert2';
import { insuranceService } from 'Services/insuranceService';
import { useDispatch } from 'react-redux';
import { VEHICLE_REG_STEP_ONE } from 'Constants/RegistrationConstant';
import { useForm } from 'react-hook-form';

interface propsType {
    setConfirmVehicle: Function
} 
 
type formType = {
    vehicleMakeId: string;
    vehicleModelId: string;
    year: string;
    type: string;
    typeOfInsurance: string;
    insuranceProvider: string
}

const CoVehicleTypeForm:FC<propsType> = ({setConfirmVehicle}) => {

    const limit = 100;
    const offset = 0;
 
    const [make, setMake] = useState<Array<makeType>>()
    const [model, setModel] = useState<Array<modelType>>()
    const [vehicleYear, setVehicleYear] = useState<Array<vehicleYearType>>()
    const [insuranceProvider, setInsuranceProvider] = useState<insuranceProviderType[]>()
    const [thirdpartyInsurancVeri, setThirdpartyInsurancVeri] = useState<string>('d-block')
    const [confirmVeh, setConfirmVeh] = useState<string>('d-block')
    
    const getMake = async () =>{
         await vehicleService.getMake( limit, offset).then((response) =>{
             console.log("response make", response.data)
             setMake(response.data)
         }, error => {
 
             console.log("error make", error)
             Swal.fire({
                 position: "top-end",
                 icon: "warning",
                 text: error.response.data.message,
                 showConfirmButton: false,
                 timer: 3000
             });
         })
     }
 
     const getYear = async () =>{
         await vehicleService.getVehicleYear( limit, offset).then((response) =>{
             console.log("response make", response.data)
             setVehicleYear(response.data)
         }, error => {
 
             console.log("error make", error)
             Swal.fire({
                 position: "top-end",
                 icon: "warning",
                 text: error.response.data.message,
                 showConfirmButton: false,
                 timer: 3000
             });
         })
     }
 
     const getModel = async (make_id:string) =>{
         await vehicleService.getModel(make_id, limit, offset).then((response) =>{
             console.log("response model", response.data)
             setModel(response.data)
            //  Swal.fire({
            //      position: "top-end",
            //      icon: "success", 
            //      showConfirmButton: false,
            //      timer: 1500
            //  });
         }, error => {
             Swal.fire({
                 position: "top-end",
                 icon: "warning",
                 text: error.response.data.message,
                 showConfirmButton: false,
                 timer: 3000
             });
             console.log("error model", error)
         })
     }
 
     const getInsurance = async () =>{
         await insuranceService.getProviders(limit, offset).then((response) =>{
             console.log("insuranceService", response.data)
             setInsuranceProvider(response.data) 
         }, error => {
             Swal.fire({
                 position: "top-end",
                 icon: "warning",
                 text: error.response.data.message,
                 showConfirmButton: false,
                 timer: 3000
             });
             console.log("error model", error)
         })
     }
 
     
 
     useEffect(() => {
         getMake() 
         getYear()
         getInsurance()
     }, [])
 
     const dispatch = useDispatch(); 
 
     const { register, handleSubmit, formState: { errors } } = useForm<formType>()
     
     const onSubmit = (data: formType) => {
       const vehicleInfo = getVehicleInfo(data);
       dispatch({ type: VEHICLE_REG_STEP_ONE, payload: {...data, vehicleInfo}});  
       setConfirmVeh('d-none')
       setConfirmVehicle(true)
     };
 
     const getVehicleInfo = (data: formType) => { 
 
         const makeResult = make?.find(({ id }) => id === data.vehicleMakeId) ?? null;
         const modelResult = model?.find(({ id }) => id === data.vehicleModelId) ?? null;
     
         return { model: modelResult, make: makeResult };
     };


     
    return ( 
        <form onSubmit={handleSubmit(onSubmit)} className='VehicleTypeForm'>
        <div className="form-group mt-0">
            <label htmlFor="vehicleMakeId">Make</label>
            <select id="vehicleMakeId" className='form-control' {...register("vehicleMakeId", { required: "field can not be empty", onChange: (e)=>getModel(e.target.value)})}>
                <option value="">select make</option> 
                {make && make.map((mk: makeType, index:Key)=>{
                   return <option key={index} value={mk.id}>{mk.name}</option>
                })}
            </select>
            {errors.vehicleMakeId && <small className='error'>{errors.vehicleMakeId.message}</small>} 
        </div>
        <div className="form-group">
            <label htmlFor="vehicleModelId">Model</label>
            <select id="vehicleModelId" className='form-control' {...register("vehicleModelId", { required: "field can not be empty" })}>
                <option value="">select model</option> 
                {model && model.map((mod: modelType, index:Key)=>{
                   return <option key={index} value={mod.id}>{mod.name}</option>
                })}
            </select>
            {errors.vehicleModelId && <small className='error'>{errors.vehicleModelId.message}</small>} 
        </div>
        <div className="form-group">
            <label htmlFor="year">Year</label>
            <select id="year" className='form-control' {...register("year", { required: "field can not be empty" })}>
                <option value="">select year</option>
                {vehicleYear && vehicleYear.map((vy: vehicleYearType, index:Key)=>{
                   return <option key={index} value={vy.value}>{vy.value}</option>
                })}
            </select>
            {errors.year && <small className='error'>{errors.year.message}</small>} 
        </div> 
        <div className="form-group">
            <label htmlFor="type">Type</label>
            <select  {...register("type", { required: "field can not be empty" })} id="type" className='form-control'>
                <option value="">select type</option>
                <option value="private">Private</option>
                <option value="commercial">Commercial</option>
            </select>
            {errors.type && <small className='error'>{errors.type.message}</small>} 
        </div> 
        <div className="form-group">
            <label htmlFor="typeOfInsurance">Insurance you will get</label>
            <select  {...register("typeOfInsurance", { required: "field can not be empty"})} 
            onChange={(e) => {e.target.value === 'third-party-insurance' ? setThirdpartyInsurancVeri('d-none') : setThirdpartyInsurancVeri('d-block')}} 
            id="typeOfInsurance" className='form-control'>
                <option value="none">select type</option>
                <option value="third-party-insurance">Thirdparty Insurance</option> 
            </select>
            {errors.typeOfInsurance && <small className='error'>{errors.typeOfInsurance.message}</small>} 
        </div> 

        
        <div className={`form-group ${thirdpartyInsurancVeri}`}>
            <label htmlFor="insuranceProvider">Insurance Provider</label>
            <select  {...register("insuranceProvider", { required: "field can not be empty" })} id="insuranceProvider" className='form-control'>
                <option value="none">select provider</option>
                {insuranceProvider && insuranceProvider.map((isp: insuranceProviderType, index: Key)=><option key={index} value={JSON.stringify(isp)}>{isp.name}</option> )}
            </select>
            {errors.insuranceProvider && <small className='error'>{errors.insuranceProvider.message}</small>} 
        </div> 
        

        <div className="form-group"> 
            <button className={`btn btn-primary ${confirmVeh}`} type='submit'>Confirm vehicle</button>
        </div> 
    </form> 
    )
}

export default CoVehicleTypeForm
