import './Blog.scss'
import vehicle from '../../../../Assets/Images/blog_vehicle_card_bg.png'

const Blog = () => {
    return ( 
        <div className="Blog">
            
            <div className="header">
               <div className='text-center'>
                    <div className="title">BLOG</div>
                    <div className="description">Welcome to Kitaniz Blog</div>
                </div>
            </div>
            
            <div className="filter mt-5">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-8 col-md-6">
                            <div className="search">
                                <span className='d-icon d-search'></span>
                                <input type="text" className="form-control" placeholder='Search for a question here...'/>
                             </div>
                        </div>
                        <div className="col-4 col-md-2"> 
                            <button className="btn btn-filter rounded-pill" type="button">Search</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="main-blog mt-5">
                <div className="container">
                    <div className="row">

                        <div className="card col-md-6 mb-5 col-lg-4 bg-transparent border-0">
                            <div className="card-header bg-transparent">
                                <div className="img-container">
                                    <img src={vehicle} className='w-100 blog-card-img' alt="vehicle" />
                                </div>
                            </div>

                            <div className="card-body pb-0 mb-0">
                                <div className="card-text-title mb-3">Top 5 Cars for Small Business Owners in Nigeria</div>
                                <div className="card-text">My fellow business enthusiasts! Let’s dive into a topic that’s close to the hearts of small business owners—cars! We all know how crucial a car</div>
                            </div>

                            <div className="card-footer d-flex border-0 bg-transparent pt-0">
                                <div className="blog-card-footer-text me-3">29th December 2023</div>
                                <div className="blog-card-footer-text ms-3">No Comments</div>
                            </div>
                        </div>
                        
                        <div className="card col-md-6 mb-5 col-lg-4 bg-transparent border-0">
                            <div className="card-header bg-transparent">
                                <div className="img-container">
                                    <img src={vehicle} className='w-100 blog-card-img' alt="vehicle" />
                                </div>
                            </div>

                            <div className="card-body pb-0 mb-0">
                                <div className="card-text-title mb-3">Top 5 Cars for Small Business Owners in Nigeria</div>
                                <div className="card-text">My fellow business enthusiasts! Let’s dive into a topic that’s close to the hearts of small business owners—cars! We all know how crucial a car</div>
                            </div>

                            <div className="card-footer d-flex border-0 bg-transparent pt-0">
                                <div className="blog-card-footer-text me-3">29th December 2023</div>
                                <div className="blog-card-footer-text ms-3">No Comments</div>
                            </div>
                        </div>
                        
                        <div className="card col-md-6 mb-5 col-lg-4 bg-transparent border-0">
                            <div className="card-header bg-transparent">
                                <div className="img-container">
                                    <img src={vehicle} className='w-100 blog-card-img' alt="vehicle" />
                                </div>
                            </div>

                            <div className="card-body pb-0 mb-0">
                                <div className="card-text-title mb-3">Top 5 Cars for Small Business Owners in Nigeria</div>
                                <div className="card-text">My fellow business enthusiasts! Let’s dive into a topic that’s close to the hearts of small business owners—cars! We all know how crucial a car</div>
                            </div>

                            <div className="card-footer d-flex border-0 bg-transparent">
                                <div className="blog-card-footer-text me-3">29th December 2023</div>
                                <div className="blog-card-footer-text ms-3">No Comments</div>
                            </div>
                        </div>
                        
                        <div className="card col-md-6 mb-5 col-lg-4 bg-transparent border-0">
                            <div className="card-header bg-transparent">
                                <div className="img-container">
                                    <img src={vehicle} className='w-100 blog-card-img' alt="vehicle" />
                                </div>
                            </div>

                            <div className="card-body pb-0 mb-0">
                                <div className="card-text-title mb-3">Top 5 Cars for Small Business Owners in Nigeria</div>
                                <div className="card-text">My fellow business enthusiasts! Let’s dive into a topic that’s close to the hearts of small business owners—cars! We all know how crucial a car</div>
                            </div>

                            <div className="card-footer d-flex border-0 bg-transparent">
                                <div className="blog-card-footer-text me-3">29th December 2023</div>
                                <div className="blog-card-footer-text ms-3">No Comments</div>
                            </div>
                        </div>
                        
                        <div className="card col-md-6 mb-5 col-lg-4 bg-transparent border-0">
                            <div className="card-header bg-transparent">
                                <div className="img-container">
                                    <img src={vehicle} className='w-100 blog-card-img' alt="vehicle" />
                                </div>
                            </div>

                            <div className="card-body pb-0 mb-0">
                                <div className="card-text-title mb-3">Top 5 Cars for Small Business Owners in Nigeria</div>
                                <div className="card-text">My fellow business enthusiasts! Let’s dive into a topic that’s close to the hearts of small business owners—cars! We all know how crucial a car</div>
                            </div>

                            <div className="card-footer d-flex border-0 bg-transparent">
                                <div className="blog-card-footer-text me-3">29th December 2023</div>
                                <div className="blog-card-footer-text ms-3">No Comments</div>
                            </div>
                        </div>
                        
                        <div className="card col-md-6 mb-5 col-lg-4 bg-transparent border-0">
                            <div className="card-header bg-transparent">
                                <div className="img-container">
                                    <img src={vehicle} className='w-100 blog-card-img' alt="vehicle" />
                                </div>
                            </div>

                            <div className="card-body pb-0 mb-0">
                                <div className="card-text-title mb-3">Top 5 Cars for Small Business Owners in Nigeria</div>
                                <div className="card-text">My fellow business enthusiasts! Let’s dive into a topic that’s close to the hearts of small business owners—cars! We all know how crucial a car</div>
                            </div>

                            <div className="card-footer d-flex border-0 bg-transparent">
                                <div className="blog-card-footer-text me-3">29th December 2023</div>
                                <div className="blog-card-footer-text ms-3">No Comments</div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default Blog;