import React from 'react'
import "./ContactUs.scss"
import { JoinKitaniz } from '../../Components'

const ContactUs = () => {
    return (
        <div className='ContactUs'>
            <div className="header">
               <div className='text-center'>
                    <div className="title">CONTACT US</div>
                    <div className="description">We are here to help</div>
                </div>
            </div>


            <div className="contact-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-title">
                                For general inquiries or questions, please leave your message below and we will get back to you as soon as possible or contact our customer support
                            </div>

                            <form action="" className='row'>
                                <div className="form-group col-md-12">
                                    <input type="text" className='form-control' placeholder='Full name'></input>
                                </div>
                                <div className="form-group col-md-6">
                                    <input type="text" className='form-control' placeholder='Email'></input>
                                </div>

                                <div className="form-group col-md-6">
                                    <input type="text" className='form-control' placeholder='Phone Number'></input>
                                </div>

                                <div className="form-group col-md-12">
                                    <textarea className='form-control' placeholder='Message'></textarea>
                                </div>

                                <div className="form-group col-md-12">
                                    <button className='btn btn-default'>Send Message</button>
                                </div>
                            </form>
                        </div>
                        <div className="col-md-6">
                            <div className="card support-card">
                                <div className="card-header">
                                     Customer Support
                                </div>
                                <div className="card-body">
                                    <ul>
                                        <li>
                                            <b>Tel.:</b> 08105578524
                                        </li>
                                        <li>
                                            <b>Email:</b> support@kitaniz.com
                                        </li> 
                                        <li>
                                            <b>Address:</b> 12 Oyedele close, Ojodu berger.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <JoinKitaniz></JoinKitaniz>
        </div>
    )
}

export default ContactUs
