import React, { useState } from 'react'
import "./RenewDriversLicense.scss"
import { BreadCrumb } from 'Components' 
import { Link } from 'react-router-dom'

const RenewDriversLicense = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [confirmVehicle, setConfirmVehicle] = useState(false) 

    return (
        <div className='RenewDriversLicense'>
             <div className="container">
                <BreadCrumb parentTitle={'Registration'} parentUrl={'/renew-drivers-license'} pageTitle={'Renew Drivers License'}></BreadCrumb>
                <div className="row justify-content-center">
                    <div className="col-md-5 mb-5 mt-4">
                       <div className="card border-0 py-3">
                        <div className="card-header text-left border-0 pt-3"> 
                            <div className="title">
                            What type of vehicle?
                            </div> 
                        </div>
                        <div className="card-body pt-2">
                            <form action="">
                                <div className="form-group mt-0">
                                    <label htmlFor="">First Name</label>
                                    <select name="" id="" className='form-control'>
                                        <option value="">Benjamin</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Last Name</label>
                                    <select name="" id="" className='form-control'>
                                        <option value="">Wood</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Driver's License Number</label>
                                    <select name="" id="" className='form-control'>
                                        <option value="">MH12 20211234567</option>
                                    </select>
                                </div> 
                                
                                <div className="form-group"> 
                                <Link to='/upload-old-drivers-license'>
                                    <button className='btn btn-primary' type='button' onClick={()=>setConfirmVehicle(true)}>Continue</button>
                                </Link>
                                </div> 
                            </form>
                        </div>
                       </div>
                    </div>

                </div>
             </div>
        </div>
    )
}

export default RenewDriversLicense
