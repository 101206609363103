import React from 'react'
import "./CoSummaryAuth.scss"
import { BreadCrumb } from 'Components' 
import { CoSummaryAuthItem } from 'Pages/Frontend/Components';

const CoSummaryAuth = () => {
    return (
        <div className='CoSummaryAuth'> 
            <div className="container">
                <BreadCrumb parentTitle={'Registration'} parentUrl={'/register-vehicle'} pageTitle={'New Registration'} childTitle={'Summary'}></BreadCrumb>
            
                <CoSummaryAuthItem module="user"></CoSummaryAuthItem>
           </div>
        </div>
    )
}

export default CoSummaryAuth
