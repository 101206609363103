import { Link } from 'react-router-dom';
import './RecorverPassword.scss'
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { authService } from 'Services/authService';

type formType = { 
    phoneNumber: string; 
}

const RecorverPassword = () => {
    const navigate = useNavigate();
    const { handleSubmit, register, formState: { errors } } = useForm<formType>()

    const [request, setRequest] = useState<boolean>(false)
  
    const submit = async (data:formType) => {
        setRequest(true)
        await authService.forgetPassword(data).then((response) => {
            console.log('Response', response)
            setRequest(false)
            navigate('/recorver-password-token', {replace: true})
            // /recorver-password-token
            
        }).catch((err) => {
            console.log('Error', err.message)
            setRequest(false)
            Swal.fire({
                icon: "warning",
                text: err.response.data.message,
                showConfirmButton: false,
                timer: 3000
            });
        })
    } 

    
    return ( 
        <div className="RecorverPassword py-5">
            
            <div className="container h-100 d-flex justify-content-center align-items-center">
                <div className="form-container p-4  d-flex justify-content-center align-items-center">
                    <div className="form-container ">
                    
                        <div className="form-header text-center">
                            <div className="form-header-title h2">Recovery Password</div>
                            <div className="form-notify-header text-secondary small mx-5">Enter the phone number used to create your Kitaniz Account </div>
                        </div>

                        <form className="form w-100 h-100" onSubmit={ handleSubmit((e) => submit(e)) }>
                             
                            {/* <div className="form-input py-2">
                                <input className={`form-control ${errors.email ? 'is-invalid' : ''}`} type="email" placeholder='Email'  {...register('email', { required : 'This field is required'})}/>
                                {errors.email && <small className='error'>{errors.email.message}</small>} 
                            </div> */}

                            <div className="form-input py-2">
                                <input className={`form-control ${errors.phoneNumber ? 'is-invalid' : ''}`} type="text" placeholder='Phone Number'  {...register('phoneNumber', { required : 'This field is required'})}/>
                                {errors.phoneNumber && <small className='error'>{errors.phoneNumber.message}</small>} 
                            </div>

                            <div className="form-input py-2">
                                <button className="form-control btn btn-primary rounded-pill">Continue {request && <span className='spinner-border spinner-border-sm'></span>}</button>
                            </div>
                            
                            <div className="form-input pt-3">
                                    <div className="small text-center">
                                        Remember?
                                        <Link to='/login' className='text-decoration-none ms-1'>Login</Link>
                                    </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default RecorverPassword;