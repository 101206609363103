import React, { useState } from 'react'
import "./UploadOldDriversLicense.scss"
import { BreadCrumb } from 'Components' 
import { Link } from 'react-router-dom'
import cloud from '../../../../../Assets/Images/cloud-plus.svg'
import file from '../../../../../Assets/Images/Layer_x0020_1.svg'

const UploadOldDriversLicense = () => {
    const [confirmUpload, setConfirmUpload] = useState(false) 

    return (
        <div className='UploadOldDriversLicense'>
             <div className="container">
                <BreadCrumb parentTitle={'Registration'} parentUrl={'/renew-drivers-license'} pageTitle={'Renew Drivers License'}></BreadCrumb>
                <div className="row justify-content-center">
                    <div className="col-md-5 mb-5 mt-4">
                       <div className="card border-0 py-3">
                        <div className="card-header text-left border-0 pt-3"> 
                            <div className="title">
                                Upload Old Drivers License
                            </div> 
                        </div>
                        <div className="card-body pt-2">
                            <form action="">
                                <div className="w-100 drag-and-drop">
                                    <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
                                        <div className="blue-cloud-upload-img-container d-flex justify-content-center w-100">
                                            <img src={cloud} alt="cloud" />
                                        </div>
                                        <div className="texts text-center" onClick={()=>setConfirmUpload(true)}>
                                            Drag and drop files or <a href="/">Browse</a> to upload
                                        </div>
                                    </div>
                                </div> 

                                
                                {confirmUpload && <div className="my-3 px-3">
                                    <div className="total-file-1 w-100">
                                        <div className="file-1 row">
                                            <div className="img-cont px-3 col-3">
                                                <img src={file} alt="file" />
                                            </div>
                                            <div className="progress-main-container col-9">
                                                <div className="text-1 pb-1">JPG_21341.pdf</div>
                                                <div className="progress" >
                                                    {/* <div className="progress-bar" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div> */}
                                                </div>
                                                <div className="text-1-2nd pt-1">2.5 MB of 5.0 MB</div>
                                            </div>
                                        </div>
                                        
                                        <div className="file-2 mt-3 row">
                                            <div className="img-cont px-3 col-3">
                                                <img src={file} alt="file" />
                                            </div>
                                            <div className="progress-main-container col-9">
                                                <div className="text-1 pb-1">JPG_21341.pdf</div>
                                                <div className="progress" >
                                                    {/* <div className="progress-bar" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div> */}
                                                </div>
                                                <div className="text-1-2nd pt-1">2.5 MB of 5.0 MB</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                                
                                <div className="form-group"> 
                                    <Link to='/checkout'>
                                        <button className='btn btn-primary' type='button'>Done</button>
                                    </Link>
                                </div> 
                            </form>
                        </div>
                       </div>
                    </div>

                </div>
             </div>
        </div>
    )
}

export default UploadOldDriversLicense
