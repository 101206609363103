 import axios from "axios"
import { forgetPassword, passwords } from "Models/userRegLogRecorverType";
// import { AxiosInterceptor } from "Config/AxiosInterceptor"
// AxiosInterceptor()

export const authService = {
   login: async (data: {
      email: string,
      password: string
    }) => { 
    return await axios.post('/auth/login', data)
   },

   register: async (data: {
      firstName: string,
      lastName: string,
      email:string,
      password: string,
      phoneNumber: string,
    }) => { 
    return await axios.post('/user/register', data)
   },

   verify: async (code: string|undefined) => { 
    return await axios.put(`/user/verify-phone?code=${code}`)
   },
   
   forgetPassword: async (data: forgetPassword) =>{
      return await axios.post('/user/forget-password', data)
  },
  
  recorveryPasswordToken: async (code: string|undefined) =>{
      console.log(code)
      return await axios.patch(`/user/forget-password-verify?code=${code}`);
  },

  resetPassword: async (data: passwords, token: string|null) =>{
      console.log("auth token", `Bearer ${token}`)
 
      return await axios.post('/user/reset-password', data,
          {
              headers: {
                  'Authorization' : `Bearer ${token}`,
                  'Content-Type': 'application/json'
              }
          }
      );
  }
}