import React, { FC, Key, useEffect, useState } from 'react'
import "./EditDeliveryAddress.scss"
import { useForm } from 'react-hook-form'
import state from "Data/stateNG.json"
import lgas from "Data/lgaNG.json"
import Swal from 'sweetalert2' 
import { userService } from 'Services'
import { deliveryEditType, deliveryListType } from 'Models/deliveryType'

type formType = {
    address: string,
    localGovernment: string,
    state: string,
    phoneNumber: string,
    primary: boolean
  }

  interface propsType {
    closeModal: Function,
    deliveryItem: deliveryListType | undefined
  }

const EditDeliveryAddress:FC<propsType> = ({closeModal, deliveryItem}) => {
    const [request, setRequest] = useState(false)
    const { register, setValue,  handleSubmit, formState: { errors } } = useForm<formType>();

    const [selectLgas, setSelectedLgas] = useState<Array<{name: string, id: number}>>([])
    const [selectState, setSelectedState] = useState<string>("")


    const handleState = (e: any) => {
        const state = JSON.parse(e.target.value); 
        setSelectedState(state.name)
        const localGovt: any = lgas[2].data;
        const getLga: any = localGovt.filter((res: any) => Number(res.state_id) === Number(state.id))
        setSelectedLgas(getLga)
      }
      

      const submit = handleSubmit( async (data: formType) =>{
        setRequest(true)

        const newData: deliveryEditType[] = [ 
            {
                key: 'phoneNumber', 
                value: data.phoneNumber
            }, 
            {
                key: 'localGovernment', 
                value: data.localGovernment
            },
            {
                key: 'address', value: data.address
            },
            {
              key: 'state', 
              value: selectState,  
            },
            {
              key: 'primary', 
              value: false, 
            }
          ]

          console.log("deliveryItem", newData)

        await userService.editDeliveryAddress(deliveryItem?.id, newData).then((response)=>{ 
            Swal.fire({
                position: "top-end",
                icon: "success", 
                showConfirmButton: false,
                timer: 1600
              });
            setRequest(false)
            closeModal()
        }, error =>{ 
            console.log("error", error)
            Swal.fire('Oops!', error.response.data.message, 'warning'); 
            setRequest(false)
        })


      })

      useEffect(() => {
        if(deliveryItem){
            setValue("address", deliveryItem.address)
            setValue("phoneNumber", deliveryItem.phoneNumber)
            setValue("localGovernment", deliveryItem.localGovernment)
            setValue("state", deliveryItem.state) 
            setSelectedState(deliveryItem.state)
        }
      }, [deliveryItem])

    return (
        <div className='AddDeliveryAddress'>
            <div className="title">Edit Address</div>

            <div className="form-wraper mt-3">
                <form onSubmit={submit}> 
                    <div className='form-input mt-3'>
                        <label htmlFor="address">Address</label>
                        <input type="text" id="address" className={`form-control ${errors.address ? 'is-invalid' : ''}`} {...register('address')}/>
                        {errors.address && <small className='error'>{errors.address.message}</small>} 
                    </div>
                    <div className='form-input mt-3'>
                        <label htmlFor="state">State</label>
                        <select id="state" className={`form-control ${errors.state ? 'is-invalid' : ''}`} {...register('state', {onChange: (e) => handleState(e)})}>
                            <option>select state</option>
                            {state.map((stateItem:{name: string}, index: Key)=><option key={index} value={JSON.stringify(stateItem)}>{stateItem.name}</option>)}
                        </select>
                        {errors.state && <small className='error'>{errors.state.message}</small>} 
                    </div>
                    <div className='form-input mt-3'>
                        <label htmlFor="lga">L.G.A</label>
                        <select id="lga" className={`form-control ${errors.localGovernment ? 'is-invalid' : ''}`} {...register('localGovernment')}>
                            <option>select L.G.A</option>
                            {selectLgas.map((stateItem:{name: string}, index: Key)=><option key={index}>{stateItem.name}</option>)}
                        </select>
                        {errors.localGovernment && <small className='error'>{errors.localGovernment.message}</small>} 
                    </div>  
                    <div className='form-input mt-3'>
                        <label htmlFor="contact_numver">Contact Number</label>
                        <input type="text" id="contact_numver" className={`form-control ${errors.phoneNumber ? 'is-invalid' : ''}`} {...register('phoneNumber')}/>
                    </div>
                    {errors.phoneNumber && <small className='error'>{errors.phoneNumber.message}</small>} 
                    
                    <div className='form-submit-button'> 
                        <button type="submit" className='btn btn-default w-100' disabled={request}>{request && <span className='spinner-border spinner-border-sm'></span>} Save Changes</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default EditDeliveryAddress
