import { VEHICLE_REG_STEP_ONE } from 'Constants/RegistrationConstant';
 
export const vehicleInformation = (state = false, action: { type: string; payload: boolean; }) => {
  switch (action.type) {
    case VEHICLE_REG_STEP_ONE:
      return action.payload
    default:
      return state
  }
}
 
