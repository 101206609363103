import { FC, Key, useState } from "react";
import { useSelector } from "react-redux"; 
import { DriverLicenseType } from "./DriverLicensePackage";
import { useForm } from "react-hook-form"; 
import state from "Data/stateNG.json"
import lgas from "Data/lgaNG.json"
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

interface propsType {
    module?: string
}
type FormData = {
    title: string;
    firstName: string;
    otherNames: string;
    sex: string;
    dateOfBirth: string;
    placeOfBirth: string;
    maritalStatus: string;
    nationality: string;
    address: string;
    stateOfOrigin: string;
    lga: string;
    phoneNumber: string;
    mothersMaidenName: string;
    bloodGroup: string;
    heightInMeters: string;
    nokPhoneNumber: string;
  };
  

const DriversLicenseRegistrationFormElement:FC<propsType> = ({ module }) => {
    const packageInformation = useSelector((state: {vehicleInformation: DriverLicenseType})=> state.vehicleInformation)
    console.log("packageInformation", packageInformation)
 

    const [selectLgas, setSelectedLgas] = useState<Array<{name: string, id: number}>>([])
    const [selectState, setSelectedState] = useState<string>("")
    const [request, setRequest] = useState<boolean>(false)
    
    const { service_id } = useParams<{ service_id: string }>()
     
    const navigate = useNavigate()
     
    const handleState = (e: any) => {
        const state = JSON.parse(e.target.value); 
        setSelectedState(state.name)
        const localGovt: any = lgas[2].data;
        const getLga: any = localGovt.filter((res: any) => Number(res.state_id) === Number(state.id))
        setSelectedLgas(getLga)
      }

    const { register, handleSubmit, trigger, formState: { errors } } = useForm<FormData>();


    const handleChange = () => {  
        trigger('phoneNumber'); 
        trigger('nokPhoneNumber'); 
    };
      
       
    const onSubmit = (data: FormData) => {
      setRequest(true)
      console.log("onSubmit", data); 

        try {
            localStorage.setItem("kit_new_reg", JSON.stringify({...packageInformation, ...data, stateOfOrigin: JSON.parse(data.stateOfOrigin).name})); 
            navigate(module && module === 'user' ? `/myaccount/checkout/drivers-license-registration/${service_id}` : "/checkout/drivers-license-registration", { replace: true });

            setRequest(false)
        } catch (error: unknown) {
            if (error instanceof DOMException && error.name === 'QuotaExceededError') {
                console.log("Local storage quota exceeded.");
                Swal.fire('Oops!', 'Local storage quota exceeded. Please free up some space.', 'warning');
            } else {
                console.log("Error:", error);
                Swal.fire('Oops!', 'An error occurred while storing data.', 'warning');
            }
            setRequest(false);
        }
    };
    
    return (  
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <div className="first-col p-3 col-md-6">
                    <div className={`form-input ${errors.title ? 'is-invalid' : ''}`}>
                    <label htmlFor="title">Title (Mr/Mrs/Miss) Surname</label>
                    <input type="text" className={`form-control ${errors.title ? 'is-invalid' : ''}`} placeholder="Mr Woods" {...register("title", { required: true })} />
                    {errors.title && <small className='error'>This field is required</small>}
                    </div>
                </div>
    
                <div className="first-col p-3 col-md-6">
                    <div className={`form-input ${errors.firstName ? 'is-invalid' : ''}`}>
                    <label htmlFor="firstName">First Name</label>
                    <input type="text" className={`form-control ${errors.firstName ? 'is-invalid' : ''}`} placeholder="Woods" {...register("firstName", { required: true })} />
                    {errors.firstName && <small className='error'>This field is required</small>}
                    </div>
                </div>
    
                <div className="first-col p-3 col-md-6">
                    <div className={`form-input ${errors.otherNames ? 'is-invalid' : ''}`}>
                    <label htmlFor="otherName">Other Name</label>
                    <input type="text" className={`form-control ${errors.otherNames ? 'is-invalid' : ''}`} placeholder="Woods" {...register("otherNames", { required: true })} />
                    {errors.otherNames && <small className='error'>This field is required</small>}
                    </div>
                </div>
    
                <div className="first-col p-3 col-md-6">
                    <div className={`form-input ${errors.sex ? 'is-invalid' : ''}`}>
                        <label htmlFor="sex">Sex</label>
                        <select className={`form-control ${errors.sex ? 'is-invalid' : ''}`} {...register("sex", { required: true })}>
                            <option value="">Select</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                        </select>
                        {errors.sex && <small className='error'>Please select your sex</small>}
                    </div>
                </div>
    
                <div className="first-col p-3 col-md-6">
                    <div className={`form-input ${errors.dateOfBirth ? 'is-invalid' : ''}`}>
                        <label htmlFor="dateOfBirth">Date of Birth (DD/MM/YYYY)</label>
                        <input type="date" className={`form-control ${errors.dateOfBirth ? 'is-invalid' : ''}`} placeholder="11/12/1994" {...register("dateOfBirth", { required: true })} />
                        {errors.dateOfBirth && <small className='error'>This field is required</small>}
                    </div>
                </div>
     

                <div className="first-col p-3 col-md-6">
                    <div className={`form-input ${errors.placeOfBirth ? 'is-invalid' : ''}`}>
                        <label htmlFor="placeOfBirth">Place of Birth (City/Town)</label>  
                        <select id="placeOfBirth" className={`form-control ${errors.placeOfBirth ? 'is-invalid' : ''}`} {...register('placeOfBirth')}>
                            <option>select state</option>
                            {state.map((stateItem:{name: string}, index: Key)=><option key={index}>{stateItem.name}</option>)}
                        </select>
                        {errors.placeOfBirth && <small className='error'>Please select your place of birth</small>}
                    </div>
                </div>

                <div className="first-col p-3 col-md-6">
                    <div className={`form-input ${errors.maritalStatus ? 'is-invalid' : ''}`}>
                        <label htmlFor="maritalStatus">Marital Status</label>
                        <select className={`form-control ${errors.maritalStatus ? 'is-invalid' : ''}`} {...register("maritalStatus", { required: true })}>
                        <option value="">Select</option>
                        <option value="single">Single</option>
                        <option value="married">Married</option>
                        </select>
                        {errors.maritalStatus && <small className='error'>Please select your marital status</small>}
                    </div>
                </div>

                <div className="first-col p-3 col-md-6">
                    <div className={`form-input ${errors.nationality ? 'is-invalid' : ''}`}>
                        <label htmlFor="nationality">Nationality</label>
                        <select className={`form-control ${errors.nationality ? 'is-invalid' : ''}`} {...register("nationality", { required: true })}>
                        <option value="">Select</option>
                        <option value="nigeria">Nigeria</option>
                        <option value="usa">USA</option>
                        </select>
                        {errors.nationality && <small className='error'>Please select your nationality</small>}
                    </div>
                </div>

                <div className="first-col p-3 col-md-6">
                    <div className={`form-input ${errors.address ? 'is-invalid' : ''}`}>
                        <label htmlFor="address">Address</label>
                        <input type="text" className={`form-control ${errors.address ? 'is-invalid' : ''}`} placeholder="44, Paul street, victoris island, Lagos, Nigeria." {...register("address", { required: true })} />
                        {errors.address && <small className='error'>This field is required</small>}
                    </div>
                </div>

                <div className="first-col p-3 col-md-6">
                    <div className={`form-input ${errors.stateOfOrigin ? 'is-invalid' : ''}`}>
                        <label htmlFor="stateOfOrigin">State of Origin</label> 
                        <select id="stateOfOrigin" className={`form-control ${errors.stateOfOrigin ? 'is-invalid' : ''}`} {...register('stateOfOrigin', {required: true, onChange: (e) => handleState(e)})}>
                            <option>select state</option>
                            {state.map((stateItem:{name: string}, index: Key)=><option key={index} value={JSON.stringify(stateItem)}>{stateItem.name}</option>)}
                        </select>
                        {errors.stateOfOrigin && <small className='error'>This field is required</small>}
                    </div>
                </div>

                <div className="first-col p-3 col-md-6">
                    <div className={`form-input ${errors.lga ? 'is-invalid' : ''}`}>
                        <label htmlFor="lgaOfOrigin">Local Govt. Area of Origin</label> 
                        <select id="lgaOfOrigin" className={`form-control ${errors.lga ? 'is-invalid' : ''}`} {...register('lga', { required: true })}>
                            <option>select L.G.A</option>
                            {selectLgas.map((stateItem:{name: string}, index: Key)=><option key={index}>{stateItem.name}</option>)}
                        </select>
                        {errors.lga && <small className='error'>This field is required</small>}
                    </div>
                </div>

                <div className="first-col p-3 col-md-6">
                    <div className={`form-input ${errors.phoneNumber ? 'is-invalid' : ''}`}>
                        <label htmlFor="phoneNumber">Phone Number</label>
                        <input type="text" className={`form-control ${errors.phoneNumber ? 'is-invalid' : ''}`} placeholder="+23491600000"  
                          {...register("phoneNumber", { 
                            required: "Field can not be empty", 
                            pattern: {
                                value: /^(\+?\d{1,3}[- ]?)?\d{10}$/,
                                message: 'Invalid phone number format. Please include country code.',
                            }, 
                            onChange: handleChange})} 
                        />
                        {errors.phoneNumber && <small className='error'>This field is required</small>}
                    </div>
                </div>

                <div className="first-col p-3 col-md-6">
                    <div className={`form-input ${errors.mothersMaidenName ? 'is-invalid' : ''}`}>
                        <label htmlFor="mothersMaidenName">Mother's Maiden Name</label>
                        <input type="text" className={`form-control ${errors.mothersMaidenName ? 'is-invalid' : ''}`} placeholder="Mary" {...register("mothersMaidenName", { required: true })} />
                        {errors.mothersMaidenName && <small className='error'>This field is required</small>}
                    </div>
                </div>

                <div className="first-col p-3 col-md-6">
                    <div className={`form-input ${errors.bloodGroup ? 'is-invalid' : ''}`}>
                        <label htmlFor="bloodGroup">Blood Group</label>
                        <select className={`form-control ${errors.bloodGroup ? 'is-invalid' : ''}`} {...register("bloodGroup", { required: true })}>
                        <option value="">Select</option>
                        <option value="o+">O+</option>
                        <option value="aa">AA</option>
                        </select>
                        {errors.bloodGroup && <small className='error'>Please select your blood group</small>}
                    </div>
                </div>

                <div className="first-col p-3 col-md-6">
                    <div className={`form-input ${errors.heightInMeters ? 'is-invalid' : ''}`}>
                        <label htmlFor="height">Height in Meters</label>
                        <select className={`form-control ${errors.heightInMeters ? 'is-invalid' : ''}`} {...register("heightInMeters", { required: true })}>
                        <option value="">Select</option>
                        <option value="170">170m</option>
                        <option value="175">175m</option>
                        </select>
                        {errors.heightInMeters && <small className='error'>Please select your height</small>}
                    </div>
                </div>

                <div className="first-col p-3 col-md-6">
                    <div className={`form-input ${errors.nokPhoneNumber ? 'is-invalid' : ''}`}>
                        <label htmlFor="nextOfKinPhoneNumber">Next of Kin Phone Number</label>
                        <input type="text" className={`form-control ${errors.nokPhoneNumber ? 'is-invalid' : ''}`} placeholder="+23491600000" 
                            {...register("nokPhoneNumber", { 
                                required: "Field can not be empty", 
                                pattern: {
                                    value: /^(\+?\d{1,3}[- ]?)?\d{10}$/,
                                    message: 'Invalid phone number format. Please include country code.',
                                }, 
                                onChange: handleChange 
                            })}
                          />
                        {errors.nokPhoneNumber && <small className='error'>This field is required</small>}
                    </div>
                </div>

                <div className="row small"> 
                    <button type="submit" className="btn rounded-pill col-md-3 mx-3" disabled={request}>Submit Application {request && <span className="spinner-border spinner-border-sm"></span>} </button>
                 </div>

            </div>
      </form>
     );
}
 
export default DriversLicenseRegistrationFormElement;