import axios from "axios"
import { AxiosInterceptor } from "Config/AxiosInterceptor"
AxiosInterceptor()

export const customizePlateService = {

    checkAvailability: async (plateNumber: string) =>{
       return await axios.put('/plate-number/registration/customized', {
        number: plateNumber
      })
    }

}