import React, { FC, useState, useEffect, Key, Fragment } from 'react'
import toyota from "Assets/Images/toyota.png"
import { Link, useParams } from 'react-router-dom';
import { vehicleService } from 'Services';
import { vehicleServiceType } from 'Models';
import Swal from 'sweetalert2';
import { vehicleServiceDocument } from 'Models/vehicleServiceType';

interface propsType {
    module?: string | undefined
}

const RenewRegistrationSummaryItem:FC<propsType> = ({module}) => {
    const limit = 100;
    const offset = 0;
    
    const { service_id } = useParams<{ service_id: string }>()
     
    const [vehicleServices, setVehicleServices] = useState<vehicleServiceType>()
 
    const getServices = async () =>{
        await vehicleService.serviceItem(service_id, limit, offset).then((response) =>{
            console.log("response vehicleServices", response.data)
            setVehicleServices(response.data[0])
        }, error => {
 
            console.log("error vehicleServices", error)
            Swal.fire({
                position: "top-end",
                icon: "warning",
                text: error.response.data.message,
                showConfirmButton: false,
                timer: 3000
            });
        })
    }
 
    useEffect(() => {
        getServices()
    }, [service_id])
 
 
    const [request, setRequest] = useState(false)
    const [serviceDocuments, setServiceDocuments] = useState<Array<vehicleServiceDocument>>()
 
    const  vehicleInformation = JSON.parse(localStorage.getItem('kit_new_reg')?? '')
 
    const getDocuments = async () => {
        setRequest(true)
       await vehicleService.serviceDocuments(service_id).then((response)=>{
        setRequest(false) 
         console.log("serviceDocuments", response.data)
         setServiceDocuments(response.data)
       }, error =>{
        setRequest(false)
        console.log("error make", error)
        Swal.fire({
            position: "top-end",
            icon: "warning",
            text: error.response.data.message,
            showConfirmButton: false,
            timer: 3000
        });
       })
    }
 
    useEffect(()=>{
        getDocuments() 
    }, [service_id])
 
    console.log("vehicleInformation", vehicleInformation)
    
    const totalAmount = ()=>{
         if(vehicleInformation.typeOfInsurance === "none" ){ 
             return vehicleServices?.amount
         } else {
             return Number(vehicleServices?.amount?? 0) + 15000
         }
     }
   
 
    return ( 
        <div className="row justify-content-center pt-3 pb-5">
            <div className={module && module==='user'? "col-md-6":"col-md-5"}>
                <div className="card border-0 mt-4 mt-md-0">
                    <div className="card-header bg-white pt-3">
                        Summary
                    </div>
                    <div className="card-body pt-0">
                        <div className="row border-bottom border-top pb-2 pt-3">
                        <div className="col-6">
                                <div className="label">
                                    Make
                                </div> 
                            </div>
                            <div className="col-6 text-end">
                                <div className="value">
                                    {vehicleInformation.vehicleInfo? vehicleInformation.vehicleInfo.make.name : vehicleInformation.make.name}
                                </div>
                            </div>

                            <div className="col-6">
                                <div className="label">
                                    Model
                                </div> 
                            </div> 

                            <div className="col-6 text-end">
                                <div className="value"> 
                                    {vehicleInformation.vehicleInfo? vehicleInformation.vehicleInfo.make.name : vehicleInformation.make.name} {vehicleInformation.vehicleInfo? vehicleInformation.vehicleInfo.model.name : vehicleInformation.model.name},
                                     {vehicleInformation.year}
                                </div>
                            </div>


                            <div className="col-6">
                                <div className="label">
                                    Year
                                </div> 
                            </div> 
                            <div className="col-6 text-end">
                                <div className="value">
                                   {vehicleInformation.year}
                                </div>
                            </div>


                            <div className="col-6">
                                <div className="label">
                                    Color
                                </div> 
                            </div> 
                            <div className="col-6 text-end">
                                <div className="value text-capitalize">
                                {vehicleInformation.color}
                                </div>
                            </div>


                            <div className="col-6">
                                <div className="label">
                                    Engine number
                                </div> 
                            </div> 

                            <div className="col-6 text-end">
                                <div className="value">
                                   {vehicleInformation.engineNumber}
                                </div>
                            </div>

                            <div className="col-6">
                                <div className="label">
                                    Chasis number
                                </div> 
                            </div> 

                            <div className="col-6 text-end">
                                <div className="value">
                                  {vehicleInformation.chasisNumber}
                                </div>
                            </div>
                        </div>


                        <div className="row border-bottom pb-2 mt-2">
                            <div className="col-6">
                                <div className="label">
                                    Name
                                </div> 
                            </div>
                            <div className="col-6 text-end">
                                <div className="value">
                                  {vehicleInformation.lastName && vehicleInformation.lastName} {vehicleInformation.firstName && vehicleInformation.firstName} {vehicleInformation.name && vehicleInformation.name}
                                </div>
                            </div>

                            <div className="col-6">
                                <div className="label">
                                    Phone number
                                </div> 
                            </div> 

                            <div className="col-6 text-end">
                                <div className="value">
                                   {vehicleInformation.phoneNumber}
                                </div>
                            </div>


                            <div className="col-6">
                                <div className="label">
                                    Email
                                </div> 
                            </div> 
                            <div className="col-6 text-end">
                                <div className="value">
                                   {vehicleInformation.email}
                                </div>
                            </div> 
                        </div>


                        <div className="row border-bottom pb-2 mt-2">
                            <div className="col-6">
                                <div className="label">
                                    Address
                                </div> 
                            </div>
                            <div className="col-6 text-end">
                                <div className="value">
                                    {vehicleInformation.address}
                                </div>
                            </div>

                            <div className="col-6">
                                <div className="label">
                                    Local Govt.
                                </div> 
                            </div> 

                            <div className="col-6 text-end">
                                <div className="value">
                                    {vehicleInformation.localGovernment?? vehicleInformation.lga}
                                </div>
                            </div>


                            <div className="col-6">
                                <div className="label">
                                    State
                                </div> 
                            </div> 

                            <div className="col-6 text-end">
                                <div className="value">
                                {vehicleInformation.state && typeof vehicleInformation.state === 'string' ?
                                        (() => {
                                            try {
                                                const parsedState = JSON.parse(vehicleInformation.state);
                                                return typeof parsedState === 'object' ? parsedState.name : vehicleInformation.state;
                                            } catch (error) {
                                                return vehicleInformation.state;
                                            }
                                        })() :
                                        null
                                    }
                                </div> 
                            </div> 

                            <div className="col-6">
                                <div className="label">
                                    Email
                                </div> 
                            </div> 
                            <div className="col-6 text-end">
                                <div className="value">
                                   {vehicleInformation.email}
                                </div>
                            </div> 
                        </div>

                        <div className="document-section">
                            <div className="row">
                                <div className="col-md-12 pt-3 mb-2">
                                    <div className="title">
                                        Documents
                                    </div> 
                                </div>
                                {request &&  <div className="col-12 h-100 bg-white text-center">
                                    <span className='spinner-border spinner-border-md text-secondary'></span>
                                    </div>}
                                    
                                {serviceDocuments && serviceDocuments.map((doc: vehicleServiceDocument, index:Key)=>{
                                return <div key={index} className="col-6">  
                                    <div className="label d-inline-flex">
                                        <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M22 10V15C22 20 20 22 15 22H9C4 22 2 20 2 15V9C2 4 4 2 9 2H14" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                                            <path d="M22 10H18C15 10 14 9 14 6V2L22 10Z" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                                        </svg>
                                        <div className='ms-2'>{doc.document.name}</div>
                                    </div>
                                </div>})} 

                                 {serviceDocuments && vehicleInformation.typeOfInsurance !== "none"  &&  <div className="col-6"> 
                                    <div className="label d-inline-flex">
                                        <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M22 10V15C22 20 20 22 15 22H9C4 22 2 20 2 15V9C2 4 4 2 9 2H14" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                                            <path d="M22 10H18C15 10 14 9 14 6V2L22 10Z" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                                        </svg>
                                        <div className='ms-2'>{vehicleInformation.typeOfInsurance}</div>
                                    </div>
                                </div>}    
  
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className={module && module==='user'? "col-md-6":"col-md-5"}>
                 
                <div className="card document-details-card p-0 border-0 mt-4 mt-md-0">

                    <div className="card-header border-0 pt-3">
                        <div className="row h-100">
                            <div className="col-md-12 text-center my-auto d-inline-flex">
                                <div className="logo"> 
                                    <img src={toyota} alt="" />
                                </div> 
                                <div className="my-auto ms-3">{vehicleInformation.vehicleInfo? vehicleInformation.vehicleInfo.make.name : vehicleInformation.make.name} {vehicleInformation.vehicleInfo? vehicleInformation.vehicleInfo.model.name: vehicleInformation.model.name}, {vehicleInformation.year}</div> 
                            </div>
                        </div>
                    </div>

                    <div className="card-body pt-2">
                        <div className="total-balance px-3 h-100"> 
                            <div className="row h-100"> 
                               {vehicleInformation.typeOfInsurance !== "none" && <Fragment>
                                    <div className="col-6 my-auto fw-lighter sub-total"> 
                                       Insurance 
                                    </div>
                                    <div className="col-6 text-end my-auto fw-lighter sub-total">
                                        ₦15,000
                                    </div>
                                </Fragment>}

                                <div className="col-6 mb-2 fw-lighter sub-total mt-1 pt-1"> 
                                    Sub Total
                                </div>
                                    <div className="col-6 text-end mb-2 fw-lighter sub-total mt-1 pt-1">
                                    ₦{vehicleServices?.amount}
                                </div>

                                <div className="col-6 my-auto">
                                    Total
                                </div>
                                <div className="col-6 text-end my-auto">
                                    ₦{totalAmount()}
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>  
                <Link to={module && module === 'user'? `/myaccount/checkout/renew-registration/${service_id}`:`/checkout/renew-registration/${service_id}`} >
                    <button className="btn checkout-btn">Checkout</button>
                </Link>
            </div>
        </div> 
    )
}

export default RenewRegistrationSummaryItem
