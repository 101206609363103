import React, { FC, Key, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ServiceDeliveryType, ServiceOrderDataType } from "Models";
import {
  deliveryService,
  orderService,
  userService,
  vehicleService,
} from "Services";
import Swal from "sweetalert2";
import "./RegistrationSummaryItem.scss";
import { CurrencyFormatter } from "Helpers";
import { useForm, useFormState, Controller } from "react-hook-form";

import { VehicleDocument } from "@/Models/vehicleDocument";
import { Lga, State } from "@/Models/location";
import {
  CHANGE_OF_OWNERSHIP_SERVICE_ID,
  CURRENT_PAYMENT_MODE,
  INSURANCE_DOCUMENT_NAME,
  LAGOS_STATE_ID,
  NEW_REGISTRATION_SERVICE_ID,
  RENEW_REGISTRATION_SERVICE_ID,
  ROAD_WORTHINESS_DOCUMENT_NAME,
  VEHICLE_LICENSE_DOCUMENT_NAME,
} from "../../../../../Constants/RegistrationConstant";
import { ReplaceCharacter } from "../../../../../Helpers/ReplaceCharacter";
import CustomToastr from "../../../../../Components/CustomToastr/CustomToastr";
import { ToastContainer, toast } from "react-toastify";
import PhoneNumberInput from "../../../../../Components/PhoneNumberInput/PhoneNumberInput";
import { isPhoneNumberValid } from "../../../../../Helpers/ValidatePhoneNumber";
import Select from "react-select";
import PaymentFailureCard from "../../PaymentFailure/PaymentFailure";
import MakePayment from "../../MakePayment/MakePayment";

interface propsType {
  module?: string | undefined;
  refreshServiceOrder?: () => void;
  vehicleOrderServices?: ServiceOrderDataType | undefined;
}

interface vehicleOrderServiceDocumentType {
  id: string;
  time: string;
  name: string;
  vehicleClassName: string;
  subClassName: string;
  vehicleClassSize: string;
  orderType: string;
  costPrice: string;
  sellingPrice: string;
  isRequired: boolean;
}

type formType = {
  address: string;
  localGovernmentUuid: string;
  state: string;
  contact_phone: string;
  primary: boolean;
  contact_name: string;
};

const customStyles = {
  control: (base: any) => ({
    ...base,
    backgroundColor: "transparent",
    border: "1px solid #DEE3EC !important",
    height: "46px",
    fontSize: "12px",
    paddingTop: "0px",
    boxShadow: "none",
  }),
  option: (base: any, state: any) => ({
    ...base,
    backgroundColor: state.isSelected ? "#186AFF" : "white",
    color: state.isSelected ? "white" : "black",
    "&:hover": { backgroundColor: "#709EFF", color: "white" },
  }),
  menu: (base: any) => ({
    ...base,
    borderRadius: "5px",
    marginTop: "5px",
    fontSize: "12px",
  }),
};

declare var $: any;

const RegistrationSummaryItem: FC<propsType> = ({
  module,
  vehicleOrderServices,
  refreshServiceOrder,
}) => {
  const navigate = useNavigate();

  const limit = 100;
  const offset = 0;

  const { service_order_id } = useParams<{ service_order_id: string }>();

  const {
    control,
    register,
    watch,
    handleSubmit,
    trigger,
    formState: { errors, isValid },
    setValue,
    getValues,
  } = useForm<formType>();

  const [selectState, setSelectedState] = useState<string>("");

  const [selectLgas, setSelectedLgas] = useState<
    Array<{ name: string; id: number }>
  >([]);

  const [requestAddr, setRequestAddr] = useState(false);
  const [newAddress, setNewAddress] = useState(false);

  const [cssPhNumber, setCssPhNum] = useState<string>("");
  const [phoneNumbers, setPhoneNumbers] = useState<string>("");

  const [newVehicleRegistrationDocuments, setNewVehicleRegistrationDocuments] =
    useState<VehicleDocument[]>();

  const [states, setStates] = useState<Array<State>>();
  const [lgas, setLgas] = useState<Array<Lga>>();
  const [processingDeliveryDetails, setProcessingDeliveryDetails] =
    useState<boolean>(false);

  const [showDeliveryDetailsForm, setShowDeliveryDetailsForm] =
    useState<boolean>(true);

  const [showVehicleOwnerInfoPanel, setShowVehicleOwnerInfoPanel] =
    useState<boolean>(true);
  const [showDeliveryDetailsPanel, setShowDeliveryDetailsPanel] =
    useState<boolean>(true);

  const toggleVehicleOwnerInfoPanel = () => {
    setShowVehicleOwnerInfoPanel(!showVehicleOwnerInfoPanel);
  };

  const toggleDeliveryDetailsPanel = () => {
    setShowDeliveryDetailsPanel(!showDeliveryDetailsPanel);
  };

  const handleState = async (e: any) => {
    const state_id = e?.target?.value || e;
    await vehicleService.getLGAsByStateId(state_id).then(
      (response) => {
        setLgas(response.data.data);
      },
      (error) => {}
    );
  };

  const submit = async (data: formType) => {
    setRequestAddr(true);

    const newData: any = [
      {
        ...data,
        state: selectState,
        primary: true,
      },
    ];

    await userService.createDeliveryAddress(newData).then(
      (response) => {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Saved",
          text: "Delivery address addeed successfully",
          showConfirmButton: false,
          timer: 1600,
        });

        setRequestAddr(false);
        setNewAddress(!newAddress);
      },
      (error) => {
        setRequestAddr(false);

        Swal.fire({
          position: "top-end",
          icon: "warning",
          title: "Error",
          text: error?.response?.data?.error,
          showConfirmButton: true,
        });
      }
    );
  };

  const validatePhoneNumber = (value: string) => {
    return true;
    // Regular expression to validate phone number with country code
    const phoneRegex = /^\+\d{1,3}-\d{3,14}$/;
    return (
      phoneRegex.test(value) ||
      "Phone number must have a leading country code (e.g., +23481456789..)"
    );
  };

  const handleChange = () => {
    trigger("contact_phone");
  };

  const checkPhNo = (phNumber: string) => {
    if (phNumber.length === 14 && phNumber.substring(0, 4) === "+234") {
      setPhoneNumbers(phNumber);
      setCssPhNum("");
    } else if (phNumber.length === 11 && phNumber.substring(0, 1) === "0") {
      setCssPhNum("");
      setPhoneNumbers(`+234${phNumber.slice(1)}`);
    } else if (phNumber.length === 0 && !phNumber) {
      setCssPhNum("");
    } else {
      setCssPhNum("Invalid");
    }
  };

  const [serviceDeliveryType, setServiceDeliveryType] =
    useState<ServiceDeliveryType[]>();

  const getStates = async () => {
    await vehicleService.getStates().then(
      (response) => {
        setStates(response.data.data);
      },
      (error) => {}
    );
  };

  useEffect(() => {
    getStates();
  }, []);

  useEffect(() => {
    let lagos = states?.find(
      (state: State) => state.name.toLowerCase() === "lagos"
    );
    setValue("state", lagos ? lagos.uuid : LAGOS_STATE_ID);
    handleState(lagos ? lagos.uuid : LAGOS_STATE_ID);
  }, [states]);

  const [selectServiceDeliveryType, setSelectServiceDeliveryType] =
    useState<ServiceDeliveryType>();

  const handleServiceDeliveryType = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const serviceType: ServiceDeliveryType = JSON.parse(e.target.value);
    processServiceDeliveryType(serviceType);
  };

  const [documentCheckedState, setDocumentCheckedState] = useState([]);

  const processServiceDeliveryType = async (
    selecttype: ServiceDeliveryType
  ) => {
    await deliveryService
      .updateVehicleServiceTypeOrder({
        amount: +selecttype.price,
        vehicleServiceTypeUuid: selecttype.uuid,
        orderUuid: `${service_order_id}`,
      })
      .then(
        (response) => {
          refreshServiceOrder && refreshServiceOrder();
        },
        (error) => {
          Swal.fire({
            position: "top-end",
            icon: "warning",
            title: "Error",
            text: error?.response?.data?.error,
            showConfirmButton: true,
          });
        }
      );
  };

  const [deliveryDetails, setDeliveryDetails] = useState<any>({
    amount: vehicleOrderServices?.totalAmount,
    orderId: service_order_id,
    localGovernmentUuid: null,
    address: null,
    contact_name: null,
    contact_phone: null,
  });

  const handleDeliveryForm = (fieldName: string, value?: string) => {
    if (fieldName && value) {
      setDeliveryDetails({
        ...deliveryDetails,
        [fieldName]: value,
        amount: vehicleOrderServices?.totalAmount,
        contact_phone: getValues("contact_phone"),
      });
    }
  };

  const handleUseDeliveryDetails = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShowDeliveryDetailsForm(!showDeliveryDetailsForm);
  };

  const processDeliveryInformation = async () => {
    const payload = {
      ...deliveryDetails,
      contact_phone: getValues("contact_phone"),
    };
    if (!payload.stateUuid) {
      payload.stateUuid = getValues("state") || LAGOS_STATE_ID;
    }
    if (!payload.address) {
      payload.address = getValues("address");
    }
    if (!payload.contact_name) {
      payload.contact_name = getValues("contact_name");
    }
    if (!payload.contact_phone) {
      payload.contact_phone = getValues("contact_phone");
    }
    if (!payload.localGovernmentUuid) {
      payload.localGovernmentUuid = getValues("localGovernmentUuid");
    }
    //   return
    setProcessingDeliveryDetails(true);
    await deliveryService.saveDeliveryDetails(payload).then(
      (response) => {
        refreshServiceOrder && refreshServiceOrder();
        setProcessingDeliveryDetails(false);
        setShowDeliveryDetailsForm(false);
        setShowDeliveryDetailsSummary(true);
        toast(<CustomToastr message={`Delivery details added successfully`} />);
      },
      (error) => {
        setProcessingDeliveryDetails(false);
        Swal.fire({
          position: "top-end",
          icon: "warning",
          title: "Error",
          text: error?.response?.data?.error,
          showConfirmButton: true,
        });
      }
    );
  };

  const [deliveryDetailsFormIsValid, setDeliveryDetailsFormIsValid] =
    useState<boolean>(false);
  useEffect(() => {
    const fields: Array<keyof formType> = [
      "address",
      "localGovernmentUuid",
      "state",
      "contact_phone",
      "contact_name",
    ];
    const allFieldsFilled = fields.every((field) => !!watch(field));

    setDeliveryDetailsFormIsValid(allFieldsFilled);
  }, [
    watch("address"),
    watch("localGovernmentUuid"),
    watch("state"),
    watch("contact_phone"),
    watch("contact_name"),
  ]);

  const [requestPayment, setRequestPayment] = useState(false);

  const makePayment = async (type?: string) => {
    if(type === 'offline'){
      $('#makePaymentModal').modal('show')
    }
    else if(type === 'online'){
      const payload = {
        orderId: `${service_order_id}`,
        totalOrderAmount: Number(vehicleOrderServices?.totalAmount),
      };
      setRequestPayment(true);
      await orderService.initiatePayment(payload).then(
        (response) => {
          setRequestPayment(false);
          window.location.href = response?.data?.data?.data;
        },
        (error) => {
          Swal.fire({
            position: "top-end",
            icon: "warning",
            title: "Error",
            text: error?.response?.data?.error?.message,
            showConfirmButton: true,
          });
          setRequestPayment(false);
        }
      );
    }
  };

  const [cmrChecked, setCmrChecked] = useState(false);

  const [temporaryPngImageUrl, setTemporaryImageUrl] = useState<string>(
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAWCAYAAAAvg9c4AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAICSURBVHgBtZI9TFNRFMf/9/W9tGiV1toXEUpKYh1ECVI/hiZ+btqlDsjgUE0cjTp108iIjo5GTHRQFxcjEzExYdBETdS41KSmGgol5ZtQQsvjnkMoH72F11L+ycv9OPf97v+ec8S9gc5g0Wn0wxIXsHP9LRatmFYwHJ11ApKCDod2VxNC86DO0rALUkKPeLr4O+BqQoO+j+c0NruPcpzmJ/znOW4berujDyFvlxwf42zTFdw49gAd/nO8T6DEmZdyPMT7KumqzfnCLP7PJiUwyuvkxFfpNsxzuuxz5j1+jn3CRH4Etp026G60uEN49buXLxjPZ3i9XgS/FrpvH0qggdQz/Jn8VtrLSTBd9kM6JNch+dFaJXFn8HRcDv2oUlSs+cKMCvmi5pZSA1eko0od338Rl82byBfnMJR7i1/TH8vOKJ02GiYivm6YzrayWORgN5zaXj5DcFtOW/e0I3Y4wT9GfNfZzVDuTSnu0taKQ25V2uA07I2ip6WXgSVn0vF6R4PZ55gqZLGwtPL8LZ1e8t/CKe9V5aGwJ4qAqx3vhvs4h6o8bnDqMwLsrhJwVaarDT2BR2jUTWwnkU6n40LY71N69ofMUyTnvlRCVt+nlO9Yc4KLWEk1N//mAtYFSqICxluflOVZpFIpj2EY3y3LCqJGTS1m8frfQ9lqo5OLmuPkMjsomVugXiEtAAAAAElFTkSuQmCC"
  );

  // const checkDocument = (document:VehicleDocument) => {
  //     if(document.documentName.toLowerCase() === 'cmr' && vehicleOrderServices?.requiredCRM){
  //        return cmrChecked
  //     }
  // }

  const [refreshingDocuments, setRefreshingDocuments] = useState<boolean>(false)

  const checkDocument = (document: VehicleDocument, type: string) => {
    if(type === 'default'){
      let doc = vehicleOrderServices?.registrationServiceDocumentOrders.find(doc => doc.registrationServiceDocument?.uuid === document?.uuid)
      return doc ? true : false
    }
    else if(type === 'extra'){
      let doc = vehicleOrderServices?.vehicleUseDocumentOrders.find(doc => doc?.vehicleUseDocuments?.uuid === document?.uuid)
      return doc ? true : false
    }
  };

  const [isDocumentSelectionValid, setIsDocumentSelectionValid] =
    useState<boolean>(false);

  const [selectedDocuments, setSelectedDocuments] = useState<any[]>([]);

  const handleSelectDocument = async (index: number, e: any, type: string) => {
    setRefreshingDocuments(true)
    let payload = {}
    if(type === 'default'){
      payload = {
        amount: newVehicleRegistrationDocuments
          ? newVehicleRegistrationDocuments[index]?.price
          : 0,
        registrationServiceDocument: newVehicleRegistrationDocuments
          ? newVehicleRegistrationDocuments[index]?.uuid
          : "",
        order: vehicleOrderServices?.uuid,
      };
    }
    else if(type === 'extra'){
      payload = {
        amount: extraDocuments
          ? extraDocuments[index]?.documentPrice
          : 0,
        registrationServiceDocument: extraDocuments
          ? extraDocuments[index]?.uuid
          : "",
        order: vehicleOrderServices?.uuid,
      };
    }
    addDocumentToOrder(payload, type);
    checkIfDocumentSelectionIsValid(e.target.value, e.target.checked);
  };

  const checkIfDocumentSelectionIsValid = (data: any, status: boolean) => {
    if (status) {
      let docs = selectedDocuments;
      docs.push(data);
      setSelectedDocuments(docs);
    } else {
      let docs = selectedDocuments;
      let updatedDocs = docs.filter((x) => x != data);
      setSelectedDocuments(updatedDocs);
    }
  };

  const addDocumentToOrder = async (payload: any, type: string) => {
    console.log(payload)
    if(type === 'default'){
      console.log('default')
      await orderService.addDocumentToAnOrder(payload).then(
        (response) => {
          refreshServiceOrder && refreshServiceOrder();
          setRefreshingDocuments(false)
        },
        (error) => {
          setRefreshingDocuments(false)
          Swal.fire({
            position: "top-end",
            icon: "warning",
            title: "Error",
            text: error?.response?.data?.error?.message,
            showConfirmButton: true,
          });
        }
      );
    }
    else if(type === 'extra'){
      console.log('extra')
      await orderService.addExtraDocumentToAnOrder(payload).then(
        (response) => {
          refreshServiceOrder && refreshServiceOrder();
          setRefreshingDocuments(false)
        },
        (error) => {
          setRefreshingDocuments(false)
          Swal.fire({
            position: "top-end",
            icon: "warning",
            title: "Error",
            text: error?.response?.data?.error?.message,
            showConfirmButton: true,
          });
        }
      );
    }
  };

  const goToVehicleInformation = () => {
    navigate({
      pathname: `/registration-service/${vehicleOrderServices?.registrationService.uuid}`,
      search: `?step=1&orderId=${vehicleOrderServices?.uuid}`,
    });
  };

  const goToVehicleOwnerInformation = () => {
    navigate({
      pathname: `/registration-service/${vehicleOrderServices?.registrationService.uuid}`,
      search: `?step=2&orderId=${vehicleOrderServices?.uuid}`,
    });
  };

  const goToEditDocument = () => {
    navigate({
      pathname: `/registration-service/${vehicleOrderServices?.registrationService.uuid}`,
      search: `?step=3&orderId=${vehicleOrderServices?.uuid}`,
    });
  };

  const [showDeliveryDetailsSummary, setShowDeliveryDetailsSummary] =
    useState<boolean>(false);

  const [showEditDeliveryDetailsForm, setShowEditDeliveryDetailsForm] =
    useState<boolean>(false);

  const editDeliveryDetails = () => {
    setShowDeliveryDetailsSummary(false);
    setShowEditDeliveryDetailsForm(true);
    setValue(
      "address",
      vehicleOrderServices?.localGovernmentOrders[0]?.address
    );
    setValue(
      "state",
      vehicleOrderServices?.localGovernmentOrders[0]?.state?.uuid
        ? vehicleOrderServices?.localGovernmentOrders[0]?.state?.uuid
        : LAGOS_STATE_ID
    );
    setValue(
      "localGovernmentUuid",
      vehicleOrderServices?.localGovernmentOrders[0]?.localGovernment?.id
    );
    setValue(
      "contact_name",
      vehicleOrderServices?.localGovernmentOrders[0]?.contact_name
    );
    setValue(
      "contact_phone",
      vehicleOrderServices?.localGovernmentOrders[0]?.contact_phone
    );
  };

  const openPaymentFailureModal = () => {
    $('#paymentFailureModal').modal('show')
  }

  const [extraDocuments, setExtraDocuments] = useState<any[]>([]);

  const getRegistrationServiceDocuments = async () => {
    await vehicleService.getDocumentsByVehicleRegistrationServiceAndVehicleClass(`${vehicleOrderServices?.registrationService.uuid}`, `${vehicleOrderServices?.vehicleClass?.name}`)
      .then(
        (response) => {
          console.log(response)
          const documents = [...response.data.data];
          setNewVehicleRegistrationDocuments(documents);
        },
        (error) => {}
      );
  }

  const getDocumentsByVehicleByRegistrationServiceAndVehicleUseandVehicleClass =
    async () => {
      await vehicleService
        .getDocumentsByVehicleByRegistrationServiceAndVehicleUseandVehicleClass(
          `${vehicleOrderServices?.registrationService.uuid}`,
          `${vehicleOrderServices?.vehicleUse?.uuid}`,
          `${vehicleOrderServices?.vehicleClass?.name}`
        )
        .then(
          (response) => {
            console.log(response)
            const documents = [...response.data.data];
            setExtraDocuments(documents);
          },
          (error) => {}
        );
    };

  const getVehicleDocumentsByVehicleClassName = async () => {
    await vehicleService
      .getDocumentsByVehicleRegistrationServiceAndVehicleClass(
        `${vehicleOrderServices?.registrationService.uuid}`,
        `${vehicleOrderServices?.vehicleClass?.name}`
      )
      .then(
        (response) => {
          console.log(response)
          const documents = [...response.data.data];
          setNewVehicleRegistrationDocuments(documents);
        },
        (error) => {}
      );
  };

  useEffect(() => {
    if(vehicleOrderServices?.registrationService.uuid === RENEW_REGISTRATION_SERVICE_ID){
      getRegistrationServiceDocuments();
    }
    else{
      getVehicleDocumentsByVehicleClassName();
    }
    getDocumentsByVehicleByRegistrationServiceAndVehicleUseandVehicleClass();
  }, [vehicleOrderServices]);

  // const [extraDocuments, setExtraDocuments] = useState<any[]>([]);

  // const getDocumentsByVehicleByRegistrationServiceAndVehicleUseandVehicleClass =
  //   async () => {
  //     await vehicleService
  //       .getDocumentsByVehicleByRegistrationServiceAndVehicleUseandVehicleClass(
  //         `${vehicleOrderServices?.registrationService.uuid}`,
  //         `${vehicleOrderServices?.vehicleUse?.uuid}`,
  //         `${vehicleOrderServices?.vehicleClass?.name}`
  //       )
  //       .then(
  //         (response) => {
  //           console.log(response)
  //           const documents = [...response.data.data];
  //           setExtraDocuments(documents);
  //         },
  //         (error) => {}
  //       );
  //   };

  // const getVehicleDocumentsByVehicleClassName = async () => {
  //   await vehicleService
  //     .getDocumentsByVehicleRegistrationServiceAndVehicleClass(
  //       `${vehicleOrderServices?.registrationService.uuid}`,
  //       `${vehicleOrderServices?.vehicleClass?.name}`
  //     )
  //     .then(
  //       (response) => {
  //         console.log(response)
  //         const documents = [...response.data.data];
  //         setNewVehicleRegistrationDocuments(documents);
  //       },
  //       (error) => {}
  //     );
  // };

  // useEffect(() => {
  //   getVehicleDocumentsByVehicleClassName();
  //   getDocumentsByVehicleByRegistrationServiceAndVehicleUseandVehicleClass();
  // }, [vehicleOrderServices]);

  useEffect(() => {
    setCmrChecked(
      vehicleOrderServices ? vehicleOrderServices?.requiredCRM : false
    );
  }, [vehicleOrderServices?.requiredCRM]);

  const getServiceTypes = async () => {
    await vehicleService
      .getVehicleServiceType(
        `${vehicleOrderServices?.registrationService.uuid}`,
        `${vehicleOrderServices?.vehicleClass.name}`
      )
      .then(
        (response) => {
          setServiceDeliveryType(response.data.data);
        },
        (error) => {}
      );
  };

  useEffect(() => {
    getServiceTypes();
  }, [vehicleOrderServices?.registrationService]);

  return (
    <>
      <div className="RegistrationSummaryItem row justify-content-center pt-3 pb-5">
        <div className="col-12 col-md-6 col-lg-6 col-xl-5">
          <div className="card border-0 mt-3 mt-md-0 mb-4 px-4">
            <div className="d-flex justify-content-between align-items-center">
              <div className="card-header bg-white pt-3 px-0">
                Vehicle Information
              </div>
              <p className="edit-text" onClick={goToVehicleInformation}>
                Edit
              </p>
            </div>
            <div className="card-body pt-0 px-0">
              <div className="row border-top pb-2 pt-3">
                {vehicleOrderServices?.plateNumber && (
                  <>
                    <div className="col-6">
                      <div className="label">Plate Number</div>
                    </div>
                    <div className="col-6 text-end">
                      <div className="value">
                        {vehicleOrderServices?.plateNumber}
                      </div>
                    </div>
                  </>
                )}

                {vehicleOrderServices?.vehicleIdentificationNumber && (
                  <>
                    <div className="col-6">
                      <div className="label">Vehicle Identification Number</div>
                    </div>
                    <div className="col-6 text-end">
                      <div className="value">
                        {vehicleOrderServices?.vehicleIdentificationNumber}
                      </div>
                    </div>
                  </>
                )}
                <div className="col-6">
                  <div className="label">Make</div>
                </div>
                <div className="col-6 text-end">
                  <div className="value">
                    {vehicleOrderServices?.vehicleMake?.name}
                  </div>
                </div>

                <div className="col-6">
                  <div className="label">Model</div>
                </div>

                <div className="col-6 text-end">
                  <div className="value">
                    {vehicleOrderServices?.vehicleModel?.name}
                  </div>
                </div>

                <div className="col-6">
                  <div className="label">Year</div>
                </div>
                <div className="col-6 text-end">
                  <div className="value">
                    {vehicleOrderServices?.vehicleYear.year}
                  </div>
                </div>

                {vehicleOrderServices?.vehicleColor && (
                  <>
                    <div className="col-6">
                      <div className="label">Color</div>
                    </div>
                    <div className="col-6 text-end">
                      <div className="value text-capitalize">
                        {vehicleOrderServices?.vehicleColor}
                      </div>
                    </div>
                  </>
                )}

                {vehicleOrderServices?.engineNumber && (
                  <>
                    <div className="col-6">
                      <div className="label">Engine number</div>
                    </div>

                    <div className="col-6 text-end">
                      <div className="value">
                        {vehicleOrderServices?.engineNumber}
                      </div>
                    </div>
                  </>
                )}

                {vehicleOrderServices?.chasisNumber && (
                  <>
                    <div className="col-6">
                      <div className="label">Chasis number</div>
                    </div>

                    <div className="col-6 text-end">
                      <div className="value">
                        {vehicleOrderServices?.chasisNumber}
                      </div>
                    </div>
                  </>
                )}

                <div className="col-6">
                  <div className="label">Vehicle Class</div>
                </div>

                <div className="col-6 text-end">
                  <div className="value">
                    {vehicleOrderServices?.vehicleClass?.name}
                  </div>
                </div>

                <div className="col-6">
                  <div className="label">Vehicle Use</div>
                </div>

                <div className="col-6 text-end">
                  <div className="value">
                    {vehicleOrderServices?.vehicleUse?.name}
                  </div>
                </div>

                <div className="col-6">
                  <div className="label">Vehicle Cylinder</div>
                </div>

                <div className="col-6 text-end">
                  <div className="value">
                    {vehicleOrderServices?.vehicleCylinder?.name}
                  </div>
                </div>

                <div className="col-6">
                  <div className="label">CMR(Central Motor Registry)</div>
                </div>

                <div className="col-6 text-end">
                  <div className="value">
                    {vehicleOrderServices?.requiredCRM ? "Yes" : "No"}
                  </div>
                </div>

                {vehicleOrderServices?.nin &&
                  vehicleOrderServices?.requiredCRM && (
                    <>
                      <div className="col-6">
                        <div className="label">NIN</div>
                      </div>

                      <div className="col-6 text-end">
                        <div className="value">{vehicleOrderServices?.nin}</div>
                      </div>
                    </>
                  )}

                {vehicleOrderServices?.requiredCRM && (
                  <>
                    {vehicleOrderServices?.stateLiencingOffice && (
                      <>
                        <div className="col-6">
                          <div className="label">State licensing office</div>
                        </div>

                        <div className="col-6 text-end">
                          <div className="value">
                            {vehicleOrderServices?.stateLiencingOffice?.name}
                          </div>
                        </div>
                      </>
                    )}

                    {vehicleOrderServices?.stateRequestingOffice && (
                      <>
                        <div className="col-6">
                          <div className="label">State Requesting office</div>
                        </div>

                        <div className="col-6 text-end">
                          <div className="value">
                            {vehicleOrderServices?.stateRequestingOffice?.name}
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="card border-0 mt-3 mt-md-0 px-4">
            <div className="d-flex justify-content-between align-items-center border-bottom">
              <div className="card-header bg-white pt-3 px-0">
                Vehicle Owner's Information
              </div>
              <div className="edit-text-container">
                <p
                  className="edit-text mr-4"
                  onClick={goToVehicleOwnerInformation}
                >
                  Edit
                </p>
              </div>
            </div>

            {showVehicleOwnerInfoPanel && (
              <div className="card-body pt-0 px-0">
                {vehicleOrderServices?.registrationService.uuid ===
                CHANGE_OF_OWNERSHIP_SERVICE_ID ? (
                  <>
                    <div className="row border-bottom pb-2 mt-2 px-0">
                      <p className="mb-0 text-14px font-bold">
                        REGISTERED OWNER
                      </p>
                      <div className="col-6">
                        <div className="label">First Name</div>
                      </div>
                      <div className="col-6 text-end">
                        <div className="value">
                          {vehicleOrderServices?.firstName}
                        </div>
                      </div>

                      <div className="col-6">
                        <div className="label">Last Name</div>
                      </div>
                      <div className="col-6 text-end">
                        <div className="value">
                          {vehicleOrderServices?.lastName}
                        </div>
                      </div>

                      <div className="col-6">
                        <div className="label">Address</div>
                      </div>

                      <div className="col-6 text-end">
                        <div className="value">
                          {vehicleOrderServices?.address}
                        </div>
                      </div>

                      <div className="col-6">
                        <div className="label">Phone number</div>
                      </div>

                      <div className="col-6 text-end">
                        <div className="value">
                          {vehicleOrderServices?.phoneNumber}
                        </div>
                      </div>
                    </div>
                    <div className="row border-bottom pb-2 mt-2 px-0">
                      <p className="mb-0 text-14px font-bold">NEW OWNER</p>
                      <div className="col-6">
                        <div className="label">First Name</div>
                      </div>
                      <div className="col-6 text-end">
                        <div className="value">
                          {vehicleOrderServices?.newOwnerFirstName}
                        </div>
                      </div>

                      <div className="col-6">
                        <div className="label">Last Name</div>
                      </div>
                      <div className="col-6 text-end">
                        <div className="value">
                          {vehicleOrderServices?.newOwnerLastName}
                        </div>
                      </div>

                      <div className="col-6">
                        <div className="label">Address</div>
                      </div>

                      <div className="col-6 text-end">
                        <div className="value">
                          {vehicleOrderServices?.newOwnerAddress}
                        </div>
                      </div>

                      <div className="col-6">
                        <div className="label">Phone number</div>
                      </div>

                      <div className="col-6 text-end">
                        <div className="value">
                          {vehicleOrderServices?.newOwnerPhoneNumber}
                        </div>
                      </div>

                      <div className="col-6">
                        <div className="label">Email</div>
                      </div>

                      <div className="col-6 text-end">
                        <div className="value">
                          {vehicleOrderServices?.newOwnerEmail}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="row border-bottom pb-2 mt-2 px-0">
                    <div className="col-6">
                      <div className="label">First Name</div>
                    </div>
                    <div className="col-6 text-end">
                      <div className="value">
                        {vehicleOrderServices?.firstName}
                      </div>
                    </div>

                    <div className="col-6">
                      <div className="label">Last Name</div>
                    </div>
                    <div className="col-6 text-end">
                      <div className="value">
                        {vehicleOrderServices?.lastName}
                      </div>
                    </div>

                    <div className="col-6">
                      <div className="label">Email</div>
                    </div>
                    <div className="col-6 text-end">
                      <div className="value">{vehicleOrderServices?.email}</div>
                    </div>

                    <div className="col-6">
                      <div className="label">Phone number</div>
                    </div>

                    <div className="col-6 text-end">
                      <div className="value">
                        {vehicleOrderServices?.phoneNumber}
                      </div>
                    </div>

                    <div className="col-6">
                      <div className="label">Address</div>
                    </div>

                    <div className="col-6 text-end">
                      <div className="value">
                        {vehicleOrderServices?.address}
                      </div>
                    </div>

                    <div className="col-6">
                      <div className="label">State</div>
                    </div>

                    <div className="col-6 text-end">
                      <div className="value">
                        {vehicleOrderServices?.state.name}
                      </div>
                    </div>

                    <div className="col-6">
                      <div className="label">LGA</div>
                    </div>

                    <div className="col-6 text-end">
                      <div className="value">
                        {vehicleOrderServices?.localGovernment?.name}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>

          {/* {(showUploadedDocumentsForCOO && vehicleOrderServices?.registrationService.uuid === CHANGE_OF_OWNERSHIP_SERVICE_ID)  && */}
          {vehicleOrderServices?.registrationService.uuid ===
            CHANGE_OF_OWNERSHIP_SERVICE_ID && (
            <div className="col-12 mt-3 mt-md-0">
              <div className="card border-0 mt-4 px-4">
                <div className="card-header bg-white pt-3 border-bottom px-0 d-flex justify-content-between align-items-center">
                  Documents
                  <p className="edit-text" onClick={goToEditDocument}>
                    Edit
                  </p>
                </div>
                <div className="card-body pt-0 px-0 mt-4">
                  {vehicleOrderServices.uploadedDocuments.map((document) => {
                    return (
                      <div className="d-flex justify-content-between document-container">
                        <div className="d-flex">
                          <img
                            src={temporaryPngImageUrl}
                            alt=""
                            height="20px"
                          />
                          <p className="doc-name">
                            {ReplaceCharacter(document.documentName, "_", " ")}
                          </p>
                        </div>
                        <p className="doc-size">1MB</p>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="col-12 col-md-6 col-lg-6 col-xl-5 mt-3 mt-md-0">
          <div className="col-12 mb-4">
            <div className="card px-4 py-1 border-0 delivery-details">
              <div className="card-header profile-title px-0 border-bottom pb-1 d-flex justify-content-between align-items-center">
                Delivery Information
                <p className="edit-text" onClick={editDeliveryDetails}>
                  Edit
                </p>
              </div>

              {vehicleOrderServices?.localGovernmentOrders.length === 0 && (
                <div className="row  pb-2 mt-2">
                  <div className="col-12">
                    <div className="form-group d-flex form-crm-input">
                      <input
                        type="checkbox"
                        id="requiredCRM"
                        className="my-auto me-2"
                        checked={!showDeliveryDetailsForm}
                        onChange={handleUseDeliveryDetails}
                      />
                      <label htmlFor="requiredCRM" className="my-auto">
                        Use Vehicle Owner's Information for documents delivery
                      </label>
                    </div>
                  </div>
                </div>
              )}

              {/* {(showEditDeliveryDetailsForm || (showDeliveryDetailsForm && vehicleOrderServices?.localGovernmentOrders.length === 0)) && */}
              {(showEditDeliveryDetailsForm ||
                (showDeliveryDetailsForm &&
                  vehicleOrderServices?.localGovernmentOrders.length === 0)) &&
                !showDeliveryDetailsSummary && (
                  <>
                    <div className="card-body pt-0 px-0">
                      <div className="form-group mt-3">
                        <label htmlFor="address">Address</label>
                        <input
                          type="text"
                          id="address"
                          className={`form-control ${
                            errors.address ? "is-invalid" : ""
                          }`}
                          {...register("address", {
                            required: "This field is required",
                            onChange: handleDeliveryForm,
                          })}
                        />
                        {errors.address && (
                          <small className="error">
                            {errors.address.message}
                          </small>
                        )}
                      </div>
                      <div
                        className="form-group mt-3"
                        title="Service available within Lagos. Other locations coming soon."
                      >
                        <label htmlFor="state">State</label>
                        <select
                          disabled
                          id="state"
                          className={`form-control ${
                            errors.state ? "is-invalid" : ""
                          }`}
                          {...register("state", {
                            required: "This field is required",
                            onChange: (e) => {
                              handleState(e);
                              handleDeliveryForm("state", e.target.value);
                            },
                          })}
                        >
                          <option>select state</option>
                          {states?.map((stateItem: State, index: Key) => (
                            <option key={index} value={stateItem.uuid}>
                              {stateItem.name}
                            </option>
                          ))}
                        </select>
                        {errors.state && (
                          <small className="error">
                            {errors.state.message}
                          </small>
                        )}
                      </div>
                      <div className="form-group mt-3">
                        <label htmlFor="lga">L.G.A</label>
                        <Controller
                          name="localGovernmentUuid"
                          control={control}
                          rules={{ required: "Field cannot be empty" }}
                          render={({ field }) => (
                            <Select
                              options={lgas?.map((lga: Lga) => ({
                                value: lga.id,
                                label: lga.name,
                              }))}
                              onChange={(selectedOption) => {
                                field.onChange(selectedOption?.value);
                                handleDeliveryForm(
                                  "localGovernmentUuid",
                                  selectedOption?.value
                                );
                              }}
                              placeholder="Select L.G.A"
                              className={`react-select-container ${
                                errors.localGovernmentUuid ? "is-invalid" : ""
                              }`}
                              classNamePrefix="react-select"
                              styles={customStyles}
                            />
                          )}
                        />
                        {errors.localGovernmentUuid && (
                          <small className="error">
                            {errors.localGovernmentUuid.message}
                          </small>
                        )}
                      </div>
                      <div className="form-group mt-3">
                        <label htmlFor="contactName">Contact Name</label>
                        <input
                          type="text"
                          id="contactName"
                          className={`form-control ${
                            errors.contact_name ? "is-invalid" : ""
                          }`}
                          {...register("contact_name", {
                            required: "This field is required",
                            onChange: handleDeliveryForm,
                          })}
                        />
                        {errors.contact_name && (
                          <small className="error">
                            {errors.contact_name.message}
                          </small>
                        )}
                      </div>

                      <div className="form-group mt-3">
                        <label htmlFor="contact_phone">Phone Number</label>
                        <Controller
                          name="contact_phone"
                          control={control}
                          rules={{ required: "Phone number is required" }}
                          render={({
                            field: { onChange, onBlur, value, ref },
                            fieldState: { error },
                          }) => (
                            <>
                              <PhoneNumberInput
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                                className={`form-control ${
                                  error ? "is-invalid" : ""
                                }`}
                              />
                              {error && (
                                <div className="invalid-feedback">
                                  {error.message}
                                </div>
                              )}
                            </>
                          )}
                        />
                      </div>
                    </div>
                    {/* <button className="btn checkout-btn" disabled={!deliveryDetailsFormIsValid} onClick={processDeliveryInformation}>Save Details {processingDeliveryDetails && <span className="spinner-border spinner-border-sm"></span>}</button> */}
                    <button
                      className="btn checkout-btn"
                      disabled={
                        !deliveryDetailsFormIsValid ||
                        !isPhoneNumberValid(getValues("contact_phone"))
                      }
                      onClick={processDeliveryInformation}
                    >
                      Save Details{" "}
                      {processingDeliveryDetails && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                    </button>
                  </>
                )}

              {/* {(!showEditDeliveryDetailsForm && (vehicleOrderServices?.localGovernmentOrders && vehicleOrderServices?.localGovernmentOrders.length > 0)) && */}
              {((!showEditDeliveryDetailsForm &&
                vehicleOrderServices?.localGovernmentOrders &&
                vehicleOrderServices?.localGovernmentOrders.length > 0) ||
                showDeliveryDetailsSummary) && (
                <div className="card-body pt-0 px-0">
                  <div className="row pb-1 pt-1">
                    <div className="col-6">
                      <div className="label">Address</div>
                    </div>
                    <div className="col-6 text-end">
                      <div className="value">
                        {
                          vehicleOrderServices?.localGovernmentOrders[0]
                            ?.address
                        }
                      </div>
                    </div>

                    <div className="col-6">
                      <div className="label">State</div>
                    </div>
                    <div className="col-6 text-end">
                      <div className="value">
                        {
                          vehicleOrderServices?.localGovernmentOrders[0]?.state
                            ?.name
                        }
                      </div>
                    </div>

                    <div className="col-6">
                      <div className="label">L.G.A</div>
                    </div>
                    <div className="col-6 text-end">
                      <div className="value">
                        {
                          vehicleOrderServices?.localGovernmentOrders[0]
                            ?.localGovernment?.name
                        }
                      </div>
                    </div>

                    <div className="col-6">
                      <div className="label">Contact Name</div>
                    </div>
                    <div className="col-6 text-end">
                      <div className="value">
                        {
                          vehicleOrderServices?.localGovernmentOrders[0]
                            ?.contact_name
                        }
                      </div>
                    </div>

                    <div className="col-6">
                      <div className="label">Contact Number</div>
                    </div>
                    <div className="col-6 text-end">
                      <div className="value">
                        {
                          vehicleOrderServices?.localGovernmentOrders[0]
                            ?.contact_phone
                        }
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="card document-details-card p-0 border-0">
            <div className="card-header pb-1 px-0 pt-1 mx-4">
              <div className="row h-100">
                <div className="col-md-12 text-center my-auto d-inline-flex">
                  Documents you will get
                </div>
              </div>
            </div>

            <div className="card-body pt-3 px-0">
              <div className="container-fluid">
                <div className="row px-4">
                  {(vehicleOrderServices?.registrationService.uuid ===
                    NEW_REGISTRATION_SERVICE_ID ||
                    vehicleOrderServices?.registrationService.uuid ===
                      CHANGE_OF_OWNERSHIP_SERVICE_ID) && (
                    <>
                      {newVehicleRegistrationDocuments?.map(
                        (doc: VehicleDocument, index: number) => {
                          return doc.documentName.toLowerCase() === "cmr" &&
                            !vehicleOrderServices.requiredCRM ? (
                            ""
                          ) : (
                            <div key={index} className="col-md-6">
                              <div className="ro label-inner">
                                <svg
                                  width="18"
                                  height="18"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M9 17.75C8.9 17.75 8.81 17.73 8.71 17.69C8.43 17.58 8.25 17.3 8.25 17V11C8.25 10.59 8.59 10.25 9 10.25C9.41 10.25 9.75 10.59 9.75 11V15.19L10.47 14.47C10.76 14.18 11.24 14.18 11.53 14.47C11.82 14.76 11.82 15.24 11.53 15.53L9.53 17.53C9.39 17.67 9.19 17.75 9 17.75Z"
                                    fill="#00163A"
                                  />
                                  <path
                                    d="M9.00141 17.7514C8.81141 17.7514 8.62141 17.6814 8.47141 17.5314L6.47141 15.5314C6.18141 15.2414 6.18141 14.7614 6.47141 14.4714C6.76141 14.1814 7.24141 14.1814 7.53141 14.4714L9.53141 16.4714C9.82141 16.7614 9.82141 17.2414 9.53141 17.5314C9.38141 17.6814 9.19141 17.7514 9.00141 17.7514Z"
                                    fill="#00163A"
                                  />
                                  <path
                                    d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H14C14.41 1.25 14.75 1.59 14.75 2C14.75 2.41 14.41 2.75 14 2.75H9C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V10C21.25 9.59 21.59 9.25 22 9.25C22.41 9.25 22.75 9.59 22.75 10V15C22.75 20.43 20.43 22.75 15 22.75Z"
                                    fill="#00163A"
                                  />
                                  <path
                                    d="M22 10.7485H18C14.58 10.7485 13.25 9.41852 13.25 5.99852V1.99852C13.25 1.69852 13.43 1.41852 13.71 1.30852C13.99 1.18852 14.31 1.25852 14.53 1.46852L22.53 9.46852C22.74 9.67852 22.81 10.0085 22.69 10.2885C22.57 10.5685 22.3 10.7485 22 10.7485ZM14.75 3.80852V5.99852C14.75 8.57852 15.42 9.24852 18 9.24852H20.19L14.75 3.80852Z"
                                    fill="#00163A"
                                  />
                                </svg>
                                <p>{doc.documentName}</p>
                              </div>
                            </div>
                          );
                        }
                      )}
                      {extraDocuments?.map(
                        (doc: VehicleDocument, index: number) => {
                          return doc.documentName.toLowerCase() === "cmr" &&
                            !vehicleOrderServices.requiredCRM ? (
                            ""
                          ) : (
                            <div key={index} className="col-md-6">
                              <div className="ro label-inner">
                                <svg
                                  width="18"
                                  height="18"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M9 17.75C8.9 17.75 8.81 17.73 8.71 17.69C8.43 17.58 8.25 17.3 8.25 17V11C8.25 10.59 8.59 10.25 9 10.25C9.41 10.25 9.75 10.59 9.75 11V15.19L10.47 14.47C10.76 14.18 11.24 14.18 11.53 14.47C11.82 14.76 11.82 15.24 11.53 15.53L9.53 17.53C9.39 17.67 9.19 17.75 9 17.75Z"
                                    fill="#00163A"
                                  />
                                  <path
                                    d="M9.00141 17.7514C8.81141 17.7514 8.62141 17.6814 8.47141 17.5314L6.47141 15.5314C6.18141 15.2414 6.18141 14.7614 6.47141 14.4714C6.76141 14.1814 7.24141 14.1814 7.53141 14.4714L9.53141 16.4714C9.82141 16.7614 9.82141 17.2414 9.53141 17.5314C9.38141 17.6814 9.19141 17.7514 9.00141 17.7514Z"
                                    fill="#00163A"
                                  />
                                  <path
                                    d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H14C14.41 1.25 14.75 1.59 14.75 2C14.75 2.41 14.41 2.75 14 2.75H9C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V10C21.25 9.59 21.59 9.25 22 9.25C22.41 9.25 22.75 9.59 22.75 10V15C22.75 20.43 20.43 22.75 15 22.75Z"
                                    fill="#00163A"
                                  />
                                  <path
                                    d="M22 10.7485H18C14.58 10.7485 13.25 9.41852 13.25 5.99852V1.99852C13.25 1.69852 13.43 1.41852 13.71 1.30852C13.99 1.18852 14.31 1.25852 14.53 1.46852L22.53 9.46852C22.74 9.67852 22.81 10.0085 22.69 10.2885C22.57 10.5685 22.3 10.7485 22 10.7485ZM14.75 3.80852V5.99852C14.75 8.57852 15.42 9.24852 18 9.24852H20.19L14.75 3.80852Z"
                                    fill="#00163A"
                                  />
                                </svg>
                                <p>{doc.documentName}</p>
                              </div>
                            </div>
                          );
                        }
                      )}
                    </>
                  )}

                  {vehicleOrderServices?.registrationService.uuid ===
                    RENEW_REGISTRATION_SERVICE_ID && (
                    <>
                      {
                        refreshingDocuments ? 
                        <p>loading...</p> :
                        <>
                          {newVehicleRegistrationDocuments?.map(
                            (doc: VehicleDocument, index: number) => {
                              return doc.documentName.toLowerCase() === "cmr" &&
                                !vehicleOrderServices.requiredCRM ? (
                                ""
                              ) : (
                                <div key={index} className="col-md-6">
                                  <div className="row label-inner-checkbox">
                                    <div className="col-1 px-0">
                                      <input
                                        type="checkbox"
                                        id={doc.documentName}
                                        checked={checkDocument(doc, 'default')}
                                        name={doc.documentName}
                                        className="active"
                                        value={doc.documentName}
                                        onChange={(e) =>
                                          handleSelectDocument(index, e, 'default')
                                        }
                                      ></input>
                                    </div>

                                    <div className="col-11">
                                      <label htmlFor={doc.documentName}>
                                        {doc.documentName}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          )}
                          {extraDocuments?.map(
                            (doc: VehicleDocument, index: number) => {
                              return doc.documentName.toLowerCase() === "cmr" &&
                                !vehicleOrderServices.requiredCRM ? (
                                ""
                              ) : (
                                <div key={index} className="col-md-6">
                                  <div className="row label-inner-checkbox">
                                    <div className="col-1 px-0">
                                      <input
                                        type="checkbox"
                                        id={doc.documentName}
                                        checked={checkDocument(doc, 'extra')}
                                        name={doc.documentName}
                                        className="active"
                                        value={doc.documentName}
                                        onChange={(e) =>
                                          handleSelectDocument(index, e, 'extra')
                                        }
                                      ></input>
                                    </div>

                                    <div className="col-11">
                                      <label htmlFor={doc.documentName}>
                                        {doc.documentName}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </>
                      }
                    </>
                  )}

                  {/* {
                                    (vehicleOrderServices?.registrationService.uuid === RENEW_REGISTRATION_SERVICE_ID) &&
                                    // (vehicleOrderServices?.registrationService.uuid === RENEW_REGISTRATION_SERVICE_ID && checkedState?.length > 0) &&
                                    (
                                        <>
                                            {newVehicleRegistrationDocuments?.map((doc: VehicleDocument, index:number)=>{
                                                return (
                                                    (doc.documentName.toLowerCase() === 'cmr' && !vehicleOrderServices.requiredCRM ) ? '' :
                                                    <div key={index} className="col-md-6">
                                                        <div className="row label-inner-checkbox">
                                                               <div className="col-1 px-0">
                                                                   <input type='checkbox' id={doc.documentName} checked={checkDocument(doc)} name={doc.documentName} className="active" value={doc.documentName} onChange={(e) => handleSelectDocument(index, e)}></input>
                                                               </div>
               
                                                               <div className="col-11">
                                                                   <label htmlFor={doc.documentName}>{doc.documentName}</label>
                                                               </div>  
                                                           </div>     
                                                    </div>  
                                                )
                                                
                                            })}
                                            {extraDocuments?.map((doc: VehicleDocument, index:number)=>{
                                                return (
                                                    (doc.documentName.toLowerCase() === 'cmr' && !vehicleOrderServices.requiredCRM ) ? '' :
                                                    <div key={index} className="col-md-6">
                                                        <div className="row label-inner-checkbox">
                                                               <div className="col-1 px-0">
                                                                   <input type='checkbox' id={doc.documentName} checked={checkDocument(doc)} name={doc.documentName} className="active" value={doc.documentName} onChange={(e) => handleSelectDocument(index, e)}></input>
                                                               </div>
               
                                                               <div className="col-11">
                                                                   <label htmlFor={doc.documentName}>{doc.documentName}</label>
                                                               </div>  
                                                           </div>     
                                                    </div>  
                                                )
                                                
                                            })}
                                        </>
                                    )
                                } */}
                </div>
              </div>

              <div className="service-type px-3 py-2 mt-3">
                <div className="container-fluid h-100">
                  <div className="row h-100">
                    <div className="col-12 my-auto">
                      <div className="title">Service type</div>
                      {serviceDeliveryType &&
                        serviceDeliveryType.map(
                          (serviceType: ServiceDeliveryType, index: Key) => {
                            return (
                              <div className="row h-100 mt-2" key={index}>
                                <div className="col-8 type my-auto d-flex h-100">
                                  <input
                                    defaultChecked={serviceType.default}
                                    type="radio"
                                    name="serice_type"
                                    id={`standard_delivery_${serviceType.uuid}`}
                                    value={JSON.stringify(serviceType)}
                                    onChange={handleServiceDeliveryType}
                                  />{" "}
                                  <label
                                    htmlFor={`standard_delivery_${serviceType.uuid}`}
                                    className="ms-2 my-auto"
                                  >
                                    {" "}
                                    {serviceType.service_name} Delivery -{" "}
                                    {
                                      serviceType.registrationService
                                        .processingTime
                                    }{" "}
                                    days{" "}
                                  </label>
                                </div>
                                <div className="col-4 amount h-100 text-end">
                                  ₦
                                  {CurrencyFormatter(Number(serviceType.price))}
                                </div>
                              </div>
                            );
                          }
                        )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="total-balance px-4 border-0">
                <div className="row h-100 pt-2">
                  <div className="col-6 my-auto">Total</div>
                  <div className="col-6 text-end my-auto">
                    ₦
                    {CurrencyFormatter(
                      Number(vehicleOrderServices?.totalAmount)
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {(vehicleOrderServices?.registrationService.uuid ===
            NEW_REGISTRATION_SERVICE_ID ||
            vehicleOrderServices?.registrationService.uuid ===
              CHANGE_OF_OWNERSHIP_SERVICE_ID) && (
            <button
              className="btn checkout-btn"
              disabled={
                vehicleOrderServices?.localGovernmentOrders.length === 0 &&
                showDeliveryDetailsForm &&
                !deliveryDetailsFormIsValid
              }
              onClick={() => makePayment(CURRENT_PAYMENT_MODE)}
            >
              Make Payment{" "}
              {requestPayment && (
                <span className="spinner-border spinner-border-sm"></span>
              )}
            </button>
          )}

          {vehicleOrderServices?.registrationService.uuid ===
            RENEW_REGISTRATION_SERVICE_ID && (
            // <button className="btn checkout-btn" disabled={(showDeliveryDetailsForm && !deliveryDetailsFormIsValid) || selectedDocuments.length < 1} onClick={makePayment}>Make Payment {requestPayment && <span className="spinner-border spinner-border-sm"></span>}</button>
            <button
              className="btn checkout-btn"
              disabled={
                (showDeliveryDetailsForm && !deliveryDetailsFormIsValid) ||
                vehicleOrderServices?.registrationServiceDocumentOrders
                  ?.length < 1
              }
              onClick={() => makePayment(CURRENT_PAYMENT_MODE)}
            >
              Make Payment{" "}
              {requestPayment && (
                <span className="spinner-border spinner-border-sm"></span>
              )}
            </button>
          )}
        </div>

        <div className="col-12 col-md-6 col-lg-6 col-xl-5"></div>

        <ToastContainer hideProgressBar={true} pauseOnHover autoClose={5000} />
      </div>

      <div className="modal fade" id="paymentFailureModal" role="dialog" aria-labelledby="paymentFailureModalLabel" aria-hidden="true"> 
        <PaymentFailureCard />
      </div>

      <div className="modal fade" id="makePaymentModal" role="dialog" aria-labelledby="makePaymentModalLabel" aria-hidden="true"> 
        <MakePayment orderDetails={vehicleOrderServices}/>
      </div>
    </>
  );
};

export default RegistrationSummaryItem;

// import React, { FC, Key, useEffect, useRef, useState } from 'react'
// import { Link, useNavigate, useParams } from 'react-router-dom';
// import { ServiceDeliveryType, ServiceOrderDataType } from 'Models';
// import { deliveryService, orderService, userService, vehicleService } from 'Services';
// import Swal from 'sweetalert2';
// import { vehicleServiceDocument } from 'Models/vehicleServiceType';
// import "./RegistrationSummaryItem.scss"
// import { CurrencyFormatter } from 'Helpers';
// import { useForm, useFormState, Controller } from 'react-hook-form';

// import { VehicleDocument } from '@/Models/vehicleDocument';
// import { Lga, State } from '@/Models/location';
// import { CHANGE_OF_OWNERSHIP_SERVICE_ID, INSURANCE_DOCUMENT_NAME, LAGOS_STATE_ID, NEW_REGISTRATION_SERVICE_ID, RENEW_REGISTRATION_SERVICE_ID, ROAD_WORTHINESS_DOCUMENT_NAME, VEHICLE_LICENSE_DOCUMENT_NAME } from '../../../../../Constants/RegistrationConstant';
// import { ReplaceCharacter } from '../../../../../Helpers/ReplaceCharacter';
// import CustomToastr from '../../../../../Components/CustomToastr/CustomToastr';
// import { ToastContainer, toast } from "react-toastify";
// import PhoneNumberInput from '../../../../../Components/PhoneNumberInput/PhoneNumberInput';
// import { isPhoneNumberValid } from '../../../../../Helpers/ValidatePhoneNumber';
// import Select from 'react-select'

// interface propsType {
//     module?: string | undefined,
//     refreshServiceOrder?: ()=>void
//     vehicleOrderServices?: ServiceOrderDataType | undefined
// }

// interface vehicleOrderServiceDocumentType {
//     id: string;
//     time: string;
//     name: string;
//     vehicleClassName: string;
//     subClassName: string;
//     vehicleClassSize: string;
//     orderType: string;
//     costPrice: string;
//     sellingPrice: string;
//     isRequired: boolean;
// }

// type formType = {
//     address: string,
//     localGovernmentUuid: string,
//     state: string,
//     contact_phone: string,
//     primary: boolean,
//     contact_name: string,
//   }

// const customStyles = {
//     control: (base: any) => ({
//         ...base,
//         backgroundColor: 'transparent',
//         border: '1px solid #DEE3EC !important',
//         height: '46px',
//         fontSize: '12px',
//         paddingTop: '0px',
//         boxShadow: 'none'
//     }),
//     option: (base: any, state: any) => ({
//         ...base,
//         backgroundColor: state.isSelected ? '#186AFF' : 'white',
//         color: state.isSelected ? 'white' : 'black',
//         '&:hover': { backgroundColor: '#709EFF', color: 'white' },
//     }),
//     menu: (base: any) => ({
//         ...base,
//         borderRadius: '5px',
//         marginTop: '5px',
//         fontSize: '12px'
//     })
// };

// const RegistrationSummaryItem:FC<propsType> = ({module, vehicleOrderServices, refreshServiceOrder}) => {

//     console.log(vehicleOrderServices)

//    const navigate = useNavigate();

//    const limit = 100;
//    const offset = 0;

//    const { service_order_id } = useParams<{ service_order_id: string }>()

//     const { control, register, watch, handleSubmit, trigger, formState: { errors, isValid }, setValue, getValues } = useForm<formType>();

//     const [selectState, setSelectedState] = useState<string>("")

//     const [selectLgas, setSelectedLgas] = useState<Array<{name: string, id: number}>>([])

//     const [requestAddr, setRequestAddr] = useState(false)
//     const [newAddress, setNewAddress] = useState(false)

//     const [cssPhNumber, setCssPhNum]  = useState<string>('')
//     const [phoneNumbers, setPhoneNumbers] = useState<string>('')

//     const [newVehicleRegistrationDocuments, setNewVehicleRegistrationDocuments] = useState<VehicleDocument[]>()

//     const [states, setStates] = useState<Array<State>>()
//     const [lgas, setLgas] = useState<Array<Lga>>()
//     const [processingDeliveryDetails, setProcessingDeliveryDetails] = useState<boolean>(false)

//     const [showDeliveryDetailsForm, setShowDeliveryDetailsForm] = useState<boolean>(true)

//     const [showVehicleOwnerInfoPanel, setShowVehicleOwnerInfoPanel] = useState<boolean>(true)
//     const [showDeliveryDetailsPanel, setShowDeliveryDetailsPanel] = useState<boolean>(true)

//     const toggleVehicleOwnerInfoPanel = () => {
//         setShowVehicleOwnerInfoPanel(!showVehicleOwnerInfoPanel)
//     }

//     const toggleDeliveryDetailsPanel = () => {
//         setShowDeliveryDetailsPanel(!showDeliveryDetailsPanel)
//     }

//     const handleState = async (e: any) => {
//         const state_id = e?.target?.value || e;
//         await vehicleService.getLGAsByStateId(state_id).then((response)=>{
//             console.log("getService", response)
//             setLgas(response.data.data)
//           }, error=>{
//            console.log("getService error", error)
//         //    Swal.fire({
//         //        position: "top-end",
//         //        icon: "warning",
//         //        text: error.response.data.message,
//         //        showConfirmButton: false,
//         //        timer: 3000
//         //    });
//           })
//     }

//     const submit = async (data: formType) =>{
//         setRequestAddr(true)

//         const newData:any = [
//             {
//                 ...data,
//             state: selectState,
//             primary: true,
//             }
//         ]

//         await userService.createDeliveryAddress(newData).then((response)=>{
//             Swal.fire({
//                 position: "top-end",
//                 icon: "success",
//                 title: "Saved",
//                 text: "Delivery address addeed successfully",
//                 showConfirmButton: false,
//                 timer: 1600
//             });

//             setRequestAddr(false)
//             setNewAddress(!newAddress)

//         }, error =>{
//             setRequestAddr(false)
//             console.log("error address", error.response)

//             Swal.fire({
//                 position: "top-end",
//                 icon: "warning",
//                 title: "Error",
//                 text: error?.response?.data?.error,
//                 showConfirmButton: true
//             });
//         })
//     }

//   const validatePhoneNumber = (value: string) => {
//       return true
//     // Regular expression to validate phone number with country code
//     const phoneRegex = /^\+\d{1,3}-\d{3,14}$/;
//     return phoneRegex.test(value) || 'Phone number must have a leading country code (e.g., +23481456789..)';
//   };

//   const handleChange = () => {
//     trigger('contact_phone');
//   };

//   const checkPhNo = (phNumber: string) => {

//     if(phNumber.length === 14 && phNumber.substring(0, 4) === '+234') {
//         setPhoneNumbers(phNumber)
//         setCssPhNum('')
//     } else if (phNumber.length === 11 && phNumber.substring(0, 1) === '0') {
//         setCssPhNum('');
//         setPhoneNumbers(`+234${phNumber.slice(1)}`)
//     } else if(phNumber.length === 0 && !phNumber) {
//         setCssPhNum('')
//     }
//      else {
//         setCssPhNum('Invalid')
//     }
//   }

//   const [serviceDeliveryType, setServiceDeliveryType] = useState<ServiceDeliveryType[]>()

// //   const getServiceTypes = async () => {
// //     await deliveryService.getServiceTypeByregistrationServiceIdAndVehicleTypeId(`${vehicleOrderServices?.registrationService.uuid}`, `${vehicleOrderServices?.vehicleType?.uuid}`).then((response)=>{
// //         console.log("getServiceType data", response.data.data)
// //         setServiceDeliveryType(response.data.data)
// //     }, error => {
// //         console.log("error getServiceType", error.response)

// //         // Swal.fire({
// //         //     position: "top-end",
// //         //     icon: "warning",
// //         //     title: "Error",
// //         //     text: error?.response?.data?.error,
// //         //     showConfirmButton: true
// //         //   });
// //     })
// //   }

//   const getStates = async () =>{
//     await vehicleService.getStates().then((response) =>{
//         console.log("states", response.data)
//         setStates(response.data.data)
//     }, error => {

//         console.log("error make", error)
//         // Swal.fire({
//         //     position: "top-end",
//         //     icon: "warning",
//         //     text: error.response.data.message,
//         //     showConfirmButton: false,
//         //     timer: 3000
//         // });
//     })
// }

//   useEffect(()=>{
//     getStates()
//   }, [])

// //   useEffect(() => {
// //     if(vehicleOrderServices?.registrationService.uuid){
// //         getServiceTypes()
// //     }
// //   }, [vehicleOrderServices?.registrationService.uuid])

//   useEffect(() => {
//     let lagos = states?.find((state: State) => state.name.toLowerCase() === 'lagos')
//     setValue('state', lagos ? lagos.uuid : LAGOS_STATE_ID)
//     handleState(lagos ? lagos.uuid : LAGOS_STATE_ID)
//   }, [states])

//   const [selectServiceDeliveryType, setSelectServiceDeliveryType] = useState<ServiceDeliveryType>()

//   const handleServiceDeliveryType = async (e:React.ChangeEvent<HTMLInputElement>) => {
//      console.log("handleServiceDeliveryType", e.target.value)
//      const serviceType:ServiceDeliveryType = JSON.parse(e.target.value);
//      console.log(serviceType)
//      processServiceDeliveryType(serviceType)
//   }

//   const [documentCheckedState, setDocumentCheckedState] = useState([]);

//   const processServiceDeliveryType = async (selecttype:ServiceDeliveryType ) => {
//     console.log(selecttype)
//     await deliveryService.updateVehicleServiceTypeOrder({
//        amount: +selecttype.price,
//        vehicleServiceTypeUuid: selecttype.uuid,
//        orderUuid: `${service_order_id}`
//     }).then((response)=>{
//         console.log(response)
//         refreshServiceOrder && refreshServiceOrder()
//     }, error => {
//         console.log("error getServiceType", error.response)

//         Swal.fire({
//             position: "top-end",
//             icon: "warning",
//             title: "Error",
//             text: error?.response?.data?.error,
//             showConfirmButton: true
//           });
//     })
//   }

// //   const processServiceDeliveryType = async (selecttype:ServiceDeliveryType ) => {
// //     console.log(selecttype)
// //     await deliveryService.updateVehicleTypePriceOrder({
// //        amount: +selecttype.price,
// //        vehicleTypePriceId: selecttype.uuid,
// //        orderUuid: `${service_order_id}`
// //     }).then((response)=>{
// //         console.log(response)
// //         refreshServiceOrder && refreshServiceOrder()
// //     }, error => {
// //         console.log("error getServiceType", error.response)

// //         Swal.fire({
// //             position: "top-end",
// //             icon: "warning",
// //             title: "Error",
// //             text: error?.response?.data?.error,
// //             showConfirmButton: true
// //           });
// //     })
// //   }

// const [deliveryDetails, setDeliveryDetails] = useState<any>({
//     amount: vehicleOrderServices?.totalAmount,
//     orderId: service_order_id,
//     localGovernmentUuid: null,
//     address: null,
//     contact_name: null,
//     contact_phone: null
// })

// //   const handleDeliveryForm = (e:React.ChangeEvent<HTMLInputElement>) => {
// //       console.log(e)
// //     setDeliveryDetails(
// //         {
// //             ...deliveryDetails,
// //             [e.target.name]: e.target.value,
// //             amount: vehicleOrderServices?.totalAmount,
// //             contact_phone: getValues('contact_phone')
// //         }
// //     )
// //   }

// const handleDeliveryForm = (fieldName: string, value?:string) => {
//     console.log(fieldName)
//     console.log(value)
//   if(fieldName && value){
//       setDeliveryDetails(
//           {
//               ...deliveryDetails,
//               [fieldName]: value,
//               amount: vehicleOrderServices?.totalAmount,
//               contact_phone: getValues('contact_phone')
//           }
//       )
//   }
// }

//   const handleUseDeliveryDetails = (e:React.ChangeEvent<HTMLInputElement>) => {
//     console.log(e.target.checked)
//     setShowDeliveryDetailsForm(!showDeliveryDetailsForm)
//     console.log(showDeliveryDetailsForm)
//   }

// //   const handleUseDeliveryDetails = (e:React.ChangeEvent<HTMLInputElement>) => {
// //         console.log(e.target.checked)
// //       setShowDeliveryDetailsForm(e.target.checked)
// //       console.log(showDeliveryDetailsForm)
// //   }

//   const processDeliveryInformation = async () => {
//       console.log(deliveryDetails)
//       const payload = {...deliveryDetails, contact_phone: getValues('contact_phone')}
//       if(!payload.stateUuid){
//         payload.stateUuid = getValues('state') || LAGOS_STATE_ID
//       }
//       if(!payload.address){
//         payload.address = getValues('address')
//       }
//       if(!payload.contact_name){
//         payload.contact_name = getValues('contact_name')
//       }
//       if(!payload.contact_phone){
//         payload.contact_phone = getValues('contact_phone')
//       }
//       if(!payload.localGovernmentUuid){
//         payload.localGovernmentUuid = getValues('localGovernmentUuid')
//       }
//       console.log(payload)
//       return
//       setProcessingDeliveryDetails(true)
//       await deliveryService.saveDeliveryDetails(payload).then((response)=>{
//             console.log(response)
//             refreshServiceOrder && refreshServiceOrder()
//             setProcessingDeliveryDetails(false)
//             setShowDeliveryDetailsForm(false)
//             setShowDeliveryDetailsSummary(true)
//             toast(<CustomToastr message={`Delivery details added successfully`}/>);
//         }, error => {
//             setProcessingDeliveryDetails(false)
//             console.log("error getServiceType", error.response)

//             Swal.fire({
//                 position: "top-end",
//                 icon: "warning",
//                 title: "Error",
//                 text: error?.response?.data?.error,
//                 showConfirmButton: true
//                 });
//         })
//   }

//     const [deliveryDetailsFormIsValid, setDeliveryDetailsFormIsValid] = useState<boolean>(false)
//     useEffect(() => {
//         const fields: Array<keyof formType> = ['address', 'localGovernmentUuid', 'state', 'contact_phone', 'contact_name'];
//         const allFieldsFilled = fields.every(field => !!watch(field));

//         setDeliveryDetailsFormIsValid(allFieldsFilled)

//       }, [
//         watch('address'),
//         watch('localGovernmentUuid'),
//         watch('state'),
//         watch('contact_phone'),
//         watch('contact_name')
//       ]);

//  const [requestPayment, setRequestPayment] = useState(false)

//  const makePayment = async () => {
//     const payload = {
//         orderId: `${service_order_id}`,
//         totalOrderAmount: Number(vehicleOrderServices?.totalAmount)
//     }
//     setRequestPayment(true)
//     console.log(payload)
//     await orderService.initiatePayment(payload).then((response)=>{
//         console.log(response)
//          setRequestPayment(false)
//          console.log("Payment", response.data.data)

//          window.location.href = response.data.data.data
//     }, error =>{
//         console.log(error)
//         Swal.fire({
//             position: "top-end",
//             icon: "warning",
//             title: "Error",
//             text: error?.response?.data?.error?.message,
//             showConfirmButton: true
//         });
//         setRequestPayment(false)
//     })
//  }

//  const [cmrChecked, setCmrChecked] = useState(false)

//  const [temporaryPngImageUrl, setTemporaryImageUrl] = useState<string>("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAWCAYAAAAvg9c4AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAICSURBVHgBtZI9TFNRFMf/9/W9tGiV1toXEUpKYh1ECVI/hiZ+btqlDsjgUE0cjTp108iIjo5GTHRQFxcjEzExYdBETdS41KSmGgol5ZtQQsvjnkMoH72F11L+ycv9OPf97v+ec8S9gc5g0Wn0wxIXsHP9LRatmFYwHJ11ApKCDod2VxNC86DO0rALUkKPeLr4O+BqQoO+j+c0NruPcpzmJ/znOW4berujDyFvlxwf42zTFdw49gAd/nO8T6DEmZdyPMT7KumqzfnCLP7PJiUwyuvkxFfpNsxzuuxz5j1+jn3CRH4Etp026G60uEN49buXLxjPZ3i9XgS/FrpvH0qggdQz/Jn8VtrLSTBd9kM6JNch+dFaJXFn8HRcDv2oUlSs+cKMCvmi5pZSA1eko0od338Rl82byBfnMJR7i1/TH8vOKJ02GiYivm6YzrayWORgN5zaXj5DcFtOW/e0I3Y4wT9GfNfZzVDuTSnu0taKQ25V2uA07I2ip6WXgSVn0vF6R4PZ55gqZLGwtPL8LZ1e8t/CKe9V5aGwJ4qAqx3vhvs4h6o8bnDqMwLsrhJwVaarDT2BR2jUTWwnkU6n40LY71N69ofMUyTnvlRCVt+nlO9Yc4KLWEk1N//mAtYFSqICxluflOVZpFIpj2EY3y3LCqJGTS1m8frfQ9lqo5OLmuPkMjsomVugXiEtAAAAAElFTkSuQmCC")

// const checkDocument = (document:VehicleDocument) => {
//     if(document.documentName.toLowerCase() === 'cmr' && vehicleOrderServices?.requiredCRM){
//        return cmrChecked
//     }

//     // if(vehicleOrderServices?.registrationService.uuid === RENEW_REGISTRATION_SERVICE_ID){
//     //     if(document.documentName.toLowerCase() === ROAD_WORTHINESS_DOCUMENT_NAME.toLowerCase()){
//     //         return true
//     //     }
//     //     if(document.documentName.toLowerCase() === INSURANCE_DOCUMENT_NAME.toLowerCase()){
//     //         return true
//     //     }
//     //     if(document.documentName.toLowerCase() === VEHICLE_LICENSE_DOCUMENT_NAME.toLowerCase()){
//     //         return true
//     //     }
//     // }

//     // return false

// }

// // //
// // // const [checkedState, setCheckedState] = useState<boolean[]>([]);
// // const [checkedState, setCheckedState] = useState<any>([]);

// // useEffect(() => {
// //     const initialCheckedState = newVehicleRegistrationDocuments?.map((doc: VehicleDocument) =>
// //       checkDocument(doc) // Set initial checked status based on your logic
// //     );
// //     setCheckedState(initialCheckedState);
// //   }, [newVehicleRegistrationDocuments]);
// // //

// const [isDocumentSelectionValid, setIsDocumentSelectionValid] = useState<boolean>(false)

// const [selectedDocuments, setSelectedDocuments] = useState<any[]>([])

// // const handleSelectDocument = async (index: number, e:any) => {
// //     const payload = {
// //         amount: newVehicleRegistrationDocuments ? newVehicleRegistrationDocuments[index]?.price : 0,
// //         registrationServiceDocument: newVehicleRegistrationDocuments ? newVehicleRegistrationDocuments[index]?.uuid : '',
// //         order: vehicleOrderServices?.uuid
// //     }
// //     addDocumentToOrder(payload)
// //     checkIfDocumentSelectionIsValid(e.target.value, e.target.checked)
// //     const newCheckedState = [...checkedState]
// //     newCheckedState[index] = e.target.checked
// //     setCheckedState(newCheckedState)
// //  }

// const handleSelectDocument = async (index: number, e:any) => {
//     const payload = {
//         amount: newVehicleRegistrationDocuments ? newVehicleRegistrationDocuments[index]?.price : 0,
//         registrationServiceDocument: newVehicleRegistrationDocuments ? newVehicleRegistrationDocuments[index]?.uuid : '',
//         order: vehicleOrderServices?.uuid
//     }
//     addDocumentToOrder(payload)
//     checkIfDocumentSelectionIsValid(e.target.value, e.target.checked)
//  }

//  const checkIfDocumentSelectionIsValid = (data: any, status: boolean) => {
//     if(status){
//         let docs = selectedDocuments
//         docs.push(data)
//         setSelectedDocuments(docs)
//     }
//     else{
//         let docs = selectedDocuments
//         let updatedDocs = docs.filter(x => x != data)
//         setSelectedDocuments(updatedDocs)
//     }
//  }

//  const addDocumentToOrder = async (payload: any) => {
//     console.log(payload)
//     await orderService.addDocumentToAnOrder(payload).then((response)=>{
//         console.log(response)
//          console.log("Add document to order", response.data)
//          refreshServiceOrder && refreshServiceOrder()
//     }, error =>{
//         console.log(error)
//         Swal.fire({
//             position: "top-end",
//             icon: "warning",
//             title: "Error",
//             text: error?.response?.data?.error?.message,
//             showConfirmButton: true
//         });
//     })
//  }

//  const goToVehicleInformation = () => {
//     navigate({
//         pathname: `/registration-service/${vehicleOrderServices?.registrationService.uuid}`,
//         search: `?step=1&orderId=${vehicleOrderServices?.uuid}`,
//     });
//  }

//  const goToVehicleOwnerInformation = () => {
//     navigate({
//         pathname: `/registration-service/${vehicleOrderServices?.registrationService.uuid}`,
//         search: `?step=2&orderId=${vehicleOrderServices?.uuid}`,
//     });
//  }

//  const goToEditDocument = () => {
//     navigate({
//         pathname: `/registration-service/${vehicleOrderServices?.registrationService.uuid}`,
//         search: `?step=3&orderId=${vehicleOrderServices?.uuid}`,
//     });
//  }

//  const [isDocumentsInitialized, setIsDocumentsInitialized] = useState(false);

//  const getVehicleDocumentsByVehicleUseId = async () => {
//     await vehicleService.getDocumentsByVehicleUseAndRegistrationService(`${vehicleOrderServices?.registrationService.uuid}`,`${vehicleOrderServices?.vehicleUse.uuid}`).then((response)=>{
//         const documents = [...newVehicleRegistrationDocuments || [], ...response.data.data]
//         setNewVehicleRegistrationDocuments(documents)
//     }, error =>{
//         console.log(error)
//     })
//  }

// const [showDeliveryDetailsSummary, setShowDeliveryDetailsSummary] = useState<boolean>(false)

//  const [showEditDeliveryDetailsForm, setShowEditDeliveryDetailsForm] = useState<boolean>(false)

//  const editDeliveryDetails = () => {
//     setShowDeliveryDetailsSummary(false)
//     setShowEditDeliveryDetailsForm(true)
//     setValue('address', vehicleOrderServices?.localGovernmentOrders[0]?.address)
//     setValue('state', vehicleOrderServices?.localGovernmentOrders[0]?.state?.uuid ? vehicleOrderServices?.localGovernmentOrders[0]?.state?.uuid : LAGOS_STATE_ID)
//     setValue('localGovernmentUuid', vehicleOrderServices?.localGovernmentOrders[0]?.localGovernment?.id)
//     setValue('contact_name', vehicleOrderServices?.localGovernmentOrders[0]?.contact_name)
//     setValue('contact_phone', vehicleOrderServices?.localGovernmentOrders[0]?.contact_phone)
//  }

// const hasFetchedDocuments = useRef(false);

// useEffect(() => {
//     if (vehicleOrderServices?.registrationService.documents && !isDocumentsInitialized) {
//       setNewVehicleRegistrationDocuments(vehicleOrderServices.registrationService.documents);
//       setIsDocumentsInitialized(true);
//     }
//   }, [vehicleOrderServices]);

// useEffect(() => {
//     if (isDocumentsInitialized && !hasFetchedDocuments.current) {
//         hasFetchedDocuments.current = true; // Prevent further calls
//       getVehicleDocumentsByVehicleUseId();
//     }
// }, [isDocumentsInitialized, vehicleOrderServices]);

//  useEffect(() => {
//     setCmrChecked(vehicleOrderServices ? vehicleOrderServices?.requiredCRM : false)
//  }, [vehicleOrderServices?.requiredCRM])

//     const getServiceTypes = async () => {
//         console.log(vehicleOrderServices)
//         await vehicleService.getVehicleServiceType(`${vehicleOrderServices?.registrationService.uuid}`,`${vehicleOrderServices?.vehicleClass.name}`)
//             .then((response)=>{
//                 console.log(response)
//                 setServiceDeliveryType(response.data.data)
//             }, error =>{
//                 console.log(error)
//             })
//     }

//     useEffect(() => {
//         getServiceTypes()
//     }, [vehicleOrderServices?.registrationService])

//     // const [showUploadedDocumentsForCOO, setShowUploadedDocumentsForCOO] = useState<boolean>(false)

//     // useEffect(() => {
//     //     console.log(vehicleOrderServices)
//     //     if(vehicleOrderServices?.uploadedDocuments){
//     //         setShowUploadedDocumentsForCOO(true)
//     //     }
//     // }, [vehicleOrderServices?.uploadedDocuments])

//     return (
//         <>
//         <div className="RegistrationSummaryItem row justify-content-center pt-3 pb-5">
//             <div className="col-12 col-md-6 col-lg-6 col-xl-5">
//                 <div className="card border-0 mt-3 mt-md-0 mb-4 px-4">
//                     <div className="d-flex justify-content-between align-items-center">
//                         <div className="card-header bg-white pt-3 px-0">
//                             Vehicle Information
//                         </div>
//                         <p className="edit-text" onClick={goToVehicleInformation}>Edit</p>
//                     </div>
//                     <div className="card-body pt-0 px-0">
//                         <div className="row border-top pb-2 pt-3">
//                             {
//                                 vehicleOrderServices?.plateNumber &&
//                                 <>
//                                     <div className="col-6">
//                                         <div className="label">
//                                             Plate Number
//                                         </div>
//                                     </div>
//                                     <div className="col-6 text-end">
//                                         <div className="value">
//                                             {vehicleOrderServices?.plateNumber }
//                                         </div>
//                                     </div>
//                                 </>
//                             }
//                             <div className="col-6">
//                                 <div className="label">
//                                     Make
//                                 </div>
//                             </div>
//                             <div className="col-6 text-end">
//                                 <div className="value">
//                                     {vehicleOrderServices?.vehicleMake?.name }
//                                 </div>
//                             </div>

//                             <div className="col-6">
//                                 <div className="label">
//                                     Model
//                                 </div>
//                             </div>

//                             <div className="col-6 text-end">
//                                 <div className="value">
//                                     {vehicleOrderServices?.vehicleModel?.name}
//                                 </div>
//                             </div>

//                             <div className="col-6">
//                                 <div className="label">
//                                     Year
//                                 </div>
//                             </div>
//                             <div className="col-6 text-end">
//                                 <div className="value">
//                                    {vehicleOrderServices?.vehicleYear.year}
//                                 </div>
//                             </div>

//                             {vehicleOrderServices?.vehicleColor &&
//                                 <>
//                                 <div className="col-6">
//                                     <div className="label">
//                                         Color
//                                     </div>
//                                 </div>
//                                 <div className="col-6 text-end">
//                                     <div className="value text-capitalize">
//                                     {vehicleOrderServices?.vehicleColor}
//                                     </div>
//                                 </div>
//                                 </>
//                             }

//                             {vehicleOrderServices?.engineNumber &&
//                                 <>
//                                 <div className="col-6">
//                                     <div className="label">
//                                         Engine number
//                                     </div>
//                                 </div>

//                                 <div className="col-6 text-end">
//                                     <div className="value">
//                                     {vehicleOrderServices?.engineNumber}
//                                     </div>
//                                 </div>
//                                 </>
//                             }

//                             {vehicleOrderServices?.chasisNumber &&
//                                 <>
//                                 <div className="col-6">
//                                     <div className="label">
//                                         Chasis number
//                                     </div>
//                                 </div>

//                                 <div className="col-6 text-end">
//                                     <div className="value">
//                                     {vehicleOrderServices?.chasisNumber}
//                                     </div>
//                                 </div>
//                                 </>
//                             }

//                             <div className="col-6">
//                                 <div className="label">
//                                     Vehicle Class
//                                 </div>
//                             </div>

//                             <div className="col-6 text-end">
//                                 <div className="value">
//                                   {vehicleOrderServices?.vehicleClass?.name}
//                                 </div>
//                             </div>

//                             <div className="col-6">
//                                 <div className="label">
//                                     Vehicle Use
//                                 </div>
//                             </div>

//                             <div className="col-6 text-end">
//                                 <div className="value">
//                                   {vehicleOrderServices?.vehicleUse?.name}
//                                 </div>
//                             </div>

//                             <div className="col-6">
//                                 <div className="label">
//                                     Vehicle Cylinder
//                                 </div>
//                             </div>

//                             <div className="col-6 text-end">
//                                 <div className="value">
//                                   {vehicleOrderServices?.vehicleCylinder?.name}
//                                 </div>
//                             </div>

//                             <div className="col-6">
//                                 <div className="label">
//                                    CMR(Central Motor Registry)
//                                 </div>
//                             </div>

//                             <div className="col-6 text-end">
//                                 <div className="value">
//                                   {vehicleOrderServices?.requiredCRM ? 'Yes' : 'No'}
//                                 </div>
//                             </div>

//                             {vehicleOrderServices?.nin &&
//                                 <>
//                                 <div className="col-6">
//                                     <div className="label">
//                                     NIN
//                                     </div>
//                                 </div>

//                                 <div className="col-6 text-end">
//                                     <div className="value">
//                                         {vehicleOrderServices?.nin}
//                                     </div>
//                                 </div>
//                                 </>
//                             }

//                             {vehicleOrderServices?.stateLiencingOffice &&
//                                 <>
//                                 <div className="col-6">
//                                     <div className="label">
//                                     State licensing office
//                                     </div>
//                                 </div>

//                                 <div className="col-6 text-end">
//                                     <div className="value">
//                                         {vehicleOrderServices?.stateLiencingOffice?.name}
//                                     </div>
//                                 </div>
//                                 </>
//                             }

//                             {vehicleOrderServices?.stateRequestingOffice &&
//                                 <>
//                                 <div className="col-6">
//                                     <div className="label">
//                                     State Requesting office
//                                     </div>
//                                 </div>

//                                 <div className="col-6 text-end">
//                                     <div className="value">
//                                         {vehicleOrderServices?.stateRequestingOffice?.name}
//                                     </div>
//                                 </div>
//                                 </>
//                             }

//                         </div>
//                     </div>
//                 </div>

//                 <div className="card border-0 mt-3 mt-md-0 px-4">
//                     <div className="d-flex justify-content-between align-items-center border-bottom">
//                         <div className="card-header bg-white pt-3 px-0">
//                             Vehicle Owner's Information
//                         </div>
//                         <div className="edit-text-container">
//                             <p className="edit-text mr-4" onClick={goToVehicleOwnerInformation}>Edit</p>
//                             {/* {showVehicleOwnerInfoPanel ?
//                                 <svg onClick={toggleVehicleOwnerInfoPanel} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
//                                     <path d="M11.9219 22C17.4219 22 21.9219 17.5 21.9219 12C21.9219 6.5 17.4219 2 11.9219 2C6.42188 2 1.92188 6.5 1.92188 12C1.92187 17.5 6.42188 22 11.9219 22Z" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
//                                     <path d="M7.92188 12H15.9219" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
//                                 </svg> :
//                                 <svg className="cursor-pointer" onClick={toggleVehicleOwnerInfoPanel} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
//                                     <path d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
//                                     <path d="M8 12H16" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
//                                     <path d="M12 16V8" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
//                                 </svg>
//                             } */}
//                         </div>
//                     </div>

//                     {showVehicleOwnerInfoPanel &&
//                     <div className="card-body pt-0 px-0">

//                         {vehicleOrderServices?.registrationService.uuid === CHANGE_OF_OWNERSHIP_SERVICE_ID ?

//                             <>
//                                 <div className="row border-bottom pb-2 mt-2 px-0">
//                                     <p className="mb-0 text-14px font-bold">REGISTERED OWNER</p>
//                                     <div className="col-6">
//                                         <div className="label">
//                                         First Name
//                                         </div>
//                                     </div>
//                                     <div className="col-6 text-end">
//                                         <div className="value">
//                                         {vehicleOrderServices?.firstName}
//                                         </div>
//                                     </div>

//                                     <div className="col-6">
//                                         <div className="label">
//                                         Last Name
//                                         </div>
//                                     </div>
//                                     <div className="col-6 text-end">
//                                         <div className="value">
//                                         {vehicleOrderServices?.lastName}
//                                         </div>
//                                     </div>

//                                     <div className="col-6">
//                                         <div className="label">
//                                             Address
//                                         </div>
//                                     </div>

//                                     <div className="col-6 text-end">
//                                         <div className="value">
//                                         {vehicleOrderServices?.address}
//                                         </div>
//                                     </div>

//                                     <div className="col-6">
//                                         <div className="label">
//                                             Phone number
//                                         </div>
//                                     </div>

//                                     <div className="col-6 text-end">
//                                         <div className="value">
//                                         {vehicleOrderServices?.phoneNumber}
//                                         </div>
//                                     </div>
//                                 </div>
//                                 <div className="row border-bottom pb-2 mt-2 px-0">
//                                     <p className="mb-0 text-14px font-bold">NEW OWNER</p>
//                                     <div className="col-6">
//                                         <div className="label">
//                                         First Name
//                                         </div>
//                                     </div>
//                                     <div className="col-6 text-end">
//                                         <div className="value">
//                                         {vehicleOrderServices?.newOwnerFirstName}
//                                         </div>
//                                     </div>

//                                     <div className="col-6">
//                                         <div className="label">
//                                         Last Name
//                                         </div>
//                                     </div>
//                                     <div className="col-6 text-end">
//                                         <div className="value">
//                                         {vehicleOrderServices?.newOwnerLastName}
//                                         </div>
//                                     </div>

//                                     <div className="col-6">
//                                         <div className="label">
//                                             Address
//                                         </div>
//                                     </div>

//                                     <div className="col-6 text-end">
//                                         <div className="value">
//                                         {vehicleOrderServices?.newOwnerAddress}
//                                         </div>
//                                     </div>

//                                     <div className="col-6">
//                                         <div className="label">
//                                             Phone number
//                                         </div>
//                                     </div>

//                                     <div className="col-6 text-end">
//                                         <div className="value">
//                                         {vehicleOrderServices?.newOwnerPhoneNumber}
//                                         </div>
//                                     </div>

//                                     <div className="col-6">
//                                         <div className="label">
//                                             Email
//                                         </div>
//                                     </div>

//                                     <div className="col-6 text-end">
//                                         <div className="value">
//                                         {vehicleOrderServices?.newOwnerEmail}
//                                         </div>
//                                     </div>
//                                 </div>
//                             </>

//                             :

//                             <div className="row border-bottom pb-2 mt-2 px-0">
//                                 <div className="col-6">
//                                     <div className="label">
//                                     First Name
//                                     </div>
//                                 </div>
//                                 <div className="col-6 text-end">
//                                     <div className="value">
//                                     {vehicleOrderServices?.firstName}
//                                     </div>
//                                 </div>

//                                 <div className="col-6">
//                                     <div className="label">
//                                     Last Name
//                                     </div>
//                                 </div>
//                                 <div className="col-6 text-end">
//                                     <div className="value">
//                                     {vehicleOrderServices?.lastName}
//                                     </div>
//                                 </div>

//                                 <div className="col-6">
//                                     <div className="label">
//                                         Email
//                                     </div>
//                                 </div>
//                                 <div className="col-6 text-end">
//                                     <div className="value">
//                                     {vehicleOrderServices?.email}
//                                     </div>
//                                 </div>

//                                 <div className="col-6">
//                                     <div className="label">
//                                         Phone number
//                                     </div>
//                                 </div>

//                                 <div className="col-6 text-end">
//                                     <div className="value">
//                                     {vehicleOrderServices?.phoneNumber}
//                                     </div>
//                                 </div>

//                                 <div className="col-6">
//                                     <div className="label">
//                                         Address
//                                     </div>
//                                 </div>

//                                 <div className="col-6 text-end">
//                                     <div className="value">
//                                     {vehicleOrderServices?.address}
//                                     </div>
//                                 </div>

//                                 <div className="col-6">
//                                     <div className="label">
//                                         State
//                                     </div>
//                                 </div>

//                                 <div className="col-6 text-end">
//                                     <div className="value">
//                                     {vehicleOrderServices?.state.name}
//                                     </div>
//                                 </div>

//                                 <div className="col-6">
//                                     <div className="label">
//                                         LGA
//                                     </div>
//                                 </div>

//                                 <div className="col-6 text-end">
//                                     <div className="value">
//                                     {vehicleOrderServices?.localGovernment?.name}
//                                     </div>
//                                 </div>
//                             </div>
//                         }

//                     </div>
//                     }
//                 </div>

//             {/* {(showUploadedDocumentsForCOO && vehicleOrderServices?.registrationService.uuid === CHANGE_OF_OWNERSHIP_SERVICE_ID)  && */}
//             {vehicleOrderServices?.registrationService.uuid === CHANGE_OF_OWNERSHIP_SERVICE_ID  &&
//                 <div className="col-12 mt-3 mt-md-0">
//                     <div className="card border-0 mt-4 px-4">
//                         <div className="card-header bg-white pt-3 border-bottom px-0 d-flex justify-content-between align-items-center">
//                             Documents
//                             <p className="edit-text" onClick={goToEditDocument}>Edit</p>
//                         </div>
//                         <div className="card-body pt-0 px-0 mt-4">
//                             {vehicleOrderServices.uploadedDocuments.map(document => {
//                                 return (
//                                     <div className="d-flex justify-content-between document-container">
//                                         <div className="d-flex">
//                                             <img src={temporaryPngImageUrl} alt="" height="20px"/>
//                                             <p className="doc-name">{ReplaceCharacter(document.documentName, "_", " ")}</p>
//                                         </div>
//                                         <p className="doc-size">1MB</p>
//                                     </div>
//                                 )
//                             })}
//                         </div>
//                     </div>
//                 </div>
//             }

//             </div>

//             <div className="col-12 col-md-6 col-lg-6 col-xl-5 mt-3 mt-md-0">

//                     <div className="col-12 mb-4">
//                         <div className="card px-4 py-1 border-0 delivery-details">
//                             <div className="card-header profile-title px-0 border-bottom pb-1 d-flex justify-content-between align-items-center">
//                                 Delivery Information
//                                 <p className="edit-text" onClick={editDeliveryDetails}>Edit</p>
//                             </div>

//                             {vehicleOrderServices?.localGovernmentOrders.length === 0 &&
//                                 <div className="row  pb-2 mt-2">
//                                     <div className="col-12">
//                                         <div className="form-group d-flex form-crm-input">
//                                             <input type="checkbox" id="requiredCRM" className='my-auto me-2' checked={!showDeliveryDetailsForm}  onChange={handleUseDeliveryDetails}/>
//                                             <label htmlFor="requiredCRM" className='my-auto'>Use Vehicle Owner's Information for documents delivery</label>
//                                         </div>
//                                     </div>
//                                 </div>
//                             }

//                             {/* {(showEditDeliveryDetailsForm || (showDeliveryDetailsForm && vehicleOrderServices?.localGovernmentOrders.length === 0)) && */}
//                             {((showEditDeliveryDetailsForm || (showDeliveryDetailsForm && vehicleOrderServices?.localGovernmentOrders.length === 0)) && !showDeliveryDetailsSummary) &&
//                                 <>
//                                 <div className="card-body pt-0 px-0">
//                                     <div className='form-group mt-3'>
//                                         <label htmlFor="address">Address</label>
//                                         <input type="text" id="address" className={`form-control ${errors.address ? 'is-invalid' : ''}`} {...register('address', { required: "This field is required", onChange: handleDeliveryForm})}/>
//                                         {errors.address && <small className='error'>{errors.address.message}</small>}
//                                     </div>
//                                     <div className='form-group mt-3' title="Service available within Lagos. Other locations coming soon.">
//                                         <label htmlFor="state">State</label>
//                                         <select disabled id="state" className={`form-control ${errors.state ? 'is-invalid' : ''}`} {...register('state', { required: "This field is required", onChange: (e) => {handleState(e); handleDeliveryForm('state', e.target.value)}})}>
//                                             <option>select state</option>
//                                             {states?.map((stateItem: State, index: Key)=><option key={index} value={stateItem.uuid}>{stateItem.name}</option>)}
//                                         </select>
//                                         {errors.state && <small className='error'>{errors.state.message}</small>}
//                                     </div>
//                                     <div className='form-group mt-3'>
//                                         <label htmlFor="lga">L.G.A</label>
//                                         {/* <select id="lga" className={`form-control ${errors.localGovernmentUuid ? 'is-invalid' : ''}`} {...register('localGovernmentUuid', { required: "This field is required", onChange: handleDeliveryForm})}>
//                                             <option>select L.G.A</option>
//                                             {lgas?.map((lga:Lga, index: Key)=><option key={index} value={lga.id}>{lga.name}</option>)}
//                                         </select> */}
//                                         <Controller
//                                             name="localGovernmentUuid"
//                                             control={control}
//                                             rules={{ required: 'Field cannot be empty' }}
//                                             render={({ field }) =>
//                                                 (
//                                                 <Select
//                                                 options={lgas?.map((lga: Lga) => ({
//                                                     value: lga.id,
//                                                     label: lga.name,
//                                                 }))}
//                                                 onChange={(selectedOption) => {
//                                                     field.onChange(selectedOption?.value);
//                                                     handleDeliveryForm('localGovernmentUuid', selectedOption?.value);
//                                                 }}
//                                                 placeholder="Select L.G.A"
//                                                 className={`react-select-container ${errors.localGovernmentUuid ? 'is-invalid' : ''}`}
//                                                 classNamePrefix="react-select"
//                                                 styles={customStyles}
//                                             />
//                                             )}
//                                         />
//                                         {errors.localGovernmentUuid && <small className='error'>{errors.localGovernmentUuid.message}</small>}
//                                     </div>
//                                     <div className='form-group mt-3'>
//                                         <label htmlFor="contactName">Contact Name</label>
//                                         <input type="text" id="contactName" className={`form-control ${errors.contact_name ? 'is-invalid' : ''}`}
//                                         {...register("contact_name", { required: "This field is required", onChange: handleDeliveryForm})} />
//                                         {errors.contact_name && <small className='error'>{errors.contact_name.message}</small>}
//                                     </div>

//                                     <div className='form-group mt-3'>
//                                         <label htmlFor="contact_phone">Phone Number</label>
//                                         <Controller
//                                             name="contact_phone"
//                                             control={control}
//                                             rules={{ required: "Phone number is required" }}
//                                             render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
//                                             <>
//                                                 <PhoneNumberInput
//                                                     value={value}
//                                                     onChange={onChange}
//                                                     onBlur={onBlur}
//                                                     className={`form-control ${error ? "is-invalid" : ""}`}
//                                                 />
//                                                 {error && <div className="invalid-feedback">{error.message}</div>}
//                                             </>
//                                             )}
//                                         />
//                                     </div>

//                                 </div>
//                                 {/* <button className="btn checkout-btn" disabled={!deliveryDetailsFormIsValid} onClick={processDeliveryInformation}>Save Details {processingDeliveryDetails && <span className="spinner-border spinner-border-sm"></span>}</button> */}
//                                 <button className="btn checkout-btn" disabled={!deliveryDetailsFormIsValid || !isPhoneNumberValid(getValues('contact_phone'))} onClick={processDeliveryInformation}>Save Details {processingDeliveryDetails && <span className="spinner-border spinner-border-sm"></span>}</button>
//                                 </>
//                             }

//                             {/* {(!showEditDeliveryDetailsForm && (vehicleOrderServices?.localGovernmentOrders && vehicleOrderServices?.localGovernmentOrders.length > 0)) && */}
//                             {((!showEditDeliveryDetailsForm && (vehicleOrderServices?.localGovernmentOrders && vehicleOrderServices?.localGovernmentOrders.length > 0)) || showDeliveryDetailsSummary ) &&
//                                 <div className="card-body pt-0 px-0">
//                                         <div className="row pb-1 pt-1">
//                                             <div className="col-6">
//                                                 <div className="label">
//                                                 Address
//                                                 </div>
//                                             </div>
//                                             <div className="col-6 text-end">
//                                                 <div className="value">
//                                                 {vehicleOrderServices?.localGovernmentOrders[0]?.address}
//                                                 </div>
//                                             </div>

//                                             <div className="col-6">
//                                                 <div className="label">
//                                                 State
//                                                 </div>
//                                             </div>
//                                             <div className="col-6 text-end">
//                                                 <div className="value">
//                                                 {vehicleOrderServices?.localGovernmentOrders[0]?.state?.name}
//                                                 </div>
//                                             </div>

//                                             <div className="col-6">
//                                                 <div className="label">
//                                                 L.G.A
//                                                 </div>
//                                             </div>
//                                             <div className="col-6 text-end">
//                                                 <div className="value">
//                                                 {vehicleOrderServices?.localGovernmentOrders[0]?.localGovernment?.name}
//                                                 </div>
//                                             </div>

//                                             <div className="col-6">
//                                                 <div className="label">
//                                                 Contact Name
//                                                 </div>
//                                             </div>
//                                             <div className="col-6 text-end">
//                                                 <div className="value">
//                                                 {vehicleOrderServices?.localGovernmentOrders[0]?.contact_name}
//                                                 </div>
//                                             </div>

//                                             <div className="col-6">
//                                                 <div className="label">
//                                                 Contact Number
//                                                 </div>
//                                             </div>
//                                             <div className="col-6 text-end">
//                                                 <div className="value">
//                                                 {vehicleOrderServices?.localGovernmentOrders[0]?.contact_phone}
//                                                 </div>
//                                             </div>
//                                         </div>
//                                 </div>
//                             }

//                         </div>
//                     </div>

//                 {/* {(vehicleOrderServices?.localGovernmentOrders && vehicleOrderServices?.localGovernmentOrders.length > 0) &&
//                     <div className="col-12 mb-4">
//                         <div className="card border-0 px-4">
//                             <div className="card-header bg-white pt-3 border-bottom px-0 d-flex justify-content-between">
//                                 Delivery Information
//                                 {
//                                     showDeliveryDetailsPanel ?
//                                     <svg className="cursor-pointer" onClick={toggleDeliveryDetailsPanel} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
//                                         <path d="M11.9219 22C17.4219 22 21.9219 17.5 21.9219 12C21.9219 6.5 17.4219 2 11.9219 2C6.42188 2 1.92188 6.5 1.92188 12C1.92187 17.5 6.42188 22 11.9219 22Z" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
//                                         <path d="M7.92188 12H15.9219" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
//                                     </svg> :
//                                     <svg className="cursor-pointer" onClick={toggleDeliveryDetailsPanel} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
//                                         <path d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
//                                         <path d="M8 12H16" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
//                                         <path d="M12 16V8" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
//                                     </svg>
//                                 }

//                             </div>
//                             {showDeliveryDetailsPanel &&
//                             <div className="card-body pt-0 px-0">
//                                     <div className="row border-top pb-2 pt-3">
//                                         <div className="col-6">
//                                             <div className="label">
//                                             Address
//                                             </div>
//                                         </div>
//                                         <div className="col-6 text-end">
//                                             <div className="value">
//                                             {vehicleOrderServices?.localGovernmentOrders[0]?.address}
//                                             </div>
//                                         </div>

//                                         <div className="col-6">
//                                             <div className="label">
//                                             State
//                                             </div>
//                                         </div>
//                                         <div className="col-6 text-end">
//                                             <div className="value">

//                                             </div>
//                                         </div>

//                                         <div className="col-6">
//                                             <div className="label">
//                                             L.G.A
//                                             </div>
//                                         </div>
//                                         <div className="col-6 text-end">
//                                             <div className="value">

//                                             </div>
//                                         </div>

//                                         <div className="col-6">
//                                             <div className="label">
//                                             Contact Name
//                                             </div>
//                                         </div>
//                                         <div className="col-6 text-end">
//                                             <div className="value">
//                                             {vehicleOrderServices?.localGovernmentOrders[0]?.contact_name}
//                                             </div>
//                                         </div>

//                                         <div className="col-6">
//                                             <div className="label">
//                                             Contact Number
//                                             </div>
//                                         </div>
//                                         <div className="col-6 text-end">
//                                             <div className="value">
//                                             {vehicleOrderServices?.localGovernmentOrders[0]?.contact_phone}
//                                             </div>
//                                         </div>
//                                     </div>
//                             </div>
//                             }
//                         </div>
//                     </div>
//                 } */}

//                 <div className="card document-details-card p-0 border-0">

//                     <div className="card-header pb-1 px-0 pt-1 mx-4">
//                         <div className="row h-100">
//                             <div className="col-md-12 text-center my-auto d-inline-flex">
//                             Documents you will get
//                             </div>
//                         </div>
//                     </div>

//                     <div className="card-body pt-3 px-0">
//                         <div className="container-fluid">
//                             <div className="row px-4">

//                                 {
//                                     (vehicleOrderServices?.registrationService.uuid === NEW_REGISTRATION_SERVICE_ID || vehicleOrderServices?.registrationService.uuid === CHANGE_OF_OWNERSHIP_SERVICE_ID) &&
//                                     (
//                                         <>
//                                             {newVehicleRegistrationDocuments?.map((doc: VehicleDocument, index:number)=>{
//                                                 return (
//                                                     (doc.documentName.toLowerCase() === 'cmr' && !vehicleOrderServices.requiredCRM ) ? '' :
//                                                     <div key={index} className="col-md-6">
//                                                         <div className="ro label-inner">
//                                                             <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
//                                                                 <path d="M9 17.75C8.9 17.75 8.81 17.73 8.71 17.69C8.43 17.58 8.25 17.3 8.25 17V11C8.25 10.59 8.59 10.25 9 10.25C9.41 10.25 9.75 10.59 9.75 11V15.19L10.47 14.47C10.76 14.18 11.24 14.18 11.53 14.47C11.82 14.76 11.82 15.24 11.53 15.53L9.53 17.53C9.39 17.67 9.19 17.75 9 17.75Z" fill="#00163A"/>
//                                                                 <path d="M9.00141 17.7514C8.81141 17.7514 8.62141 17.6814 8.47141 17.5314L6.47141 15.5314C6.18141 15.2414 6.18141 14.7614 6.47141 14.4714C6.76141 14.1814 7.24141 14.1814 7.53141 14.4714L9.53141 16.4714C9.82141 16.7614 9.82141 17.2414 9.53141 17.5314C9.38141 17.6814 9.19141 17.7514 9.00141 17.7514Z" fill="#00163A"/>
//                                                                 <path d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H14C14.41 1.25 14.75 1.59 14.75 2C14.75 2.41 14.41 2.75 14 2.75H9C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V10C21.25 9.59 21.59 9.25 22 9.25C22.41 9.25 22.75 9.59 22.75 10V15C22.75 20.43 20.43 22.75 15 22.75Z" fill="#00163A"/>
//                                                                 <path d="M22 10.7485H18C14.58 10.7485 13.25 9.41852 13.25 5.99852V1.99852C13.25 1.69852 13.43 1.41852 13.71 1.30852C13.99 1.18852 14.31 1.25852 14.53 1.46852L22.53 9.46852C22.74 9.67852 22.81 10.0085 22.69 10.2885C22.57 10.5685 22.3 10.7485 22 10.7485ZM14.75 3.80852V5.99852C14.75 8.57852 15.42 9.24852 18 9.24852H20.19L14.75 3.80852Z" fill="#00163A"/>
//                                                             </svg>
//                                                             <p>{doc.documentName}</p>
//                                                         </div>
//                                                     </div>
//                                                 )

//                                             })}
//                                         </>
//                                     )
//                                 }

//                                 {
//                                     (vehicleOrderServices?.registrationService.uuid === RENEW_REGISTRATION_SERVICE_ID) &&
//                                     // (vehicleOrderServices?.registrationService.uuid === RENEW_REGISTRATION_SERVICE_ID && checkedState?.length > 0) &&
//                                     (
//                                         <>
//                                             {newVehicleRegistrationDocuments?.map((doc: VehicleDocument, index:number)=>{
//                                                 return (
//                                                     (doc.documentName.toLowerCase() === 'cmr' && !vehicleOrderServices.requiredCRM ) ? '' :
//                                                     <div key={index} className="col-md-6">
//                                                         <div className="row label-inner-checkbox">
//                                                                <div className="col-1 px-0">
//                                                                    <input type='checkbox' id={doc.documentName} checked={checkDocument(doc)} name={doc.documentName} className="active" value={doc.documentName} onChange={(e) => handleSelectDocument(index, e)}></input>
//                                                                    {/* <input type='checkbox'
//                                                                         id={doc.documentName}
//                                                                         checked={checkedState[index]}
//                                                                         name={doc.documentName}
//                                                                         className="active"
//                                                                         value={doc.documentName}
//                                                                         onChange={(e) => handleSelectDocument(index, e)}
//                                                                     ></input> */}
//                                                                </div>

//                                                                <div className="col-11">
//                                                                    <label htmlFor={doc.documentName}>{doc.documentName}</label>
//                                                                </div>
//                                                            </div>
//                                                     </div>
//                                                 )

//                                             })}
//                                         </>
//                                     )
//                                 }

//                             </div>
//                         </div>

//                         <div className="service-type px-3 py-2 mt-3">

//                             <div className="container-fluid h-100">
//                                 <div className="row h-100">
//                                     <div className="col-12 my-auto">
//                                        <div className="title">Service type</div>
//                                        {serviceDeliveryType && serviceDeliveryType.map((serviceType:ServiceDeliveryType, index:Key)=>{
//                                         return <div className="row h-100 mt-2" key={index}>
//                                             <div className="col-8 type my-auto d-flex h-100">
//                                                <input defaultChecked={serviceType.default} type="radio" name="serice_type" id={`standard_delivery_${serviceType.uuid}`} value={JSON.stringify(serviceType)} onChange={handleServiceDeliveryType}/> <label htmlFor={`standard_delivery_${serviceType.uuid}`} className='ms-2 my-auto'> {serviceType.service_name} - {serviceType.registrationService.processingTime}  days </label>
//                                                {/* <input type="radio" name="serice_type" id={`standard_delivery_${serviceType.uuid}`} value={JSON.stringify(serviceType)} onChange={handleServiceDeliveryType}/> <label htmlFor={`standard_delivery_${serviceType.uuid}`} className='ms-2 my-auto'> {serviceType.service_name} - {serviceType.registrationService.processingTime}  days </label> */}
//                                             </div>
//                                             <div className="col-4 amount h-100 text-end">
//                                                ₦{CurrencyFormatter(Number(serviceType.price))}
//                                             </div>
//                                         </div>
//                                          })}
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>

//                         {/* <div className="processing_time px-4">
//                            <div className="row h-100">
//                             <div className="col-6 title my-auto">Processing Time</div>
//                             <div className="col-6 time my-auto">{vehicleOrderServices?.registrationService.processingTime} days</div>
//                            </div>
//                         </div> */}

//                         <div className="total-balance px-4 border-0">
//                             <div className="row h-100 pt-2">
//                                 <div className="col-6 my-auto">
//                                     Total
//                                 </div>
//                                 <div className="col-6 text-end my-auto">
//                                    ₦{CurrencyFormatter(Number(vehicleOrderServices?.totalAmount))}
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>

//                 {/* {
//                     (vehicleOrderServices?.registrationService.uuid === NEW_REGISTRATION_SERVICE_ID || vehicleOrderServices?.registrationService.uuid === CHANGE_OF_OWNERSHIP_SERVICE_ID) &&
//                     <button className="btn checkout-btn" disabled={showDeliveryDetailsForm && !deliveryDetailsFormIsValid} onClick={makePayment}>Make Payment {requestPayment && <span className="spinner-border spinner-border-sm"></span>}</button>
//                 }

//                 {
//                     (vehicleOrderServices?.registrationService.uuid === RENEW_REGISTRATION_SERVICE_ID) &&
//                     <button className="btn checkout-btn" disabled={(showDeliveryDetailsForm && !deliveryDetailsFormIsValid) || selectedDocuments.length < 1} onClick={makePayment}>Make Payment {requestPayment && <span className="spinner-border spinner-border-sm"></span>}</button>
//                 } */}

//                 {
//                     (vehicleOrderServices?.registrationService.uuid === NEW_REGISTRATION_SERVICE_ID || vehicleOrderServices?.registrationService.uuid === CHANGE_OF_OWNERSHIP_SERVICE_ID) &&
//                     <button className="btn checkout-btn" disabled={vehicleOrderServices?.localGovernmentOrders.length === 0 && (showDeliveryDetailsForm && !deliveryDetailsFormIsValid)} onClick={makePayment}>Make Payment {requestPayment && <span className="spinner-border spinner-border-sm"></span>}</button>
//                 }

//                 {
//                     (vehicleOrderServices?.registrationService.uuid === RENEW_REGISTRATION_SERVICE_ID) &&
//                     <button className="btn checkout-btn" disabled={(showDeliveryDetailsForm && !deliveryDetailsFormIsValid) || selectedDocuments.length < 1} onClick={makePayment}>Make Payment {requestPayment && <span className="spinner-border spinner-border-sm"></span>}</button>
//                 }

//             </div>

//             <div className="col-12 col-md-6 col-lg-6 col-xl-5"></div>

//             <ToastContainer
//                 hideProgressBar={true}
//                 pauseOnHover
//                 autoClose={5000}
//             />

//         </div>
//         </>
//     )
// }

// export default RegistrationSummaryItem
