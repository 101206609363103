import React, { FC, useEffect, useState } from 'react' 
import './MakePayment.scss'
import { FileUploader } from 'react-drag-drop-files';
import jpg from 'Assets/Icons/jpg.png';
import png from 'Assets/Icons/png.png';
import pdf from 'Assets/Icons/pdf.svg';
import { orderService } from '../../../../Services';
import { ServiceOrderDataType } from '@/Models';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { firebaseStorage } from "Services/firebaseInitService";
import MoniepointLogo from "../../../../Assets/Images/moniepoint_logo.svg"
import SterlingLogo from "../../../../Assets/Images/sterling_logo.svg";
import { toast, ToastContainer } from 'react-toastify';
import CustomToastr from '../../../../Components/CustomToastr/CustomToastr';
import kitaniz_gif from "../../../../Assets/gif/icon_gif.gif";

interface propsType {
    orderDetails?: ServiceOrderDataType
}

interface IAccount {
    id: number;
    uuid: string;
    bankName: string;
    accountNumber: string;
    accountName: string;
    bankBranch: string;
    swiftCode: string;
    status: boolean;
    createdAt: string;
    updatedAt: string;
}

declare var $: any;

const MakePayment:FC<propsType> = ({ orderDetails }) => { 

    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(false);
    const [accounts, setAccounts] = useState<IAccount[]>([]);
    const [activeAccount, setActiveAccount] = useState<IAccount>();
    const [uploadedProofOfPayment, setUploadedProofOfPayment] = useState<boolean>(false);
    const [fileName, setFileName] = useState<string>('');
    const [uploadingProof, setUploadingProof] = useState<boolean>(false);
    const [file, setFile] = useState<File>();

    const fileTypes = ["JPG", "PNG"];
    
    const fileIcon:any = {
        'image/png': png,
        'image/jpg': jpg,
        'image/jpeg': jpg,
        'image/pdf': pdf
    }

    const handleUpload = (files: FileList) => {
        setUploadingProof(true)
        let file = files[0]
        setFile(file)
        setFileName(file?.name)
        const storageRef = ref(firebaseStorage, `files/${file.name}`); 
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on("state_changed",
            (snapshot) => {
                const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);

            },(error) => {
                console.log(error)
                setUploadingProof(false)
                alert(error);
            },() => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => { 
                    const payload = {
                        payment_receipt: downloadURL
                    }
                    uploadPaymnetProof(`${orderDetails?.uuid}`, payload)
                });
            }
            );
    };

    const uploadPaymnetProof = async (orderId: string, payload: {payment_receipt: string}) => {
        await orderService.uploadProofOfPayment(orderId, payload).then(
            (response) => {
              console.log(response);
              setUploadedProofOfPayment(true)
              setUploadingProof(false)
            },
            (error) => {
              setUploadingProof(false)
              Swal.fire({
                position: "top-end",
                icon: "warning",
                title: "Error",
                text: error?.response?.data?.error?.message,
                showConfirmButton: true,
              });
            }
          );
    }

    const changeAccount = (account: IAccount) => {
        setActiveAccount(account)
    }

    const copyAccountNumber = (accountNumber: string) => {
        navigator.clipboard.writeText(accountNumber)
        toast(<CustomToastr message={`Account number copied to clipboard`} />);
    }

    const fetchAccountDetails = async () => {
        setLoading(true)
        const payload = {
            orderId: `${orderDetails?.uuid}`,
            totalOrderAmount: Number(orderDetails?.totalAmount),
            paymentMethod: "MANUAL_BANK_TRANSFER"
          };
          await orderService.initiatePayment(payload).then(
            (response) => {
              console.log(response);
              setAccounts(response?.data?.data?.data)
              setLoading(false);
            },
            (error) => {
              setLoading(false);
              Swal.fire({
                position: "top-end",
                icon: "warning",
                title: "Error",
                text: error?.response?.data?.error?.message,
                showConfirmButton: true,
              });
            }
          );
    }

    const closePaymentModal = () => {
        $('#makePaymentModal').modal('hide')
        navigate({
            pathname: `/tracking`,
            search: `?code=${orderDetails?.orderReferenceCode}&made-payment=1`,
        });
    }

    const closeModal = () => {
        $('#makePaymentModal').modal('hide')
    }

    useEffect(() => {
        setActiveAccount(accounts[0])
    },[accounts])

    useEffect(() => {
        fetchAccountDetails()
    }, [])

    const getBankLogo = (bankName: string): string => {
        if(bankName.toLowerCase().includes('moniepoint')){
           return MoniepointLogo
        }
        else if(bankName.toLowerCase().includes('sterling')){
           return SterlingLogo
        }
        else{
            return ''
        }
    }


    return ( 
        <div className="modal-dialog">
            <div className="modal-content">
                <div className='make-payment-container'>
                    <div className="card-body mt-4">
                        <div className='d-flex justify-content-between title-container'>
                            <p>Pay with bank transfer</p>
                            <div className='cursor-pointer'>
                                <svg onClick={closeModal} xmlns="http://www.w3.org/2000/svg" width="30" height="31" viewBox="0 0 30 31" fill="none">
                                    <path d="M15 28.107C21.875 28.107 27.5 22.3578 27.5 15.3309C27.5 8.30397 21.875 2.55469 15 2.55469C8.125 2.55469 2.5 8.30397 2.5 15.3309C2.5 22.3578 8.125 28.107 15 28.107Z" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M11.4609 18.9462L18.5359 11.7148" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M18.5359 18.9462L11.4609 11.7148" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                        </div>
                        <p className='subtitle'>Please transfer to any of the accounts listed below</p>
                        {
                            loading ? 
                            <div className='d-flex justify-content-center loader-container'>
                                <img src={kitaniz_gif} height="250px"/>
                            </div>
                            :
                            <>
                                <div className='tab-header-container'>
                                    {
                                        accounts.map(account => {
                                            return (
                                                <div className='tab-header'>
                                                    <div className={account?.id === activeAccount?.id ? `active-tab-header d-flex gap-4px` : 'd-flex gap-4px'} key={account.id} onClick={() => changeAccount(account)}>
                                                        <img src={getBankLogo(account.bankName)} height='15px'></img>
                                                        <p className='cursor-pointer'>{account.bankName}</p>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <div className='account-number-container'>
                                    <div className='account-number-inner-container'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                                            <rect width="48" height="48" rx="24" fill="#0E1F33" fill-opacity="0.1"/>
                                            <path d="M29.0802 22.649L28.5525 25.2519H30.8143V28.3118H27.9305L27.3084 31.3519H24.2927L24.9147 28.3118H21.7858L21.1638 31.3519H18.1481L18.7701 28.3118H15.9805V25.2519H19.3921L19.9198 22.649H17.1868V19.5891H20.523L21.1261 16.6484H24.1419L23.5387 19.5891H26.6676L27.2708 16.6484H30.2865L29.6834 19.5891H32.0206V22.649H29.0802ZM26.0644 22.649H22.9356L22.4078 25.2519H25.5367L26.0644 22.649Z" fill="black"/>
                                        </svg>
                                        <div>
                                            <p className='info-title'>Account Number</p>
                                            <p className='info-value'>{activeAccount?.accountNumber}</p>
                                        </div>
                                    </div>
                                    <div>
                                        <button className='copy-btn' onClick={() => copyAccountNumber(`${activeAccount?.accountNumber}`)}>
                                            Copy
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                <path d="M8.325 17.0625H5.175C2.2425 17.0625 0.9375 15.7575 0.9375 12.825V9.675C0.9375 6.7425 2.2425 5.4375 5.175 5.4375H8.325C11.2575 5.4375 12.5625 6.7425 12.5625 9.675V12.825C12.5625 15.7575 11.2575 17.0625 8.325 17.0625ZM5.175 6.5625C2.85 6.5625 2.0625 7.35 2.0625 9.675V12.825C2.0625 15.15 2.85 15.9375 5.175 15.9375H8.325C10.65 15.9375 11.4375 15.15 11.4375 12.825V9.675C11.4375 7.35 10.65 6.5625 8.325 6.5625H5.175Z" fill="#186AFF"/>
                                                <path d="M12.825 12.5625H12C11.6925 12.5625 11.4375 12.3075 11.4375 12V9.675C11.4375 7.35 10.65 6.5625 8.325 6.5625H6C5.6925 6.5625 5.4375 6.3075 5.4375 6V5.175C5.4375 2.2425 6.7425 0.9375 9.675 0.9375H12.825C15.7575 0.9375 17.0625 2.2425 17.0625 5.175V8.325C17.0625 11.2575 15.7575 12.5625 12.825 12.5625ZM12.5625 11.4375H12.825C15.15 11.4375 15.9375 10.65 15.9375 8.325V5.175C15.9375 2.85 15.15 2.0625 12.825 2.0625H9.675C7.35 2.0625 6.5625 2.85 6.5625 5.175V5.4375H8.325C11.2575 5.4375 12.5625 6.7425 12.5625 9.675V11.4375Z" fill="#186AFF"/>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                                <div className='account-name-container'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                                        <circle cx="24" cy="24" r="24" fill="#0E1F33" fill-opacity="0.1"/>
                                        <path d="M24.6461 24.6477C27.3329 24.6477 29.511 22.4696 29.511 19.7828C29.511 17.096 27.3329 14.918 24.6461 14.918C21.9593 14.918 19.7812 17.096 19.7812 19.7828C19.7812 22.4696 21.9593 24.6477 24.6461 24.6477Z" fill="#292D32"/>
                                        <path d="M24.6451 27.0801C19.7705 27.0801 15.8008 30.3493 15.8008 34.3774C15.8008 34.6498 16.0148 34.8639 16.2873 34.8639H33.0029C33.2754 34.8639 33.4894 34.6498 33.4894 34.3774C33.4894 30.3493 29.5197 27.0801 24.6451 27.0801Z" fill="#292D32"/>
                                    </svg>
                                    <div>
                                        <p className='info-title'>Name</p>
                                        <p className='info-value'>{activeAccount?.accountName}</p>
                                    </div>
                                </div>
                                {
                            uploadingProof ? 
                            <div className='d-flex justify-content-center loader-container'>
                                <img src={kitaniz_gif} height="70px"/>
                            </div> :
                            <div>
                                <FileUploader handleChange={(e:any) => handleUpload(e)} name="file" types={fileTypes}  multiple={true} classes="text-center w-100  mt-1">
                                    <div className="file-uploader-container cursor-pointer">
                                        <svg width="20" height="20" className='me-2' viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_4956_44565)">
                                                <path d="M16.5004 16.5014L12.5004 12.5014M12.5004 12.5014L8.50045 16.5014M12.5004 12.5014V21.5014M20.8904 18.8914C21.8658 18.3597 22.6363 17.5183 23.0803 16.5001C23.5244 15.4818 23.6167 14.3447 23.3427 13.2681C23.0686 12.1916 22.4439 11.237 21.5671 10.5549C20.6903 9.87283 19.6113 9.50218 18.5004 9.50145H17.2404C16.9378 8.33069 16.3736 7.24378 15.5904 6.32244C14.8072 5.4011 13.8253 4.6693 12.7185 4.18206C11.6118 3.69481 10.409 3.46481 9.20057 3.50933C7.99213 3.55385 6.80952 3.87175 5.74163 4.43911C4.67374 5.00648 3.74836 5.80855 3.03507 6.78503C2.32178 7.76151 1.83914 8.88699 1.62343 10.0768C1.40772 11.2667 1.46455 12.49 1.78966 13.6547C2.11477 14.8194 2.69969 15.8953 3.50045 16.8014" stroke="#186AFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_4956_44565">
                                                    <rect width="24" height="24" fill="white" transform="translate(0.5 0.5)"/>
                                                </clipPath>
                                            </defs>
                                        </svg> 
                                        Upload Proof of Payment
                                    </div>
                                </FileUploader>
                            </div>
                        }
                        {
                            uploadedProofOfPayment &&
                            <div className='d-flex justify-content-center proof-name'>
                                <div className='proof-name-inner-container'>
                                    <img src={fileIcon[`${file?.type}`]} alt="" height="15px"/>
                                    <p>{fileName}</p>
                                    <div className='cursor-pointer'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 30 31" fill="none">
                                            <path d="M15 28.107C21.875 28.107 27.5 22.3578 27.5 15.3309C27.5 8.30397 21.875 2.55469 15 2.55469C8.125 2.55469 2.5 8.30397 2.5 15.3309C2.5 22.3578 8.125 28.107 15 28.107Z" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M11.4609 18.9462L18.5359 11.7148" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M18.5359 18.9462L11.4609 11.7148" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        }
                        <button disabled={!uploadedProofOfPayment} className='btn checkout-btn' onClick={closePaymentModal}>I have made payment</button>
                            </>
                        }
                    </div>
                </div>
            </div>
          <ToastContainer hideProgressBar={true} pauseOnHover autoClose={5000} />
        </div>
    )
}

export default MakePayment;