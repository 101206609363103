import { NewOwnersInformationForm } from 'Pages/Frontend/Components';
import './NewOwnersInformation.scss';


const NewOwnersInformation = () => {
    return (
        <div className="NewOwnersInformation">
            
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-5 my-5">
                       <div className="card border-0">
                        <div className="card-header text-center border-0 pt-4"> 
                            <div className="title">
                                New Owner’s Information
                            </div>
                        </div>
                        <div className="card-body"> 
                            <NewOwnersInformationForm></NewOwnersInformationForm>
                        </div>
                       </div>
                    </div>
                </div>
             </div>
        </div>
    )
}

export default NewOwnersInformation;