import { RegistrationMenuOption } from 'Pages/Frontend/Components'
import React from 'react'
import "./RegistrationHome.scss"

const RegistrationHome = () => {
    return (
        <div className='RegistrationHome'> 
           <RegistrationMenuOption module="user"></RegistrationMenuOption>
        </div>
    )
}

export default RegistrationHome
