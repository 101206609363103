export function TitleCase (str: string) {
    const minorWords = ["and", "or", "the", "of", "in", "on", "a", "an"];
    return str
      .split(" ")
      .map((word, index) =>
        // Always capitalize the first word, otherwise check for minor words
        index === 0 || !minorWords.includes(word.toLowerCase())
          ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
          : word.toLowerCase()
      )
      .join(" ");
}