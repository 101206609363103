import React, { useState } from 'react'
import "./Password.scss"
import { useForm } from 'react-hook-form'; 
import { getAuth, updatePassword } from 'firebase/auth';
import Swal from 'sweetalert2';
import { useAuth } from 'Components/AuthProvider/AuthProvider';

interface formType {
    currentPassword: string;
    newPassword: string; 
    confirmPassword: string;
}
const Password = () => {
    const [request, setRequest] = useState(false)

    const auth = getAuth(); 
    const { logout } = useAuth()

    const { register, handleSubmit, formState: { errors }, watch } = useForm<formType>();
    const newPassword = watch("newPassword", "");  

    const onSubmit = handleSubmit((data: formType) => {
        const { newPassword } = data;
        setRequest(true)

        const user = auth.currentUser; 
        if(user){
            updatePassword(user, newPassword).then(() => {
                setRequest(false)
                Swal.fire({
                    position: "top-end",
                    icon: "success", 
                    showConfirmButton: false,
                    timer: 1600
                  });

                  setTimeout(()=>{
                    logout()
                  }, 3000)
            }, (error) => {
                setRequest(false) 
                console.log("error", error)
                Swal.fire('Oops!', error.message, 'warning'); 
            });
        }
    });

    return ( 
        <div className="Password">
            <div className="Password-header d-flex justify-content-start align-items-center my-3">
                <div className="Password-pg-title">Change Password</div>
            </div>
            <div className="">
                <form onSubmit={onSubmit}>
                    <div className="row justify-content-start">
                        <div className="col-md-6">
                            <div className="form-group mt-0">
                                <label htmlFor="currentPassword">Current Password</label>
                                <input type="password" className="form-control" {...register("currentPassword", { required: "Field is required" })} />
                                {errors.currentPassword && <small className="error">{errors.currentPassword.message}</small>}
                            </div>
                            <div className="form-group mt-3">
                                <label htmlFor="newPassword">New Password</label>
                                <input type="password" className="form-control" {...register("newPassword", { required: "Field is required" })} />
                                {errors.newPassword && <small className="error">{errors.newPassword.message}</small>}
                            </div>
                            <div className="form-group mt-3">
                                <label htmlFor="confirmPassword">Confirm Password</label>
                                <input type="password" className="form-control" {...register("confirmPassword", {
                                    required: "Field is required",
                                    validate: value => value === newPassword || "Passwords do not match"
                                })} />
                                {errors.confirmPassword && <small className="error">{errors.confirmPassword.message}</small>}
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <button className='btn btn-default' type='submit'> {request && <span className='spinner-border spinner-border-sm'></span>} Change Password</button>
                    </div>
                </form>
            </div>
        </div> 
     );
}
 
export default Password;