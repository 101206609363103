import { deliveryEditType, deliveryType } from "Models/deliveryType";
import axios from "axios"
import { AxiosInterceptor } from "Config/AxiosInterceptor"
AxiosInterceptor()

export const userService = {
   saveUser: async (token:string) => {

      const config = {
         headers: {
           'Authorization': `Bearer ${token}` // Set the Authorization header with the bearer token
         }
       };

    return await axios.post('/user/profile', {}, config)
   },
   
   verifyUser: async (token:string) => {
      const config = {
         headers: {
           'Authorization': `Bearer ${token}` // Set the Authorization header with the bearer token
         }
       };
      return await axios.put('/user', {}, config)
   },

   getUserProfile: async (token:string, email:string | null)=>{
      const config = {
         headers: {
           'Authorization': `Bearer ${token}` // Set the Authorization header with the bearer token
         }
       };

      return await axios.get(`/user?email=${email}`, config)
   },

   createDeliveryAddress: async (data: Array<deliveryType>)=>{
      return await axios.post('/delivery/detail', data)
   },

   editDeliveryAddress: async (deliveryId:string|undefined, data: Array<deliveryEditType>)=>{
      return await axios.patch(`/delivery/detail/${deliveryId}`, data)
   },

   getDeliveryAddress: async (limit:number, offset: number)=>{
      return await axios.get(`/delivery/detail?limit=${limit}&offset=${offset}`)
   },

   makeDefaultDeliveryAddress: async (deliveryId:string|undefined)=>{
      return await axios.patch(`/delivery/detail/${deliveryId}`, [{ key: 'primary', value: true }])
   },

   deleteDeliveryAddress: async (deliveryId:string|undefined)=>{
      return await axios.delete(`/delivery/detail/${deliveryId}`)
   },

   createGuestUser: async () =>{
      return await axios.post('/user/guest/create')
   },

   createGuestUserToken: async (userUuid: string) =>{
      return await axios.post('/user/guest/token', { uid: userUuid })
   },

}