import React from 'react'
import "./CoSummaryGuest.scss"
import { BreadCrumb } from 'Components' 
import { CoSummaryAuthItem } from 'Pages/Frontend/Components';

const CoSummaryGuest = () => {
    return (
        <div className='CoSummary'> 
            <div className="container">
                <BreadCrumb parentTitle={'Registration'} parentUrl={'/register-vehicle'} pageTitle={'New Registration'} childTitle={'Summary'}></BreadCrumb>
            
                <CoSummaryAuthItem></CoSummaryAuthItem>
           </div>
        </div>
    )
}

export default CoSummaryGuest
