import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; 
import { useAuth } from './AuthProvider';
import { signInWithCustomToken } from 'firebase/auth';
import { auth } from 'Services/firebaseInitService';

const guestUserMiddleware = <P extends object>(WrappedComponent: React.ComponentType<P>) => {
  return (props: P) => {
    const { user, getTemporaryToken } = useAuth();
    const navigate = useNavigate();

    const checkAuth = async () => { 
      if (!user) {  
        try {
          const token = await getTemporaryToken();
          if(token && token.data){

            console.log('pre Temporary token:', token.data);
            const userCredential = await signInWithCustomToken(auth, token.data.customToken
            );
            const idToken = await userCredential.user.getIdToken();
           
            console.log('Temporary token:', idToken);

            sessionStorage.setItem('ktGuestToken', JSON.stringify({ token: idToken, userId: token.data.userId} ));
            window.location.reload()
          }
          
        } catch (error) {
          console.error('Failed to get temporary token:', error);
          // navigate('/login');
        }
      } 
   };

    useEffect(() => { 
      checkAuth();
    }, [user, getTemporaryToken, navigate]);
 
    return <WrappedComponent {...props} />;
  };
};

export default guestUserMiddleware;
